import '@ag-grid-community/all-modules/dist/styles/ag-grid.css'
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css'
import { AllModules, ModuleRegistry } from '@ag-grid-enterprise/all-modules'
import { useLazyQueryMasterQueuedLink } from '@curvo/apollo'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { FetchDataParams, ServerSideGrid } from '../../../components/ServerSideGrid'
import { columns } from './QueuedLinkMasterGridColumns'

const QUEUED_LINK_MASTER_GRID = 'QUEUED_LINK_MASTER_GRID'

ModuleRegistry.registerModules(AllModules)
export const QueuedLinkMasterGrid: React.FC = () => {
  const [getData, { loading }] = useLazyQueryMasterQueuedLink({ notifyOnNetworkStatusChange: true })

  const fetchData = useCallback(
    (request: FetchDataParams) => {
      return getData({
        variables: {
          selectedFields: request.selectedFields,
          startRow: request.startRow,
          endRow: request.endRow,
          sorting: request.sorting,
          filter: request.filter,
        },
      }).then(resp => ({
        data: resp.data?.masterStudyLinks.edges.map(e => e.node) || [],
        total: resp.data?.masterStudyLinks.metadata.total || 0,
      }))
    },
    [getData],
  )

  return (
    <Container>
      <ServerSideGrid gridKey={QUEUED_LINK_MASTER_GRID} columns={columns} fetching={loading} fetchData={fetchData} />
    </Container>
  )
}

const Container = styled.div`
  margin: 24px;
`
