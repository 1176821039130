import React, { useEffect, useImperativeHandle, useState } from 'react'
import { Select } from 'antd'
import { ConstructsData, PurchaseOrder } from '@curvo/apollo'
import { LabeledValue, SelectProps } from 'antd/lib/select'
import { StyledSelect, StyledSelectPropsType } from '../../Update/components/Select/common'
import { AgWrapperPropsType } from './ManufacturerSelectAgWrapper'

const { Option } = Select

export const GradingConstructsSelect: React.FC<SelectProps<LabeledValue> & StyledSelectPropsType> = props => (
  <ConstructsData>
    {({ data, loading }) => (
      <StyledSelect
        loading={loading}
        showSearch
        labelInValue
        filterOption={(inputValue: string, option: React.ReactElement) => {
          const label = option?.props?.label || ''
          const searchLabel = inputValue.toLowerCase() === inputValue ? label.toLowerCase() : label
          return searchLabel.indexOf(inputValue) > -1
        }}
        showArrow={false}
        defaultActiveFirstOption={false}
        placeholder="Select Construct"
        {...props}>
        {data &&
          data.gradingConstructs &&
          data.gradingConstructs.map(construct => (
            <Option key={construct.id} label={`${construct.conId}|${construct.conName}`}>
              {construct.conId}|{construct.conName}
            </Option>
          ))}
      </StyledSelect>
    )}
  </ConstructsData>
)

export const GradingConstructSelectAgWrapper = React.forwardRef<{}, AgWrapperPropsType<PurchaseOrder>>(
  (params, ref) => {
    const { data, api } = params

    const [labeledValue, setLabeledValue] = useState<LabeledValue>()
    useImperativeHandle(ref, () => ({
      getValue: () => labeledValue || undefined,
    }))
    useEffect(() => {
      if (labeledValue) {
        api.stopEditing()
      }
    }, [labeledValue, api])

    const value = data && data.construct ? { key: data.construct.id, label: data.construct.conName } : undefined

    return <GradingConstructsSelect value={value} allowClear onChange={v => setLabeledValue(v)} />
  },
)
