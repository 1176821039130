// import styled from 'styled-components'
import {
  deleteMaterialMutation,
  Material,
  materialCreateMutation,
  MaterialsData,
  MaterialsOrderByEnum,
  MaterialsQueryArgs,
  MATERIALS_QUERY,
  materialUpdateMutation,
  SortOrderEnum,
} from '@curvo/apollo'
import { Button, message, Pagination, Popconfirm, Table } from 'antd'
import { useState } from 'react'
import { ResizableHeader } from '../../../components/ResizableColumnHeader'
import {
  ColumnGenFunctionType,
  PageWrapper,
  PaginationContainer,
  RedA,
  SearchWrapper,
  StyledSearchInput,
} from './common'
import { CreateMaterialDrawer } from './components/EditPanel/CreateMaterial'
import { EditMaterial } from './components/EditPanel/EditMaterial'

export const Materials = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [limitPerPage, setLimitPerPage] = useState(10)
  const [queryArgs, setQueryArgs] = useState<MaterialsQueryArgs>({
    first: limitPerPage,
    skip: 0,
  })
  const [selected, setSelected] = useState<Material>()
  const [openNew, setOpenNew] = useState(false)
  const [columns, setColumns] = useState(
    columnsGen(
      record => {
        setSelected(record)
      },
      record => {
        deleteMaterialMutation(
          { id: record.id },
          { refetchQueries: [{ query: MATERIALS_QUERY, variables: queryArgs }] },
        )
      },
    ),
  )
  const handleUpdateColumnSize = (index: number) => (size: number) => {
    const nextCols = [...columns]
    nextCols[index].width = size
    setColumns(nextCols)
  }
  return (
    <PageWrapper>
      <MaterialsData variables={queryArgs} fetchPolicy="network-only" selfHandleError>
        {({ data, error, loading }) => {
          if (error) {
            message.error(error.message)
          }
          const materials = data && data.materials ? data.materials.edges.map(edge => edge.node) : []
          const totalPages = data && data.materials ? data.materials.metadata.total : 0
          return (
            <div>
              <SearchWrapper>
                <StyledSearchInput
                  placeholder="Search Material by name"
                  enterButton
                  onSearch={searchText => {
                    setQueryArgs(query => ({
                      ...query,
                      searchText,
                    }))
                  }}
                />
                <Button
                  type="primary"
                  icon="plus"
                  onClick={() => {
                    setOpenNew(true)
                  }}>
                  New Material
                </Button>
              </SearchWrapper>
              <Table
                bordered
                loading={loading}
                columns={columns.map((col, i) => ({
                  ...col,
                  onHeaderCell: () => ({ width: col.width, onResize: handleUpdateColumnSize(i) }),
                }))}
                components={{ header: { cell: ResizableHeader } }}
                dataSource={materials}
                rowKey={record => record.id.toString()}
                pagination={false}
                onChange={(_, __, sorter) => {
                  setQueryArgs({
                    ...queryArgs,
                    orderBy: sorter.columnKey ? (sorter.columnKey as MaterialsOrderByEnum) : MaterialsOrderByEnum.Id,
                    direction: sorter.order === 'descend' ? SortOrderEnum.Desc : SortOrderEnum.Asc,
                  })
                }}
              />
              <PaginationContainer>
                <Pagination
                  total={totalPages}
                  defaultCurrent={currentPage}
                  onChange={pageNumber => {
                    setCurrentPage(pageNumber)
                    setQueryArgs(query => ({
                      ...query,
                      first: limitPerPage,
                      skip: (pageNumber - 1) * limitPerPage,
                    }))
                  }}
                  showSizeChanger
                  onShowSizeChange={(_, size) => setLimitPerPage(size)}
                />
              </PaginationContainer>
              <EditMaterial
                visible={!!selected}
                onCancel={() => setSelected(undefined)}
                onSubmit={editing => {
                  materialUpdateMutation({
                    input: editing,
                  })
                  setSelected(undefined)
                }}
                material={selected}
              />
              <CreateMaterialDrawer
                visible={openNew}
                onCancel={() => setOpenNew(false)}
                onSubmit={creating => {
                  materialCreateMutation(
                    { input: creating },
                    {
                      refetchQueries: [{ query: MATERIALS_QUERY, variables: queryArgs }],
                    },
                  ).then(() => message.success('Created'))
                  setOpenNew(false)
                }}
                width={250}
              />
            </div>
          )
        }}
      </MaterialsData>
    </PageWrapper>
  )
}

const columnsGen: ColumnGenFunctionType<Material> = (onEdit, onDelete) => [
  {
    title: 'ID',
    key: 'id',
    width: 50,
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: 'Name',
    key: 'name',
    width: 100,
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: 'Long name',
    key: 'longName',
    width: 200,
    dataIndex: 'longName',
  },
  {
    title: 'Action',
    width: 150,
    key: 'action',
    render: (_text, record) => (
      <span>
        <Button type="link" onClick={() => onEdit(record)}>
          Edit
        </Button>
        <span> | </span>
        <Popconfirm
          title={`Are you sure you want to delete Material ${record.name}?`}
          okText="Yes"
          cancelText="No"
          onConfirm={() => onDelete && onDelete(record)}>
          <RedA>Delete</RedA>
        </Popconfirm>
      </span>
    ),
  },
]
