import { ValueFormatterParams } from '@ag-grid-community/core'

export const currencyFormatter = (params: ValueFormatterParams) => {
  return params.value && (typeof params.value === 'number' || typeof params.value === 'string')
    ? `$${formatNumber(params.value)}`
    : ''
}

function formatNumber(num: number | string) {
  const finalString = typeof num === 'string' ? num : num.toFixed(2)
  return finalString.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
