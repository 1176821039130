import {
  AllowedGicsTypeOneData,
  createGicImageMutation,
  deleteGicImageMutation,
  Gic,
  GicImage,
  GicUpdateInput,
  GIC_IMAGES,
  ListItemUpdateType,
  useGicImagesData,
} from '@curvo/apollo'
import { Col, Form, Icon, Input, message, Row } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import { LabeledValue } from 'antd/lib/select'
import { UploadFile } from 'antd/lib/upload/interface'
import { omit } from 'lodash'
import React, { useEffect, useState } from 'react'
import { EditPanelWrapper, emptyOrReturn } from '../../../../../components/common'
import { EditDrawer } from '../../../../../components/EditDrawer'
import { S3Uploader } from '../../../Upload/components/Panel/S3Uploader'
import { GicMaterial } from '../Select/GICMaterialSelect'
import { GICsTypeOneSelect } from '../Select/GICTypeOneSelect'

export const EditGIC: React.FunctionComponent<
  {
    gic?: Gic
    onSubmit: (editingPart: GicUpdateInput) => Promise<void>
    onCancel: () => void
  } & DrawerProps
> = ({ gic, onSubmit, onCancel, ...drawerProps }) => {
  const [updatingState, setUpdatingState] = useState(false)
  const [updatingGic, setUpdatingGic] = useState<GicUpdateInput>({
    id: (gic && gic.id) || 0,
    allowedGicTypeOnes: [],
    allowedGicMaterials: [],
  })

  const { data: gicImagesData } = useGicImagesData({
    variables: {
      id: gic?.id!,
    },
    skip: !gic,
  })

  const gicImages = gicImagesData?.gicImages || []

  const onUpdateField =
    (field: keyof GicUpdateInput) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setUpdatingGic({
        ...updatingGic,
        [field]: e.target.value,
      })
    }

  useEffect(() => {
    if (gic) {
      setUpdatingGic(old => ({
        ...old,
        ...omit<Omit<Gic, 'allowedGicTypeOnes'>>(gic, ['__typename', 'imageUrl', 'inputName', 'allowedGicTypeOnes']),
      }))
    }
  }, [gic])

  const onUpdateAllowedGicsTypeOne = (mode: 'select' | 'deselect') => (value: LabeledValue) => {
    const originUpdate = updatingGic.allowedGicTypeOnes || []
    const action = mode === 'select' ? ListItemUpdateType.Add : ListItemUpdateType.Delete
    const revertAction = mode === 'select' ? ListItemUpdateType.Delete : ListItemUpdateType.Add
    const remainUpdate = originUpdate.filter(
      typeOneUpdate => typeOneUpdate.updateType !== revertAction || typeOneUpdate.gicTypeOneId !== value.key,
    )
    if (remainUpdate.length < originUpdate.length) {
      setUpdatingGic({
        ...updatingGic,
        allowedGicTypeOnes: remainUpdate,
      })
    } else {
      setUpdatingGic({
        ...updatingGic,
        allowedGicTypeOnes: originUpdate.concat([
          {
            gicTypeOneId: value.key,
            updateType: action,
          },
        ]),
      })
    }
  }

  const onUpdateAllowedGicsMaterial = (mode: 'select' | 'deselect') => (value: LabeledValue) => {
    const originUpdate = updatingGic.allowedGicMaterials || []
    const action = mode === 'select' ? ListItemUpdateType.Add : ListItemUpdateType.Delete
    const revertAction = mode === 'select' ? ListItemUpdateType.Delete : ListItemUpdateType.Add
    const remainUpdate = originUpdate.filter(
      typeOneUpdate => typeOneUpdate.updateType !== revertAction || typeOneUpdate.gicMaterialId !== value.key,
    )
    if (remainUpdate.length < originUpdate.length) {
      setUpdatingGic({
        ...updatingGic,
        allowedGicMaterials: remainUpdate,
      })
    } else {
      setUpdatingGic({
        ...updatingGic,
        allowedGicMaterials: originUpdate.concat([
          {
            gicMaterialId: value.key,
            updateType: action,
          },
        ]),
      })
    }
  }

  return (
    <AllowedGicsTypeOneData variables={{ gicId: (gic && gic.id) || 0 }}>
      {({ data, error }) => {
        if (error) {
          message.error(error.message)
        }
        const allowedGicsTypeOne = ((data && data.allowedGicsTypeOne) || []).map(typeOne => ({
          key: typeOne.id,
          label: typeOne.name,
        }))
        const genAllowedGicsTypeOneValue = () =>
          (updatingGic.allowedGicTypeOnes || []).reduce((prev, cur) => {
            if (cur.updateType === ListItemUpdateType.Add) {
              return prev.concat([{ key: cur.gicTypeOneId, label: cur.gicTypeOneId }])
            }
            return prev.filter(p => p.key !== cur.gicTypeOneId)
          }, allowedGicsTypeOne)

        return (
          <EditDrawer
            onCancel={onCancel}
            onSave={() => {
              setUpdatingState(true)
              onSubmit(updatingGic).finally(() => {
                setUpdatingState(false)
                setUpdatingGic({
                  id: (gic && gic.id) || 0,
                  allowedGicTypeOnes: [],
                  allowedGicMaterials: [],
                })
              })
            }}
            isDisabled={updatingState}
            {...drawerProps}
            title={gic && `Edit GIC ${gic.id} (${gic.name})`}>
            <EditPanelWrapper>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Name">
                    <Input value={emptyOrReturn(updatingGic.name, gic && gic.name)} onChange={onUpdateField('name')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Form.Item label="Includes">
                  <Input.TextArea
                    value={emptyOrReturn(updatingGic.includes, (gic && gic.includes) || '')}
                    onChange={onUpdateField('includes')}
                  />
                </Form.Item>
              </Row>
              <Row gutter={16}>
                <Form.Item label="Excludes">
                  <Input.TextArea
                    value={emptyOrReturn(updatingGic.excludes, (gic && gic.excludes) || '')}
                    onChange={onUpdateField('excludes')}
                  />
                </Form.Item>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label="Size One Description">
                    <Input
                      value={emptyOrReturn(updatingGic.sizeOneDescription, (gic && gic.sizeOneDescription) || '')}
                      onChange={onUpdateField('sizeOneDescription')}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Size Two Description">
                    <Input
                      value={emptyOrReturn(updatingGic.sizeTwoDescription, (gic && gic.sizeTwoDescription) || '')}
                      onChange={onUpdateField('sizeTwoDescription')}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Size Three Description">
                    <Input
                      value={emptyOrReturn(updatingGic.sizeThreeDescription, (gic && gic.sizeThreeDescription) || '')}
                      onChange={onUpdateField('sizeThreeDescription')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Type One">
                    <GICsTypeOneSelect
                      value={genAllowedGicsTypeOneValue()}
                      mode="multiple"
                      onSelect={v => onUpdateAllowedGicsTypeOne('select')(v as LabeledValue)}
                      onDeselect={v => onUpdateAllowedGicsTypeOne('deselect')(v as LabeledValue)}
                      createNewIfNotFound={true}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Material">
                    <GicMaterial
                      gic={gic}
                      allowedGicMaterials={updatingGic.allowedGicMaterials}
                      onSelect={v => onUpdateAllowedGicsMaterial('select')(v as LabeledValue)}
                      onDeselect={v => onUpdateAllowedGicsMaterial('deselect')(v as LabeledValue)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item label="Gic Picture">
                <div className="clearfix">
                  <S3Uploader
                    isDragger={false}
                    listType="picture-card"
                    fileList={gicImagesToFileUpload(gicImages)}
                    accept=".jpg"
                    onRemove={e => {
                      if (gic) {
                        deleteGicImageMutation(
                          { id: e.uid },
                          { refetchQueries: [{ query: GIC_IMAGES, variables: { id: gic.id } }] },
                        )
                      }
                    }}
                    onUploaded={async e => {
                      if (gic) {
                        await createGicImageMutation(
                          {
                            input: {
                              imageUrl: e,
                              gicId: gic.id,
                            },
                          },
                          { refetchQueries: [{ query: GIC_IMAGES, variables: { id: gic.id } }] },
                        )
                      }
                    }}>
                    <div className="ant-upload-list-picture-card-container">
                      <Icon type="plus" />
                      <div className="ant-upload-text">Upload</div>
                    </div>
                  </S3Uploader>
                </div>
              </Form.Item>
            </EditPanelWrapper>
          </EditDrawer>
        )
      }}
    </AllowedGicsTypeOneData>
  )
}

export const gicImagesToFileUpload = (gicImages: GicImage[]): UploadFile[] =>
  gicImages.map(gicImage => ({
    url: gicImage.imageUrl,
    status: 'done',
    uid: gicImage.id,
    name: gicImage.imageUrl,
    size: 0,
    type: 'jpg',
  }))
