import React from 'react'
import { IDoesFilterPassParams, IFilterComp, IFilterParams } from '@ag-grid-community/core'
import { Radio } from 'antd'
import { StudyLinkCatalogEnum } from '@curvo/apollo'
import { getCatalogPrefix } from '../Grooming2'

export class MovedFromAIFilter
  extends React.PureComponent<IFilterParams & { catalog: StudyLinkCatalogEnum }, { isActive?: boolean }>
  implements IFilterComp
{
  state = {
    isActive: false,
  }

  isFilterActive() {
    return true
  }

  getGui() {
    return document.createElement('div')
  }

  getModel() {
    return this.state.isActive
  }

  setModel(isActive: boolean) {
    this.setState({
      isActive,
    })
  }

  toggleFilter(isActive: boolean) {
    this.setState({ isActive }, () => this.props.filterChangedCallback())
  }

  doesFilterPass(params: IDoesFilterPassParams) {
    if (!this.state.isActive) {
      return true
    }
    const rowValue = params.data
    return rowValue && !!rowValue[`bamf${getCatalogPrefix(this.props.catalog)}Id`]
  }

  render() {
    return (
      <div style={{ margin: '1em', display: 'flex', flexDirection: 'column' }}>
        <label>Display only Bamf Matched Parts?</label>
        <Radio.Group onChange={v => this.toggleFilter(v.target.value)} value={!!this.state.isActive}>
          <Radio value={false}>All</Radio>
          <Radio value={true}>Only</Radio>
        </Radio.Group>
      </div>
    )
  }
}
