import React from 'react'
import { Routes } from 'react-router'
import { Route } from 'react-router-dom'
import { NewParts } from './NewParts'
import { PriceFile } from './PriceFile'

export const UploadPage: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path={'/new-parts/*'} element={<NewParts />} />
      <Route path={'/price-file/*'} element={<PriceFile />} />
    </Routes>
  )
}
