import '@ag-grid-community/all-modules/dist/styles/ag-grid.css'
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css'
import {
  ColDef,
  ColumnState,
  DragStoppedEvent,
  GridReadyEvent,
  ProcessCellForExportParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from '@ag-grid-community/core'
import { AgGridColumnProps, AgGridReact } from '@ag-grid-community/react'
import { AllModules, GridApi } from '@ag-grid-enterprise/all-modules'
import {
  ADVANCED_PARTS_SEARCH_QUERY,
  ADVANCED_PARTS_SEARCH_QUERY_WITHOUT_PAGINATION,
  Client,
  PARTS_QUERY,
  PARTS_WITHOUT_PAGINATION_QUERY,
  Part,
  PartCreateInput,
  PartUpdateInput,
  PartsOrderByEnum,
  PartsQueryArgs,
  SortOrderEnum,
  bulkPartUpdateMutation,
  createPartMutation,
} from '@curvo/apollo'
import { Flex } from '@curvo/common-ui'
import { Button, DatePicker, Input, Pagination, Progress, message } from 'antd'
import Select, { LabeledValue } from 'antd/lib/select'
import { pick, uniqBy } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { PageWrapper, PaginationContainer } from './common'
import AdvancedSearchModal, { LOCAL_STORAGE_KEY, Values, getSearchParams } from './components/AdvancedSearchModal'
import { EditMode } from './components/EditPanel/EditManufacturer'
import { EditPart } from './components/EditPanel/EditPart'
import { GICSelect } from './components/Select/GICSelect'
import { ManufacturerSelect } from './components/Select/ManufacturerSelect'
import { UserSelect } from './components/Select/UserSelect'
import { StyledSelect } from './components/Select/common'
import {
  SelectBrandAgWrapper,
  SelectGicLineAgWrapper,
  SelectLiteralAgWrapper,
  SelectManufacturerAgWrapper,
  SelectMaterialAgWrapper,
  SelectProductLineAgWrapper,
  SelectSegmentationAgWrapper,
  SelectTypeOneAgWrapper,
  SelectTypeTwoAgWrapper,
} from './components/SelectAgWrapper'

const { RangePicker } = DatePicker

type AgCustomSetter = ((params: ValueSetterParams) => boolean) | string

const { Search } = Input

const ROWS_PER_LOAD = 200
const MAX_CONNECTION = 4
const PART_GRID = 'PART_GRID'

function useParallelLoadPartsData(
  query: PartsQueryArgs,
  gridRef: React.RefObject<AgGridReact & { api: GridApi }>,
  isAdvancedMode: boolean,
  advancedParams?: Values,
) {
  const [total, setTotal] = useState(0)
  const [allTotal, setAllTotal] = useState(0)
  const [parts, setParts] = useState<{ node: Part }[]>([])
  const [availableConnection, setAvailableConnection] = useState(1)
  const [loadOffset, setLoadOffset] = useState(0)
  const [error, setError] = useState<Error>()

  const queryId = useRef(1)

  const memoQueryArgs = useRef<PartsQueryArgs>()

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.api.setRowData([])
    }

    if (memoQueryArgs.current !== query) {
      memoQueryArgs.current = query
    }

    queryId.current += 1
    setTotal(-1)
    setParts([])
    setAvailableConnection(1)
    setLoadOffset(query.skip || 0)
  }, [query, gridRef, advancedParams, isAdvancedMode])

  const countPartsData = useCallback(
    async (args: PartsQueryArgs) => {
      try {
        const currentQueryId = queryId.current
        const limitData = Math.min(query.first || ROWS_PER_LOAD, ROWS_PER_LOAD)
        const { data } = await Client.getClient().query({
          query: isAdvancedMode && advancedParams ? ADVANCED_PARTS_SEARCH_QUERY : PARTS_QUERY,
          variables: {
            ...(isAdvancedMode && advancedParams ? getSearchParams(advancedParams) : query),
            first: limitData,
            skip: query.skip || 0,
            orderBy: {
              sort: PartsOrderByEnum.StrippedPartNumberNoZero,
              direction: SortOrderEnum.Asc,
            },
            // simpleSearch: true, // disable this for faster performance as it will not search by brand and productline
          },
          fetchPolicy: 'network-only',
        })

        if (args === memoQueryArgs.current && currentQueryId === queryId.current) {
          setTotal(Math.min((data?.parts || data?.advancedSearch || {}).metadata?.total, query.first || 100))
          setAllTotal((data?.parts || data?.advancedSearch || {}).metadata?.total)
          setLoadOffset((query.skip || 0) + limitData)
          if (gridRef.current) {
            const partsData: Part[] = (data?.parts || data?.advancedSearch || {}).edges.map((edge: any) => edge.node)
            setParts(old => [...old, ...partsData.map(part => ({ node: part }))])

            gridRef.current.api.applyTransaction({
              add: uniqBy(
                partsData.map(part => ({ node: part })),
                item => item.node.id,
              ),
            })
          }
        }
        setAvailableConnection(MAX_CONNECTION)
      } catch (e) {
        setError(e)
      }
    },
    [query, isAdvancedMode, advancedParams, gridRef],
  )

  const doFetchPartsData = useCallback(
    async (args: PartsQueryArgs, first: number, skip: number) => {
      setLoadOffset(offset => offset + first)
      try {
        const currentQueryId = queryId.current
        const { data } = await Client.getClient().query({
          query: isAdvancedMode ? ADVANCED_PARTS_SEARCH_QUERY_WITHOUT_PAGINATION : PARTS_WITHOUT_PAGINATION_QUERY,
          variables: {
            ...(isAdvancedMode && advancedParams ? getSearchParams(advancedParams) : query),
            ...query,
            first,
            skip,
            orderBy: {
              sort: PartsOrderByEnum.StrippedPartNumberNoZero,
              direction: SortOrderEnum.Asc,
            },
            // simpleSearch: true, // disable this for faster performance as it will not search by brand and productline
          },
          fetchPolicy: 'network-only',
        })

        if (gridRef.current && args === memoQueryArgs.current && currentQueryId === queryId.current) {
          const partsData: Part[] = data?.partsWithoutPagination || data?.advancedSearchWithoutPagination || []
          setParts(old => uniqBy([...old, ...partsData.map(part => ({ node: part }))], item => item.node.id))

          gridRef.current.api.applyTransaction({
            add: uniqBy(
              partsData.map(part => ({ node: part })),
              item => item.node.id,
            ),
          })
        }
      } catch (e) {
        setError(e)
      }
    },
    [query, gridRef, isAdvancedMode, advancedParams],
  )

  useEffect(() => {
    // if study data is not loaded
    if ((total === -1 || parts.length < total) && availableConnection > 0) {
      setAvailableConnection(old => old - 1)
      if (total === -1 || loadOffset < (query.skip || 0) + total) {
        if (total === -1) {
          countPartsData(memoQueryArgs.current!).then(() => setAvailableConnection(MAX_CONNECTION))
        } else {
          doFetchPartsData(
            memoQueryArgs.current!,
            Math.min(
              ROWS_PER_LOAD,
              Math.min(query.first || ROWS_PER_LOAD, ROWS_PER_LOAD),
              (query.first || ROWS_PER_LOAD) + (query.skip || 0) - loadOffset,
            ),
            loadOffset,
          ).then(() => setAvailableConnection(old => old + 1))
        }
      }
    }
  }, [total, loadOffset, availableConnection, parts.length, doFetchPartsData, countPartsData, query.first, query.skip])

  return {
    progress: Math.round(parts && total ? ((parts.length * 1.0) / total) * 100 : 0),
    error,
    total: allTotal,
    loading: total === -1 || parts.length < total,
  }
}

export const PartsGrid = () => {
  const [userFilter, setUserFilter] = useState<LabeledValue>()
  const [manufacturerFilter, setManufacturerFilter] = useState<LabeledValue>()
  const [GicFilter, setGicFilter] = useState<LabeledValue>()
  const [currentPage, setCurrentPage] = useState(1)
  const [openEdit, setOpenEdit] = useState(false)
  const [editting, setEditting] = useState(false)
  const [editMode, setEditMode] = useState<EditMode>(EditMode.edit)
  const [selectedPart, setSelectedPart] = useState<Part>()
  const [limit, setLimit] = useState(100)
  const [isOpen, setIsOpen] = useState(false)
  const [searchCritical, setSearchCritical] = useState<PartsQueryArgs>({
    first: limit,
    skip: 0,
    orderBy: [],
  })
  const [isAdvancedMode, setIsAdvancedMode] = useState(false)
  const [advancedParams, setAdvancedParams] = useState<Values>()
  const [updatingParts, setUpdatingParts] = useState<Part[]>()

  const debounceUpdatingParts = useDebounce(updatingParts, 200)

  React.useEffect(() => {
    if (debounceUpdatingParts && debounceUpdatingParts.length > 0 && gridRef.current) {
      const updatedPart = debounceUpdatingParts.map(inputPart => partToPartUpdateInput(inputPart))
      handleBulkPartUpdateMutation(updatedPart)
    }
  }, [debounceUpdatingParts])

  const limitOptions = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 100, label: '100' },
    { value: 500, label: '500' },
    { value: 1000, label: '1000' },
    { value: 2500, label: '2500' },
    { value: 10000, label: '10000' },
  ]

  const partToPartUpdateInput = (inputPart: Part): PartUpdateInput => {
    return {
      id: inputPart.id,
      partNumber: inputPart.partNumber,
      partName: inputPart.partName,
      strippedPartNumber: inputPart.strippedPartNumber,
      inputName: inputPart.inputName,
      id_510k: inputPart.id_510k?.toString(),
      mfgSize: inputPart.mfgSize?.toString(),
      description: inputPart.description,
      partAttributes: inputPart.partAttributes,
      dataSource: inputPart.dataSource,
      side: inputPart.side,
      isSterile: inputPart.isSterile,
      isCustom: inputPart.isCustom,
      isReprocessed: inputPart.isReprocessed,
      sizeOne: inputPart.sizeOne,
      sizeTwo: inputPart.sizeTwo,
      sizeThree: inputPart.sizeThree,
      quantityPerBox: inputPart.quantityPerBox,
      uom: inputPart.uom,
      data: inputPart.data,
      // udiId: inputPart.udiId, // Not in Part
      brandId: inputPart.brand ? inputPart.brand.id : null,
      productLineId: inputPart.productLine ? inputPart.productLine.id : null,
      mtlProductLine: inputPart.mtlProductLine,
      gicId: inputPart.gic ? inputPart.gic.id : null,
      typeOneId: inputPart.typeOne ? inputPart.typeOne.id : null,
      typeTwoId: inputPart.typeTwo ? inputPart.typeTwo.id : null,
      materialId: inputPart.material ? inputPart.material.id : null,
      segmentationId: inputPart.segmentation ? inputPart.segmentation.id : null,
      manufacturerId: inputPart.manufacturer ? inputPart.manufacturer.id : null,
    }
  }

  const numberSetter: AgCustomSetter = ({ data, newValue, node, colDef }) => {
    const numericValue = Number(newValue)
    const finalValue = Number.isNaN(numericValue) ? 0 : numericValue

    if (!colDef || !colDef.field) {
      return false
    }

    const field = colDef.field.split('.')[1]

    node?.setData({
      ...data,
      ...{ node: { ...data.node, ...{ [field]: finalValue } } },
    })

    return true
  }

  const selectorSetter: AgCustomSetter = ({ data, newValue, api, colDef }) => {
    if (!colDef.field) {
      return false
    }

    const field = colDef.field.split('.')[1]

    const finalValue = typeof newValue === 'object' ? newValue : { name: newValue }
    if (finalValue.id === data.node[field]?.id) {
      return false
    }

    if (api) {
      api.applyTransaction({
        update: [
          {
            ...data,
            ...{
              node: {
                ...data.node,
                [field]: typeof newValue === 'object' ? newValue : { ...data.node[field], name: newValue },
              },
            },
          },
        ],
      })
    }

    return true
  }

  const defaultValueFormatter = ({ data, colDef, value }: ValueFormatterParams) => {
    if (!colDef.field) {
      return false
    }

    const field = colDef.field.split('.')[1]

    if (data) {
      return (
        (data && data.node[field] && data.node[field].name) || (data && data.node[field] && data.node[field].id) || ''
      )
    }

    return value
  }

  const defaultValueGetter = ({ data, colDef }: ValueGetterParams) => {
    if (!colDef.field) {
      return false
    }

    const field = colDef.field.split('.')[1]

    if (!data.node[field]) {
      return ''
    }

    return field === 'gic'
      ? `${data.node[field].id} | ${data.node[field].name}`
      : data.node[field].name
      ? data.node[field].name
      : data.node[field].id
  }

  const defaultValueComparision = (va: any, vb: any) => {
    const vaName = va?.name || (typeof va === 'string' ? va : '')
    const vbName = vb?.name || (typeof vb === 'string' ? vb : '')
    return vaName > vbName ? 1 : vaName === vbName ? 0 : -1
  }

  const gridRef = useRef<AgGridReact & { api: GridApi }>(null)
  const columnDefs: AgGridColumnProps[] = [
    {
      headerName: 'Manufacturer',
      field: 'node.manufacturer',
      colId: 'node.manufacturer',
      cellEditorFramework: SelectManufacturerAgWrapper,
      valueSetter: params => {
        const result = selectorSetter(params)
        if (!result) {
          return result
        }

        const { api, node } = params
        if (api) {
          const data = node?.data
          api.applyTransaction({
            update: [
              {
                ...data,
                ...{
                  node: {
                    ...data.node,
                    ...{ brand: null },
                    ...{ productLine: null },
                  },
                },
              },
            ],
          })
          api.onFilterChanged()
        }

        return true
      },
      valueFormatter: defaultValueFormatter,
      valueGetter: defaultValueGetter,
    },
    {
      headerName: 'Part Number',
      field: 'node.partNumber',
      colId: 'node.partNumber',
    },
    {
      headerName: 'Stripped Part Number',
      field: 'node.strippedPartNumber',
      colId: 'node.strippedPartNumber',
    },
    {
      headerName: 'Part Name',
      field: 'node.partName',
      colId: 'node.partName',
    },
    {
      headerName: 'Brand',
      field: 'node.brand',
      colId: 'node.brand',
      cellEditorFramework: SelectBrandAgWrapper,
      valueSetter: selectorSetter,
      valueGetter: defaultValueGetter,
      comparator: defaultValueComparision,
    },
    {
      headerName: 'Product Line',
      field: 'node.productLine',
      colId: 'node.productLine',
      cellEditorFramework: SelectProductLineAgWrapper,
      valueSetter: selectorSetter,
      valueGetter: defaultValueGetter,
      comparator: defaultValueComparision,
    },
    {
      headerName: 'Quantity Per Box',
      field: 'node.quantityPerBox',
      colId: 'node.quantityPerBox',
      type: 'numericColumn',
      valueSetter: numberSetter,
    },
    {
      headerName: 'GIC',
      field: 'node.gic',
      colId: 'node.gic',
      cellEditorFramework: SelectGicLineAgWrapper,
      valueSetter: params => {
        const result = selectorSetter(params)
        if (!result) {
          return result
        }

        const { api, node } = params
        if (api) {
          const data = node?.data
          api.applyTransaction({
            update: [
              {
                ...data,
                ...{
                  node: {
                    ...data.node,
                    ...{ typeOne: null },
                    ...{ typeTwo: null },
                  },
                },
              },
            ],
          })
          api.onFilterChanged()
        }

        return true
      },
      valueGetter: defaultValueGetter,
      comparator: defaultValueComparision,
    },
    {
      headerName: 'Type One',
      field: 'node.typeOne',
      colId: 'node.typeOne',
      cellEditorFramework: SelectTypeOneAgWrapper,
      valueSetter: selectorSetter,
      valueGetter: defaultValueGetter,
      filter: 'agSetColumnFilter',
      comparator: defaultValueComparision,
    },
    {
      headerName: 'Type Two',
      field: 'node.typeTwo',
      colId: 'node.typeTwo',
      cellEditorFramework: SelectTypeTwoAgWrapper,
      valueSetter: selectorSetter,
      valueGetter: defaultValueGetter,
      comparator: defaultValueComparision,
    },
    {
      headerName: 'Material',
      field: 'node.material',
      colId: 'node.material',
      cellEditorFramework: SelectMaterialAgWrapper,
      valueSetter: selectorSetter,
      valueGetter: defaultValueGetter,
      comparator: defaultValueComparision,
    },
    {
      headerName: 'Size One',
      field: 'node.sizeOne',
      colId: 'node.sizeOne',
      valueSetter: numberSetter,
    },
    {
      headerName: 'Size Two',
      field: 'node.sizeTwo',
      colId: 'node.sizeTwo',
      valueSetter: numberSetter,
    },
    {
      headerName: 'Size Three',
      field: 'node.sizeThree',
      colId: 'node.sizeThree',
      valueSetter: numberSetter,
    },
    {
      headerName: 'Segmentation',
      field: 'node.segmentation.id',
      colId: 'node.segmentation.id',
      cellEditorFramework: SelectSegmentationAgWrapper,
      valueSetter: selectorSetter,
    },
    {
      headerName: 'UDI',
      field: 'node.udi',
      colId: 'node.udi',
    },
    {
      headerName: 'Data Source',
      field: 'node.dataSource',
      colId: 'node.dataSource',
    },
    {
      headerName: 'Input Name',
      field: 'node.inputName',
      colId: 'node.inputName',
    },
    {
      headerName: 'ID 510k',
      field: 'node.id_510k',
      colId: 'node.id_510k',
    },
    {
      headerName: 'Mfg Size',
      field: 'node.mfgSize',
      colId: 'node.mfgSize',
    },
    {
      headerName: 'Description',
      field: 'node.description',
      colId: 'node.description',
    },
    {
      headerName: 'Part Attributes',
      field: 'node.partAttributes',
      colId: 'node.partAttributes',
    },
    {
      headerName: 'Side',
      field: 'node.side',
      colId: 'node.side',
    },
    {
      headerName: 'Is Sterile',
      field: 'node.isSterile',
      colId: 'node.isSterile',
      cellEditorFramework: SelectLiteralAgWrapper,
      cellEditorParams: {
        values: [
          { label: 'N/A', value: null },
          { label: 'True', value: true },
          { label: 'False', value: false },
        ],
      },
      valueSetter: selectorSetter,
    },
    {
      headerName: 'Is Custom',
      field: 'node.isCustom',
      colId: 'node.isCustom',
      cellEditorFramework: SelectLiteralAgWrapper,
      cellEditorParams: {
        values: [
          { label: 'N/A', value: null },
          { label: 'True', value: true },
          { label: 'False', value: false },
        ],
      },
      valueSetter: selectorSetter,
    },
    {
      headerName: 'Is Reprocessed',
      field: 'node.isReprocessed',
      colId: 'node.isReprocessed',
      cellEditorFramework: SelectLiteralAgWrapper,
      cellEditorParams: {
        values: [
          { label: 'N/A', value: null },
          { label: 'True', value: true },
          { label: 'False', value: false },
        ],
      },
      valueSetter: selectorSetter,
    },
    {
      headerName: 'UoM',
      field: 'node.uom',
      colId: 'node.uom',
    },
    {
      headerName: 'Data',
      field: 'node.data',
      colId: 'node.data',
    },
    {
      headerName: 'MTL Product Line',
      field: 'node.mtlProductLine',
      colId: 'node.mtlProductLine',
    },
    {
      headerName: 'Is Inactive',
      field: 'node.isInactive',
      colId: 'node.isInactive',
      editable: false,
    },
    {
      headerName: 'Duplicate ID',
      field: 'node.',
      colId: 'node.',
      valueGetter: value => {
        if (value.data?.duplicateId) {
          return 'v'
        }
        return ''
      },
    },
    {
      headerName: 'Created at',
      field: 'node.createdAt',
      editable: false,
      valueGetter: ({ data }) => {
        const timestamp = Number(data.node.createdAt)
        return Number.isNaN(timestamp) ? '' : moment(timestamp).format('MM/DD/YYYY HH:mm:ss')
      },
      comparator: (_va, _vb, na, nb) => {
        const naCreatedAt = Number(na.data.node.createdAt)
        const nbCreatedAt = Number(nb.data.node.createdAt)
        return naCreatedAt > nbCreatedAt ? 1 : naCreatedAt === nbCreatedAt ? 0 : -1
      },
    },
  ]

  const { loading, error, total, progress } = useParallelLoadPartsData(
    searchCritical,
    gridRef,
    isAdvancedMode,
    advancedParams,
  )

  // const getRowData = () => {
  //   let rowData
  //   if (!isAdvancedMode && !loading && data && data.parts && data.parts.edges) {
  //     rowData = data.parts.edges
  //   }
  //   if (
  //     isAdvancedMode &&
  //     !searchingAdvanced &&
  //     advancedSearchData &&
  //     advancedSearchData.advancedSearch &&
  //     advancedSearchData.advancedSearch.edges
  //   ) {
  //     rowData = advancedSearchData.advancedSearch.edges
  //   }

  //   return rowData
  // }

  if (error) {
    message.error(error.message)
  }

  const handleBulkPartUpdateMutation = (updatedParts: PartUpdateInput[]) => {
    if (updatedParts.length > 0) {
      bulkPartUpdateMutation({ input: updatedParts })
        .then(response => {
          message.success('Updated')
          setOpenEdit(false)
          setUpdatingParts(undefined)
          if (
            gridRef.current &&
            response &&
            response.data &&
            response.data.bulkUpdatePart &&
            response.data.bulkUpdatePart.length > 0
          ) {
            gridRef.current.api.applyTransaction({
              update: [{ node: response.data.bulkUpdatePart[0] }],
            })
          }
        })
        .catch(e => message.error(e.message))
        .finally(() => setEditting(false))
    }
  }

  const handleFinish = (params: Values) => {
    setIsAdvancedMode(true)
    setAdvancedParams(params)
  }

  const clearAdvancedSearch = () => {
    setIsAdvancedMode(false)
    setAdvancedParams(undefined)
    localStorage.removeItem(LOCAL_STORAGE_KEY)
  }

  useEffect(() => {
    if (gridRef.current) {
      if (loading) {
        gridRef.current.api.showLoadingOverlay()
      } else if (total === 0) {
        gridRef.current.api.showNoRowsOverlay()
      } else {
        gridRef.current.api.hideOverlay()
      }
    }
  }, [loading, gridRef, total])

  return (
    <PageWrapper>
      <div>
        <SearchWrapper>
          {!isAdvancedMode && (
            <>
              <Flex flex={1} paddingRight="1rem">
                <StyledSelect
                  labelInValue
                  filterOption={false}
                  showArrow={false}
                  defaultActiveFirstOption={false}
                  placeholder="Select"
                  value={{ key: String(limit), label: `${limit} records` }}
                  allowClear
                  onChange={v => {
                    const num = parseInt(v.key, 10)
                    setLimit(num)
                    setSearchCritical({
                      ...searchCritical,
                      first: num,
                      skip: 0,
                    })
                    setCurrentPage(0)
                  }}>
                  {limitOptions.map(option => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label} records
                    </Select.Option>
                  ))}
                </StyledSelect>
              </Flex>
              <Flex flex={2} paddingRight="1rem">
                <ManufacturerSelect
                  value={manufacturerFilter}
                  allowClear
                  onChange={v => {
                    setManufacturerFilter(v)
                    setCurrentPage(0)
                    setSearchCritical({
                      ...searchCritical,
                      args: {
                        ...searchCritical.args,
                        manufacturerId: v ? v.key : undefined,
                      },
                      skip: 0,
                    })
                  }}
                />
              </Flex>
              <Flex flex={2} paddingRight="1rem">
                <RangePicker
                  format="YYYY-MM-DD"
                  placeholder={['Created', 'To']}
                  onChange={value => {
                    const [from, to] = value

                    if (!from || !to) {
                      return
                    }

                    setCurrentPage(0)
                    setSearchCritical({
                      ...searchCritical,
                      args: {
                        ...searchCritical.args,
                        createdAt: {
                          from: moment(from).format('YYYY-MM-DD'),
                          to: moment(to).format('YYYY-MM-DD'),
                        },
                      },
                      skip: 0,
                    })
                  }}
                />
              </Flex>
              <Flex flex={2} paddingRight="1rem">
                <RangePicker
                  format="YYYY-MM-DD"
                  placeholder={['Updated', 'To']}
                  onChange={value => {
                    const [from, to] = value

                    if (!from || !to) {
                      return
                    }

                    setCurrentPage(0)
                    setSearchCritical({
                      ...searchCritical,
                      args: {
                        ...searchCritical.args,
                        updatedAt: {
                          from: moment(from).format('YYYY-MM-DD'),
                          to: moment(to).format('YYYY-MM-DD'),
                        },
                      },
                      skip: 0,
                    })
                  }}
                />
              </Flex>
              <Flex flex={2} paddingRight="1rem">
                <GICSelect
                  value={GicFilter}
                  allowClear
                  onChange={v => {
                    setGicFilter(v)
                    setCurrentPage(0)
                    setSearchCritical({
                      ...searchCritical,
                      args: {
                        ...searchCritical.args,
                        gicId: v ? parseInt(v.key, 10) : undefined,
                      },
                      skip: 0,
                    })
                  }}
                />
              </Flex>
              <Flex flex={2} paddingRight="1rem">
                <UserSelect
                  value={userFilter}
                  allowClear
                  onChange={v => {
                    setUserFilter(v)
                    setCurrentPage(0)
                    setSearchCritical({
                      ...searchCritical,
                      args: {
                        ...searchCritical.args,
                        lastUserId: v ? v.key : undefined,
                      },
                      skip: 0,
                    })
                  }}
                />
              </Flex>
              <Flex flex={2} paddingRight="1rem">
                <StyledSearchInput
                  placeholder="Search"
                  enterButton
                  onSearch={searchText => {
                    setSearchCritical({
                      ...searchCritical,
                      searchText,
                    })
                  }}
                />
              </Flex>
            </>
          )}

          <Flex paddingRight="1rem">
            <Button type="primary" onClick={() => setIsOpen(true)}>
              Advanced Search
            </Button>
            {isAdvancedMode && advancedParams && (
              <Button onClick={clearAdvancedSearch} type="link">
                Clear
              </Button>
            )}
          </Flex>

          <Button
            type="primary"
            icon="plus"
            onClick={() => {
              setOpenEdit(true)
              setEditMode(EditMode.create)
            }}>
            New Part
          </Button>
        </SearchWrapper>

        <div className="ag-theme-balham" style={{ height: '70vh', width: 'fill-parent' }}>
          {loading && <Progress percent={progress} />}
          <AgGridReact
            ref={gridRef}
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,
              editable: true,
            }}
            processCellForClipboard={(params: ProcessCellForExportParams) => {
              let colDef: ColDef = params.column.getColDef()
              if (typeof colDef.valueFormatter === 'function') {
                return colDef.valueFormatter({
                  ...params,
                  data: params.node?.data,
                  colDef: colDef,
                } as ValueFormatterParams)
              }

              return params.value
            }}
            // rowData={getRowData()}
            enableRangeSelection={true}
            enableFillHandle={true}
            modules={[...AllModules]}
            onDragStopped={(e: DragStoppedEvent) => {
              const columnState = JSON.stringify(
                e.columnApi.getColumnState().map(colState => pick(colState, ['colId', 'width', 'pinned', 'hide'])),
              )
              localStorage.setItem(PART_GRID, columnState)
            }}
            onGridReady={({ api, columnApi }: GridReadyEvent) => {
              const columnStateStr = localStorage.getItem(PART_GRID)
              if (columnStateStr) {
                const savedColumnStates: ColumnState[] = JSON.parse(columnStateStr)
                const currentColumnStates = columnApi.getColumnState()
                const unsavedColumns = currentColumnStates.filter(
                  col => !savedColumnStates.find(savedCol => savedCol.colId === col.colId),
                )

                columnApi.setColumnState([...savedColumnStates, ...unsavedColumns])
                columnApi.setColumnVisible('state', false)

                columnApi.moveColumn('index', 0)
              }
              api.onFilterChanged()
            }}
            columnDefs={columnDefs}
            getRowNodeId={node => {
              return node.id ? node.id : node.node.id
            }}
            suppressClearOnFillReduction={true}
            undoRedoCellEditing={true}
            onCellValueChanged={({ node }) => {
              setUpdatingParts(old => [...(old || []), node.data.node])
            }}
            statusBar={{
              statusPanels: [
                {
                  statusPanel: 'agAggregationComponent',
                  statusPanelParams: {
                    aggFuncs: ['count', 'sum'],
                  },
                },
              ],
            }}
          />
        </div>
        <PaginationContainer>
          <Pagination
            total={total}
            current={currentPage}
            onChange={pageNumber => {
              setCurrentPage(pageNumber)
              setSearchCritical({
                ...searchCritical,
                first: limit,
                skip: (pageNumber - 1) * limit,
              })
            }}
            pageSize={limit}
          />
        </PaginationContainer>
        <EditPart
          part={selectedPart}
          editMode={editMode}
          onSubmit={editingPart => {
            setEditting(true)
            if (editMode === EditMode.edit) {
              handleBulkPartUpdateMutation([editingPart as PartUpdateInput])
              setSelectedPart(undefined)
            } else {
              createPartMutation({ input: editingPart as PartCreateInput })
                .then(response => {
                  message.success('Created')
                  setOpenEdit(false)
                  if (gridRef.current && response && response.data) {
                    gridRef.current.api.applyTransaction({ add: [response.data.createPart] })
                  }
                })
                .catch(e => message.error(e.message))
                .finally(() => setEditting(false))
            }
          }}
          onCancel={() => {
            setOpenEdit(false)
            setSelectedPart(undefined)
          }}
          visible={openEdit}
          submitting={editting}
          title={
            selectedPart &&
            `Edit ${selectedPart.partNumber} ${selectedPart.manufacturer ? selectedPart.manufacturer.name : ''}`
          }
        />

        <StyledTotalResultsSpan>
          <StyledTotalResultBold>{total} </StyledTotalResultBold>
          results
        </StyledTotalResultsSpan>
      </div>
      <AdvancedSearchModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onFinish={handleFinish}
        isAdvancedMode={isAdvancedMode}
      />
    </PageWrapper>
  )
}

const StyledTotalResultsSpan = styled.span`
  font-style: italic;
  margin-right: 12px;
  opacity: 0.8;
  font-size: 13px;
`
const StyledTotalResultBold = styled.span`
  font-weight: 800;
`

const SearchWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`

const StyledSearchInput = styled(Search)`
  width: 200px;
`

function useDebounce<T>(value: T, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  React.useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay], // Only re-call effect if value or delay changes
  )
  return debouncedValue
}
