import { MeWithoutPaymentContext, Role } from '@curvo/apollo'
import { useContext } from 'react'
import { Navigate, useLocation } from 'react-router'

type Props = { roles?: Role[]; children: React.ReactElement }

const ProtectedRoute: React.FC<Props> = ({ roles, children }) => {
  const meData = useContext(MeWithoutPaymentContext)
  const location = useLocation()
  const userRole = meData?.data?.role
  const isAccessible = !userRole || !roles?.length || roles.includes(userRole)
  if (!isAccessible) {
    return (
      <Navigate
        to={{
          pathname: '/auth/error-permission',
        }}
        state={{ from: location }}
      />
    )
  }

  return children
}

export default ProtectedRoute
