import { GicCreateInput } from '@curvo/apollo'
import { Col, Form, Input, InputNumber, Row } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import React, { useState } from 'react'
import { EditPanelWrapper } from '../../../../../components/common'
import { EditDrawer } from '../../../../../components/EditDrawer'

const newGicCreateInput = (): GicCreateInput => ({
  name: '',
  id: 0,
})

export const CreateGIC: React.FunctionComponent<
  {
    onSubmit: (gic: GicCreateInput) => void
    onCancel: () => void
  } & DrawerProps
> = ({ onSubmit, onCancel, ...drawerProps }) => {
  const [creatingGic, setCreatingGic] = useState<GicCreateInput>(newGicCreateInput())
  const onUpdateField =
    (field: keyof GicCreateInput) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setCreatingGic({
        ...creatingGic,
        [field]: e.target.value,
      })
    }
  return (
    <EditDrawer
      onCancel={onCancel}
      onSave={() => {
        onSubmit(creatingGic)
        setCreatingGic(newGicCreateInput())
      }}
      {...drawerProps}
      title={'New GIC'}>
      <EditPanelWrapper>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="ID">
              <InputNumber
                value={creatingGic.id}
                onChange={v => {
                  setCreatingGic({ ...creatingGic, id: v || 0 })
                }}
              />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item label="Name">
              <Input value={creatingGic.name} onChange={onUpdateField('name')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Form.Item label="Excludes">
            <Input.TextArea value={creatingGic.excludes || ''} onChange={onUpdateField('excludes')} />
          </Form.Item>
        </Row>
        <Row gutter={16}>
          <Form.Item label="Include">
            <Input.TextArea value={creatingGic.includes || ''} onChange={onUpdateField('includes')} />
          </Form.Item>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Size One Description">
              <Input value={creatingGic.sizeOneDescription || ''} onChange={onUpdateField('sizeOneDescription')} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Size Two Description">
              <Input value={creatingGic.sizeTwoDescription || ''} onChange={onUpdateField('sizeTwoDescription')} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Size Three Description">
              <Input value={creatingGic.sizeThreeDescription || ''} onChange={onUpdateField('sizeThreeDescription')} />
            </Form.Item>
          </Col>
        </Row>
      </EditPanelWrapper>
    </EditDrawer>
  )
}
