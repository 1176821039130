import { ColDef, ColGroupDef } from '@ag-grid-community/core'

export const columns: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Basic',
    marryChildren: true,
    children: [
      {
        headerName: 'ID',
        colId: 'id',
        field: 'id',
        type: 'text',
        hide: true,
      },
      {
        headerName: 'Vendor',
        colId: 'vendor',
        field: 'vendor',
        type: 'text',
      },
      {
        headerName: 'Manufacturer',
        colId: 'manufacturer',
        field: 'manufacturer',
        type: 'text',
      },
      {
        headerName: 'Mfgitem',
        colId: 'mfgitem',
        field: 'mfgitem',
        type: 'text',
      },
      {
        headerName: 'Venitem',
        colId: 'venitem',
        field: 'venitem',
        type: 'text',
      },
      {
        headerName: 'Description',
        colId: 'description',
        field: 'description',
        type: 'text',
      },
      {
        headerName: 'Uom',
        colId: 'uom',
        field: 'uom',
        type: 'text',
      },
      {
        headerName: 'Uom Conversion',
        colId: 'uomconversion',
        field: 'uomconversion',
        type: 'text',
      },
      {
        headerName: 'Qty Purchase',
        colId: 'qtypurchase',
        field: 'qtypurchase',
        type: 'number',
      },
      {
        headerName: 'Asp',
        colId: 'asp',
        field: 'asp',
        type: 'text',
      },
      {
        headerName: 'Ignore',
        colId: 'ignoreRow',
        field: 'ignoreRow',
      },
    ],
  },
  {
    headerName: 'Stan',
    marryChildren: true,
    children: [
      {
        headerName: 'Bamf StanId',
        colId: 'bamfStanId',
        field: 'bamfStanId',
        type: 'text',
      },
      {
        headerName: 'Match StanId',
        colId: 'matchStanId',
        field: 'matchStanId',
        type: 'text',
      },
      {
        headerName: 'Stan Manufacturer Id',
        colId: 'stanManufacturerId',
        field: 'stanManufacturerId',
        type: 'text',
      },
      {
        headerName: 'Stan Part Number',
        colId: 'stanPartNumber',
        field: 'stanPartNumber',
        type: 'text',
      },
      {
        headerName: 'Stan Part Description',
        colId: 'stanPartDescription',
        field: 'stanPartDescription',
        type: 'text',
      },
      {
        headerName: 'Stan Manufacturer',
        colId: 'stanManufacturer',
        field: 'stanManufacturer',
        type: 'text',
      },
      {
        headerName: 'Ai Stan Overwrite',
        colId: 'aiStanOverwrite',
        field: 'aiStanOverwrite',
        filter: false,
      },
      {
        headerName: 'Manufacturer Stan Type',
        colId: 'manufacturerStanType',
        field: 'manufacturerStanType',
        type: 'text',
      },
    ],
  },
  {
    headerName: 'Gudid',
    marryChildren: true,
    children: [
      {
        headerName: 'Bamf Gudid Id',
        colId: 'bamfGudidId',
        field: 'bamfGudidId',
        type: 'text',
      },
      {
        headerName: 'Match Gudid Id',
        colId: 'matchGudidId',
        field: 'matchGudidId',
        type: 'text',
      },
      {
        headerName: 'Gudid Company Name',
        colId: 'gudidCompanyName',
        field: 'gudidCompanyName',
        type: 'text',
      },
      {
        headerName: 'Gudid Manufacturer',
        colId: 'gudidManufacturer',
        field: 'gudidManufacturer',
        type: 'text',
      },
      {
        headerName: 'Gudid Catalog Number',
        colId: 'gudidCatalogNumber',
        field: 'gudidCatalogNumber',
        type: 'text',
      },
      {
        headerName: 'Gudid Description',
        colId: 'gudidDescription',
        field: 'gudidDescription',
        type: 'text',
      },
      {
        headerName: 'Gudid Version Model Number',
        colId: 'gudidVersionModelNumber',
        field: 'gudidVersionModelNumber',
        type: 'text',
      },
      {
        headerName: 'Ai Gudid Overwrite',
        colId: 'aiGudidOverwrite',
        field: 'aiGudidOverwrite',
        filter: false,
      },

      {
        headerName: 'Manufacturer Gudid Type',
        colId: 'manufacturerGudidType',
        field: 'manufacturerGudidType',
        type: 'text',
      },
    ],
  },
  {
    headerName: 'Med',
    marryChildren: true,
    children: [
      {
        headerName: 'Bamf Med Id',
        colId: 'bamfMedId',
        field: 'bamfMedId',
        type: 'text',
      },
      {
        headerName: 'Match Med Id',
        colId: 'matchMedId',
        field: 'matchMedId',
        type: 'text',
      },
      {
        headerName: 'Med Manufacturer',
        colId: 'medManufacturer',
        field: 'medManufacturer',
        type: 'text',
      },
      {
        headerName: 'Med Sku',
        colId: 'medSku',
        field: 'medSku',
        type: 'text',
      },
      {
        headerName: 'Med Material Code',
        colId: 'medMaterialCode',
        field: 'medMaterialCode',
        type: 'text',
      },
      {
        headerName: 'Med Description',
        colId: 'medDescription',
        field: 'medDescription',
        type: 'text',
      },
      {
        headerName: 'Ai Med Overwrite',
        colId: 'aiMedOverwrite',
        field: 'aiMedOverwrite',
        filter: false,
      },
      {
        headerName: 'Manufacturer Med Type',
        colId: 'manufacturerMedType',
        field: 'manufacturerMedType',
        type: 'text',
      },
    ],
  },
]
