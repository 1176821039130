import { Study } from '@curvo/apollo'
import { LabeledValue } from 'antd/lib/select'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import { UserSelect } from '../../Update/components/Select/UserSelect'
import { AgWrapperPropsType } from './ManufacturerSelectAgWrapper'

export const CurvoAdminUserSelectAgWrapper = React.forwardRef<{}, AgWrapperPropsType<Study>>((params, ref) => {
  const { data, api } = params

  const value =
    (data &&
      data.user && {
        key: data.user.id,
        label: data.user.username,
      }) ||
    undefined
  const [selectedUser, setSelectedUser] = useState<LabeledValue>()

  useImperativeHandle(ref, () => ({
    getValue: () => selectedUser || undefined,
  }))

  useEffect(() => {
    if (selectedUser !== undefined) {
      api.stopEditing()
    }
  }, [selectedUser, api])

  return <UserSelect style={{ width: 300 }} value={value} onChange={v => setSelectedUser(v)} />
})
