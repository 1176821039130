import { PurchaseOrder } from '@curvo/apollo'
import { RowDataTransaction } from '@ag-grid-community/core'

export type DataUpdateTransaction<T> = {
  remove?: Partial<T>[]
  add?: T[]
  update?: T[]
}

export class PurchaseOrdersMap {
  public purchaseOrders: Map<number, Map<string, PurchaseOrder>>

  constructor() {
    this.purchaseOrders = new Map<number, Map<string, PurchaseOrder>>()
  }

  public updateStudy({ remove, add, update }: RowDataTransaction, studyId: number) {
    if (!this.purchaseOrders.has(studyId)) {
      return
    }

    const study = this.purchaseOrders.get(studyId)!
    if (update) {
      update.forEach(po => {
        study.set(po.id, po)
      })
    }
    if (add) {
      add.forEach(po => {
        study.set(po.id, po)
      })
    }
    if (remove) {
      remove.forEach(po => {
        if (po.id) {
          study.delete(po.id)
        }
      })
    }
  }

  private getOrCreateStudy(studyId: number): Map<string, PurchaseOrder> {
    if (this.purchaseOrders.has(studyId)) {
      return this.purchaseOrders.get(studyId)!
    }

    const study = new Map<string, PurchaseOrder>()
    this.purchaseOrders.set(studyId, study)
    return study
  }

  public addToStudy(POs: PurchaseOrder[], studyId: number) {
    const study = this.getOrCreateStudy(studyId)

    POs.forEach(po => {
      study.set(po.id, po)
    })
  }
}

export const GradingCache = new PurchaseOrdersMap()
