"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Colors = void 0;
exports.Colors = {
    Primary: '#007bff',
    // Secondary: '#6c757d',
    Secondary: '#c5cfd3',
    Success: '#28a745',
    Danger: '#dc3545',
    Warning: '#ffc107',
    Info: '#17a2b8',
    Light: '#f8f9fa',
    Dark: '#343a40',
    Link: '#007bff',
    Gray: '#899DA6',
    White: '#ffffff',
    Black: '#000000',
    Transparent: 'transparent',
    Border: 'rgba(137, 157, 166, 0.35)',
    InputBackground: '#F6F9FA',
};
