import { Route, Routes } from 'react-router'
import { Tracking } from '../../components/Tracking'
import { NotPermission } from './NotPermission'
import { SignIn } from './SignIn'

export const Auth = () => (
  <>
    <Tracking />
    <Routes>
      <Route path="/error-permission" element={<NotPermission />} />
      <Route path="/signin" element={<SignIn />} />
    </Routes>
  </>
)
