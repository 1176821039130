import { Segmentation, SegmentationData } from '@curvo/apollo'
import { Flex, SelectInput } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import DebounceInput from './DebounceInput'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & {
  value: any
  onChange: (option: any) => void
}

const OptionContainer = styled(Flex)`
  width: 100%;
`

const SegmentationName = styled(Flex)`
  flex: 1;
`

const transformDataToOption = (data: Segmentation[]) => {
  if (!data) {
    return []
  }

  return data.map(({ id, segment, subSegment }) => ({
    extra: id,
    label: `${id} ${segment && segment.name} ${subSegment && subSegment.name}`,
    value: `${id}`,
  }))
}

const SegmentationSelect: React.SFC<Props> = ({ value, onChange }) => (
  <DebounceInput>
    {({ searchText, onInputChange }) => (
      <SegmentationData variables={{ searchText }} fetchPolicy="network-only">
        {({ data, loading }) => (
          <SelectInput
            label="Segmentation"
            onInputChange={onInputChange}
            options={data && data.segmentations ? transformDataToOption(data.segmentations) : []}
            loading={loading}
            value={value}
            onChange={onChange}
            renderOption={option => (
              <OptionContainer>
                <SegmentationName>{`${option.label}`}</SegmentationName>
              </OptionContainer>
            )}
            orderOptions={options =>
              options.sort((a, b) => {
                return +a.extra - +b.extra
              })
            }
            asyncMode
          />
        )}
      </SegmentationData>
    )}
  </DebounceInput>
)

SegmentationSelect.defaultProps = defaultProps

export default React.memo(SegmentationSelect)
