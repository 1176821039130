import { ColumnProps, TableRowSelection } from 'antd/es/table'
import { useState } from 'react'

export function useSelectableTable<T>(
  tableColumns: ColumnProps<T>[],
  hideColumnsInSelectMode: string[],
  rowKeyFunc: (record: T) => string,
  invertSelection: boolean = true,
) {
  const [isMultipleSelection, setMultipleSelection] = useState(false)
  const [selected, setSelected] = useState<T[]>([])
  const [isSelectedAll, setIsSelectedAll] = useState(false)
  const rowSelection: TableRowSelection<T> | undefined = isMultipleSelection
    ? {
        onSelect: (row: T, isSelected: boolean) => {
          if (invertSelection && isSelectedAll ? !isSelected : isSelected) {
            setSelected([...selected, row])
          } else {
            setSelected(selected.filter(sel => rowKeyFunc(sel) !== rowKeyFunc(row)))
          }
        },
        onSelectAll: newSelected => {
          setIsSelectedAll(newSelected)
          setSelected([])
        },
        getCheckboxProps: record => {
          const isSelected = selected.findIndex(sel => rowKeyFunc(sel) === rowKeyFunc(record)) !== -1
          return {
            checked: isSelectedAll ? !isSelected : isSelected,
          }
        },
      }
    : undefined
  const columns = isMultipleSelection
    ? tableColumns.filter(col => (col.key ? hideColumnsInSelectMode.indexOf(col.key.toString()) === -1 : true))
    : tableColumns
  const cleanSelected = () => {
    setIsSelectedAll(false)
    setSelected([])
  }
  return {
    isMultipleSelection,
    setMultipleSelection,
    columns,
    rowSelection,
    selected,
    isSelectedAll,
    cleanSelected,
  }
}
