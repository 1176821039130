import React, { useState, useImperativeHandle, useEffect } from 'react'
import { Select } from 'antd'
import { SelectProps, LabeledValue } from 'antd/lib/select'
import { StudyTransactionType, Part, AdminSearchData } from '@curvo/apollo'
import { debounce } from 'lodash'
import { StyledSelectPropsType, StyledSelect } from '../../Update/components/Select/common'
import { AgWrapperPropsType } from './ManufacturerSelectAgWrapper'

const { Option } = Select

type PartSearchSelectProps = SelectProps<LabeledValue> &
  StyledSelectPropsType & { onFullValueChange?: (part?: Part) => void; manufacturerId?: string }

export const PartSearchSelect: React.FC<PartSearchSelectProps> = ({
  onChange,
  onFullValueChange,
  manufacturerId,
  ...props
}) => {
  const [searchText, setSearchText] = useState('')

  return (
    <AdminSearchData variables={{ searchText, manufacturerId }} fetchPolicy="network-only">
      {({ data, loading }) => (
        <StyledSelect
          onSearch={debounce(setSearchText, 800)}
          loading={loading}
          showSearch
          labelInValue
          filterOption={false}
          showArrow={true}
          defaultActiveFirstOption={false}
          placeholder="Search Part"
          onChange={(v, com) => {
            if (onChange) {
              onChange(v, com)
            }
            if (onFullValueChange) {
              const selected = data && data.adminSearch.data.find(result => result.id === v.key)
              onFullValueChange(selected)
            }
          }}
          {...props}>
          {data &&
            data.adminSearch &&
            data.adminSearch.data.map(result => (
              <Option key={result.id}>{`${result.partName} | ${result.partNumber}`}</Option>
            ))}
        </StyledSelect>
      )}
    </AdminSearchData>
  )
}

export const PartSearchSelectAgWrapper = React.forwardRef<{}, AgWrapperPropsType<StudyTransactionType>>(
  (params, ref) => {
    const { data, api } = params

    const value = data
      ? data.matchPartId
        ? {
            key: data.matchPartId,
            label: data.bamfPartNumber,
          }
        : undefined
      : undefined
    const [labeledValue, setLabeledValue] = useState<LabeledValue>()

    useImperativeHandle(ref, () => ({
      getValue: () => labeledValue || undefined,
    }))

    useEffect(() => {
      if (labeledValue) {
        api.stopEditing()
      }
    }, [labeledValue, api])

    return <PartSearchSelect value={value} onChange={v => setLabeledValue(v)} />
  },
)
