import { useCustomersQuery } from '@curvo/apollo'
import { Select, Spin } from 'antd'
import { SelectProps } from 'antd/lib/select'
import { debounce } from 'lodash'
import React, { useState } from 'react'

export const CustomerSelector: React.FC<SelectProps> = props => {
  const [searchText, setSearchText] = useState('')

  const { data, loading } = useCustomersQuery({
    variables: { searchText },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  return (
    <Select onSearch={debounce(setSearchText, 800)} filterOption={false} showSearch {...props} loading={loading}>
      {loading && (
        <Select.Option key="loading" disabled>
          <Spin />
        </Select.Option>
      )}
      {!loading && data?.customers.map(n => <Select.Option value={n.id}>{n.name}</Select.Option>)}
    </Select>
  )
}
