import {
  BATCH_LIST_QUERY,
  Client,
  CountParsedPrices,
  COUNT_QUEUED_PRICES,
  deleteAllPricesMutation,
  NewPricesInput,
  parsePricesMutation,
  ParsePricesResult,
} from '@curvo/apollo'
import { Button, DatePicker, Form, Icon, Input, InputNumber, message, Popconfirm, Skeleton, Steps } from 'antd'
import { LabeledValue } from 'antd/lib/select'
import Axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Cognito from '../../../config/Cognito'
import { ManufacturerSelect } from '../Update/components/Select/ManufacturerSelect'
import { NewPartsStepContentProps, StyledPaginationWrapper, UploadContentWrapper } from './common'
import { BatchNewPriceSelect } from './components/BatchNewPriceSelect'
import { NewPricesDataStep } from './components/NewPricesDataStep'
import { S3Uploader } from './components/Panel/S3Uploader'

export const PriceFile: React.FC = () => {
  return (
    <CountParsedPrices>
      {({ data, error, loading }) => {
        if (error) {
          message.error(error.message)
        }
        if (loading) {
          return <Skeleton active />
        }
        return <PriceFileComponent data={data && data.countParsedPrices} />
      }}
    </CountParsedPrices>
  )
}

const PriceFileComponent: React.FC<{
  data?: ParsePricesResult
}> = ({ data }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [discarding, setDiscarding] = useState(false)
  useEffect(() => {
    if (!data || data.total === 0) {
      setCurrentStep(0)
    } else {
      setCurrentStep(1)
    }
  }, [data])
  const handleDiscardAll = () => {
    if (selectedBatch) {
      message.info('Discarding...')
      setDiscarding(true)
      deleteAllPricesMutation(
        {
          batchName: selectedBatch,
        },
        {
          refetchQueries: [{ query: COUNT_QUEUED_PRICES }, { query: BATCH_LIST_QUERY }],
          awaitRefetchQueries: true,
        },
      )
        .then(() => {
          message.success('Successfully deleted')
          setSelectedBatch(undefined)
        })
        .catch(e => {
          message.error(e.message)
        })
        .finally(() => {
          setDiscarding(false)
        })
    }
  }
  const hasData = data && data.total > 0
  const [selectedBatch, setSelectedBatch] = useState<string>()

  return (
    <UploadContentWrapper>
      <Steps current={currentStep} onChange={setCurrentStep}>
        <Steps.Step title="Upload" icon={<Icon type="upload" />} />
        <Steps.Step
          title="Handle Errors"
          disabled={!hasData}
          icon={<Icon type="exclamation-circle" />}
          description={hasData ? `${data!.discarded} records` : undefined}
        />
        <Steps.Step
          title="Approve"
          disabled={!hasData}
          icon={<Icon type="check-circle" />}
          description={hasData ? `${data!.valid} records` : undefined}
        />
        <Steps.Step
          title="Finish"
          icon={<Icon type="import" />}
          disabled={!hasData}
          description={hasData ? `${data?.approved} records` : undefined}
        />
      </Steps>
      {(currentStep === 1 || currentStep === 2 || currentStep === 3) && (
        <>
          <BatchNewPriceSelect value={selectedBatch} onChange={v => setSelectedBatch(v)} />
          {selectedBatch && (
            <NewPricesDataStep currentStep={currentStep} setCurrentStep={setCurrentStep} batchName={selectedBatch} />
          )}
        </>
      )}
      {currentStep === 0 && <UploadStep currentStep={currentStep} setCurrentStep={setCurrentStep} />}
      <StyledPaginationWrapper>
        <Button
          type="primary"
          icon="download"
          onClick={async () => {
            const session = await Cognito.getSession()
            const token = session.getAccessToken().getJwtToken()
            const baseUrl = Client.getUrl()
            Axios.post(
              `${window.location.protocol}//${baseUrl}/update-price-errors`,
              {},
              {
                responseType: 'blob',
                params: {
                  batchName: selectedBatch,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            ).then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', `${selectedBatch || 'price'}-error.xlsx`)
              document.body.appendChild(link)
              link.click()
            })
          }}>
          Export
        </Button>
        <Popconfirm title="Do you want to delete all queued new prices?" onConfirm={handleDiscardAll}>
          <Button type="danger" icon="delete" disabled={discarding}>
            Discard All
          </Button>
        </Popconfirm>
      </StyledPaginationWrapper>
    </UploadContentWrapper>
  )
}

const UploadStep: React.FC<Omit<NewPartsStepContentProps, 'batchName'>> = () => {
  const [processing, setProcessing] = useState(false)
  const [input, setInput] = useState<Partial<NewPricesInput>>({
    effectiveDate: new Date(),
  })
  const handleUploaded = () => {
    message.info('Processing data...')
    setProcessing(true)
    parsePricesMutation(
      {
        input: {
          ...input,
          id: input.id!,
          manufacturerId: input.manufacturerId!,
          batchName: input.batchName!,
        },
      },
      {
        refetchQueries: [{ query: COUNT_QUEUED_PRICES }, { query: BATCH_LIST_QUERY }],
        awaitRefetchQueries: true,
      },
      true,
      e => message.error(e.message),
    )
      .then(result => {
        if (!result || !result.data) {
          return
        }
        message.success(
          `Prices update file is successfully processed, ${result.data.parsePrices.total} total, ${result.data.parsePrices.valid} valid, ${result.data.parsePrices.discarded} discarded`,
        )
      })
      .finally(() => setProcessing(false))
  }
  // if (hasData) {
  //   return (
  //     <Result
  //       status="info"
  //       title="Not yet"
  //       subTitle="You need to finish current update before making new, discard or finish current process"
  //       extra={[
  //         <Button type="primary" key="console" onClick={() => setCurrentStep(1)}>
  //           Next
  //         </Button>,
  //       ]}
  //     />
  //   )
  // }
  return (
    <React.Fragment>
      <UploadStepWrapper>
        <Form.Item label="Manufacturer">
          <ManufacturerSelect
            disabled={processing}
            style={{ width: '100%' }}
            value={input.manufacturerId ? { key: input.manufacturerId, label: input.manufacturerId } : undefined}
            onChange={manufacturerOpt =>
              setInput({
                ...input,
                manufacturerId: (manufacturerOpt as LabeledValue).key,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Effective Date">
          <DatePicker
            disabled={processing}
            style={{ width: '100%' }}
            mode="date"
            value={moment(input.effectiveDate)}
            allowClear={false}
            onChange={newDate =>
              setInput({
                ...input,
                effectiveDate: newDate ? newDate.toDate() : new Date(),
              })
            }
          />
        </Form.Item>
        <Form.Item label="Period Type">
          <InputNumber
            disabled={processing}
            style={{ width: '100%' }}
            value={input.periodType ? parseInt(input.periodType, 10) : new Date().getFullYear()}
            onChange={num => {
              setInput({
                ...input,
                periodType: num?.toString(),
              })
            }}
          />
        </Form.Item>
        <Form.Item label="Batch Name">
          <Input
            disabled={processing}
            style={{ width: '100%' }}
            value={input.batchName}
            onChange={v =>
              setInput({
                ...input,
                batchName: v.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Update file">
          <S3Uploader
            onChange={e => {
              if (!input.batchName) {
                setInput({
                  ...input,
                  batchName: e.file.name,
                })
              }
            }}
            disabled={processing}
            onUploaded={key =>
              setInput(old => ({
                ...old,
                id: key,
              }))
            }
          />
        </Form.Item>
      </UploadStepWrapper>
      <StyledPaginationWrapper>
        <Button onClick={handleUploaded} disabled={!input.id || !input.manufacturerId || processing}>
          {processing && <Icon type="sync" spin />}
          {processing ? 'Processing' : 'Process'}
        </Button>
        <Button
          type="ghost"
          icon="download"
          onClick={() => window.open('/Update_Prices_Template.xlsx')}
          style={{ marginRight: 16 }}>
          Download Template
        </Button>
      </StyledPaginationWrapper>
    </React.Fragment>
  )
}

const UploadStepWrapper = styled.div`
  margin: auto;
  width: 300px;
`
