import { ProductLine, ProductLinesData } from '@curvo/apollo'
import { SelectInput } from '@curvo/common-ui'
import React from 'react'
import DebounceInput from './DebounceInput'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & {
  value: any
  onChange: (option: any) => void
}

const ProductLinesSelect: React.SFC<Props> = ({ value, onChange }) => (
  <DebounceInput>
    {({ searchText, onInputChange }) => (
      <ProductLinesData variables={{ searchText }}>
        {({ data, loading }) => (
          <SelectInput
            label="Product Line"
            onInputChange={onInputChange}
            options={
              data && data.productLines
                ? data.productLines.edges.map(edge => ({
                    label: edge.node.name,
                    value: edge.node.id,
                    extra: edge.node,
                  }))
                : []
            }
            renderOption={option => `${option.label} (${(option.extra as ProductLine).manufacturer.name})`}
            loading={loading}
            value={value}
            onChange={onChange}
            asyncMode
          />
        )}
      </ProductLinesData>
    )}
  </DebounceInput>
)

ProductLinesSelect.defaultProps = defaultProps

export default React.memo(ProductLinesSelect)
