import { Part, PartCreateInput, PartUpdateInput } from '@curvo/apollo'
import { Alert, Checkbox, Col, Form, Input, InputNumber, Row } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import TextArea from 'antd/lib/input/TextArea'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { EditDrawer } from '../../../../../components/EditDrawer'
import { EditPanelWrapper } from '../../../../../components/common'
import { useForm } from '../../../../../components/useForm'
import { PartSearchSelect } from '../../../DataCleaning/components/PartSearchAgWrapper'
import { BrandSelect } from '../Select/BrandSelect'
import { GICSelect } from '../Select/GICSelect'
import { AllowedGICsTypeOneSelect } from '../Select/GICTypeOneSelect'
import { AllowedGICsTypeTwoSelect } from '../Select/GICTypeTwoSelect'
import { ManufacturerSelect } from '../Select/ManufacturerSelect'
import { MaterialSelect } from '../Select/MaterialSelect'
import { ProductLineSelect } from '../Select/ProductLineSelect'
import { SegmentationSelect } from '../Select/SegmentationSelect'
import { EditMode } from './EditManufacturer'

const convertToEdit = (p?: Part): Partial<PartUpdateInput & PartCreateInput> =>
  p
    ? {
        ...p,
        brandId: p.brand ? p.brand.id : undefined,
        gicId: p.gic ? p.gic.id : undefined,
        materialId: p.material ? p.material.id : undefined,
        productLineId: p.productLine ? p.productLine.id : undefined,
        typeOneId: p.typeOne ? p.typeOne.id : undefined,
        typeTwoId: p.typeTwo ? p.typeTwo.id : undefined,
        segmentationId: p.segmentation ? p.segmentation.id : undefined,
        manufacturerId: p.manufacturer ? p.manufacturer.id : undefined,
      }
    : {}

const validateSchema = () => {
  const shape = {
    partName: Yup.string().required('Part Name is required'),
    partNumber: Yup.string().required('Part Number is required'),
    manufacturerId: Yup.string().required('Manufacturer is required'),
    gicId: Yup.string().required('GIC is required'),
  }
  return Yup.object().shape(shape)
}

export const EditPart: React.FunctionComponent<
  {
    submitting?: boolean
    onSubmit: (editingPart: Partial<PartUpdateInput> | Partial<PartCreateInput>) => void
    onCancel: () => void
    part?: Part
    editMode: EditMode
  } & DrawerProps
> = ({ part, submitting, onSubmit, onCancel, editMode, ...props }) => {
  const [values, onUpdatedField, editedPart] = useForm<PartUpdateInput & PartCreateInput, Part>(convertToEdit, part)
  const [validationErrors, setValidationErrors] = useState<string[]>([])

  const onUpdateField =
    (field: keyof (PartUpdateInput & PartCreateInput)) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onUpdatedField(field)(e.target.value)
    }

  const onUpdateNumberField = (field: keyof PartUpdateInput) => (value: number | undefined) =>
    onUpdatedField(field)(value)

  useEffect(() => {
    if (editedPart.typeOneId && values.typeOneId !== editedPart.typeOneId) {
      onUpdatedField('typeTwoId')(undefined)
    }
  }, [values.typeOneId, editedPart.typeOneId, onUpdatedField])

  const handleSubmitPart = () => {
    setValidationErrors([])
    const isEditting = part && editMode === EditMode.edit
    if (!isEditting) {
      try {
        validateSchema().validateSync(editedPart, { abortEarly: false })
      } catch (e) {
        if (e.name === 'ValidationError') {
          setValidationErrors(e.errors)
        }
        return
      }
    }
    onSubmit(isEditting ? { id: part.id, ...editedPart } : editedPart)
  }

  return (
    <EditDrawer
      loading={submitting}
      onSave={() => handleSubmitPart()}
      onCancel={() => {
        onCancel()
      }}
      {...props}>
      {part && part.createdAt && (
        <StyledCreatedAtSpan>{`Created at: ${moment(parseInt(part.createdAt, 10)).toISOString()}`}</StyledCreatedAtSpan>
      )}
      <EditPanelWrapper>
        {validationErrors && validationErrors.length > 0 && (
          <Row gutter={16}>
            <Alert
              message="Validation Failed:"
              description={
                <ul style={{ margin: '0 0 0 16px' }}>
                  {validationErrors.map((m, i) => (
                    <li key={i}>{m}</li>
                  ))}
                </ul>
              }
              type="error"
            />
          </Row>
        )}
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Manufacturer" required>
              <ManufacturerSelect
                value={
                  (editedPart as Partial<PartCreateInput>).manufacturerId
                    ? {
                        key: (editedPart as Partial<PartCreateInput>).manufacturerId!,
                        label: part && part.manufacturer && part.manufacturer.name,
                      }
                    : editMode === EditMode.edit && part && part.manufacturer
                    ? { key: part.manufacturer.id, label: part.manufacturer.name }
                    : undefined
                }
                obsolete={EditMode.create ? true : undefined}
                onChange={value => onUpdatedField('manufacturerId')(value.key)}
                disabled={submitting || editMode === EditMode.edit}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Part name" required>
              <Input value={values.partName || ''} onChange={onUpdateField('partName')} disabled={submitting} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Input Name" required={editMode !== EditMode.edit}>
              <Input
                value={values.partNumber || undefined}
                disabled={submitting || editMode === EditMode.edit}
                onChange={onUpdateField('partNumber')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Major Brand">
              <BrandSelect
                value={
                  values.brandId
                    ? {
                        key: values.brandId,
                        label: part && part.brand ? part.brand.name : values.brandId,
                      }
                    : undefined
                }
                manufacturerId={values?.manufacturerId || undefined}
                onChange={value => onUpdatedField('brandId')(value.key)}
                disabled={submitting}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Product Line">
              <ProductLineSelect
                value={
                  values.productLineId
                    ? {
                        key: values.productLineId,
                        label: part && part.productLine ? part.productLine.name : values.productLineId,
                      }
                    : undefined
                }
                manufacturerId={values?.manufacturerId || undefined}
                onChange={value => onUpdatedField('productLineId')(value.key)}
                disabled={submitting}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Mtl Product Line">
              <Input
                value={values.mtlProductLine || ''}
                onChange={onUpdateField('mtlProductLine')}
                disabled={submitting}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="GIC" required>
              <GICSelect
                value={
                  values.gicId
                    ? {
                        key: values.gicId.toString(),
                        label: part ? `${part.gic.id}.${part.gic.name}` : values.gicId.toString(),
                      }
                    : undefined
                }
                onChange={value => onUpdatedField('gicId')(parseInt(value.key, 10))}
                disabled={submitting}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Gic Type One">
              <AllowedGICsTypeOneSelect
                gicId={values.gicId || undefined}
                value={
                  values.typeOneId
                    ? {
                        key: values.typeOneId,
                        label: part && part.typeOne ? part.typeOne.name : values.typeOneId,
                      }
                    : undefined
                }
                onChange={v => onUpdatedField('typeOneId')(v && v.key)}
                disabled={submitting}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Gic Type Two">
              <AllowedGICsTypeTwoSelect
                disabled={submitting || (!values.gicId && !(part && part.gic))}
                gicId={values!.gicId || (part && part.gic.id)}
                typeOneId={values.typeOneId || undefined}
                value={
                  values.typeTwoId
                    ? {
                        key: values.typeTwoId,
                        label: part && part.typeTwo ? part.typeTwo.name : values.typeTwoId,
                      }
                    : undefined
                }
                onChange={v => onUpdatedField('typeTwoId')(v && v.key)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Part Attributes">
              <Input
                value={values.partAttributes || ''}
                onChange={onUpdateField('partAttributes')}
                disabled={submitting}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Material">
              <MaterialSelect
                value={
                  values.materialId
                    ? {
                        key: values.materialId,
                        label: part && part.material ? `${part.material.id}.${part.material.name}` : undefined,
                      }
                    : undefined
                }
                onChange={value => onUpdatedField('materialId')(value.key)}
                disabled={submitting}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Sizes">
              <Input.Group compact>
                <InputNumber
                  step={0.01}
                  value={values.sizeOne || 0}
                  onChange={onUpdateNumberField('sizeOne')}
                  disabled={submitting}
                />
                <InputNumber
                  step={0.01}
                  value={values.sizeTwo || 0}
                  onChange={onUpdateNumberField('sizeTwo')}
                  disabled={submitting}
                />
                <InputNumber
                  step={0.01}
                  value={values.sizeThree || 0}
                  onChange={onUpdateNumberField('sizeThree')}
                  disabled={submitting}
                />
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="MFG Size">
              <Input value={values.mfgSize || ''} onChange={onUpdateField('mfgSize')} disabled={submitting} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Quantity per box">
              <InputNumber
                value={values.quantityPerBox || 0}
                onChange={onUpdateNumberField('quantityPerBox')}
                disabled={submitting}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Side">
              <Input value={values.side || ''} onChange={onUpdateField('side')} disabled={submitting} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Uom">
              <Input value={values.uom || ''} onChange={onUpdateField('uom')} disabled={submitting} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="ID 510k">
              <Input value={values.id_510k || ''} onChange={onUpdateField('id_510k')} disabled={submitting} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item label="Is custom">
              <Checkbox
                checked={!!values.isCustom}
                onChange={e => onUpdatedField('isCustom')(e.target.checked)}
                disabled={submitting}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Is Reprocessed">
              <Checkbox
                checked={!!values.isReprocessed}
                onChange={e => onUpdatedField('isReprocessed')(e.target.checked)}
                disabled={submitting}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Is Sterile">
              <Checkbox
                checked={!!values.isSterile}
                onChange={e => onUpdatedField('isSterile')(e.target.checked)}
                disabled={submitting}
              />
            </Form.Item>
          </Col>
          {editMode ? (
            <Col span={4}>
              <Form.Item label="Is Inactive">
                <Checkbox
                  checked={!!values.isInactive}
                  onChange={e => onUpdatedField('isInactive')(e.target.checked)}
                  disabled={submitting}
                />
              </Form.Item>
            </Col>
          ) : null}
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Data source">
              <Input value={values.dataSource || ''} onChange={onUpdateField('dataSource')} disabled={submitting} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Segmentation">
              <SegmentationSelect
                value={
                  values.segmentationId
                    ? {
                        key: values.segmentationId,
                        label:
                          part && part.segmentation
                            ? `${part.segmentation.segment && part.segmentation.segment.name} & ${
                                part.segmentation.subSegment && part.segmentation.subSegment.name
                              }`
                            : values.segmentationId,
                      }
                    : undefined
                }
                onChange={value => onUpdatedField('segmentationId')(value.key)}
                disabled={submitting}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="UDI">
          <TextArea value={values.udiId || ''} onChange={onUpdateField('udiId')} disabled={submitting} />
        </Form.Item>

        <Form.Item label="Note">
          <TextArea value={values.description || ''} onChange={onUpdateField('description')} disabled={submitting} />
        </Form.Item>
        {editMode ? (
          <Form.Item label="Duplicate Part">
            <PartSearchSelect onChange={e => onUpdatedField('duplicateId')(e.key)} disabled={submitting} />
          </Form.Item>
        ) : null}
      </EditPanelWrapper>
    </EditDrawer>
  )
}

const StyledCreatedAtSpan = styled.span`
  font-size: 11px;
  opacity: 0.8;
  font-style: italic;
  text-align: right;
  padding-right: 16px;
  width: 100%;
  float: right;
`
