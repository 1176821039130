import { SegmentationData } from '@curvo/apollo'
import { LabeledValue, SelectProps } from 'antd/lib/select'
import React from 'react'
import styled from 'styled-components'
import { StyledSelect, StyledSelectPropsType, TypedSelect } from './common'

export const SegmentationSelect: React.FC<SelectProps<LabeledValue> & StyledSelectPropsType> = props => {
  return (
    <SegmentationData>
      {({ data, loading }) => (
        <StyledSelect
          loading={loading}
          showSearch
          labelInValue
          filterOption={true}
          showArrow={false}
          defaultActiveFirstOption={false}
          placeholder="Select Segmentation"
          {...props}>
          {((data && data.segmentations) || []).map(seg => (
            <TypedSelect.Option
              key={props.fullData ? JSON.stringify(seg) : seg.id}
              value={props.fullData ? JSON.stringify(seg) : seg.id}>
              <OptionTitleSpan>{seg.id}</OptionTitleSpan>
              <span>{`${seg.segment && seg.segment.name} | ${seg.subSegment && seg.subSegment.name}`}</span>
            </TypedSelect.Option>
          ))}
        </StyledSelect>
      )}
    </SegmentationData>
  )
}

const OptionTitleSpan = styled.span`
  font-weight: bold;
  margin-right: 8px;
`
