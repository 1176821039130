import { Client } from '@curvo/apollo'
import Cognito from './Cognito'

Client.configClient({
  protocol: 'https',
  wsProtocol: 'wss',
  getToken: async () => {
    const session = await Cognito.getSession()
    return session.getAccessToken().getJwtToken()
  },
  enableSubscription: true,
})

export default Client
