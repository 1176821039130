import { generateBulkUpdateFileUploadUrlMutation } from '@curvo/apollo'
import { Icon, message, Upload } from 'antd'
import { UploadProps } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import axios from 'axios'
import React, { useState, useEffect } from 'react'

type S3UploaderProps = UploadProps & { isDragger?: boolean; onUploaded: (s3Key: string) => void }

export const S3Uploader: React.FC<S3UploaderProps> = ({
  onUploaded,
  onChange,
  accept,
  fileList,
  isDragger = true,
  children,
  ...uploadProps
}) => {
  const [newFileList, setNewFileList] = useState<S3UploadFile[]>([])

  useEffect(() => {
    setNewFileList([])
  }, [fileList])

  const props: UploadProps = {
    onChange: info => {
      if (onChange) {
        onChange(info)
      }
      if (info.file.status === 'removed') {
        setNewFileList(newFileList.filter(file => file.uid !== info.file.uid))
        return
      }
      upload(info.file, file => {
        if (file.status === 'success') {
          onUploaded(file.s3Id!)
        }
        const others = newFileList.filter(newFile => newFile.uid !== info.file.uid)
        setNewFileList([...others, file])
      })
    },
    beforeUpload: () => false,
    accept: accept || '.xlsx,.csv',
    fileList: [...(fileList || []), ...newFileList],
    multiple: false,
    ...uploadProps,
  }

  const childrenRender = children || (
    <>
      <p className="ant-upload-drag-icon">
        <Icon type="inbox" />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
    </>
  )

  return isDragger ? (
    <Upload.Dragger {...props}>{childrenRender}</Upload.Dragger>
  ) : (
    <Upload {...props}>{childrenRender}</Upload>
  )
}

type S3UploadFile = UploadFile & {
  s3Id?: string
}

const upload = async (file: S3UploadFile, setFileStatus: (file: S3UploadFile) => void): Promise<void> => {
  const fetchResult = await generateBulkUpdateFileUploadUrlMutation({
    extension: getExtension(file.name),
  })
  if (!fetchResult) {
    throw new Error()
  }
  const { data, errors } = fetchResult
  if (errors) {
    message.error(errors[0].message)
    throw errors[0]
  }
  if (!data) {
    throw new Error()
  }
  setFileStatus({
    ...file,
    status: 'uploading',
    percent: 0,
  })
  axios
    .put(data.generateBulkUpdateFileUploadUrl.uploadUrl, file, {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        setFileStatus({
          ...file,
          name: file.name,
          percent: percentCompleted,
        })
      },
    })
    .catch(e => {
      setFileStatus({
        ...file,
        name: file.name,
        status: 'error',
        error: e.message,
      })
    })
    .then(() => {
      setFileStatus({
        ...file,
        name: file.name,
        status: 'success',
        s3Id: data.generateBulkUpdateFileUploadUrl.key,
      })
    })
}

const getExtension = (fileName: string): string | undefined => {
  const dotIdx = fileName.lastIndexOf('.')
  return dotIdx !== -1 ? fileName.slice(dotIdx + 1) : undefined
}
