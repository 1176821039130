import { ProductLinesQueryArgs, ProductLinesData } from '@curvo/apollo'
import { Select } from 'antd'
import { LabeledValue, SelectProps } from 'antd/lib/select'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'
import { StyledSelect, StyledSelectPropsType } from './common'

const { Option } = Select

export const ProductLineSelect: React.FunctionComponent<
  SelectProps<LabeledValue> &
    StyledSelectPropsType & {
      search?: string
      manufacturerId?: string
    }
> = ({ search, manufacturerId, ...props }) => {
  const [searchText, setSearchText] = useState(search)
  const [query, setQuery] = useState<ProductLinesQueryArgs>({})

  useEffect(() => {
    setQuery({
      ...(searchText ? { searchText: searchText } : {}),
      ...(manufacturerId ? { manufacturerId: manufacturerId } : {}),
    })
  }, [manufacturerId, searchText])

  return (
    <ProductLinesData variables={query} fetchPolicy="network-only">
      {({ data, loading }) => (
        <StyledSelect
          onSearch={debounce(setSearchText, 800)}
          loading={loading}
          showSearch
          labelInValue
          filterOption={false}
          showArrow={false}
          defaultActiveFirstOption={false}
          placeholder="Select Product Line"
          {...props}>
          {data &&
            data.productLines &&
            data.productLines.edges.map(edge => (
              <Option key={props.fullData ? JSON.stringify(edge.node) : edge.node.id}>{edge.node.name}</Option>
            ))}
        </StyledSelect>
      )}
    </ProductLinesData>
  )
}
