"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addUnit = exports.isNotUndefinedOrNull = void 0;
const isNotUndefinedOrNull = (value) => value !== undefined && value !== null;
exports.isNotUndefinedOrNull = isNotUndefinedOrNull;
const addUnit = (value) => {
    if (value === undefined || value === null) {
        return '';
    }
    if (typeof value === 'number') {
        if (value === 0) {
            return `${value}`;
        }
        return `${value}px`;
    }
    return value;
};
exports.addUnit = addUnit;
