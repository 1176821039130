import { MeWithoutPaymentContext, Role } from '@curvo/apollo'
import { Layout } from 'antd'
import React, { useContext, useMemo, useState } from 'react'
import { Routes, useNavigate } from 'react-router'
import { Route } from 'react-router-dom'
import styled from 'styled-components'
import { Header } from '../../components/Header'
import { NavigationItem, Navigator } from '../../components/Navigator'
import ProtectedRoute from '../../components/ProtectedRoute'
import { BlocklistPage } from './Blocklist/Blocklist'
import { CategoryRules } from './CategoryRules/CategoryRules'
import { DataCleaning } from './DataCleaning/DataCleaning'
import { QueuedLinkMaster } from './QueuedLinkMaster/QueuedLinkMaster'
import { UpdatePage } from './Update/Update'
import { UploadPage } from './Upload/Upload'

const menus: (NavigationItem & { permissions?: Role[] })[] = [
  {
    routes: [
      { path: 'new-parts', label: 'New Parts' },
      { path: 'price-file', label: 'Price File' },
    ],
    label: 'Upload',
    iconType: 'tool',
    prefixUrl: '/upload',
  },
  {
    routes: [
      { path: 'parts', label: 'Parts' },
      { path: 'parts-grid', label: 'Parts Grid' },
      { path: 'gics', label: 'GICs' },
      { path: 'manufacturers', label: 'Manufacturers' },
      { path: 'materials', label: 'Materials' },
      { path: 'segmentations', label: 'Segmentations' },
      { path: 'segments', label: 'Segments' },
      { path: 'brands', label: 'Brands' },
      { path: 'product-line', label: 'Product Lines' },
      { path: 'normalized-supplier', label: 'Normalized Supplier' },
    ],
    label: 'Update',
    iconType: 'table',
    prefixUrl: '/update',
  },
  {
    label: 'Import',
    iconType: 'solution',
    prefixUrl: '/data-cleaning',
    routes: [
      { path: '', label: 'Studies' },
      { path: 'norm-suppliers', label: 'Normalize Suppliers' },
    ],
  },
  {
    label: 'Master',
    iconType: 'table',
    prefixUrl: '/master',
    permissions: [Role.DataEnrichmentAdmin],
  },
  {
    routes: [{ path: 'matching-suggestion', label: 'Matching Suggestion' }],
    label: 'Blocklist',
    iconType: 'table',
    prefixUrl: '/blocklist',
    permissions: [Role.DataEnrichmentAdmin],
  },
  {
    label: 'Category rules',
    iconType: 'table',
    prefixUrl: '/category-rules',
    permissions: [Role.DataEnrichmentAdmin],
  },
]

export const MainPage: React.FC = () => {
  const navigate = useNavigate()
  const [navCollapsed, setNavCollapsed] = useState(false)
  const meData = useContext(MeWithoutPaymentContext)
  const userRole = meData?.data?.role
  const accessibleMenus = useMemo(
    () => menus.filter(r => !userRole || !r.permissions || r.permissions.includes(userRole)),
    [userRole],
  )

  const WithRouterNavigator = Navigator
  return (
    <MainContainer>
      <Sider collapsible width="256" trigger={null} collapsed={navCollapsed}>
        <WithRouterNavigator
          menus={accessibleMenus}
          collapsed={navCollapsed}
          onMenuItemClick={(prefixUrl, route) => navigate(`${prefixUrl}/${route ? route.path : ''}`)}
        />
      </Sider>
      <Layout>
        <Header navCollapsed={navCollapsed} setNavCollapsed={setNavCollapsed} />
        <Content style={{ flex: 1 }}>
          <Routes>
            <Route path="/update/*" element={<UpdatePage />} />
            <Route path="/upload/*" element={<UploadPage />} />
            <Route path="/data-cleaning/*" element={<DataCleaning />} />
            <Route
              path="/master/*"
              element={
                <ProtectedRoute roles={[Role.DataEnrichmentAdmin]}>
                  <QueuedLinkMaster />
                </ProtectedRoute>
              }
            />
            <Route
              path="/blocklist/*"
              element={
                <ProtectedRoute roles={[Role.DataEnrichmentAdmin]}>
                  <BlocklistPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/category-rules/*"
              element={
                <ProtectedRoute roles={[Role.DataEnrichmentAdmin]}>
                  <CategoryRules />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Content>
      </Layout>
    </MainContainer>
  )
}

const { Sider, Content } = Layout

const MainContainer = styled(Layout)`
  min-height: 100vh;
`
