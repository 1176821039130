import '@ag-grid-community/all-modules/dist/styles/ag-grid.css'
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css'
import { AgGridColumnProps, AgGridReact } from '@ag-grid-community/react'
import {
  AllModules,
  CheckboxSelectionCallbackParams,
  ColumnState,
  FillEndEvent,
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  IHeaderParams,
  MenuItemDef,
} from '@ag-grid-enterprise/all-modules'
import {
  BamfToHiMatchesBulkUpdateInput,
  FindAndReplaceStudyTransactionsMutationArgs,
  Gic,
  GicTypeOne,
  GicTypeTwo,
  ManufacturerType,
  Material,
  RematchTransactionsInput,
  STUDIES_QUERY,
  Segment,
  StringFilterType,
  StudyStatusEnum,
  StudyTransactionBulkUpdateInput,
  StudyTransactionFindAndReplaceInput,
  StudyTransactionState,
  StudyTransactionType,
  bulkUpdateBamfToHIMatchesMutation,
  bulkUpdateStudyTransactionsMutation,
  commitStudy,
  createNewPartsFromStudyTransaction,
  findAndReplaceStudyTransactionsMutation,
  rematchStudyTransactionsMutation,
} from '@curvo/apollo'
import { Button, DatePicker, Icon, Input, PageHeader, Progress, Select, message } from 'antd'
import { Omit, isNil, omit, pick, range } from 'lodash'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import styled from 'styled-components'
import { currencyFormatter } from './common'
import { GICAgWrapper } from './components/GICAgWrapper'
import { GicTypeOneAgWrapper } from './components/GicTypeOneAgWrapper'
import { GicTypeTwoAgWrapper } from './components/GicTypeTwoAgWrapper'
import { GroomTransactionsQueryType, GroomingCache } from './components/GroomingCache'
import { GroomingMatchedRatioAgComponent } from './components/GroomingMatchedRatioAgComponent'
import { updateGroomQueryArgs, useLoadStudyTransactions } from './components/LoadStudyTransactionsHook'
import { ManufacturerSelectAgWrapper } from './components/ManufacturerSelectAgWrapper'
import { MaterialSelectAgWrapper } from './components/MaterialSelectAgWrapper'
import { PartSelectAgWrapper } from './components/PartSelectAgWrapper'
import { SegmentSelectAgWrapper } from './components/SegmentSelectAgWrapper'
import { StudyTransactionStateFilter } from './components/StudyTransactionStateFilter'

type GridRefProps = React.RefObject<AgGridReact & { api: GridApi; step?: StudyTransactionState }>

type GroomingContextProps = {
  gridRef: React.RefObject<AgGridReact & { api: GridApi; step?: StudyTransactionState }>
  studyId: number
}

const ID_PER_UPDATE_CHUNK = 500

const GROOMING_COLUMN_STATE_PROP = 'groomingColumnState'

export enum MatchEnum {
  Error,
  Warning,
  Success,
}

export const StudyTransactionGroom: React.FC = () => {
  const navigate = useNavigate()
  const routeParams = useParams()
  const studyId = parseInt(routeParams.studyId!, 10)

  const savedQueryArgs = GroomingCache.savedQueryArgs.get(studyId)
  const [queryArgs, setQueryArgs] = useState<GroomTransactionsQueryType>(
    savedQueryArgs || {
      state: StudyTransactionState.BamfMatching,
    },
  )

  // to indicate study's min/max datepurchase were used to update queryArgs, one-time action
  const [updatedDatepurchase, setUpdatedDatepurchase] = useState(false)

  // quick hack to re-render to update selected rows each time onSelectionChanged
  const [version, setVersion] = useState(0)

  const gridRef = useRef<AgGridReact & { api: GridApi; step?: StudyTransactionState }>(null)

  const { error, progress, loading, updatedStudy } = useLoadStudyTransactions(studyId, gridRef, queryArgs, true)
  if (error) {
    message.error(error.message)
  }

  useEffect(() => {
    if (updatedStudy && updatedStudy.maxDatePurchase && !updatedDatepurchase) {
      setQueryArgs(args => updateGroomQueryArgs(args, updatedStudy))
      setUpdatedDatepurchase(true)
    }
  }, [updatedStudy, updatedDatepurchase])

  const pageHeaderExtras = [
    // @ts-ignore
    <DatePicker.RangePicker
      value={[
        (queryArgs.datepurchaseFrom && moment(queryArgs.datepurchaseFrom, 'YYYY-MM-DD')) || undefined,
        (queryArgs.datepurchaseTo && moment(queryArgs.datepurchaseTo, 'YYYY-MM-DD')) || undefined,
      ]}
      disabledDate={(current: moment.Moment | null) => {
        if (!updatedStudy || !updatedStudy.minDatePurchase || !updatedStudy.maxDatePurchase) {
          return false
        }
        if (current && current.isBefore(updatedStudy.minDatePurchase)) {
          return true
        }
        if (current && current.isAfter(updatedStudy.maxDatePurchase)) {
          return true
        }
        return false
      }}
      onChange={v =>
        setQueryArgs({
          ...queryArgs,
          datepurchaseFrom: (v[0] && v[0].format('YYYY-MM-DD')) || undefined,
          datepurchaseTo: (v[1] && v[1].format('YYYY-MM-DD')) || undefined,
        })
      }
    />,
    <Select
      style={{ width: 150 }}
      value={queryArgs.state}
      onChange={(v: StudyTransactionState) => {
        const manufacturerType =
          v === StudyTransactionState.SupplierMatching ? queryArgs.manufacturerType || ManufacturerType.O : undefined
        setQueryArgs({ ...queryArgs, state: v, manufacturerType })
        if (gridRef.current) {
          const api = gridRef.current.api
          const stateFilter: StudyTransactionStateFilter = api.getFilterInstance('state') as any
          stateFilter.getModel().state = v
          gridRef.current.step = v
        }
      }}>
      <Select.Option key="AI" value={StudyTransactionState.BamfMatching}>
        AI Matches
      </Select.Option>
      <Select.Option key="HI" value={StudyTransactionState.SupplierMatching}>
        HI Matches
      </Select.Option>
      <Select.Option key="BL" value={StudyTransactionState.BlacklistSupplier}>
        Blacklist Supplier
      </Select.Option>
      <Select.Option key="BLI" value={StudyTransactionState.BlacklistStrippedItem}>
        Blacklist Items
      </Select.Option>
      <Select.Option key="Ignored" value={StudyTransactionState.IgnoreSupplier}>
        Ignored Supplier
      </Select.Option>
      <Select.Option key="Approved" value={StudyTransactionState.FinalReview}>
        Approved
      </Select.Option>
    </Select>,
    <Select
      style={{ width: 150 }}
      allowClear
      value={queryArgs.manufacturerType}
      onChange={v => {
        setQueryArgs({ ...queryArgs, manufacturerType: v })
      }}
      placeholder="Select Type">
      <Select.Option key="O" value={ManufacturerType.O}>
        Ortho
      </Select.Option>
      <Select.Option key="P" value={ManufacturerType.P}>
        PPI
      </Select.Option>
      <Select.Option key="I" value={ManufacturerType.I}>
        Instruments
      </Select.Option>
      <Select.Option key="L" value={ManufacturerType.L}>
        Laboratory
      </Select.Option>
      <Select.Option key="Other" value={ManufacturerType.Other}>
        Other
      </Select.Option>
      <Select.Option key="Unknown" value={ManufacturerType.Unknown}>
        Unknown NO BL
      </Select.Option>
    </Select>,
    <Button
      type="primary"
      onClick={() => {
        commitStudy({ id: studyId }, { refetchQueries: [{ query: STUDIES_QUERY }] })
        navigate('/data-cleaning/')
      }}>
      Commit Study
    </Button>,
  ]

  const pageTitle = `Study ${studyId} ${
    updatedStudy && updatedStudy.status === StudyStatusEnum.AutoMatching ? '(Auto-matching inprogress)' : ''
  }`

  const pageSubTitle = updatedStudy && `Facility ID: ${updatedStudy.facilityId}, Facility: ${updatedStudy.facilityName}`

  return (
    <div style={{ height: '100%', flexDirection: 'column', display: 'flex' }}>
      <PageHeader
        title={pageTitle}
        subTitle={pageSubTitle}
        onBack={() => navigate('/data-cleaning/')}
        extra={pageHeaderExtras}
      />
      <div
        style={{
          marginLeft: '24px',
          marginRight: '24px',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
        }}>
        {loading && <Progress percent={progress} />}
        <div className="ag-theme-balham" style={{ flexGrow: 1, display: 'flex', width: 'fill-parent' }}>
          <div style={{ width: '100%', height: 'auto' }}>
            <AgGridReact
              suppressRowClickSelection
              ref={gridRef}
              columnDefs={genColumns(studyId, gridRef)}
              enableFillHandle={true}
              defaultColDef={{
                sortable: true,
                filter: true,
                resizable: true,
                headerCheckboxSelectionFilteredOnly: true,
                headerCheckboxSelection: isFirstColumn,
                checkboxSelection: isFirstColumn,
              }}
              enableRangeSelection={true}
              fillOperation={params => {
                if (typeof params.initialValues[0] === 'string') {
                  return params.initialValues[0]
                }
                return false
              }}
              modules={AllModules}
              rowSelection="multiple"
              rowGroupPanelShow="always"
              groupSelectsChildren={true}
              getContextMenuItems={params => [...getContextMenuItems(gridRef, studyId)(params), 'copy']}
              onFillEnd={handleOnFillEnd(studyId, gridRef)}
              applyColumnDefOrder={false}
              onGridReady={onGridReady}
              getRowNodeId={node => node.id}
              autoGroupColumnDef={{
                headerName: 'Group',
                cellRendererParams: {
                  checkbox: true,
                },
                comparator: (_valueA, _valueB, nodeA, nodeB) => {
                  return (
                    ((nodeA.childrenAfterFilter && nodeA.childrenAfterFilter.length) || 0) -
                    ((nodeB.childrenAfterFilter && nodeB.childrenAfterFilter.length) || 0)
                  )
                },
              }}
              onSelectionChanged={() => {
                setVersion(version + 1)
              }}
              enableGroupEdit={true}
              onDragStopped={e => {
                const columnState = JSON.stringify(
                  e.columnApi.getColumnState().map(colState => pick(colState, ['colId', 'width', 'hide', 'pinned'])),
                )
                localStorage.setItem(GROOMING_COLUMN_STATE_PROP, columnState)
              }}
              statusBar={{
                statusPanels: [
                  {
                    statusPanelFramework: GroomingMatchedRatioAgComponent,
                    align: 'right',
                    statusPanelParams: { aggFunc: ['count'] },
                  },
                ],
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '2em',
            justifyContent: 'space-between',
          }}>
          {queryArgs.state === StudyTransactionState.SupplierMatching && (
            <FindAndReplaceControls
              studyId={studyId}
              gridRef={gridRef}
              onFindAndReplace={args => findAndReplaceStudyTransactionsMutation(args)}
            />
          )}
          <ControlsWrapper>
            {queryArgs.state === StudyTransactionState.BamfMatching && (
              <BamfMatchingButtons studyId={studyId} gridRef={gridRef} />
            )}
            {queryArgs.state === StudyTransactionState.SupplierMatching && (
              <SupplierMatchingButtons studyId={studyId} gridRef={gridRef} />
            )}
            {queryArgs.state === StudyTransactionState.IgnoreSupplier && (
              <IgnoreSupplierButtons studyId={studyId} gridRef={gridRef} />
            )}
            {queryArgs.state === StudyTransactionState.BlacklistSupplier && (
              <BlacklistSupplierButtons studyId={studyId} gridRef={gridRef} />
            )}
            {queryArgs.state === StudyTransactionState.BlacklistStrippedItem && (
              <BlacklistStrippedItemButtons studyId={studyId} gridRef={gridRef} />
            )}
            {queryArgs.state === StudyTransactionState.FinalReview && (
              <ApprovedItemButtons studyId={studyId} gridRef={gridRef} />
            )}
          </ControlsWrapper>
        </div>
      </div>
    </div>
  )
}

/**
 * Components
 */

const MatchingCheckAgRenderer = ({ value }) => {
  if (value === undefined) {
    return ''
  }
  if (value === MatchEnum.Success) {
    return <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
  }
  if (value === MatchEnum.Error) {
    return <Icon type="close-circle" twoToneColor="#eb2f96" theme="twoTone" />
  }
  return <Icon type="info-circle" theme="twoTone" twoToneColor="#ffd75a" />
}

export const ControlsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row-reverse;
`

const getBlacklistMutationInput = (
  params: GetContextMenuItemsParams,
): Omit<StudyTransactionBulkUpdateInput, 'studyId'> | undefined => {
  if (params.node.group) {
    switch (params.node.field) {
      case 'vendor': {
        return {
          vendor: Object.values<string>(params.node.groupData)[0],
          blacklistVendor: true,
        }
      }
      case 'manufacturer': {
        return {
          manufacturer: Object.values<string>(params.node.groupData)[0],
          blacklistManufacturer: true,
        }
      }
    }
  }
  switch (params.column.getColDef().field) {
    case 'vendor': {
      return {
        vendor: params.value,
        blacklistVendor: true,
      }
    }
    case 'manufacturer': {
      return {
        manufacturer: params.value,
        blacklistManufacturer: true,
      }
    }
    case 'venitem': {
      if (params.node.data) {
        return {
          ids: [params.node.data.id],
          blacklistVenitem: true,
        }
      }
      message.error('You can only blacklist venitem/mfgitem one at a time')
      return undefined
    }
    case 'mfgitem': {
      if (params.node.data) {
        return {
          ids: [params.node.data.id],
          blacklistMfgitem: true,
        }
      }
      message.error('You can only blacklist venitem/mfgitem one at a time')
      return undefined
    }
    default: {
      return undefined
    }
  }
}

const getManufacturerString = (data?: StudyTransactionType): string => {
  if (data && data.matchManufacturerId) {
    return `${data.matchManufacturerId} | ${data.bamfManufacturer || ''}`
  }
  return ''
}

export const transformForBamfString = (str: string | null | undefined, stripped?: boolean) => {
  const nicedStr = (str || '').trim().toUpperCase()
  return stripped ? nicedStr.replace(/[^a-zA-Z0-9]/g, '') : nicedStr
}

const handleOnFillEnd =
  (studyId: number, gridRef: GridRefProps) =>
  async ({ api, finalRange, initialRange }: FillEndEvent) => {
    if (finalRange.columns.length > 1) {
      return
    }
    const selectedColumn = finalRange.columns[0]
    const initTransaction =
      initialRange.endRow && (api.getDisplayedRowAtIndex(initialRange.endRow.rowIndex)?.data as StudyTransactionType)

    const updatingParams = ((): Partial<StudyTransactionBulkUpdateInput> | undefined => {
      switch (selectedColumn.getColDef().headerName) {
        case 'Matched Supplier':
          return initTransaction && { matchManufacturerId: initTransaction.matchManufacturerId }
        case 'Gic':
          return (
            initTransaction && {
              gic: initTransaction.gicId ? { id: initTransaction.gicId, name: initTransaction.gicName || '' } : null,
            }
          )
        case 'Type One':
          return (
            initTransaction && {
              typeOne: initTransaction.typeOneId
                ? { id: initTransaction.typeOneId, name: initTransaction.typeOneName || '' }
                : null,
            }
          )
        case 'Type Two':
          return (
            initTransaction && {
              typeTwo: initTransaction.typeTwoId
                ? {
                    id: initTransaction.typeTwoId,
                    name: initTransaction.typeTwoName || '',
                  }
                : null,
            }
          )
        case 'Material':
          return (
            initTransaction && {
              material: initTransaction.materialId
                ? {
                    id: initTransaction.materialId,
                    name: initTransaction.materialName || '',
                  }
                : null,
            }
          )
        case 'Segment':
          return (
            initTransaction && {
              segment: initTransaction.segmentId
                ? {
                    id: initTransaction.segmentId,
                    name: initTransaction.segmentName || '',
                  }
                : null,
            }
          )
        default:
          return undefined
      }
    })()

    if (updatingParams === undefined) {
      return
    }
    const start = finalRange.startRow!.rowIndex
    const end = finalRange.endRow!.rowIndex
    const selectedIds = range(start, end + 1).map(
      index => (api.getDisplayedRowAtIndex(index)?.data as StudyTransactionType).id,
    )
    doBulkUpdateStudyTransactions({ studyId, ids: selectedIds, ...updatingParams }, gridRef)
  }

const doBulkUpdateStudyTransactions = async (input: StudyTransactionBulkUpdateInput, gridRef: GridRefProps) => {
  const gridApi = gridRef.current && gridRef.current.api
  gridApi && gridApi.showLoadingOverlay()
  try {
    const chunks =
      input.ids && input.ids.length > ID_PER_UPDATE_CHUNK
        ? Array.from(Array(Math.ceil(input.ids.length / ID_PER_UPDATE_CHUNK)).keys()).map(i => ({
            ...input,
            ids: input.ids!.slice(i * ID_PER_UPDATE_CHUNK, Math.min((i + 1) * ID_PER_UPDATE_CHUNK, input.ids!.length)),
          }))
        : [input]
    const results = await Promise.all(chunks.map(chunk => bulkUpdateStudyTransactionsMutation({ input: chunk })))

    results.forEach(result => {
      if (result) {
        const { data, errors } = result
        if (errors) {
          message.error('Update failed, please reload web page!')
        }
        if (data) {
          const updated = data.updateBulkStudyTransactions
          updateUpdatedRows(updated, gridRef, input.studyId)
        }
      }
    })
    gridApi && gridApi.deselectAll()
    message.success('Update success')
  } catch (e) {
    message.error(e.message)
  } finally {
    gridApi && gridApi.hideOverlay()
  }
}

const updateUpdatedRows = (updated: StudyTransactionType[], gridRef: GridRefProps, studyId: number) => {
  GroomingCache.addToStudy(updated, studyId, GroomingCache.savedQueryArgs.get(studyId)!)
  const gridApi = gridRef.current && gridRef.current.api
  if (gridApi) {
    gridApi.updateRowData({
      update: updated,
    })
    gridApi.onFilterChanged()
  }
}

const addNewPartFromTransaction = (gridRef: GridRefProps) => {
  const selectedRows = gridRef.current && gridRef.current.api.getSelectedRows()
  if (selectedRows && selectedRows.length) {
    createNewPartsFromStudyTransaction({
      inputs: selectedRows.map(currentRow => ({
        manufacturerId: currentRow.matchManufacturerId,
        partNumber: currentRow.venitem || currentRow.mfgitem,
        partName: currentRow.description || '',
        strippedPartNumber: transformForBamfString(currentRow.venitem || currentRow.mfgitem),
      })),
    })
      .then(() => {
        gridRef.current && gridRef.current.api.deselectAll()
        message.success('Added new part to queued part')
      })
      .catch(e => {
        message.error(e.message)
      })
  }
}

const getSelectedRows = (gridRef: GridRefProps) => {
  return gridRef.current ? gridRef.current.api.getSelectedRows() : []
}

const getContextMenuItems =
  (gridRef: GridRefProps, studyId: number) =>
  (params: GetContextMenuItemsParams): MenuItemDef[] => {
    const step = gridRef.current && gridRef.current.step
    if (step === StudyTransactionState.SupplierMatching) {
      const input = getBlacklistMutationInput(params)
      if (input) {
        const field = params.node.group ? params.node.field : params.column.getColDef().field
        const blacklistMenu = {
          name: `Blacklist this ${field}`,
          action: () => {
            doBulkUpdateStudyTransactions({ ...input, studyId }, gridRef)
          },
        }
        if (field === 'vendor' || field === 'manufacturer') {
          return [
            blacklistMenu,
            {
              name: `Ignore this ${field}`,
              action: () => {
                doBulkUpdateStudyTransactions(
                  {
                    studyId,
                    [field]: params.value,
                    [field === 'vendor' ? 'ignoreVendor' : 'ignoreManufacturer']: true,
                  },
                  gridRef,
                )
              },
            },
          ]
        }
        return [blacklistMenu]
      }
    }

    return []
  }

const doBulkUpdateBamfToHIMatches = async (input: BamfToHiMatchesBulkUpdateInput, gridRef: GridRefProps) => {
  const gridApi = gridRef.current && gridRef.current.api
  gridApi && gridApi.showLoadingOverlay()
  try {
    const chunks =
      input.ids && input.ids.length > ID_PER_UPDATE_CHUNK
        ? Array.from(Array(Math.ceil(input.ids.length / ID_PER_UPDATE_CHUNK)).keys()).map(i => ({
            ...input,
            ids: input.ids!.slice(i * ID_PER_UPDATE_CHUNK, Math.min((i + 1) * ID_PER_UPDATE_CHUNK, input.ids!.length)),
          }))
        : [input]
    const results = await Promise.all(chunks.map(chunk => bulkUpdateBamfToHIMatchesMutation({ input: chunk })))

    results.forEach(result => {
      if (result) {
        const { data, errors } = result
        if (errors) {
          message.error('Update failed, please reload web page!')
        }
        if (data) {
          const updated = data.bulkUpdateBamfToHIMatches
          updateUpdatedRows(updated, gridRef, input.studyId)
        }
      }
    })

    gridApi && gridApi.deselectAll()
    message.success('Update success')
  } catch (e) {
    message.error(e.message)
  } finally {
    gridApi && gridApi.hideOverlay()
  }
}

const doRematchStudyTransactions = async (
  { ids, numberOfZeros, isLeadingZeros }: Omit<RematchTransactionsInput, 'studyId'>,
  gridRef: GridRefProps,
  studyId: number,
) => {
  const gridApi = gridRef.current && gridRef.current.api
  gridApi && gridApi.hideOverlay()
  gridApi && gridApi.showLoadingOverlay()
  try {
    const chunks =
      ids && ids.length > ID_PER_UPDATE_CHUNK
        ? Array.from(Array(Math.ceil(ids.length / ID_PER_UPDATE_CHUNK)).keys()).map(i =>
            ids.slice(i * ID_PER_UPDATE_CHUNK, Math.min((i + 1) * ID_PER_UPDATE_CHUNK, ids.length)),
          )
        : [ids]
    const results = await Promise.all(
      chunks.map(chunk =>
        rematchStudyTransactionsMutation({
          input: {
            ids: chunk,
            studyId,
            numberOfZeros,
            isLeadingZeros,
          },
        }),
      ),
    )

    results.forEach(result => {
      if (result) {
        const { data, errors } = result
        if (errors) {
          message.error('Update failed, please reload web page!')
        }
        if (data) {
          const updated = data.rematchStudyTransactions
          updateUpdatedRows(updated, gridRef, studyId)
        }
      }
    })

    gridApi && gridApi.deselectAll()
    message.success('Update success')
  } catch (e) {
    message.error(e.message)
  } finally {
    gridApi && gridApi.hideOverlay()
  }
}

const genColumns = (studyId: number, gridRef: GridRefProps): AgGridColumnProps[] => [
  {
    headerName: '#',
    colId: 'index',
    valueGetter: ({ node }) => (!isNil(node?.rowIndex) ? node!.rowIndex + 1 : null),
    width: 50,
    pinned: true,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
  },
  {
    headerName: 'Matched Supplier',
    colId: 'matchedSupplier',
    editable: true,

    cellEditorFramework: ManufacturerSelectAgWrapper,
    valueGetter: ({ data }) => getManufacturerString(data),
    valueSetter: ({ data, node, newValue }) => {
      if (newValue !== undefined) {
        if ((newValue === null || newValue.key) && node) {
          const updateQueryParams: StudyTransactionBulkUpdateInput = node.group
            ? {
                ids: (node.childrenAfterFilter || [])
                  .map(row => row.id)
                  .filter(id => !!id)
                  .map(id => id!),
                studyId,
                isSupplierVen: node.field === 'vendor',
              }
            : {
                ids: [data.id],
                studyId,
              }
          doBulkUpdateStudyTransactions(
            { ...updateQueryParams, matchManufacturerId: newValue ? newValue.key : newValue },
            gridRef,
          )
          return true
        }
      }

      return false
    },
    enableRowGroup: true,
    showRowGroup: false,
  },
  {
    headerName: 'Matched Part',
    colId: 'matchedPart',
    editable: true,
    cellEditorFramework: PartSelectAgWrapper,

    valueGetter: ({ data }) => {
      if (!data) {
        return ''
      }
      const bamfPartNumber = data.bamfPartId && data.bamfPartNumber
      const matchPartNumber = data.matchPartId && data.bamfPartNumber
      if (data.aiOverwrite) {
        return matchPartNumber
      }
      return bamfPartNumber || matchPartNumber
    },
    valueSetter: ({ data, node, newValue }) => {
      if (node?.group) {
        message.error('You can only edit Part for one transaction at a time')
        return false
      }
      if (newValue !== undefined) {
        doBulkUpdateStudyTransactions(
          {
            studyId,
            ids: [data.id],
            [data.state === StudyTransactionState.BamfMatching ? 'bamfPartId' : 'matchPartId']:
              newValue && newValue.key,
          },
          gridRef,
        )
        return true
      }
      return false
    },
  },
  {
    headerName: 'Matched Description',
    colId: 'matchedDescription',
    field: 'bamfPartDescription',

    valueGetter: ({ data }) => data && (data.bamfPartId || data.matchPartId) && data.bamfPartDescription,
  },
  {
    headerName: 'IN Vendor',
    colId: 'vendor',
    field: 'vendor',
    enableRowGroup: true,
    showRowGroup: false,
  },
  {
    headerName: 'IN Vendor Item',
    field: 'venitem',
    colId: 'venitem',
    valueGetter: ({ data }) => data && data.venitem && data.venitem.toString(),
    editable: true,
    valueSetter: ({ data, node, newValue }) => {
      if (node?.group) {
        message.error('You can only edit venitem for one transaction at a time')
        return false
      }
      if (newValue !== undefined) {
        doBulkUpdateStudyTransactions(
          {
            studyId,
            ids: [data.id],
            venitem: newValue && newValue.toString(),
          },
          gridRef,
        )
        return true
      }
      return false
    },
  },
  {
    headerName: 'IN Manufacturer',
    colId: 'manufacturer',
    field: 'manufacturer',
    enableRowGroup: true,
    showRowGroup: false,
  },
  {
    headerName: 'IN Manufacturer Item',
    field: 'mfgitem',
    colId: 'mfgitem',
    editable: true,
    valueSetter: ({ data, node, newValue }) => {
      if (node?.group) {
        message.error('You can only edit mfg for one transaction at a time')
        return false
      }
      if (newValue !== undefined) {
        doBulkUpdateStudyTransactions(
          {
            studyId,
            ids: [data.id],
            mfgitem: newValue && newValue.toString(),
          },
          gridRef,
        )
        return true
      }
      return false
    },
  },
  {
    headerName: 'IN Description',
    colId: 'description',
    field: 'description',
    editable: true,
    valueSetter: () => false,
  },
  {
    headerName: 'Unit Price',
    field: 'unitprice',
    colId: 'unitprice',
    valueFormatter: currencyFormatter,
    filter: 'agNumberColumnFilter',
    type: 'numericColumn',
  },
  {
    headerName: 'Gic',
    field: 'gicName',
    colId: 'gicName',
    editable: true,
    cellEditorFramework: GICAgWrapper,
    valueSetter: ({ data, node, newValue }) => {
      if (node?.group) {
        message.error('You can only edit gic for one transaction at a time')
        return false
      }
      if (newValue !== undefined) {
        if (newValue !== null) {
          doBulkUpdateStudyTransactions(
            {
              studyId,
              ids: [data.id],
              gic: omit<Gic & { __typename?: string }, 'imageUrl' | '__typename'>(newValue, ['imageUrl', '__typename']),
            },
            gridRef,
          )
        } else {
          doBulkUpdateStudyTransactions(
            {
              studyId,
              ids: [data.id],
              gic: null,
            },
            gridRef,
          )
        }
        return true
      }
      return false
    },
  },
  {
    headerName: 'Type One',
    colId: 'typeOneName',
    field: 'typeOneName',
    editable: true,
    cellEditorFramework: GicTypeOneAgWrapper,
    valueSetter: ({ data, node, newValue }) => {
      if (node?.group) {
        message.error('You can only edit gic type one for one transaction at a time')
        return false
      }
      if (newValue && newValue.id) {
        doBulkUpdateStudyTransactions(
          {
            studyId,
            ids: [data.id],
            typeOne: omit<GicTypeOne & { __typename?: string }>(newValue, [
              '__typename',
              'includes',
              'excludes',
            ]) as GicTypeOne,
          },
          gridRef,
        )
        return true
      }
      return false
    },
  },
  {
    headerName: 'Type Two',
    field: 'typeTwoName',
    colId: 'typeTwoName',
    editable: true,
    cellEditorFramework: GicTypeTwoAgWrapper,
    valueSetter: ({ data, node, newValue }) => {
      if (node?.group) {
        message.error('You can only edit gic type two for one transaction at a time')
        return false
      }
      if (newValue && newValue.id) {
        doBulkUpdateStudyTransactions(
          {
            studyId,
            ids: [data.id],
            typeTwo: omit<GicTypeTwo & { __typename?: string }>(newValue, [
              '__typename',
              'includes',
              'excludes',
            ]) as GicTypeTwo,
          },
          gridRef,
        )
        return true
      }
      return false
    },
  },
  {
    headerName: 'Segment',
    field: 'segmentName',
    colId: 'segmentName',
    editable: true,
    cellEditorFramework: SegmentSelectAgWrapper,
    valueSetter: ({ data, node, newValue }) => {
      if (node?.group) {
        message.error('You can only edit segment for one transaction at a time')
        return false
      }
      if (newValue && newValue.id) {
        doBulkUpdateStudyTransactions(
          {
            studyId,
            ids: [data.id],
            segment: omit<Segment & { __typename?: string }, '__typename'>(newValue, ['__typename']),
          },
          gridRef,
        )
        return true
      }
      return false
    },
  },
  {
    headerName: 'Material',
    field: 'materialName',
    colId: 'materialName',
    editable: true,
    cellEditorFramework: MaterialSelectAgWrapper,
    valueSetter: ({ data, node, newValue }) => {
      if (node?.group) {
        message.error('You can only edit material for one transaction at a time')
        return false
      }
      if (newValue && newValue.id) {
        doBulkUpdateStudyTransactions(
          {
            studyId,
            ids: [data.id],
            material: omit<Material & { __typename?: string }, '__typename'>(newValue, ['__typename']),
          },
          gridRef,
        )
        return true
      }
      return false
    },
  },
  { headerName: 'PO Num', editable: true, field: 'ponum', valueSetter: () => false },
  {
    headerName: 'Date',
    field: 'datepurchase',
    colId: 'datepurchase',
    valueGetter: ({ data }) => data && data.datepurchase && moment(data.datepurchase).format('YYYY-MM-DD'),
  },
  { headerName: 'State', field: 'state', colId: 'state', hide: true, filter: StudyTransactionStateFilter },
  {
    headerName: 'Match',
    colId: 'match',
    valueGetter: ({ data }) => {
      if (!data || !data.bamfPartNumber) {
        return undefined
      }
      const strippedVenitem = data && data.venitem && transformForBamfString(data.venitem, true)
      const strippedMfgitem = data && data.mfgitem && transformForBamfString(data.mfgitem, true)
      const strippedBamfPartNumber = data && data.bamfPartNumber && transformForBamfString(data.bamfPartNumber, true)
      const matchPart = strippedVenitem === strippedBamfPartNumber ? true : strippedMfgitem === strippedBamfPartNumber
      const matchVendor =
        data.normVendorIds && (data.normVendorIds as string[]).find(supId => data.matchManufacturerId === supId)
      const matchManufacturer =
        data.normManufacturerIds &&
        (data.normManufacturerIds as string[]).find(supId => data.matchManufacturerId === supId)
      if (!matchPart) {
        return MatchEnum.Error
      }
      if (matchVendor || matchManufacturer) {
        return MatchEnum.Success
      }
      return MatchEnum.Warning
    },
    width: 80,
    cellRendererFramework: MatchingCheckAgRenderer,
  },
  {
    headerName: 'WASTE',
    field: 'waste',
    colId: 'waste',
    width: 80,
    editable: true,
    valueSetter: ({ data, node, newValue: rawNewValue }) => {
      if (rawNewValue === 'true' || rawNewValue === 'false') {
        const newValue = rawNewValue === 'true'
        const updateQueryParams: StudyTransactionBulkUpdateInput = node?.group
          ? {
              ids: (node?.childrenAfterFilter || [])
                .map(row => row.id)
                .filter(id => !!id)
                .map(id => id!),
              studyId,
              isSupplierVen: node?.field === 'vendor',
            }
          : {
              ids: [data.id],
              studyId,
            }
        doBulkUpdateStudyTransactions({ ...updateQueryParams, waste: newValue }, gridRef)
        return true
      }

      return false
    },
    cellRendererFramework: ({ value }) => (value !== undefined ? <Icon type={value ? 'check' : 'close'} /> : null),
  },
]

const SupplierMatchingButtons: React.FC<GroomingContextProps> = ({ studyId, gridRef }) => {
  const [replacePosition, setReplacePosition] = useState('Leading')
  const [numberOfZeros, setNumberOfZeros] = useState(0)
  return (
    <React.Fragment>
      <Button
        icon="check"
        onClick={() =>
          doBulkUpdateStudyTransactions(
            { studyId, isApproved: true, ids: getSelectedRows(gridRef).map(row => row.id) },
            gridRef,
          )
        }>
        Approve
      </Button>
      <Button onClick={() => addNewPartFromTransaction(gridRef)}>Add to queued Part</Button>
      <Select value={replacePosition} onChange={(v: string) => setReplacePosition(v)}>
        <Select.Option key={1} value="Leading">
          Leading 0
        </Select.Option>
        <Select.Option key={2} value="Trailing">
          Trailing 0
        </Select.Option>
      </Select>
      <Select value={numberOfZeros} onChange={(v: number) => setNumberOfZeros(v)}>
        {[0, 1, 2, 3, 4].map(i => (
          <Select.Option key={i} value={i}>
            {i}
          </Select.Option>
        ))}
      </Select>
      <Button
        icon="check"
        onClick={() =>
          doRematchStudyTransactions(
            {
              ids: getSelectedRows(gridRef).map(row => row.id),
              isLeadingZeros: replacePosition === 'Leading',
              numberOfZeros,
            },
            gridRef,
            studyId,
          )
        }>
        Retry with
      </Button>
    </React.Fragment>
  )
}

const IgnoreSupplierButtons: React.FC<GroomingContextProps> = ({ studyId, gridRef }) => (
  <React.Fragment>
    <Button
      onClick={() =>
        doBulkUpdateStudyTransactions(
          {
            ignoreVendor: false,
            ignoreManufacturer: false,
            studyId,
            ids: getSelectedRows(gridRef).map(row => row.id),
          },
          gridRef,
        )
      }>
      Remove ignored supplier
    </Button>
  </React.Fragment>
)

const BlacklistSupplierButtons: React.FC<GroomingContextProps> = ({ studyId, gridRef }) => (
  <React.Fragment>
    <Button
      onClick={() =>
        doBulkUpdateStudyTransactions(
          {
            studyId,
            blacklistManufacturer: false,
            blacklistVendor: false,
            ids: getSelectedRows(gridRef).map(row => row.id),
          },
          gridRef,
        )
      }>
      Remove blacklist supplier
    </Button>
  </React.Fragment>
)

const BlacklistStrippedItemButtons: React.FC<GroomingContextProps> = ({ studyId, gridRef }) => (
  <React.Fragment>
    <Button
      onClick={() =>
        doBulkUpdateStudyTransactions(
          {
            studyId,
            blacklistMfgitem: false,
            blacklistVenitem: false,
            ids: getSelectedRows(gridRef).map(row => row.id),
          },
          gridRef,
        )
      }>
      Remove blacklist Items
    </Button>
  </React.Fragment>
)

const ApprovedItemButtons: React.FC<GroomingContextProps> = ({ studyId, gridRef }) => (
  <Button
    onClick={() =>
      doBulkUpdateStudyTransactions(
        { studyId, isApproved: false, ids: getSelectedRows(gridRef).map(row => row.id) },
        gridRef,
      )
    }>
    Undo approval
  </Button>
)

const isValidRegexPattern = (input: string) => {
  try {
    const reg = new RegExp(input)
    reg.flags
  } catch (e) {
    return false
  }
  return true
}

export const FindAndReplaceControls: React.FC<
  GroomingContextProps & {
    onFindAndReplace: (args: FindAndReplaceStudyTransactionsMutationArgs, usingRegex?: boolean) => Promise<any>
    hasRegexOption?: boolean
  }
> = ({ studyId, gridRef, onFindAndReplace, hasRegexOption }) => {
  const [findAndReplaceColumn, setFindAndReplaceColumn] = useState<keyof StudyTransactionType>('vendor')
  const [filterType, setFilterType] = useState(StringFilterType.EndsWith)
  const [findTerm, setFindTerm] = useState('')
  const [replaceTerm, setReplaceTerm] = useState('')

  const updateGrid = () => {
    message.success('Success')
    if (gridRef.current) {
      gridRef.current.api.forEachNode(node => {
        if (node.group) {
          return
        }
        const transaction = node.data
        let found = false
        const value = (transaction[findAndReplaceColumn] as any) || ''

        switch (filterType) {
          case StringFilterType.Equals:
            found = transaction[findAndReplaceColumn] === findTerm
            break
          case StringFilterType.Contains:
            found = value.indexOf(findTerm) > -1
            break
          case StringFilterType.StartsWith:
            found = value.startsWith(findTerm)
            break
          case StringFilterType.EndsWith:
            found = value.endsWith(findTerm)
            break
          case StringFilterType.NotContains:
            found = value.indexOf(findTerm) === -1
            break
          case StringFilterType.Regex:
            found = value.match(new RegExp(findTerm))
            break
        }
        if (found) {
          node.setData({
            ...transaction,
            [findAndReplaceColumn]: value.replace(
              filterType === StringFilterType.EndsWith
                ? new RegExp(`${findTerm}$`)
                : filterType === StringFilterType.Regex
                ? new RegExp(findTerm)
                : findTerm,
              replaceTerm,
            ),
          })
        }
      })
    }
  }

  return (
    <ControlsWrapper>
      <React.Fragment>
        <Button
          disabled={filterType === StringFilterType.Regex && !isValidRegexPattern(findTerm)}
          onClick={() => {
            const input: StudyTransactionFindAndReplaceInput = {
              studyId,
              filterType,
              find: findTerm,
              replace: replaceTerm,
              field: findAndReplaceColumn,
            }
            if (gridRef.current) {
              gridRef.current.api.showLoadingOverlay()
            }
            onFindAndReplace({ input })
              .then(updateGrid)
              .catch(e => {
                message.error(e.message)
              })
              .finally(() => {
                if (gridRef.current) {
                  gridRef.current.api.hideOverlay()
                }
              })
          }}>
          Find & Replace
        </Button>
        <Input.Group compact style={{ marginRight: '1em' }}>
          <Select
            style={{ width: 150 }}
            placeholder="Select column"
            value={findAndReplaceColumn}
            onChange={v => setFindAndReplaceColumn(v)}>
            <Select.Option key={1} value="vendor">
              IN Vendor
            </Select.Option>
            <Select.Option key={2} value="venitem">
              IN Venitem
            </Select.Option>
          </Select>
          <Select style={{ width: 150 }} value={filterType} onChange={v => setFilterType(v)}>
            <Select.Option key={StringFilterType.Equals} value={StringFilterType.Equals}>
              {StringFilterType.Equals.toString()}
            </Select.Option>
            <Select.Option key={StringFilterType.Contains} value={StringFilterType.Contains}>
              {StringFilterType.Contains.toString()}
            </Select.Option>
            <Select.Option key={StringFilterType.EndsWith} value={StringFilterType.EndsWith}>
              {StringFilterType.EndsWith.toString()}
            </Select.Option>
            <Select.Option key={StringFilterType.StartsWith} value={StringFilterType.StartsWith}>
              {StringFilterType.StartsWith.toString()}
            </Select.Option>
            <Select.Option key={StringFilterType.NotContains} value={StringFilterType.NotContains}>
              {StringFilterType.NotContains.toString()}
            </Select.Option>
            {hasRegexOption && (
              <Select.Option key={StringFilterType.Regex} value={StringFilterType.Regex}>
                {StringFilterType.Regex.toString()}
              </Select.Option>
            )}
          </Select>

          <Input
            style={{ width: 150 }}
            placeholder="Find"
            value={findTerm}
            onChange={e => setFindTerm(e.target.value)}
          />
          <Input
            style={{ width: 150 }}
            placeholder="Replace"
            value={replaceTerm}
            onChange={e => setReplaceTerm(e.target.value)}
          />
        </Input.Group>
      </React.Fragment>
    </ControlsWrapper>
  )
}

const BamfMatchingButtons: React.FC<GroomingContextProps> = ({ studyId, gridRef }) => (
  <React.Fragment>
    <Button
      icon="check"
      onClick={() =>
        doBulkUpdateStudyTransactions(
          { studyId, isApproved: true, ids: getSelectedRows(gridRef).map(row => row.id) },
          gridRef,
        )
      }>
      Approve
    </Button>
    <Button
      icon="swap-right"
      onClick={() =>
        doBulkUpdateBamfToHIMatches({ studyId, ids: getSelectedRows(gridRef).map(row => row.id) }, gridRef)
      }>
      Move to HI Matches
    </Button>
  </React.Fragment>
)

const onGridReady = ({ columnApi, api }: GridReadyEvent) => {
  const columnStateStr = localStorage.getItem(GROOMING_COLUMN_STATE_PROP)
  if (columnStateStr) {
    const savedColumnStates: ColumnState[] = JSON.parse(columnStateStr)
    const currentColumnStates = columnApi.getColumnState()
    const unsavedColumns = currentColumnStates.filter(
      col => !savedColumnStates.find(savedCol => savedCol.colId === col.colId),
    )

    columnApi.setColumnState([...savedColumnStates, ...unsavedColumns])
    columnApi.setColumnVisible('state', false)
  }
  const stateFilter: StudyTransactionStateFilter = api.getFilterInstance('state') as any
  if (!stateFilter.getModel().state) {
    stateFilter.getModel().state = StudyTransactionState.BamfMatching
  }
  const unitpriceColumn = columnApi.getColumn('unitprice')
  if (unitpriceColumn) {
    columnApi.setColumnAggFunc(unitpriceColumn, 'sum')
    columnApi.addValueColumn(unitpriceColumn)
  }

  columnApi.moveColumn('index', 0)
}

function isFirstColumn({ columnApi, column }: IHeaderParams | CheckboxSelectionCallbackParams) {
  return columnApi?.getAllDisplayedColumns()[0] === column
}
