import { StudyLinkType, StudyLinksQueryArgs } from '@curvo/apollo'

export class StudiesMap {
  public studies: Map<number, Map<string, StudyLinkType>>
  public savedQueryArgs: Map<number, StudyLinksQueryArgs>

  constructor() {
    this.studies = new Map<number, Map<string, StudyLinkType>>()
    this.savedQueryArgs = new Map<number, StudyLinksQueryArgs>()
  }

  private getOrCreateStudy(studyId: number): Map<string, StudyLinkType> {
    if (this.studies.has(studyId)) {
      return this.studies.get(studyId)!
    }

    const study = new Map<string, StudyLinkType>()
    this.studies.set(studyId, study)
    return study
  }

  public addToStudy(studyLinks: StudyLinkType[], studyId: number, newQueryArgs: StudyLinksQueryArgs) {
    const study = this.getOrCreateStudy(studyId)
    this.savedQueryArgs.set(studyId, newQueryArgs)

    studyLinks.forEach(studyLink => {
      study.set(studyLink.id, studyLink)
    })
  }
}

export const StudyLinkCache = new StudiesMap()
