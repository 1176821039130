import { GridApi } from '@ag-grid-community/core'
import {
  BasketLookupInput,
  BasketLookupType,
  createBasketLookupMutation,
  deleteBasketLookupsMutation,
  updateBasketLookupsMutation,
  useLazyBasketLookupsQuery,
} from '@curvo/apollo'
import { Button, message } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { FetchDataParams, ServerSideGrid } from '../../../components/ServerSideGrid'
import { CategoryRuleFormDrawer } from './CategoryRuleFormDrawer'
import { columns } from './CategoryRulesColumns'

const CATEGORY_RULES_GRID = 'CATEGORY_RULES_GRID'

export const CategoryRulesGrid: React.FC = () => {
  const gridApiRef = useRef<GridApi | null>()
  const [selectedRows, setSelectedRows] = useState<BasketLookupType[]>([])
  const [formVisible, setFormVisible] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [getData, { loading }] = useLazyBasketLookupsQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })
  const [editingRecord, setEdittingRecord] = useState<BasketLookupType>()

  const fetchData = useCallback(
    (request: FetchDataParams) => {
      return getData({
        variables: {
          selectedFields: request.selectedFields,
          startRow: request.startRow,
          endRow: request.endRow,
          sorting: request.sorting,
          filter: request.filter,
        },
      }).then(resp => {
        const rows = resp.data?.basketLookups.edges.map(e => e.node) || []

        return {
          data: rows,
          total: resp.data?.basketLookups.metadata.total || 0,
        }
      })
    },
    [getData],
  )

  const handleRemoveRows = useCallback(() => {
    if (!gridApiRef.current) {
      return
    }
    if (!selectedRows?.length) {
      return
    }
    gridApiRef.current?.showLoadingOverlay()
    return deleteBasketLookupsMutation({ ids: selectedRows.map(r => r.id) })
      .then(() => {
        message.success('Removed!')
        gridApiRef.current?.deselectAll()
        gridApiRef.current?.refreshServerSideStore({ purge: true })
      })
      .finally(() => gridApiRef.current?.hideOverlay())
  }, [selectedRows])

  const handleSubmit = (values: BasketLookupInput) => {
    setSubmitting(true)
    if (!editingRecord) {
      createBasketLookupMutation({ input: values })
        .then(() => {
          gridApiRef.current?.refreshServerSideStore({ purge: true })
          message.success('Created')
        })
        .then(() => {
          setFormVisible(false)
        })
        .catch(e => {
          message.error(e.message)
        })
        .finally(() => setSubmitting(false))
    } else {
      updateBasketLookupsMutation({ input: values, ids: [editingRecord.id] })
        .then(() => {
          gridApiRef.current?.refreshServerSideStore({ purge: true })
          message.success('Updated')
        })
        .then(() => {
          setFormVisible(false)
          setEdittingRecord(undefined)
        })
        .catch(e => {
          message.error(e.message)
        })
        .finally(() => setSubmitting(false))
    }
  }

  useEffect(() => {
    setFormVisible(!!editingRecord)
  }, [editingRecord])

  return (
    <Container>
      <ToolbarContainer>
        <Button
          icon="plus"
          onClick={() => {
            setEdittingRecord(undefined)
            setFormVisible(true)
          }}>
          Create
        </Button>
        <Button
          icon="edit"
          disabled={selectedRows.length !== 1}
          onClick={() => {
            const selected = gridApiRef.current?.getSelectedRows()
            if (selected?.length === 1) {
              setEdittingRecord(selected[0])
            }
          }}>
          Edit
        </Button>
        <Button disabled={selectedRows.length === 0} icon="delete" onClick={handleRemoveRows}>
          Remove
        </Button>
      </ToolbarContainer>
      <ServerSideGrid
        gridKey={CATEGORY_RULES_GRID}
        gridApiRef={api => {
          gridApiRef.current = api
        }}
        columns={columns}
        fetching={loading}
        fetchData={fetchData}
        gridProps={{
          rowSelection: 'multiple',
          onSelectionChanged: e => {
            setSelectedRows(e.api.getSelectedRows())
          },
          getRowNodeId: data => data.id,
        }}
      />
      <CategoryRuleFormDrawer
        visible={formVisible}
        submitting={submitting}
        basketLookup={editingRecord}
        onCancel={() => {
          setFormVisible(false)
          setEdittingRecord(undefined)
        }}
        onSubmit={values => handleSubmit(values)}
      />
    </Container>
  )
}

const Container = styled.div`
  margin: 24px;
`

const ToolbarContainer = styled.div`
  margin: 16px 0;
  display: flex;
  gap: 8px;
`
