import React from 'react'
import _ from 'lodash'

const defaultProps = {}

const initialState = {
  value: '',
}

type RenderProps = {
  searchText: string
  onInputChange: (value: string) => void
}

type Props = Partial<typeof defaultProps> & {
  children: (props: RenderProps) => React.ReactNode
}

type State = Readonly<typeof initialState>

export default class DebounceInput extends React.PureComponent<Props, State> {
  static defaultProps = defaultProps

  constructor(props: Props) {
    super(props)
    this.state = initialState

    this.handleInputChange = _.debounce(this.handleInputChange, 500)
  }

  private handleInputChange = value => this.setState({ value })

  render() {
    const { value: searchText } = this.state
    const { handleInputChange: onInputChange } = this
    return this.props.children({ searchText, onInputChange })
  }
}
