import React from 'react'
import { Routes } from 'react-router'
import { Route } from 'react-router-dom'
import { BlocklistMatchingSuggestion } from './BlocklistMatchingSuggestion'

export const BlocklistPage: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path={'matching-suggestion/*'} element={<BlocklistMatchingSuggestion />} />
    </Routes>
  )
}
