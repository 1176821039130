import {
  AllowedGicsTypeOneData,
  AllowedGicsTypeOneQueryArgs,
  AllowedGicsTypeTwoQueryArgs,
  ALLOWED_GIC_TYPE_ONE_QUERY,
  ALLOWED_GIC_TYPE_TWO_QUERY,
  Gic,
  GicTypeOne,
  GicTypeTwo,
  updateAllowedGicTypeTwoMutation,
  updateGicTypeOneMutation,
  UpdateGicTypeTwoInput,
  updateGicTypeTwoMutation,
  useGicImagesData,
} from '@curvo/apollo'
import { Button, message, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React, { useEffect, useState } from 'react'
import { QueryResult } from '@apollo/client'
import { EditGicTypeOne } from '../routes/Main/Update/components/EditPanel/EditGicTypeOne'
import { EditGicTypeTwo } from '../routes/Main/Update/components/EditPanel/EditGicTypeTwo'

type GicTypeTwoNodeData = GicTypeTwo & { typeOneId: string }
type GicTypeOneNodeData = GicTypeOne & {
  children: GicTypeTwoNodeData[]
  loaded: boolean
}

export const AllowedGicsTypeOne: React.FunctionComponent<{ gic: Gic }> = ({ gic }) => {
  return (
    <AllowedGicsTypeOneData variables={{ gicId: gic.id }}>
      {result => <GICTypeOneTable result={result} gic={gic} />}
    </AllowedGicsTypeOneData>
  )
}

const GICTypeOneTable: React.FunctionComponent<{
  result: QueryResult<{
    allowedGicsTypeOne: GicTypeOne[]
  }>
  gic: Gic
}> = ({ result: { data, loading, error, client }, gic }) => {
  if (error) {
    message.error(error.message)
  }

  const { data: gicImagesData } = useGicImagesData({
    variables: {
      id: gic?.id!,
    },
    skip: !gic,
  })

  const gicImages = gicImagesData?.gicImages || []
  const [typeOnes, setTypeOnes] = useState<undefined | GicTypeOne[]>([])
  const [gicTypeOneNodes, setGicTypeOneNodes] = useState<GicTypeOneNodeData[]>([])
  const [selected, setSelected] = useState<GicTypeOne>()
  const [selectedTypeTwo, setSelectedTypeTwo] = useState<GicTypeTwoNodeData>()

  useEffect(() => {
    setTypeOnes(data && data.allowedGicsTypeOne)
  }, [data])

  useEffect(() => {
    if (typeOnes) {
      setGicTypeOneNodes(
        typeOnes.map(
          (typeOne): GicTypeOneNodeData => ({
            ...typeOne,
            loaded: false,
            children: [],
          }),
        ),
      )
    }
  }, [typeOnes, client])
  const columns = columnsGen((record, isTypeTwo) => {
    if (isTypeTwo) {
      setSelectedTypeTwo(record as any as GicTypeTwoNodeData)
    } else {
      setSelected(record)
    }
  })

  const handleUpdateRowsChange = async (updatedIds: any, newGicTypeOneNodes: GicTypeOneNodeData[]) => {
    const newTypeOnes = await Promise.all(
      newGicTypeOneNodes.map(async (typeOne): Promise<GicTypeOneNodeData> => {
        await client.query<{ allowedGicsTypeOne: GicTypeOne[] }, AllowedGicsTypeOneQueryArgs>({
          query: ALLOWED_GIC_TYPE_ONE_QUERY,
          variables: {
            gicId: gic.id,
          },
          fetchPolicy: 'network-only',
        })
        if (!typeOne.loaded && (updatedIds as string[]).indexOf(typeOne.id) === -1) {
          return typeOne
        }
        const typeTwos = await client.query<{ allowedGicsTypeTwo: GicTypeTwo[] }, AllowedGicsTypeTwoQueryArgs>({
          query: ALLOWED_GIC_TYPE_TWO_QUERY,
          variables: {
            gicId: gic.id,
            typeOneId: typeOne.id,
          },
          fetchPolicy: 'network-only',
        })

        return {
          ...typeOne,
          loaded: true,
          children: typeTwos.data.allowedGicsTypeTwo.map(typeTwo => ({
            ...typeTwo,
            typeOneId: typeOne.id,
          })),
        }
      }),
    )
    setGicTypeOneNodes(newTypeOnes)
  }

  const handleSubmitTypeOne = async (input, updateTypeOneInput) => {
    setSelected(undefined)
    Promise.all([updateAllowedGicTypeTwoMutation({ input }), updateGicTypeOneMutation({ input: updateTypeOneInput })])
      .then(() => {
        handleUpdateRowsChange(
          [input.gicTypeOneId],
          gicTypeOneNodes.map(typeOne =>
            typeOne.id !== input.gicTypeOneId
              ? typeOne
              : {
                  ...typeOne,
                  loaded: false,
                },
          ),
        )
      })
      .catch(e => message.error(e.message))
  }

  const handleSubmitTypeTwo = (gicTypeOneId, input: UpdateGicTypeTwoInput) => {
    setSelectedTypeTwo(undefined)
    updateGicTypeTwoMutation({ input }).then(() =>
      handleUpdateRowsChange(
        [gicTypeOneId],
        gicTypeOneNodes.map(typeOne =>
          typeOne.id !== gicTypeOneId
            ? typeOne
            : {
                ...typeOne,
                loaded: false,
              },
        ),
      ),
    )
  }

  return (
    <div>
      <span>GIC Type One</span>
      <Table
        loading={loading}
        dataSource={gicTypeOneNodes}
        columns={columns}
        pagination={false}
        bordered={false}
        onExpandedRowsChange={expandedIds => handleUpdateRowsChange(expandedIds, gicTypeOneNodes)}
        rowKey="id"
      />
      <EditGicTypeOne
        gicId={gic.id}
        gicTypeOne={selected}
        gicImages={gicImages}
        onCancel={() => setSelected(undefined)}
        visible={!!selected}
        width={250}
        onSubmit={handleSubmitTypeOne}
      />
      <EditGicTypeTwo
        gicId={gic.id}
        gicTypeOneId={selectedTypeTwo?.typeOneId}
        gicTypeTwo={selectedTypeTwo}
        gicImages={gicImages}
        onCancel={() => setSelectedTypeTwo(undefined)}
        visible={!!selectedTypeTwo}
        width={250}
        onSubmit={handleSubmitTypeTwo}
      />
    </div>
  )
}

const columnsGen: (
  onEdit: (record: GicTypeOneNodeData, isTypeTwo?: boolean) => void,
) => ColumnProps<GicTypeOneNodeData>[] = onEdit => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Type',
    key: 'type',
    render: (_, record) => <span>{record.children ? 'GIC type 1' : 'GIC type 2'}</span>,
  },
  {
    title: 'Action',
    key: 'action',
    width: 138,
    render: (_, record) => (
      <span>
        <Button type="link" onClick={() => onEdit(record, !record.children)}>
          Edit
        </Button>
      </span>
    ),
  },
]
