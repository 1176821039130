import { Material, MaterialUpdateInput } from '@curvo/apollo'
import { Form, Input } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import React, { useEffect, useState } from 'react'
import { EditPanelWrapper, emptyOrReturn } from '../../../../../components/common'
import { EditDrawer } from '../../../../../components/EditDrawer'

export const EditMaterial: React.FunctionComponent<
  {
    onSubmit: (editing: MaterialUpdateInput) => void
    onCancel: () => void
    material?: Material
  } & DrawerProps
> = ({ onSubmit, onCancel, material, ...drawerProps }) => {
  const [editing, setEditing] = useState<MaterialUpdateInput>()
  useEffect(() => {
    if (material) {
      setEditing({
        id: material.id,
      })
    }
  }, [material])

  const getValue = (field: keyof MaterialUpdateInput) =>
    editing && material && emptyOrReturn(editing[field], material[field] || '')
  const handleChange = (field: keyof MaterialUpdateInput) => (e: React.ChangeEvent<HTMLInputElement>) => {
    editing &&
      setEditing({
        ...editing,
        [field]: e.target.value,
      })
  }
  return (
    <EditDrawer onCancel={onCancel} onSave={() => editing && onSubmit(editing)} {...drawerProps}>
      {editing && (
        <EditPanelWrapper>
          <Form.Item label="ID">
            <Input value={getValue('id')} onChange={handleChange('id')} />
          </Form.Item>
          <Form.Item label="Name">
            <Input value={getValue('name')} onChange={handleChange('name')} />
          </Form.Item>
          <Form.Item label="Long Name">
            <Input value={getValue('longName')} onChange={handleChange('longName')} />
          </Form.Item>
        </EditPanelWrapper>
      )}
    </EditDrawer>
  )
}
