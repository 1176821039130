import { GridApi, RowNode } from '@ag-grid-community/all-modules'
import { SEGMENTS_QUERY, Segment, SegmentsData, createSegmentMutation } from '@curvo/apollo'
import LoadingIndicator from '@curvo/common-ui/dist/components/Loading/Spinner'
import { Icon } from 'antd'
import { LabeledValue, SelectProps } from 'antd/lib/select'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react'
import styled from 'styled-components'
import { TypedSelect } from './common'

type AgWrapperPropsType<T> = {
  data: T
  node: RowNode
  api: GridApi
}

type SegmentSelectProps = SelectProps<LabeledValue> & {
  createIfNotExist?: boolean
  onChangeFullValue?: (v?: Segment) => void
}

export const SegmentSelect: React.FC<SegmentSelectProps> = ({
  createIfNotExist,
  onChange,
  onChangeFullValue,
  ...props
}) => {
  const [searchText, setSearchText] = useState('')
  const dropdownRender = useCallback(
    (menu: React.ReactNode, options: Segment[]) =>
      !createIfNotExist || options.length > 0 ? (
        menu
      ) : (
        <div
          style={{ padding: '4px 8px', cursor: 'pointer' }}
          onMouseDown={e => e.preventDefault()}
          onClick={() => {
            createSegmentMutation(
              { input: { name: searchText } },
              { refetchQueries: [{ query: SEGMENTS_QUERY, variables: { searchText } }] },
            )
          }}>
          <Icon style={{ marginRight: '8px' }} type="plus" />
          {`Add Segment: ${searchText}`}
        </div>
      ),
    [searchText, createIfNotExist],
  )
  return (
    <SegmentsData variables={{ searchText }}>
      {({ data, loading }) => {
        const options = ((data && data.segments && data.segments.edges) || []).map(edge => edge.node)
        return (
          <StyledSelect
            loading={loading}
            onSearch={debounce(setSearchText, 800)}
            showSearch
            labelInValue
            filterOption={false}
            showArrow={false}
            defaultActiveFirstOption={false}
            placeholder="Select Segment"
            notFoundContent={loading ? <LoadingIndicator /> : 'No Data'}
            dropdownRender={menu => dropdownRender(menu, options)}
            onChange={(v, com) => {
              if (onChange) {
                onChange(v, com)
              }
              if (onChangeFullValue && v) {
                const selectedSegment = options.find(seg => seg.id === v.key)
                onChangeFullValue(selectedSegment)
              }
            }}
            {...props}>
            {options.map(seg => (
              <TypedSelect.Option key={seg.id}>{seg.name}</TypedSelect.Option>
            ))}
          </StyledSelect>
        )
      }}
    </SegmentsData>
  )
}

const StyledSelect = styled(TypedSelect)<{ children?: React.ReactNode }>`
  width: 250px;
`

export const SegmentSelectAgWrapper = React.forwardRef<{}, AgWrapperPropsType<Segment>>((params, ref) => {
  const { data, api } = params

  const [labeledValue, setLabeledValue] = useState<LabeledValue>()
  useImperativeHandle(ref, () => ({
    getValue: () => labeledValue || undefined,
  }))
  useEffect(() => {
    if (labeledValue) {
      api.stopEditing()
    }
  }, [labeledValue, api])

  const value = data ? { key: data.id, label: data.name } : undefined

  return <SegmentSelect value={value} allowClear onChange={v => setLabeledValue(v)} />
})

export const SegmentValueSelectAgWrapper = React.forwardRef<{}, AgWrapperPropsType<Segment>>((params, ref) => {
  const { data, api } = params

  const [fullValue, setFullValue] = useState<Segment>()
  useImperativeHandle(ref, () => ({
    getValue: () => fullValue || undefined,
  }))
  useEffect(() => {
    if (fullValue) {
      api.stopEditing()
    }
  }, [fullValue, api])

  const value = data ? { key: data.id, label: data.name } : undefined

  return <SegmentSelect value={value} allowClear onChangeFullValue={v => setFullValue(v)} />
})
