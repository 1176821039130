import { Button, Drawer } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import React from 'react'
import styled from 'styled-components'

export const EditDrawer: React.FunctionComponent<EditDrawerPropsType> = ({
  onSave,
  children,
  onCancel,
  isDisabled,
  loading,
  ...props
}) => (
  <Drawer width={700} placement="right" bodyStyle={{ padding: 0 }} onClose={onCancel} closable={false} {...props}>
    <EditDrawerBody>{children}</EditDrawerBody>
    <EditDrawerBottomActions>
      {onSave && (
        <ActionButton loading={loading} disabled={isDisabled} type="primary" onClick={onSave}>
          Save
        </ActionButton>
      )}
      <ActionButton loading={loading} type="default" onClick={onCancel}>
        Cancel
      </ActionButton>
    </EditDrawerBottomActions>
  </Drawer>
)

export type EditDrawerPropsType = {
  onSave?: () => void
  onCancel?: () => void
  isDisabled?: boolean
  loading?: boolean
} & DrawerProps

const EditDrawerBody = styled.div`
  margin-bottom: 80px;
`

const EditDrawerBottomActions = styled.div`
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
  border-radius: 0 0 4px 4px;
  bottom: 0;
  left: 0;
  position: absolute;
`

const ActionButton = styled(Button)`
  width: 80px;
  margin-left: 8px;
`
