import { GicEdge, GicsData } from '@curvo/apollo'
import { Flex, SelectInput } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import DebounceInput from './DebounceInput'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & {
  value: any
  manufacturer: { extra: string }
  onChange: (option: any) => void
}

const OptionContainer = styled(Flex)`
  width: 100%;
`

const GicName = styled(Flex)`
  flex: 1;
`

const transformDataToOption = (data: GicEdge[]) => {
  if (!data) {
    return []
  }
  return data.map(({ node }) => ({
    extra: node.id,
    label: `${node.id}. ${node.name}`,
    value: node.id,
  }))
}

const GicsSelect: React.SFC<Props> = ({ value, manufacturer, onChange }) => (
  <DebounceInput>
    {({ searchText, onInputChange }) => (
      <GicsData
        variables={{ searchText, manufacturerId: manufacturer && manufacturer.extra, first: 50 }}
        fetchPolicy="network-only">
        {({ data, loading }) => (
          <SelectInput
            label="GIC"
            onInputChange={onInputChange}
            options={data && data.gics ? transformDataToOption(data.gics.edges) : []}
            loading={loading}
            value={value}
            onChange={onChange}
            renderOption={option => (
              <OptionContainer>
                <GicName>{`${option.label}`}</GicName>
              </OptionContainer>
            )}
            orderOptions={options =>
              options.sort((a, b) => {
                return +a.extra - +b.extra
              })
            }
            asyncMode
          />
        )}
      </GicsData>
    )}
  </DebounceInput>
)

GicsSelect.defaultProps = defaultProps

export default React.memo(GicsSelect)
