import { Client, StudyLinksQueryArgs, StudyLinkType } from '@curvo/apollo'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { bufferCount, Observable, Subscriber, map as rxMap } from 'rxjs'
import Cognito from '../../../../../config/Cognito'
import { AgGridRefProps } from './common'
import { StudyLinkCache } from './StudyLinkCache'

export function useLoadStudyLinksWithPipe(
  studyId: number,
  gridRef: React.RefObject<AgGridRefProps>,
  queryArgs: StudyLinksQueryArgs,
) {
  const cachedData = StudyLinkCache.studies.get(studyId)
  const [studyLinks, setStudyLinks] = useState<StudyLinkType[]>([])
  const [total, setTotal] = useState(-1)
  const [error, setError] = useState<Error>()

  const memoQueryArgs = useRef<StudyLinksQueryArgs>()

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.api.setRowData([])
      gridRef.current.api.showLoadingOverlay()
    }

    if (memoQueryArgs.current !== queryArgs) {
      memoQueryArgs.current = queryArgs
      StudyLinkCache.studies.delete(studyId)
    }

    setTotal(-1)
    setStudyLinks([])
  }, [studyId, queryArgs, gridRef])

  useEffect(() => {
    const getToken = async () => {
      const session = await Cognito.getSession()
      return session.getAccessToken().getJwtToken()
    }

    const loadTransactions = () => {
      doFetchStudyLinksDataPipe(memoQueryArgs.current!)
    }

    const doGetStudyLinksCount = async (args: StudyLinksQueryArgs) => {
      const baseUrl = Client.getUrl()
      try {
        const token = await getToken()
        const result = await axios.get(`${window.location.protocol}//${baseUrl}/study/${studyId}/links-count`, {
          params: args,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (args === memoQueryArgs.current) {
          setTotal(result.data.count)
          if (result.data.count === 0) {
            gridRef.current?.api.showNoRowsOverlay()
          }
        }
        return result.data.count
      } catch (e) {
        setError(e)
      }
    }

    const doFetchStudyLinksDataPipe = async (args: StudyLinksQueryArgs) => {
      const baseUrl = Client.getUrl()
      try {
        const token = await getToken()
        new Observable<StudyLinkType>(observer => {
          fetch(
            `${window.location.protocol}//${baseUrl}/study/${studyId}/links?${new URLSearchParams({
              ...args,
            } as any).toString()}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          ).then(response => {
            response.body
              ?.pipeThrough(new TextDecoderStream())
              .pipeThrough(splitStream())
              .pipeThrough(parseJSON())
              .pipeTo(jsonToTable(observer))
          })
        })
          .pipe(
            bufferCount<StudyLinkType>(512),
            rxMap(chunks => {
              setStudyLinks(old => [...old, ...chunks])
              StudyLinkCache.addToStudy(chunks, studyId, memoQueryArgs.current!)

              gridRef.current?.api.applyTransaction({
                add: chunks,
              })
            }),
          )
          .subscribe({
            complete: () => {
              gridRef.current?.api.hideOverlay()
            },
          })
      } catch (e) {
        setError(e)
      }
    }

    // if study data is not loaded
    if (total === -1 || studyLinks.length < total) {
      // if we have cached data (size > 0, and have data === total, and cached queryArgs === current query args)
      if (
        cachedData &&
        cachedData.size > 0 &&
        cachedData.size === total &&
        StudyLinkCache.savedQueryArgs.get(studyId) === memoQueryArgs.current
      ) {
        const cachedRows = [...cachedData.values()]
        setStudyLinks(cachedRows)
        setTotal(cachedData.size)
        if (gridRef.current) {
          gridRef.current.api.setRowData(cachedRows)
        }
      } else if (total === -1) {
        doGetStudyLinksCount(memoQueryArgs.current!).then(() => {
          loadTransactions()
        })
      }
    }
  }, [studyId, total, studyLinks.length, cachedData, queryArgs, gridRef])

  return {
    progress: Math.round(studyLinks && total ? ((studyLinks.length * 1.0) / total) * 100 : 0),
    error,
    loading: total === -1 || studyLinks.length < total,
  }
}

const jsonToTable = (observer: Subscriber<StudyLinkType>) => {
  return new WritableStream({
    write(chunk: StudyLinkType) {
      observer.next(chunk)
    },
    close() {
      observer.complete()
    },
  })
}

const splitStream = () => {
  const splitOn = '\n,\n'
  let buffer = ''
  return new TransformStream({
    transform(chunk: string, controller) {
      buffer += chunk
      const parts = buffer.split(splitOn)
      parts.slice(0, -1).forEach(part => controller.enqueue(part))
      buffer = parts[parts.length - 1]
    },
    flush(controller) {
      if (buffer) {
        controller.enqueue(buffer)
      }
    },
  })
}

const parseJSON = () => {
  return new TransformStream({
    transform(chunk: string, controller) {
      if (chunk.startsWith('[')) {
        controller.enqueue(chunk.endsWith(']') ? JSON.parse(chunk.slice(1, -1)) : JSON.parse(chunk.slice(1)))
      } else if (chunk.endsWith('}')) {
        controller.enqueue(JSON.parse(chunk))
      } else if (chunk.endsWith(']')) {
        controller.enqueue(JSON.parse(chunk.slice(0, -1)))
      }
    },
  })
}
