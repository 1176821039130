import {
  Client,
  createSegmentMutation,
  deleteSegmentMutation,
  Query,
  Segment,
  SegmentsData,
  SegmentsQueryArgs,
  SEGMENTS_QUERY,
  updateSegmentMutation,
} from '@curvo/apollo'
import { Button, Col, message, Pagination, Popconfirm, Row, Table } from 'antd'
import { range } from 'lodash'
import React, { useState } from 'react'
import { StyledPaginationWrapper } from '../Upload/common'
import { ColumnGenFunctionType, PageWrapper, RedA, StyledSearchInput } from './common'
import { SegmentDrawer } from './components/EditPanel/CreateEditSegment'
import { EditMode } from './components/EditPanel/EditManufacturer'

const Segmentation: React.FC = () => {
  const limitPerPage = 10
  const [queryArgs, setQueryArgs] = useState<SegmentsQueryArgs>({
    first: limitPerPage,
    skip: 0,
  })
  const [isOpenPanel, setIsOpenPanel] = useState(false)
  const [panelMode, setPanelMode] = useState<EditMode>(EditMode.edit)
  const [selected, setSelected] = useState<Segment>()
  const columns = columnsGenFn(
    record => {
      setIsOpenPanel(true)
      setPanelMode(EditMode.edit)
      setSelected(record)
    },
    record => {
      deleteSegmentMutation({ id: record.id }, { refetchQueries: [{ query: SEGMENTS_QUERY }] })
        .then(() => message.success('Success'))
        .catch(e => message.error(e.message))
    },
  )

  const client = Client.getClient()

  return (
    <PageWrapper>
      <SegmentsData variables={queryArgs} fetchPolicy="network-only">
        {({ data, loading, error }) => {
          if (error) {
            message.error(error.message)
          }
          const segments = data && data.segments ? data.segments.edges.map(edge => edge.node) : []
          const total = data && data.segments ? data.segments.metadata.total : 0
          const handleExport = async () => {
            const results = await Promise.all(
              range(0, total, 10).map(offset =>
                client.query<Pick<Query, 'segments'>>({
                  query: SEGMENTS_QUERY,
                  variables: { ...queryArgs, skip: offset, first: 10 },
                }),
              ),
            )
            const allSegments = results.map(result => result?.data.segments?.edges.map(edge => edge.node) || []).flat()
            JSONToCSVConvertor(allSegments, 'segment.csv', true)
          }
          return (
            <div>
              <Row gutter={16}>
                <Col md={4}>
                  <StyledSearchInput
                    placeholder="Search segmentation by name"
                    enterButton
                    onSearch={searchText => {
                      setQueryArgs(query => ({
                        ...query,
                        searchText,
                      }))
                    }}
                  />
                </Col>
                <Col md={16} />
                <Col md={2}>
                  <Button
                    type="primary"
                    icon="plus"
                    onClick={() => {
                      setIsOpenPanel(true)
                      setPanelMode(EditMode.create)
                      setSelected(undefined)
                    }}>
                    Create
                  </Button>
                </Col>
                <Col md={2}>
                  <Button type="primary" icon="export" onClick={handleExport}>
                    Export
                  </Button>
                </Col>
              </Row>
              <Table
                dataSource={segments}
                loading={loading}
                columns={columns}
                rowKey={record => record.id.toString()}
                pagination={false}
                childrenColumnName="subSegments"
              />
              <StyledPaginationWrapper>
                <Pagination
                  total={total}
                  pageSize={limitPerPage}
                  onChange={page => setQueryArgs({ first: limitPerPage, skip: (page - 1) * limitPerPage })}
                />
              </StyledPaginationWrapper>
              <SegmentDrawer
                editMode={panelMode}
                segment={selected}
                visible={isOpenPanel}
                onCancel={() => setIsOpenPanel(false)}
                onSubmit={name => {
                  if (panelMode === EditMode.create) {
                    createSegmentMutation({ input: { name } }, { refetchQueries: [{ query: SEGMENTS_QUERY }] })
                      .then(() => message.success('success'))
                      .catch(e => message.error(e.message))
                  } else {
                    updateSegmentMutation(
                      { input: { id: selected!.id, name } },
                      { refetchQueries: [{ query: SEGMENTS_QUERY }] },
                    )
                      .then(() => message.success('success'))
                      .catch(e => message.error(e.message))
                  }
                  setIsOpenPanel(false)
                }}
                width={250}
              />
            </div>
          )
        }}
      </SegmentsData>
    </PageWrapper>
  )
}

export default Segmentation

const columnsGenFn: ColumnGenFunctionType<Segment> = (onEdit, onDelete) => [
  {
    title: 'NAME',
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: 'Action',
    key: 'action',
    render: (_text: any, record: Segment) => (
      <span>
        <Button type="link" onClick={() => onEdit(record)}>
          Edit
        </Button>
        <span> | </span>
        <Popconfirm
          title={`Are you sure you want to delete Segment ${record.name}?`}
          okText="Yes"
          cancelText="No"
          onConfirm={() => onDelete && onDelete(record)}>
          <RedA>Delete</RedA>
        </Popconfirm>
      </span>
    ),
  },
]

export function JSONToCSVConvertor(JSONData: Object[], fileName: string, ShowLabel: boolean) {
  //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData

  var CSV = ''
  //Set Report title in first row or line

  //This condition will generate the Label/Header
  if (ShowLabel) {
    var row = ''

    //This loop will extract the label from 1st index of on array
    for (var index in arrData[0]) {
      //Now convert each value to string and comma-seprated
      row += index + ','
    }

    row = row.slice(0, -1)

    //append Label row with line break
    CSV += row + '\r\n'
  }

  //1st loop is to extract each row
  for (var i = 0; i < arrData.length; i++) {
    var rrow = ''

    //2nd loop will extract each column and convert it in string comma-seprated
    for (var iindex in arrData[i]) {
      rrow += '"' + arrData[i][iindex] + '",'
    }

    rrow.slice(0, rrow.length - 1)

    //add a line break after each row
    CSV += rrow + '\r\n'
  }

  if (CSV === '') {
    message.error('Invalid data')
    return
  }

  //Initialize file format you want csv or xls
  var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension

  //this trick will generate a temp <a /> tag
  var link = document.createElement('a')
  link.href = uri

  link.setAttribute('download', fileName)
  link.click()

  //this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
