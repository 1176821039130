import { AgGridColumnProps, AgGridReact } from '@ag-grid-community/react'
import { AllModules, GridApi, GridReadyEvent, RowNode, ValueFormatterParams } from '@ag-grid-enterprise/all-modules'
import * as round from '@alvarocastro/round'
import {
  ConstructMasterGroup,
  Study,
  StudyTransactionState,
  completeStudyMutation,
  pushStudyToCasesCostMutation,
} from '@curvo/apollo'
import { Button, DatePicker, Input, Progress, Select, message } from 'antd'
import { range } from 'lodash'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { SearchWrapper } from '../Update/common'
import { CasesCostChart } from './components/CasesCostChart'
import { GroomTransactionsQueryType } from './components/GroomingCache'
import { useLoadStudyTransactions } from './components/LoadStudyTransactionsHook'

type Props = { studyId: number; study?: Study }

const moneyFormatter = (params: ValueFormatterParams): string => {
  return `$${String(round(Number(params.value), 2).toFixed(2))}`
}

export const CaseReviewDetails: React.FC<Props> = ({ studyId, study }) => {
  const navigate = useNavigate()
  const gridRef = useRef<AgGridReact & { api: GridApi }>(null)
  const [selectedRows, setSelectedRows] = useState<RowNode[]>([])
  const [marketBasketId, setMarketBasketId] = useState<string>()
  const [queryArgs, setQueryArgs] = useState<GroomTransactionsQueryType>({
    state: StudyTransactionState.FinalReview,
    masterGroup: ConstructMasterGroup.Arthroplasty,
  })

  const getAllChildNodes = (nodes: RowNode[]): RowNode[] => {
    return nodes.reduce<RowNode[]>((prev, current) => {
      if (current.group) {
        return [...prev, ...getAllChildNodes(current.childrenAfterGroup || [])]
      }

      return [...prev, current]
    }, [])
  }

  const { error, loading, progress } = useLoadStudyTransactions(studyId, gridRef, queryArgs)

  if (error) {
    message.error(error.message)
  }

  const generateChartAction = (api?: GridApi) => () => {
    if (!api) {
      return
    }

    const cellRanges = api.getCellRanges()
    if (cellRanges?.length && cellRanges[0].startRow && cellRanges[0].endRow) {
      const rows: RowNode[] = range(cellRanges[0].startRow.rowIndex, cellRanges[0].endRow.rowIndex + 1)
        .map(index => {
          return api.getDisplayedRowAtIndex(index)
        })
        .filter(row => !!row)
        .map(row => row!)

      setSelectedRows(rows || [])
    }
  }

  const rowAggregation = (nodes: RowNode[]) => {
    const allNodes = getAllChildNodes(nodes)
    const result = allNodes.reduce<any>((prev, currentNode) => {
      const extPriceAgg = {
        extprice: {
          ...prev.extprice,
          ...{
            [currentNode.data.ponum]:
              currentNode.data.extprice + ((prev.extprice && prev.extprice[currentNode.data.ponum]) || 0),
          },
        },
      }

      const poNumAgg = {
        ponum: prev.ponum ? [...new Set([...prev.ponum, currentNode.data.ponum])] : [currentNode.data.ponum],
      }

      const unitPriceAgg = {
        unitprice: currentNode.data.unitprice + (prev.unitprice || 0),
      }

      const qtyPurchaseAgg = {
        qtypurchase: currentNode.data.qtypurchase + (prev.qtypurchase || 0),
      }

      return { ...extPriceAgg, ...poNumAgg, ...unitPriceAgg, ...qtyPurchaseAgg }
    }, {})

    return {
      extprice: result.extprice
        ? Object.entries(result.extprice).reduce((prev, [, value]) => {
            return prev + Number(value)
          }, 0) / Object.keys(result.extprice).length
        : 0,
      extpriceMin: result.extprice && Math.min(...Object.values(result.extprice).map(Number)),
      extpriceMax: result.extprice && Math.max(...Object.values(result.extprice).map(Number)),
      ponum: (result.ponum && result.ponum.length) || 0,
      unitprice: result.unitprice ? result.unitprice / allNodes.length : 0,
      qtypurchase: result.qtypurchase,
    }
  }

  useEffect(() => {
    if (study) {
      setQueryArgs(oldQueryArgs => ({
        ...oldQueryArgs,
        datepurchaseFrom: study.minDatePurchase || undefined,
        datepurchaseTo: study.maxDatePurchase || undefined,
      }))
    }
  }, [study])

  useEffect(() => {
    if (!loading && gridRef.current) {
      const { api } = gridRef.current
      setSelectedRows(oldSelectedRows => {
        const chartRows =
          oldSelectedRows.length <= 0 && api.getDisplayedRowCount() > 0
            ? Array.from(Array(api.getDisplayedRowCount()).keys())
                .map(index => {
                  return api.getDisplayedRowAtIndex(index)
                })
                .filter(row => !!row)
                .map(row => row!)
            : oldSelectedRows
        return [...chartRows]
      })
    }
  }, [loading, gridRef])

  return (
    <>
      <SearchWrapper>
        <Select
          style={{ width: '200px' }}
          value={queryArgs.masterGroup}
          onChange={v => setQueryArgs(old => ({ ...old, masterGroup: v }))}>
          <Select.Option key={ConstructMasterGroup.Arthroplasty}>Arthroplasty</Select.Option>
          <Select.Option key={ConstructMasterGroup.Spine}>Spine</Select.Option>
          <Select.Option key={ConstructMasterGroup.Trauma}>Trauma</Select.Option>
          <Select.Option key={ConstructMasterGroup.Other}>All</Select.Option>
        </Select>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {<StudyDatePicker queryArgs={queryArgs} setQueryArgs={setQueryArgs} />}
          <Input
            placeholder="Market Basket ID"
            value={marketBasketId}
            onChange={v => setMarketBasketId(v.target.value)}
            style={{ marginRight: '1em', width: 200 }}
          />
          <Button
            disabled={!marketBasketId}
            onClick={() => {
              if (marketBasketId && queryArgs.masterGroup) {
                pushStudyToCasesCostMutation({
                  input: {
                    studyId,
                    marketBasketId,
                    masterGroup: queryArgs.masterGroup.toString(),
                    fromDate: queryArgs.datepurchaseFrom,
                    toDate: queryArgs.datepurchaseTo,
                  },
                })
                  .then(() => message.info('Pushed to cases cost'))
                  .catch(e => message.error(e.message))
                message.info('Pushing to cases cost')
              }
            }}
            style={{ marginRight: '1em' }}>
            Push to Cases Cost
          </Button>
          <Button
            type="primary"
            onClick={() => {
              completeStudyMutation({ id: studyId }).then(fetchResult => {
                if (fetchResult) {
                  const { errors, data } = fetchResult
                  if (errors) {
                    message.error(errors[0].message)
                  } else if (data) {
                    message.info('Completed Study')
                    navigate('/data-cleaning')
                  }
                }
              })
            }}>
            Complete
          </Button>
        </div>
      </SearchWrapper>
      {loading && <Progress percent={progress} />}
      <div
        className="ag-theme-balham"
        style={{ minHeight: '600px', height: '70vh', width: 'fill-parent', marginBottom: '16px' }}>
        <AgGridReact
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
          }}
          pivotMode={true}
          modules={[...AllModules]}
          columnDefs={columns}
          applyColumnDefOrder={false}
          ref={gridRef}
          rowSelection="multiple"
          /*onSelectionChanged={e => {
            setSelectedPurchases(e.api.getSelectedRows())
          }}*/
          getRowNodeId={node => node.id}
          enableRangeSelection={true}
          suppressMultiRangeSelection={true}
          enableCharts={true}
          popupParent={document.body}
          rowGroupPanelShow="always"
          groupSelectsChildren={true}
          // aggFuncs={{ distinct: distinctAgg }}
          enableFillHandle={true}
          getContextMenuItems={({ api }) => {
            return [
              {
                name: 'Generate chart',
                action: generateChartAction(api ? api : undefined),
              },
            ]
          }}
          groupRowAggNodes={rowAggregation}
          onGridReady={({ api }: GridReadyEvent) => {
            api.sizeColumnsToFit()
          }}
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                  syncLayoutWithGrid: true,
                },
              },
              {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                toolPanelParams: {
                  syncLayoutWithGrid: true,
                },
              },
            ],
            position: 'right',
            defaultToolPanel: 'columns',
            hiddenByDefault: false,
          }}
        />
      </div>
      {!loading && <CasesCostChart selectedRows={selectedRows} />}
    </>
  )
}

const columns: AgGridColumnProps[] = [
  {
    headerName: 'Construct Group',
    field: 'constructGroup',
    colId: 'constructGroup',
    enableRowGroup: true,
    enablePivot: true,
  },
  {
    headerName: 'Construct Procedure Type',
    field: 'constructProcedureType',
    colId: 'constructProcedureType',
    enableRowGroup: true,
    enablePivot: true,
  },
  {
    headerName: 'Construct Name',
    field: 'constructName',
    colId: 'constructName',
    enableRowGroup: true,
    enablePivot: true,
    rowGroupIndex: 1,
  },
  {
    headerName: 'Manufacturer',
    field: 'bamfManufacturer',
    colId: 'bamfManufacturer',
    enableRowGroup: true,
    enablePivot: true,
    rowGroupIndex: 0,
  },
  {
    headerName: 'Case',
    field: 'ponum',
    colId: 'ponum',
    enableRowGroup: true,
    enablePivot: true,
    aggFunc: 'numberOf',
    enableValue: true,
  },
  {
    headerName: 'Vendor Item',
    field: 'bamfPartNumber',
    colId: 'bamfPartNumber',
    // editable: true,
  },
  {
    headerName: 'Ext Price',
    field: 'extprice',
    colId: 'extprice',
    aggFunc: 'avgCase',
    // editable: true,
    valueFormatter: moneyFormatter,
  },
  {
    headerName: 'Ext Price(Min)',
    field: 'extpriceMin',
    colId: 'extpriceMin',
    aggFunc: 'minCase',
    // editable: true,
    valueFormatter: moneyFormatter,
  },
  {
    headerName: 'Ext Price(Max)',
    field: 'extpriceMax',
    colId: 'extpriceMax',
    aggFunc: 'maxCase',
    // editable: true,
    valueFormatter: moneyFormatter,
  },
  {
    headerName: 'Unit Price',
    field: 'unitprice',
    colId: 'unitprice',
    aggFunc: 'avg',
    // editable: true,
    valueFormatter: moneyFormatter,
  },
  {
    headerName: 'Qty Purchase',
    field: 'qtypurchase',
    colId: 'qtypurchase',
    aggFunc: 'sum',
    // editable: true,
  },
]

const StudyDatePicker: React.FC<{
  queryArgs: GroomTransactionsQueryType
  setQueryArgs: (newArgs: GroomTransactionsQueryType) => void
}> = ({ queryArgs, setQueryArgs }) => (
  // @ts-ignore
  <DatePicker.RangePicker
    style={{ width: 300, marginRight: '1em' }}
    value={[
      (queryArgs.datepurchaseFrom && moment(queryArgs.datepurchaseFrom, 'YYYY-MM-DD')) || undefined,
      (queryArgs.datepurchaseTo && moment(queryArgs.datepurchaseTo, 'YYYY-MM-DD')) || undefined,
    ]}
    onChange={v =>
      setQueryArgs({
        ...queryArgs,
        datepurchaseFrom: (v[0] && v[0].format('YYYY-MM-DD')) || undefined,
        datepurchaseTo: (v[1] && v[1].format('YYYY-MM-DD')) || undefined,
      })
    }
  />
)
