import { AgGridColumnProps } from '@ag-grid-community/react'
import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact'
import { StudyLinkCatalogEnum, StudyLinkType } from '@curvo/apollo'
import React from 'react'
import { currencyFormatter } from '../../common'
import { getManufacturerString, getPartString } from '../Grooming2'

export const StudyLinkMasterGrid: React.FC<{
  masterConflicts: StudyLinkType[]
  catalog: StudyLinkCatalogEnum
}> = ({ masterConflicts, catalog }) => {
  return <AgGridReact rowData={masterConflicts} columnDefs={columns(catalog)} />
}

const columns = (catalog: StudyLinkCatalogEnum): AgGridColumnProps[] => [
  {
    headerName: 'Matched Supplier',
    colId: 'matchSupplier',

    valueGetter: ({ data }) => getManufacturerString(data, catalog),
  },
  ...(catalog === StudyLinkCatalogEnum.Gudid
    ? [
        {
          colId: 'versionModelNumber',
          headerName: 'Version Model Number',
          field: 'gudidVersionModelNumber',
        },
      ]
    : []),
  ...(catalog === StudyLinkCatalogEnum.Medline
    ? [
        {
          colId: 'materialCode',
          headerName: 'Material Code',
          field: 'medMaterialCode',
        },
      ]
    : []),
  {
    colId: 'matchedPart',
    headerName:
      catalog === StudyLinkCatalogEnum.Stan
        ? 'Matched Part'
        : catalog === StudyLinkCatalogEnum.Gudid
        ? 'Catalog Number'
        : 'SKU',
    valueGetter: ({ data }) => getPartString(data, catalog),
  },
  {
    colId: 'matchDescription',
    headerName: 'Matched Description',

    valueGetter: ({ data, node }) => {
      if (node?.group) {
        return undefined
      }
      const studyLink: StudyLinkType = data
      if (studyLink) {
        if (catalog === StudyLinkCatalogEnum.Stan) {
          return studyLink.stanPartDescription
        } else if (catalog === StudyLinkCatalogEnum.Gudid) {
          return studyLink.gudidDescription
        } else if (catalog === StudyLinkCatalogEnum.Medline) {
          return studyLink.medDescription
        }
      }

      return ''
    },
  },
  {
    colId: 'vendor',
    headerName: 'IN Vendor',
    field: 'vendor',
  },
  {
    colId: 'venitem',
    headerName: 'IN Vendor Item',
    field: 'venitem',
  },
  {
    colId: 'manufacturer',
    headerName: 'IN Manufacturer',
    field: 'manufacturer',
  },
  {
    colId: 'mfgitem',
    headerName: 'IN Manufacturer Item',
    field: 'mfgitem',
  },
  {
    colId: 'description',
    headerName: 'IN Description',
    field: 'description',
  },
  {
    colId: 'qtypurchase',
    headerName: 'Quantity Purchase',
    field: 'qtypurchase',
    filter: 'agNumberColumnFilter',
    type: 'numericColumn',
  },
  {
    colId: 'asp',
    headerName: 'ASP',
    field: 'asp',
    valueFormatter: currencyFormatter,
    filter: 'agNumberColumnFilter',
    type: 'numericColumn',
  },
]
