import { Manufacturer, ManufacturersData } from '@curvo/apollo'
import LoadingIndicator from '@curvo/common-ui/dist/components/Loading/Spinner'
import { Select } from 'antd'
import { LabeledValue, SelectProps } from 'antd/lib/select'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'
import { StyledMultipleSelect, StyledSelect, StyledSelectPropsType } from './common'

const { Option } = Select

export const ManufacturerSelect: React.FunctionComponent<
  SelectProps<LabeledValue> &
    StyledSelectPropsType & {
      fullData?: boolean
      obsolete?: boolean
      onChangeFullObject?: (fullObj?: Manufacturer) => any
    }
> = ({ onChangeFullObject, onChange, fullData, obsolete, ...props }) => {
  const [searchText, setSearchText] = useState('')
  useEffect(() => {
    if (props.value) {
      setSearchText(props.value.label as string)
    }
  }, [props.value])
  return (
    <ManufacturersData variables={{ searchText, obsolete }} fetchPolicy="network-only">
      {({ data, loading }) => {
        const manufacturers = ((data && data.manufacturers && data.manufacturers.edges) || []).map(edge => edge.node)
        return (
          <StyledSelect
            onSearch={debounce(setSearchText, 800)}
            loading={loading}
            showSearch
            labelInValue
            filterOption={false}
            showArrow={false}
            defaultActiveFirstOption={false}
            placeholder="Select manufacturer"
            notFoundContent={loading ? <LoadingIndicator /> : 'No Data'}
            onChange={(v, e) => {
              if (onChange) {
                onChange(v, e)
              }
              if (onChangeFullObject) {
                onChangeFullObject(v && manufacturers.find(manufacturer => manufacturer.id === v.key))
              }
            }}
            {...props}>
            {manufacturers.map(manufacturer => (
              <Option
                key={
                  fullData ? JSON.stringify(manufacturer) : manufacturer.id
                }>{`${manufacturer.id} | ${manufacturer.name}`}</Option>
            ))}
          </StyledSelect>
        )
      }}
    </ManufacturersData>
  )
}

export const MultipleManufacturerSelect: React.FC<
  SelectProps<LabeledValue | LabeledValue[]> & StyledSelectPropsType
> = props => {
  const [searchText, setSearchText] = useState('')
  return (
    <ManufacturersData variables={{ searchText }} fetchPolicy="network-only">
      {({ data, loading }) => (
        <StyledMultipleSelect
          onSearch={debounce(setSearchText, 800)}
          loading={loading}
          showSearch
          labelInValue
          filterOption={false}
          showArrow={false}
          defaultActiveFirstOption={false}
          placeholder="Select manufacturer"
          mode="multiple"
          {...props}>
          {data &&
            data.manufacturers &&
            data.manufacturers.edges.map(edge => (
              <Option key={edge.node.id}>{`${edge.node.id} | ${edge.node.name}`}</Option>
            ))}
        </StyledMultipleSelect>
      )}
    </ManufacturersData>
  )
}
