import { ApolloProvider } from '@apollo/client'
import { MeWithoutPaymentProvider } from '@curvo/apollo'
import { ResetCSS } from '@curvo/common-ui'
import React from 'react'
import { Route, Routes } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import './assets/style/ReactDayPicker.css'
import AuthorizedRoute from './components/AuthorizedRoute'
import { Tracking } from './components/Tracking'
import Client from './config/Apollo'
import { Auth } from './routes/Auth/Auth'
import { MainPage } from './routes/Main/Main'

const App: React.FC = () => {
  return (
    <React.Fragment>
      <ResetCSS />
      <ApolloProvider client={Client.getClient()}>
        <Router>
          <Routes>
            <Route path="/auth/*" element={<Auth />} />
            <Route
              path="/*"
              element={
                <MeWithoutPaymentProvider>
                  <AuthorizedRoute>
                    <MainPage />
                  </AuthorizedRoute>
                </MeWithoutPaymentProvider>
              }
            />
          </Routes>
        </Router>
        <Tracking />
      </ApolloProvider>
    </React.Fragment>
  )
}

export default App
