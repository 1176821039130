import React from 'react'
import { Routes } from 'react-router'
import { Route } from 'react-router-dom'
import styled from 'styled-components'
import { Brands } from './Brand'
import { GICs } from './GICs'
import { Manufacturers } from './Manufacturers'
import { Materials } from './Materials'
import { NormalizedSuppliers } from './NormalizedSupplier'
import { Parts } from './Parts'
import { PartsGrid } from './PartsGrid'
import { ProductLines } from './ProductLine'
import Segmentation from './Segment'
import { SegmentationComponent } from './Segmentation'
import { SubSegmentComponent } from './SubSegment'

export const UpdatePage: React.FunctionComponent = () => {
  return (
    <ContentContainer>
      <Routes>
        <Route path={'/parts/*'} element={<Parts />} />
        <Route path={'/parts-grid/*'} element={<PartsGrid />} />
        <Route path={'/gics/*'} element={<GICs />} />
        <Route path={'/manufacturers/*'} element={<Manufacturers />} />
        <Route path={'/materials/*'} element={<Materials />} />
        <Route path={'/segments/*'} element={<Segmentation />} />
        <Route path={'/sub-segments/*'} element={<SubSegmentComponent />} />
        <Route path={'/segmentations/*'} element={<SegmentationComponent />} />
        <Route path={'/brands/*'} element={<Brands />} />
        <Route path={'/product-line/*'} element={<ProductLines />} />
        <Route path={'/normalized-supplier/*'} element={<NormalizedSuppliers />} />
      </Routes>
    </ContentContainer>
  )
}

const ContentContainer = styled.div`
  margin: 24px;
  background: #ffffff;
`
