import { Gic, StudyTransactionType } from '@curvo/apollo'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import { GICSelect } from '../../Update/components/Select/GICSelect'
import { AgWrapperPropsType } from './ManufacturerSelectAgWrapper'

export const GICAgWrapper = React.forwardRef<{}, AgWrapperPropsType<StudyTransactionType>>((params, ref) => {
  const { data, api } = params

  const value = data
    ? data.gicId
      ? {
          key: data.gicId.toString(),
          label: data.gicName,
        }
      : undefined
    : undefined
  const [selectedGic, setSelectedGic] = useState<Gic | null>()

  useImperativeHandle(ref, () => ({
    getValue: () => selectedGic || null,
  }))

  useEffect(() => {
    if (selectedGic !== undefined) {
      api.stopEditing()
    }
  }, [selectedGic, api])

  return <GICSelect allowClear value={value} onChangeFullValue={v => setSelectedGic(v || null)} />
})
