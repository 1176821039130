import { Select } from 'antd'
import { LabeledValue } from 'antd/lib/select'
import { isNil, isNumber } from 'lodash'
import styled from 'styled-components'

export const EditPanelWrapper = styled.div`
  padding: 16px;
`

export const emptyOrReturn = (v: any, ret: any) => (v !== null && v !== undefined ? v : ret)

export function getFieldValue<T>(value: T) {
  return (field: keyof T) => value[field]
}

export const TypedSelect: new () => Select<LabeledValue | LabeledValue[]> = Select as any

export const isEmpty = (s: undefined | null | string | number) => s === undefined || s === null

export function caseInsensitiveComparator(a: any, b: any) {
  if (a === b) {
    return 0
  }
  if (isNil(a)) {
    return -1
  }
  if (isNil(b)) {
    return 1
  }
  if (isNumber(a) && isNumber(b)) {
    return a - b
  }
  return a?.toString().localeCompare(b?.toString(), undefined, { sensitivity: 'base' })
}

export function camelCaseToUnderscore(str: string) {
  // don't change the string if it's capitalized
  return /^[^a-z]*$/.test(str) ? str : str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1_').toLowerCase()
}

export function underscoreToCamelCase(str: string) {
  if (!/_+/.test(str)) {
    return str
  }
  // don't change the string if it's capitalized
  return /^[^a-z|_]*$/.test(str) ? str : str.toLowerCase().replace(/_([a-z])/g, g => g[1].toUpperCase())
}

function isObject(obj: any): boolean {
  return obj === Object(obj)
}

export function underscoreArray<T extends object>(obj: any, deep: boolean = false): T | T[] {
  if (!obj || !Array.isArray(obj)) {
    return underscoreObject(obj, deep)
  }
  return obj.map(item => underscoreObject<T>(item, deep)) as T[]
}

export function underscoreObject<T extends object>(obj: any, deep: boolean = false): T | T[] {
  if (!obj || !isObject(obj)) {
    return obj
  }
  if (Array.isArray(obj)) {
    return underscoreArray<T>(obj, deep)
  }
  return Object.keys(obj).reduce(
    (previousValue: any, currentValue: string) => ({
      ...previousValue,
      [camelCaseToUnderscore(currentValue)]: deep ? underscoreObject(obj[currentValue], true) : obj[currentValue],
    }),
    {},
  )
}

export function camelCaseArray<T extends object = any>(
  obj: any,
  deep: boolean = false,
  handleObjectKey: (key: string) => string = key => key,
): T[] {
  if (!obj || !Array.isArray(obj)) {
    return camelCaseObject(obj, deep, handleObjectKey) as T[]
  }

  return obj.map(item => camelCaseObject<T>(item, deep, handleObjectKey))
}

export function camelCaseObject<T extends object = any>(
  obj: any,
  deep: boolean = false,
  handleObjectKey: (key: string) => string = key => key,
): T {
  if (!obj || !isObject(obj) || obj instanceof Date) {
    return obj
  }
  if (Array.isArray(obj)) {
    return camelCaseArray<T>(obj, deep, handleObjectKey) as T
  }
  return Object.keys(obj).reduce(
    (previousValue: any, currentValue: string) => ({
      ...previousValue,
      [underscoreToCamelCase(handleObjectKey(currentValue))]: deep
        ? camelCaseObject(obj[currentValue], true, handleObjectKey)
        : obj[currentValue],
    }),
    {},
  )
}
