import { Role, useCurvoUsersQuery, User } from '@curvo/apollo'
import { Select } from 'antd'
import { LabeledValue, SelectProps } from 'antd/lib/select'
import React from 'react'
import { StyledSelect, StyledSelectPropsType } from './common'

const { Option } = Select

export const UserSelect: React.FunctionComponent<SelectProps<LabeledValue> & StyledSelectPropsType> = props => {
  const { data, loading } = useCurvoUsersQuery({})

  const users =
    (data &&
      data.allCurvoUsers &&
      data.allCurvoUsers.filter(user => user.role === Role.CurvoAdmin || user.role === Role.DataEnrichmentAdmin)) ||
    []

  return (
    <StyledSelect
      loading={loading}
      showSearch
      labelInValue
      showArrow={false}
      defaultActiveFirstOption={false}
      placeholder="Select user"
      filterOption={(input, option) => {
        const user: User = JSON.parse(option.props.value?.toString() || '{}')
        return (
          ((user.firstName || '') + ' ' + (user.lastName || '') + ' ' + user.email + ' ' + user.username)
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        )
      }}
      {...props}>
      {users.map(user => (
        <Option key={props.fullData ? JSON.stringify(user) : user.id} value={JSON.stringify(user)}>
          {user.email}
        </Option>
      ))}
    </StyledSelect>
  )
}
