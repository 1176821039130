import {
  approveNewPricesMutation,
  COUNT_QUEUED_PRICES,
  NewPrice,
  NewPricesDataType,
  QueuedNewPricesData,
  QueuedNewPricesQueryArgs,
  updatePricesFromQueueMutation,
} from '@curvo/apollo'
import { Button, message, Pagination, Popconfirm, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelectableTable } from '../../../../components/SelectableTable'
import { NewPartsStepContentProps, StyledPaginationWrapper } from '../common'

const rowKeyGen = (price: NewPrice) => `${price.strippedPartNumber}.${price.manufacturerId}`

export const NewPricesDataStep: React.FC<NewPartsStepContentProps> = ({ currentStep, batchName }) => {
  const [currentPage, setCurrentPage] = useState({
    [NewPricesDataType.Approving]: 1,
    [NewPricesDataType.Discarded]: 1,
    [NewPricesDataType.Inserting]: 1,
  })
  const [limitPerPage, setLimitPerPage] = useState({
    [NewPricesDataType.Approving]: 10,
    [NewPricesDataType.Discarded]: 10,
    [NewPricesDataType.Inserting]: 10,
  })
  const [queryArgs, setQueryArgs] = useState<QueuedNewPricesQueryArgs>({
    type: NewPricesDataType.Approving,
    first: limitPerPage[NewPricesDataType.Approving],
    skip: 0,
    batchName,
  })

  useEffect(() => {
    setQueryArgs(old => ({
      ...old,
      batchName,
    }))
  }, [batchName])
  const { columns, setMultipleSelection, rowSelection, selected, isSelectedAll, cleanSelected } = useSelectableTable(
    newPricesColumns,
    [],
    rowKeyGen,
    true,
  )
  useEffect(() => setMultipleSelection(true), [setMultipleSelection])
  // const [processing, setProcessing] = useState(false)
  const [loadingState, setLoadingState] = useState({
    approve: false,
    discard: false,
    insert: false,
  })
  useEffect(() => {
    if (currentStep === 1) {
      setQueryArgs(q => ({
        ...q,
        type: NewPricesDataType.Discarded,
      }))
    } else if (currentStep === 2) {
      setQueryArgs(q => ({
        ...q,
        type: NewPricesDataType.Approving,
      }))
    } else {
      setQueryArgs(q => ({
        ...q,
        type: NewPricesDataType.Inserting,
      }))
    }
  }, [currentStep])
  return (
    <QueuedNewPricesData variables={queryArgs} fetchPolicy="network-only">
      {({ data, loading, error, refetch }) => {
        if (error) {
          message.error(error.message)
        }
        const newPrices = data && data.queuedNewPrices ? data.queuedNewPrices.edges.map(edge => edge.node) : []
        const totalPages = data && data.queuedNewPrices ? data.queuedNewPrices.metadata.total : 0
        const handleApproving = () => {
          message.info('Approving...')
          setLoadingState({
            ...loadingState,
            approve: true,
          })
          approveNewPricesMutation(
            {
              input: {
                partIds: selected.map(newPrice => newPrice.partId || ''),
                isInvertedSelection: isSelectedAll,
              },
            },
            {
              refetchQueries: [{ query: COUNT_QUEUED_PRICES }],
            },
          )
            .then(() => {
              refetch(queryArgs)
              message.success('Successfully approved')
            })
            .finally(() => {
              setLoadingState({
                ...loadingState,
                approve: false,
              })
              cleanSelected()
            })
        }
        const handleInsert = () => {
          message.info('Inserting')
          setLoadingState({
            ...loadingState,
            insert: true,
          })
          updatePricesFromQueueMutation(
            { batchName },
            {
              refetchQueries: [{ query: COUNT_QUEUED_PRICES }],
            },
          )
            .then(() => {
              message.success('Successfully inserted')
              refetch(queryArgs)
            })
            .finally(() => {
              setLoadingState({
                ...loadingState,
                insert: false,
              })
              cleanSelected()
            })
        }
        return (
          <React.Fragment>
            <Table
              columns={columns}
              dataSource={newPrices}
              loading={loading}
              pagination={false}
              rowKey={rowKeyGen}
              rowSelection={queryArgs.type === NewPricesDataType.Approving ? rowSelection : undefined}
            />
            <StyledPaginationWrapper>
              {queryArgs.type === NewPricesDataType.Approving && (
                <Popconfirm title="Do you want to approve all new prices?" onConfirm={handleApproving}>
                  <StyledApproveButton
                    type="primary"
                    icon="check"
                    disabled={(!isSelectedAll && !selected.length) || loadingState.approve}>
                    Approve
                  </StyledApproveButton>
                </Popconfirm>
              )}
              {queryArgs.type === NewPricesDataType.Inserting && (
                <Popconfirm title="Do you want to insert all approved new prices?" onConfirm={handleInsert}>
                  <StyledApproveButton type="primary" icon="plus" disabled={loadingState.insert}>
                    Insert All
                  </StyledApproveButton>
                </Popconfirm>
              )}
            </StyledPaginationWrapper>
            <StyledPaginationWrapper>
              <Pagination
                total={totalPages}
                defaultCurrent={currentPage[queryArgs.type]}
                onChange={pageNumber => {
                  setCurrentPage({
                    ...currentPage,
                    [queryArgs.type]: pageNumber,
                  })
                  setQueryArgs(query => ({
                    ...query,
                    first: limitPerPage[queryArgs.type],
                    skip: (pageNumber - 1) * limitPerPage[queryArgs.type],
                  }))
                }}
                showSizeChanger
                onShowSizeChange={(_, size) => {
                  setLimitPerPage({
                    ...limitPerPage,
                    [queryArgs.type]: size,
                  })
                  setQueryArgs(query => ({
                    ...query,
                    first: size,
                    skip: 0,
                  }))
                }}
              />
            </StyledPaginationWrapper>
          </React.Fragment>
        )
      }}
    </QueuedNewPricesData>
  )
}

const newPricesColumns: ColumnProps<NewPrice>[] = [
  { title: 'Number', key: 'number', dataIndex: 'strippedPartNumber' },
  { title: 'New Input name', key: 'newInputName', dataIndex: 'newInputName' },
  { title: 'Manufacturer ID', key: 'manufacturer', dataIndex: 'manufacturerId' },
  { title: 'Qty', key: 'qty', dataIndex: 'quantityPerBox' },
  {
    title: 'New price',
    key: 'newListPrice',
    dataIndex: 'newListPrice',
    render: text => <span>{text && Number.parseFloat(text).toFixed(2)}</span>,
  },
  {
    title: 'Effective Date',
    key: 'effectiveDate',
    dataIndex: 'effectiveDate',
  },
]

const StyledApproveButton = styled(Button)`
  margin-top: 16px;
  margin-left: 8px;
`
