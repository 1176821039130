import { Env } from '@curvo/apollo'

type EnvType = {
  LANDING_PAGE_LINK: string
}

const OPTIONS: { [key: string]: EnvType } = {
  DEV: {
    LANDING_PAGE_LINK: 'http://curvo-dev-landingpage.s3-website-us-east-1.amazonaws.com/',
  },
  QA: {
    LANDING_PAGE_LINK: 'http://curvo-qa-landingpage.s3-website-us-east-1.amazonaws.com/',
  },
  PROD: {
    LANDING_PAGE_LINK: 'https://onn.curvolabs.com/',
  },
}

const condition = process.env.CURVO_ENV || process.env.REACT_APP_CURVO_ENV || process.env.GATSBY_CURVO_ENV

const sharedEnv = {
  STRIPE_API_PK_KEY: Env.stripePk,
  COGNITO_POOL_ID: Env.poolId,
  COGNITO_CLIENT_ID: Env.clientId,
  TERMS_OF_SERVICE: 'https://vault.pactsafe.io/s/721e69ab-720f-4525-af86-304abdd0a676/legal.html#contract-ryrrhglgn',
  PRIVACY_POLICY: 'https://vault.pactsafe.io/s/721e69ab-720f-4525-af86-304abdd0a676/legal.html#contract-rk5gym5jq',
}

const env = {
  ...sharedEnv,
  ...((condition && OPTIONS[condition]) || OPTIONS.DEV),
  env: condition,
}

export default env
