import { Button, Radio } from 'antd'
import styled from 'styled-components'

export const UploadContentWrapper = styled.div`
  padding: 16px;
  margin: 12px;
  background: #ffffff;
`

export const RightFiltersContainer = styled.div`
  justify-content: flex-end;
`

export const StyledRadioGroup = styled(Radio.Group)`
  margin-left: 16px;
`

export const StyledApproveButton = styled(Button)`
  margin-top: 16px;
`

export const StyledPaginationWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
`

export type NewPartsStepContentProps = {
  setCurrentStep: (step: number) => void
  currentStep: number
  batchName: string
}
