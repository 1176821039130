import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import HigchartsGroupedCategories from 'highcharts-grouped-categories'
import React from 'react'
import { RowNode } from '@ag-grid-enterprise/all-modules'
import { useGicsData } from '@curvo/apollo'

type Props = {
  selectedRows: RowNode[]
}

type Categories = (
  | string
  | {
      name: string
      categories: Categories
    }
)[]

const getCategories = (rows: RowNode[]): Categories =>
  rows
    .map(row => {
      if (row.group && row.expanded) {
        return { name: row.key || '', categories: getCategories(row.childrenAfterFilter || []) }
      }

      return row.key
    })
    .filter(row => !!row)
    .map(row => row!)

const getMaxDepth = (categories: Categories, depth = 0) => {
  return categories.reduce((prevDepth, category) => {
    if (typeof category === 'string') {
      return Math.max(prevDepth, depth)
    }

    return Math.max(prevDepth, getMaxDepth(category.categories, depth + 1))
  }, depth)
}

/**
 * Return categories with empty filled missing depth levels
 * @param categories Categories to work on
 * @param depth Desired depth
 */
const getEvenCategories = (categories: Categories, depth?: number): Categories => {
  const desiredDepth = depth === undefined ? getMaxDepth(categories) : depth
  if (desiredDepth === 0) {
    return categories
  }

  return categories.map(category => {
    if (typeof category === 'string') {
      return { name: category, categories: getEvenCategories([''], desiredDepth - 1) }
    }

    if (getMaxDepth(category.categories) < desiredDepth) {
      return {
        name: category.name,
        categories: getEvenCategories(category.categories, desiredDepth - 1),
      }
    }

    return category
  })
}

const generateChart = (rows: RowNode[], gicsMap: any) => {
  const series = getSeries(
    rows.filter(row => row && row.group && row.level === 0),
    gicsMap,
  )
  // setChartSeries(series)

  const categories = getEvenCategories(getCategories(rows.filter(row => row && row.group && row.level === 0)))
  // setChartCategories(categories)

  return { series: series, categories: categories }
}

const getSeriesData = (rows: RowNode[], preCalculatedAllGics?: number[]) => {
  const allGics: number[] = preCalculatedAllGics
    ? preCalculatedAllGics
    : [
        ...new Set(
          rows.reduce<number[]>((prev, current) => {
            return [...prev, ...current.allLeafChildren.map(childRow => childRow.data.gicId)]
          }, []),
        ),
      ]

  return rows
    .map(row => {
      if (row.expanded) {
        return getSeriesData(row.childrenAfterFilter || [], allGics)
      }

      const gicStack = allGics.map(gic => {
        return {
          name: gic,
          y:
            row.allLeafChildren.reduce(
              (prev, current) => prev + (current.data.gicId === gic ? current.data.extprice : 0),
              0,
            ) / row.aggData.ponum,
        }
      })

      return [[{ name: 'Number of Cases', y: row.aggData.ponum }, ...gicStack]]
    })
    .flat(1)
}

const getSeries = (rows: RowNode[], gicsMap: any): Highcharts.SeriesColumnOptions[] => {
  const data: any[][] = getSeriesData(rows)

  return Object.values(
    data.reduce((prevAgg, currentCol) => {
      const simplifiedStack = currentCol.reduce((prevPointAgg, currentPoint) => {
        return {
          ...prevPointAgg,
          [currentPoint.name]: currentPoint.y + (prevPointAgg[currentPoint.name] || 0),
        }
      }, {})

      return Object.entries(simplifiedStack).reduce((prevSimplifiedAgg, [key, value]) => {
        return {
          ...prevSimplifiedAgg,
          [key]: {
            tooltip: {
              enabled: true,
              pointFormatter: function () {
                const point = this as any

                if (point.y <= 0) {
                  return ''
                }

                return `
                  <span style="color: ${point.color}">\u25CF</span>
                  ${point.series.name}: <b>${
                  point.series.name === 'Number of Cases'
                    ? point.y
                    : `$${Highcharts.numberFormat(point.y, 2, '.', ',')}`
                }</b><br>
                `
              },
            },
            type: key === 'Number of Cases' ? 'line' : 'column',
            yAxis: key === 'Number of Cases' ? 1 : 0,
            name: gicsMap[key] ? gicsMap[key] : key,
            data: [...(prevSimplifiedAgg[key] ? prevSimplifiedAgg[key].data : []), value],
          },
        }
      }, prevAgg)
    }, {}),
  )
}

HigchartsGroupedCategories(Highcharts)

export const CasesCostChart: React.FC<Props> = ({ selectedRows }) => {
  const gics = useGicsData({ variables: { first: 1000 } })
  if (!gics.loading && gics.data) {
    const gicsMap = gics.data.gics.edges.reduce((prev, current) => {
      return { ...prev, [current.node.id]: current.node.name }
    }, {})

    const { categories, series } = generateChart(selectedRows, gicsMap)

    const highChartsOptions = {
      title: {
        text: 'Cases Cost',
      },
      yAxis: [
        {
          title: {
            text: 'Average Cost per Case',
          },
        },
        {
          title: {
            text: 'Number of Cases',
          },
          opposite: true,
        },
      ],
      xAxis: {
        categories: categories,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      tooltip: {
        shared: true,
      },
      series: series,
      legend: {
        maxHeight: 100,
      },
    }

    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={highChartsOptions} />
      </div>
    )
  }

  return <></>
}
