import { MasterDataType, StudyLinkCatalogEnum, StudyLinkType, useSearchMasterDataParts } from '@curvo/apollo'
import LoadingIndicator from '@curvo/common-ui/dist/components/Loading/Spinner'
import { LabeledValue, SelectProps } from 'antd/lib/select'
import { debounce } from 'lodash'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import { StyledSelect, StyledSelectPropsType } from '../../Update/components/Select/common'
import { AgWrapperPropsType } from './ManufacturerSelectAgWrapper'

export const MasterDataSelector: React.FC<
  SelectProps<LabeledValue> &
    StyledSelectPropsType & {
      fullData?: boolean
      source: string
      onChangeFullValue?: (v: MasterDataType | null) => any
    }
> = ({ source, onChange, onChangeFullValue, ...props }) => {
  const [searchText, setSearchText] = useState('')

  const { data, loading } = useSearchMasterDataParts({
    variables: { input: { strippedNumber: searchText, source } },
  })

  const masterDataParts = (data && data.searchMasterData) || []

  return (
    <StyledSelect
      onSearch={debounce(setSearchText, 800)}
      loading={loading}
      labelInValue
      placeholder="Select master data part"
      notFoundContent={loading ? <LoadingIndicator /> : 'No Data'}
      onChange={(v, com) => {
        if (onChange) {
          onChange(v, com)
        }
        if (onChangeFullValue) {
          const selectedPart = (v && masterDataParts.find(part => part.id === v.key)) || null
          onChangeFullValue(selectedPart)
        }
      }}
      {...props}>
      {masterDataParts.map(masterDataPart => (
        <StyledSelect.Option key={masterDataPart.id}>
          <span>
            {masterDataPart.source === 'udi'
              ? masterDataPart.catalogNumber || masterDataPart.versionModelNumber
              : masterDataPart.sku || masterDataPart.materialCode || masterDataPart.nDescription}
          </span>
          |<span>{masterDataPart.manufacturer || masterDataPart.companyName || ''}</span>
        </StyledSelect.Option>
      ))}
    </StyledSelect>
  )
}

export const MasterDataSelectorAgWrapper = React.forwardRef<
  {},
  AgWrapperPropsType<StudyLinkType> & { catalog: StudyLinkCatalogEnum; isSecondaryField?: boolean }
>((params, ref) => {
  const { data, api, catalog, isSecondaryField } = params
  const [selectedPart, setSelectedPart] = useState<MasterDataType | null | undefined>(undefined)

  useImperativeHandle(ref, () => ({
    getValue: () => selectedPart,
  }))

  useEffect(() => {
    if (selectedPart !== undefined) {
      api.stopEditing()
    }
  }, [selectedPart, api])

  const value =
    (catalog === StudyLinkCatalogEnum.Medline
      ? data.matchMedId && {
          key: data.matchMedId,
          label: isSecondaryField ? data.medMaterialCode : data.medSku,
        }
      : data.matchGudidId && {
          key: data.matchGudidId,
          label: isSecondaryField ? data.gudidVersionModelNumber : data.gudidCatalogNumber,
        }) || undefined

  return (
    <MasterDataSelector
      source={catalog === StudyLinkCatalogEnum.Medline ? 'med' : 'udi'}
      allowClear
      showSearch
      filterOption={false}
      onChangeFullValue={v => {
        setSelectedPart(v)
      }}
      value={value}
      dropdownMatchSelectWidth={false}
      dropdownMenuStyle={{ width: 1000 }}
    />
  )
})
