import React from 'react'
import { MaterialsData } from '@curvo/apollo'
import { SelectInput } from '@curvo/common-ui'
import { transformDataToOption } from '../AdvanceForm'
import DebounceInput from './DebounceInput'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & {
  value: any
  onChange: (option: any) => void
}

const MaterialsSelect: React.SFC<Props> = ({ value, onChange }) => (
  <DebounceInput>
    {({ searchText, onInputChange }) => (
      <MaterialsData variables={{ searchText }}>
        {({ data, loading }) => (
          <SelectInput
            label="Material"
            onInputChange={onInputChange}
            loading={loading}
            options={data && data.materials ? transformDataToOption(data.materials.edges) : []}
            value={value}
            onChange={onChange}
            asyncMode
          />
        )}
      </MaterialsData>
    )}
  </DebounceInput>
)

MaterialsSelect.defaultProps = defaultProps

export default React.memo(MaterialsSelect)
