import React, { useState, useEffect } from 'react'
import { IStatusPanelParams } from '@ag-grid-enterprise/all-modules'
import { StudyTransactionType } from '@curvo/apollo'

const defaultMatchCb = (data: StudyTransactionType) => (data.bamfPartId && !data.aiOverwrite) || data.matchPartId

export const GroomingMatchedRatioAgComponent: React.FC<
  IStatusPanelParams & {
    isMatchedCb?: (v: any) => boolean
  }
> = ({ api, isMatchedCb = defaultMatchCb }) => {
  const [matchedNumber, setMatchedNumber] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    function handleRowDataUpdated() {
      let countMatched = 0
      let countTotal = 0
      api.forEachNodeAfterFilter(node => {
        if (!node.group && node.data !== undefined) {
          if (isMatchedCb(node.data)) {
            countMatched += 1
          }
          countTotal += 1
        }
      })
      setMatchedNumber(countMatched)
      setTotal(countTotal)
    }
    api.addEventListener('rowDataUpdated', handleRowDataUpdated)
    return function cleanUp() {
      api.removeEventListener('rowDataUpdated', handleRowDataUpdated)
    }
  }, [api, isMatchedCb])

  const percentage = total && Math.round((matchedNumber * 100.0) / total)

  return (
    <div style={{ lineHeight: '34px' }}>
      Match : {matchedNumber} / {total} {`(${percentage || 0}%)`}
    </div>
  )
}
