import { ManufacturersData } from '@curvo/apollo'
import { SelectInput } from '@curvo/common-ui'
import React from 'react'
import { transformDataToOption } from '../AdvanceForm'
import DebounceInput from './DebounceInput'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & {
  value: any
  onChange: (option: any) => void
}

const ManufacturersSelect: React.SFC<Props> = ({ value, onChange }) => (
  <DebounceInput>
    {({ searchText, onInputChange }) => (
      <ManufacturersData variables={{ searchText }} fetchPolicy="network-only">
        {({ data, loading }) => {
          return (
            <SelectInput
              label="Manufacturer"
              onInputChange={onInputChange}
              options={data && data.manufacturers ? transformDataToOption(data.manufacturers.edges) : []}
              loading={loading}
              value={value}
              onChange={onChange}
              asyncMode
            />
          )
        }}
      </ManufacturersData>
    )}
  </DebounceInput>
)

ManufacturersSelect.defaultProps = defaultProps

export default React.memo(ManufacturersSelect)
