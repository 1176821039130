import { pick } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

export const useForm = <TEdit, TSource>(
  transform: (source?: TSource) => Partial<TEdit>,
  source?: TSource,
): [Partial<TEdit>, (field: keyof TEdit) => (value: any) => void, Partial<TEdit>] => {
  const [editObject, setEditObject] = useState<Partial<TEdit>>(transform(source))
  useEffect(() => {
    setEditObject(transform(source))
  }, [source, transform])
  const [changedFields, setChangedFields] = useState<(keyof TEdit)[]>([])
  const onUpdateField = useCallback(
    (field: keyof TEdit) => (value: any) => {
      setEditObject(o => ({ ...o, [field]: value }))
      if (changedFields.indexOf(field) === -1) {
        setChangedFields(f => [...f, field])
      }
    },
    [changedFields],
  )
  const updates = pick(editObject, changedFields)
  return [editObject, onUpdateField, updates]
}
