import { Segment, StudyTransactionType } from '@curvo/apollo'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import { SegmentSelect } from '../../Update/components/Select/SegmentSelect'
import { AgWrapperPropsType } from './ManufacturerSelectAgWrapper'

export const SegmentSelectAgWrapper = React.forwardRef<{}, AgWrapperPropsType<StudyTransactionType>>((params, ref) => {
  const { data, api } = params

  const value = data
    ? data.segmentId
      ? {
          key: data.segmentId,
          label: data.segmentName,
        }
      : undefined
    : undefined
  const [selectedSegment, setSelectedSegment] = useState<Segment>()

  useImperativeHandle(ref, () => ({
    getValue: () => selectedSegment || undefined,
  }))

  useEffect(() => {
    if (selectedSegment) {
      api.stopEditing()
    }
  }, [selectedSegment, api])

  return <SegmentSelect value={value} onChangeFullValue={v => setSelectedSegment(v)} />
})
