import React, { useState, useImperativeHandle, useEffect } from 'react'
import { RowNode, GridApi, ColDef } from '@ag-grid-community/all-modules'
import { LabeledValue } from 'antd/lib/select'
import { StudyTransactionType, StudyLinkType, NormalizedSupplier, Manufacturer } from '@curvo/apollo'
import { ManufacturerSelect } from '../../Update/components/Select/ManufacturerSelect'

export type AgWrapperPropsType<T> = {
  data: T
  node: RowNode
  api: GridApi
  colDef: ColDef
  value: any
}

const useManufacturerSelect = (api: GridApi, ref: any, matchManufacturerId?: string | null) => {
  const [labeledValue, setLabeledValue] = useState<LabeledValue | null>()

  useImperativeHandle(ref, () => ({
    getValue: () => labeledValue,
  }))

  useEffect(() => {
    setLabeledValue(undefined)
  }, [matchManufacturerId])

  useEffect(() => {
    if (labeledValue !== undefined) {
      api.stopEditing()
    }
  }, [labeledValue, api])
  return { setLabeledValue }
}

export const ManufacturerSelectAgWrapper = React.forwardRef<{}, AgWrapperPropsType<StudyTransactionType>>(
  (params, ref) => {
    const { data, api } = params
    const matchManufacturerId = data && data.matchManufacturerId
    const value =
      (matchManufacturerId &&
        data.bamfManufacturer && {
          key: data.matchManufacturerId || data.bamfManufacturer,
          label: data.bamfManufacturer,
        }) ||
      undefined
    const { setLabeledValue } = useManufacturerSelect(api, ref, matchManufacturerId)
    return (
      <ManufacturerSelect
        value={value}
        allowClear
        onChange={v => {
          if (v) {
            setLabeledValue(v)
          } else {
            setLabeledValue(null)
          }
        }}
      />
    )
  },
)

export const ManufacturerSelectAgWrapperStudyLink = React.forwardRef<{}, AgWrapperPropsType<StudyLinkType>>(
  (params, ref) => {
    const { data, api } = params
    const matchManufacturerId = data && data.stanManufacturerId
    const value =
      (matchManufacturerId &&
        data.stanManufacturer && {
          key: matchManufacturerId || data.stanManufacturer,
          label: data.stanManufacturer,
        }) ||
      undefined
    const { setLabeledValue } = useManufacturerSelect(api, ref, matchManufacturerId)
    return (
      <ManufacturerSelect
        value={value}
        allowClear
        onChange={v => {
          if (v) {
            setLabeledValue(v)
          } else {
            setLabeledValue(null)
          }
        }}
      />
    )
  },
)

export const ManufacturerSelectAgWrapperNormSuppliers = React.forwardRef<{}, AgWrapperPropsType<NormalizedSupplier>>(
  (params, ref) => {
    const { data, api } = params

    const manufacturerId = data && data.manufacturer && data.manufacturer.id
    const value = manufacturerId
      ? {
          key: manufacturerId,
          label: manufacturerId || (data.manufacturer && data.manufacturer.name),
        }
      : undefined

    const [labeledValue, setLabeledValue] = useState<Manufacturer | null>()

    useImperativeHandle(ref, () => ({
      getValue: () => labeledValue,
    }))

    useEffect(() => {
      setLabeledValue(undefined)
    }, [manufacturerId])

    useEffect(() => {
      if (labeledValue !== undefined) {
        api.stopEditing()
      }
    }, [labeledValue, api])

    return (
      <ManufacturerSelect
        value={value}
        allowClear
        onChangeFullObject={v => {
          if (v) {
            setLabeledValue(v)
          } else {
            setLabeledValue(null)
          }
        }}
      />
    )
  },
)
