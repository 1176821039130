"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateFormat = exports.MonthFormat = exports.DayFormat = exports.dddd = exports.ddd = exports.dd = exports.Mo = exports.MM = exports.MMM = exports.MMMM = exports.isBeforeToday = exports.weekBuilder = exports.calendarBuilder = exports.WeekStartsOn = void 0;
const date_fns_1 = require("date-fns");
const arrayUtils_1 = require("../../../helpers/arrayUtils");
var WeekStartsOn;
(function (WeekStartsOn) {
    WeekStartsOn[WeekStartsOn["Sun"] = 0] = "Sun";
    WeekStartsOn[WeekStartsOn["Mon"] = 1] = "Mon";
    WeekStartsOn[WeekStartsOn["Tue"] = 2] = "Tue";
    WeekStartsOn[WeekStartsOn["Wed"] = 3] = "Wed";
    WeekStartsOn[WeekStartsOn["Thu"] = 4] = "Thu";
    WeekStartsOn[WeekStartsOn["Fri"] = 5] = "Fri";
    WeekStartsOn[WeekStartsOn["Sat"] = 6] = "Sat";
})(WeekStartsOn = exports.WeekStartsOn || (exports.WeekStartsOn = {}));
const defaultOptions = {
    weekStartsOn: 1,
};
const DAYS_IN_WEEK = 7;
const calendarBuilder = (basedDate, builderOptions) => {
    const options = {
        ...defaultOptions,
        ...builderOptions,
    };
    const { weekStartsOn } = options;
    const firstDayOfMonth = (0, date_fns_1.startOfMonth)(basedDate);
    const firstDayOfCalendar = (0, date_fns_1.startOfWeek)(firstDayOfMonth, { weekStartsOn });
    const lastDayOfMonth = (0, date_fns_1.endOfMonth)(firstDayOfMonth);
    const lastDayOfCalendar = (0, date_fns_1.endOfWeek)(lastDayOfMonth, { weekStartsOn });
    const totalDays = (0, date_fns_1.differenceInDays)(lastDayOfCalendar, firstDayOfCalendar);
    const allDays = Array.from({ length: totalDays + 1 }).map((_, index) => (0, date_fns_1.addDays)(firstDayOfCalendar, index));
    return (0, arrayUtils_1.chunk)(allDays, DAYS_IN_WEEK);
};
exports.calendarBuilder = calendarBuilder;
const weekBuilder = (builderOptions) => {
    const options = {
        ...defaultOptions,
        ...builderOptions,
    };
    const { weekStartsOn } = options;
    return Array.from({ length: DAYS_IN_WEEK }).map((_, index) => (DAYS_IN_WEEK + (index + weekStartsOn)) % DAYS_IN_WEEK);
};
exports.weekBuilder = weekBuilder;
const isBeforeToday = (date) => (0, date_fns_1.isBefore)(date, new Date(new Date().setHours(0, 0, 0, 0)));
exports.isBeforeToday = isBeforeToday;
exports.MMMM = {
    0: 'January',
    1: 'Febnuary',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
};
exports.MMM = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
};
exports.MM = {
    0: '01',
    1: '02',
    2: '03',
    3: '04',
    4: '05',
    5: '06',
    6: '07',
    7: '08',
    8: '09',
    9: '10',
    10: '11',
    11: '12',
};
exports.Mo = {
    0: '1st',
    1: '2nd',
    2: '3rd',
    3: '4th',
    4: '5th',
    5: '6th',
    6: '7th',
    7: '8th',
    8: '9th',
    9: '10th',
    10: '11th',
    11: '12th',
};
exports.dd = {
    0: 'Su',
    1: 'Mo',
    2: 'Tu',
    3: 'We',
    4: 'Th',
    5: 'Fr',
    6: 'Sa',
};
exports.ddd = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
};
exports.dddd = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
};
exports.DayFormat = {
    dd: exports.dd,
    ddd: exports.ddd,
    dddd: exports.dddd,
};
exports.MonthFormat = {
    Mo: exports.Mo,
    MM: exports.MM,
    MMM: exports.MMM,
    MMMM: exports.MMMM,
};
exports.DateFormat = {
    ...exports.DayFormat,
    ...exports.MonthFormat,
};
