import {
  createGicImageMutation,
  deleteGicImageMutation,
  GicImage,
  GicTypeTwo,
  GIC_IMAGES,
  UpdateGicTypeTwoInput,
} from '@curvo/apollo'
import { Form, Icon, Input } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import React, { useEffect, useState } from 'react'
import { EditPanelWrapper, emptyOrReturn } from '../../../../../components/common'
import { EditDrawer } from '../../../../../components/EditDrawer'
import { S3Uploader } from '../../../Upload/components/Panel/S3Uploader'
import { gicImagesToFileUpload } from './EditGIC'

export const EditGicTypeTwo: React.FC<
  {
    onCancel: () => void
    gicId: number
    gicTypeOneId?: string
    gicTypeTwo?: GicTypeTwo
    gicImages?: GicImage[]
    onSubmit: (gicTypeOneId: string, input: UpdateGicTypeTwoInput) => void
  } & DrawerProps
> = ({ gicId, gicTypeTwo, gicTypeOneId, gicImages, onSubmit, onCancel, ...drawerProps }) => {
  const [includes, setIncludes] = useState<string | null | undefined>()
  const [excludes, setExcludes] = useState<string | null | undefined>()

  useEffect(() => {
    if (gicTypeTwo) {
      setIncludes(gicTypeTwo.includes)
      setExcludes(gicTypeTwo.excludes)
    }
  }, [gicTypeTwo])

  const typeTwoImages = ((gicTypeTwo && gicImages) || []).filter(
    gicImage =>
      gicImage.gicTypeTwoId &&
      gicImage.gicTypeTwoId === gicTypeTwo?.id &&
      gicImage.gicTypeOneId &&
      gicImage.gicTypeOneId === gicTypeOneId,
  )

  const handleSave = () => {
    if (gicTypeOneId && gicTypeTwo) {
      setIncludes('')
      setExcludes('')
      onSubmit(gicTypeOneId, { id: gicTypeTwo?.id, includes, excludes })
    }
  }

  return (
    <EditDrawer onCancel={onCancel} onSave={handleSave} {...drawerProps}>
      <EditPanelWrapper>
        <Form.Item label="Includes">
          <Input.TextArea
            value={emptyOrReturn(includes, gicTypeTwo && gicTypeTwo.includes)}
            onChange={e => setIncludes(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Excludes">
          <Input.TextArea
            value={emptyOrReturn(excludes, gicTypeTwo && gicTypeTwo.excludes)}
            onChange={e => setExcludes(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Upload Image">
          <S3Uploader
            isDragger={false}
            listType="picture-card"
            fileList={gicImagesToFileUpload(typeTwoImages)}
            accept=".jpg"
            onRemove={e => {
              deleteGicImageMutation(
                { id: e.uid },
                { refetchQueries: [{ query: GIC_IMAGES, variables: { id: gicId } }] },
              )
            }}
            onUploaded={async e => {
              if (gicTypeOneId && gicTypeTwo) {
                await createGicImageMutation(
                  {
                    input: {
                      imageUrl: e,
                      gicId: gicId,
                      gicTypeOneId,
                      gicTypeTwoId: gicTypeTwo.id,
                    },
                  },
                  { refetchQueries: [{ query: GIC_IMAGES, variables: { id: gicId } }] },
                )
              }
            }}>
            <div className="ant-upload-list-picture-card-container">
              <Icon type="plus" />
              <div className="ant-upload-text">Upload</div>
            </div>
          </S3Uploader>
        </Form.Item>
      </EditPanelWrapper>
    </EditDrawer>
  )
}
