import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Tooltip } from 'antd'
import { ReactComponent as DollarSign } from '../../../../../assets/images/dollar-symbol.svg'

export const AnimatedLoadingBar: React.FC<{ progress: number }> = ({ progress }) => {
  return (
    <Wrapper>
      <StyledDollarSign width={28} height={28} />
      <Meter>
        <Tooltip title={`${progress.toFixed()}%`}>
          <span style={{ width: `${progress}%` }} />
        </Tooltip>
      </Meter>
    </Wrapper>
  )
}

const StyledDollarSign = styled(DollarSign)`
  position: relative;
  right: -10px;
  z-index: 1000;
`

const Wrapper = styled.div`
  display: flex;
`

const move = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
`

const Meter = styled.div`
  background-color: #f0a3a3;
  background-image: -moz-linear-gradient(top, #f0a3a3, #f42323);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #f0a3a3), color-stop(1, #f42323));
  background-image: -webkit-linear-gradient(#f0a3a3, #f42323);
  background: #555;
  border-radius: 3px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  width: 160px;
  height: 24px;
  padding: 2px;

  > span {
    display: block;
    height: 20px;
    border-radius: 3px;
    background-color: rgb(43, 194, 83);
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0, rgb(43, 194, 83)),
      color-stop(1, rgb(84, 240, 84))
    );
    background-image: -moz-linear-gradient(center bottom, rgb(43, 194, 83) 37%, rgb(84, 240, 84) 69%);
    box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
    position: relative;
    overflow: hidden;
  }

  > span:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: -webkit-gradient(
      linear,
      0 0,
      100% 100%,
      color-stop(0.25, rgba(255, 255, 255, 0.2)),
      color-stop(0.25, transparent),
      color-stop(0.5, transparent),
      color-stop(0.5, rgba(255, 255, 255, 0.2)),
      color-stop(0.75, rgba(255, 255, 255, 0.2)),
      color-stop(0.75, transparent),
      to(transparent)
    );
    background-image: -moz-linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
    );
    z-index: 1;
    -webkit-background-size: 50px 50px;
    -moz-background-size: 50px 50px;
    -webkit-animation: ${move} 2s linear infinite;
    -webkit-border-top-right-radius: 8px;
    -webkit-border-bottom-right-radius: 8px;
    -moz-border-radius-topright: 8px;
    -moz-border-radius-bottomright: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-bottom-left-radius: 20px;
    -moz-border-radius-topleft: 20px;
    -moz-border-radius-bottomleft: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
  }
`
