import React, { useState } from 'react'
import { Select } from 'antd'
import { SelectProps, LabeledValue } from 'antd/lib/select'
import { FileMappingsData, FileMappingType } from '@curvo/apollo'
import { debounce } from 'lodash'
import { StyledSelectPropsType, StyledSelect } from '../../Update/components/Select/common'

const { Option } = Select

export const FileMappingSelect: React.FC<
  SelectProps<LabeledValue> &
    StyledSelectPropsType & {
      onSelectionChanged: (fileMappings: FileMappingType | undefined) => void
    }
> = ({ onSelectionChanged, ...props }) => {
  const [searchText, setSearchText] = useState('')
  return (
    <FileMappingsData variables={{ input: { name: searchText }, first: 1000 }} fetchPolicy="network-only">
      {({ data, loading }) => (
        <StyledSelect
          onSearch={debounce(setSearchText, 800)}
          loading={loading}
          showSearch
          labelInValue
          filterOption={false}
          showArrow={true}
          defaultActiveFirstOption={false}
          placeholder="Select File Mapping"
          onChange={value => {
            const selectedEdge = data && value && data.fileMappings.edges.find(edge => edge.node.id === value.key)
            onSelectionChanged(selectedEdge && selectedEdge.node)
          }}
          {...props}>
          {data &&
            data.fileMappings &&
            data.fileMappings.edges.map(edge => <Option key={edge.node.id}>{edge.node.name}</Option>)}
        </StyledSelect>
      )}
    </FileMappingsData>
  )
}
