import { Button } from 'antd'
import { LabeledValue } from 'antd/lib/select'
import { FilterDropdownProps } from 'antd/lib/table'
import React, { useState } from 'react'
import styled from 'styled-components'

type FilterSearchDropdownChildrenProps = {
  selected?: LabeledValue
  setSelected: (value: LabeledValue) => void
}

type FilterSearchDropdownProps = FilterDropdownProps & {
  children: (props: FilterSearchDropdownChildrenProps) => React.ReactNode
}

export const DropdownFilter: React.FunctionComponent<FilterSearchDropdownProps> = ({
  setSelectedKeys,
  confirm,
  clearFilters,
  children,
}) => {
  const [selected, setSelected] = useState<LabeledValue>()
  return (
    <FilterWrapper>
      {children({ selected, setSelected })}
      <FilterActionsWrapper>
        <Button
          type="primary"
          onClick={() => {
            confirm && confirm()
            selected && setSelectedKeys && setSelectedKeys([selected.key])
          }}>
          Filter
        </Button>
        <Button
          onClick={() => {
            setSelected(undefined)
            clearFilters && clearFilters()
          }}>
          Clear
        </Button>
      </FilterActionsWrapper>
    </FilterWrapper>
  )
}

const FilterWrapper = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  width: 180px;
`

const FilterActionsWrapper = styled.div`
  justify-content: space-between;
  width: 100%;
  margin-top: 12px;
  display: flex;
`
