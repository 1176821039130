import React, { useState, useImperativeHandle, useEffect } from 'react'
import { Select, message } from 'antd'
import { SelectProps, LabeledValue } from 'antd/lib/select'
import { ConstructValidationType, useConstructValidationsData, PurchaseOrder } from '@curvo/apollo'
import { StyledSelectPropsType, StyledMultipleSelect } from '../../Update/components/Select/common'
import { AgWrapperPropsType } from './ManufacturerSelectAgWrapper'

const { Option } = Select

export const ConstructValidationSelect: React.FC<
  SelectProps<LabeledValue | LabeledValue[]> &
    StyledSelectPropsType & {
      onFullValueChange?: (constructValidation?: ConstructValidationType[]) => void
    }
> = ({ onChange, onFullValueChange, ...props }) => {
  const { data, error, loading } = useConstructValidationsData({ fetchPolicy: 'network-only' })

  if (error) {
    message.error(error.message)
  }

  const constructValidations = ((data && data.constructValidations) || []).sort((a, b) =>
    a.message.localeCompare(b.message),
  )

  return (
    <StyledMultipleSelect
      loading={loading}
      mode="multiple"
      showSearch
      labelInValue
      filterOption={true}
      showArrow={true}
      defaultActiveFirstOption={false}
      placeholder="Validation Errors"
      optionLabelProp="value"
      onChange={(v, com) => {
        if (onChange) {
          onChange(v, com)
        }
        if (onFullValueChange) {
          const selected =
            data &&
            data.constructValidations.filter(result =>
              (v as LabeledValue[]).find(vv => result.conId === vv.key.split('|')[0]),
            )
          onFullValueChange(selected)
        }
      }}
      {...props}>
      {constructValidations.map(validation => (
        <Option key={validation.conId} value={`${validation.conId}|${validation.message}`}>
          {validation.conId} | {validation.message}
        </Option>
      ))}
    </StyledMultipleSelect>
  )
}

export const ConstructValidationSelectAgWrapper = React.forwardRef<{}, AgWrapperPropsType<PurchaseOrder>>(
  (params, ref) => {
    const { data, api } = params

    const value = data
      ? data.validationErrors
        ? data.validationErrors.map((err: ConstructValidationType) => ({
            key: err.conId,
            label: err.message,
          }))
        : undefined
      : undefined
    const [labeledValues, setLabeledValues] = useState<ConstructValidationType[]>()

    useImperativeHandle(ref, () => ({
      getValue: () => labeledValues || undefined,
    }))

    useEffect(() => {
      if (labeledValues) {
        api.stopEditing()
      }
    }, [labeledValues, api])

    return <ConstructValidationSelect value={value} onFullValueChange={v => setLabeledValues(v)} />
  },
)
