import { AllowedGicsTypeOneData, GicTypeOne } from '@curvo/apollo'
import { SelectInput } from '@curvo/common-ui'
import React from 'react'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & {
  gicId?: string
  value: any
  onChange: (option: any) => void
  onClearValue: () => void
}

const transformDataToOption = (data: GicTypeOne[]) => {
  if (!data) {
    return []
  }
  return data.map(gicTypeOne => ({
    label: gicTypeOne.name,
    value: gicTypeOne.id,
    id: gicTypeOne.id,
  }))
}

const GicTypeOnesSelect: React.SFC<Props> = ({ gicId, onChange, value, onClearValue }) => (
  <AllowedGicsTypeOneData variables={{ gicId: gicId ? +gicId : -1 }}>
    {({ data, loading }) => {
      return (
        <SelectInput
          label="Type 1"
          options={data ? transformDataToOption(data.allowedGicsTypeOne) : []}
          disabled={gicId ? false : true}
          loading={loading}
          value={value}
          onChange={onChange}
          onClearValue={onClearValue}
        />
      )
    }}
  </AllowedGicsTypeOneData>
)

GicTypeOnesSelect.defaultProps = defaultProps

export default GicTypeOnesSelect
