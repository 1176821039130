import React from 'react'
import { Route, Routes } from 'react-router'
import { CategoryRulesGrid } from './CategoryRulesGrid'

export const CategoryRules: React.FC = () => {
  return (
    <Routes>
      <Route path={'/*'} element={<CategoryRulesGrid />} />
    </Routes>
  )
}
