import {
  AllowedGicsTypeOneData,
  GICS_TYPE_ONE_QUERY,
  GicTypeOne,
  GicsTypeOneData,
  createGicTypeOneMutation,
} from '@curvo/apollo'
import LoadingIndicator from '@curvo/common-ui/dist/components/Loading/Spinner'
import { Icon } from 'antd'
import { LabeledValue, SelectProps } from 'antd/lib/select'
import React, { useCallback, useState } from 'react'
import { StyledMultipleSelect, StyledSelect, StyledSelectPropsType, TypedMultipleSelect, TypedSelect } from './common'

type GICsTypeOneSelectProps = SelectProps<LabeledValue | LabeledValue[] | undefined> &
  StyledSelectPropsType & {
    createNewIfNotFound?: boolean
    onChangeFullValue?: (fullValue?: GicTypeOne) => void
  }

const OTHER_TYPE_ONE = 'Other'
const UNSPEC_TYPE_ONE = 'Unspec'

export const GICsTypeOneSelect: React.FC<GICsTypeOneSelectProps> = ({
  createNewIfNotFound,
  onChangeFullValue,
  onChange,
  ...props
}) => {
  const [searchText, setSearchText] = useState('')
  const dropdownRender = useCallback(
    (menu: React.ReactNode, options: GicTypeOne[]) =>
      !createNewIfNotFound || options.length > 0 ? (
        menu
      ) : (
        <div
          style={{ padding: '4px 8px', cursor: 'pointer' }}
          onMouseDown={e => e.preventDefault()}
          onClick={() => {
            createGicTypeOneMutation(
              { input: { name: searchText } },
              { refetchQueries: [{ query: GICS_TYPE_ONE_QUERY }] },
            )
          }}>
          <Icon style={{ marginRight: '8px' }} type="plus" />
          {`Add GIC type One: ${searchText}`}
        </div>
      ),
    [createNewIfNotFound, searchText],
  )
  return (
    <GicsTypeOneData fetchPolicy="network-only">
      {({ data, loading }) => {
        const typeOnes = (data && data.gicsTypeOne) || []

        const otherTypeOne = typeOnes.filter(to => to.name === OTHER_TYPE_ONE)
        const unspecTypeOne = typeOnes.filter(to => to.name === UNSPEC_TYPE_ONE)

        // move Unspec to the top of typeOne list, and Other to btm, for accessibility
        const options = [
          ...unspecTypeOne,
          ...typeOnes.filter(to => to.name !== OTHER_TYPE_ONE && to.name !== UNSPEC_TYPE_ONE),
          ...otherTypeOne,
        ].filter(typeOne => new RegExp(searchText, 'i').test(typeOne.name))
        return (
          <StyledMultipleSelect
            onSearch={setSearchText}
            loading={loading}
            showSearch
            labelInValue
            filterOption={false}
            showArrow={false}
            defaultActiveFirstOption={false}
            placeholder="Select GIC Type One"
            notFoundContent={loading ? <LoadingIndicator /> : 'No Data'}
            dropdownRender={menu => dropdownRender(menu, options)}
            onChange={(v, com) => {
              if (onChange) {
                onChange(v, com)
              }
              if (onChangeFullValue && v && !Array.isArray(v)) {
                const selectedGicTypeOne = options.find(typeOne => typeOne.id === v.key)
                onChangeFullValue(selectedGicTypeOne)
              }
            }}
            {...props}>
            {options.map(typeOne => (
              <TypedMultipleSelect.Option key={typeOne.id}>{typeOne.name}</TypedMultipleSelect.Option>
            ))}
          </StyledMultipleSelect>
        )
      }}
    </GicsTypeOneData>
  )
}

type AllowedGICsTypeOneSelectProps = SelectProps<LabeledValue | undefined> &
  StyledSelectPropsType & { gicId?: number; onChangeFullValue?: (fullValue?: GicTypeOne) => void }

export const AllowedGICsTypeOneSelect: React.FC<AllowedGICsTypeOneSelectProps> = ({
  gicId,
  onChange,
  onChangeFullValue,
  ...props
}) => (
  <AllowedGicsTypeOneData variables={{ gicId }}>
    {({ data, loading }) => (
      <StyledSelect
        loading={loading}
        showSearch
        labelInValue
        filterOption={false}
        showArrow={false}
        defaultActiveFirstOption={false}
        placeholder="Select GIC Type One"
        allowClear={true}
        onChange={(v, com) => {
          if (onChange) {
            onChange(v, com)
          }
          const typeOnes = (data && data.allowedGicsTypeOne) || []

          const otherTypeOne = typeOnes.filter(to => to.name === OTHER_TYPE_ONE)
          const unspecTypeOne = typeOnes.filter(to => to.name === UNSPEC_TYPE_ONE)

          // move Unspec to the top of typeOne list, and Other to btm, for accessibility
          const options = [
            ...unspecTypeOne,
            ...typeOnes.filter(to => to.name !== OTHER_TYPE_ONE && to.name !== UNSPEC_TYPE_ONE),
            ...otherTypeOne,
          ]
          if (onChangeFullValue && !Array.isArray(v)) {
            const selectedGicTypeOne = v ? options.find(typeOne => typeOne.id === v.key) : undefined
            onChangeFullValue(selectedGicTypeOne)
          }
        }}
        {...props}>
        {data &&
          data.allowedGicsTypeOne &&
          data.allowedGicsTypeOne.map(typeOne => (
            <TypedSelect.Option key={props.fullData ? JSON.stringify(typeOne) : typeOne.id}>
              {typeOne.name}
            </TypedSelect.Option>
          ))}
      </StyledSelect>
    )}
  </AllowedGicsTypeOneData>
)
