import React from 'react'
import { Route, Routes } from 'react-router'
import { QueuedLinkMasterGrid } from './QueuedLinkMasterGrid'

export const QueuedLinkMaster: React.FC = () => {
  return (
    <Routes>
      <Route path={'/*'} element={<QueuedLinkMasterGrid />} />
    </Routes>
  )
}
