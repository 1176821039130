import { useStudyData } from '@curvo/apollo'
import { PageHeader } from 'antd'
import React from 'react'
import { Route, Routes, useNavigate, useParams } from 'react-router'
import { CaseReviewDetails } from './CaseReviewDetails'
import { PurchaseOrderGrade } from './PurchaseOrderGrade'

type CaseReviewProps = {
  studyId: string
}
export const CaseReview: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams<CaseReviewProps>()
  const studyId = params.studyId ? parseInt(params.studyId, 10) : 0
  const { data } = useStudyData({ id: studyId })
  const study = data && data.study
  const total = study && study.poCount

  return (
    <>
      <PageHeader
        title={`Study ${study && study.id}`}
        subTitle={
          study &&
          `Client: ${study.facilityId} ${study.facilityName}
          | Cases: ${total || '-'}
          | From: ${(study && study.minDatePurchase) || ''}
          | To: ${(study && study.maxDatePurchase) || ''}`
        }
        onBack={() => navigate('/data-cleaning/')}
      />
      <div style={{ marginLeft: '24px', marginRight: '24px' }}>
        <Routes>
          <Route path={'/details'} element={<CaseReviewDetails studyId={studyId} study={study} />} />
          <Route path={'/'} element={<PurchaseOrderGrade studyId={studyId} />} />
        </Routes>
      </div>
    </>
  )
}
