import { Icon, Menu } from 'antd'
import React, { useState } from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components'

const { SubMenu } = Menu

export const Navigator: React.FunctionComponent<NavigatorPropTypes> = ({ onMenuItemClick, menus, collapsed }) => {
  const location = useLocation()
  const openKeysByUrl = menus.map(menu => menu.prefixUrl).filter(prefixUrl => location.pathname.startsWith(prefixUrl))
  const selectedKeys =
    openKeysByUrl.length > 0 ? [location.pathname.replace(openKeysByUrl[0], '').substring(1), openKeysByUrl[0]] : []
  const [openKeys, setOpenKeys] = useState(openKeysByUrl)
  return (
    <React.Fragment>
      <LogoContainer>
        <LogoTextSpan>ONN</LogoTextSpan>
      </LogoContainer>
      <Menu
        mode="inline"
        theme="dark"
        openKeys={collapsed ? undefined : openKeys}
        selectedKeys={collapsed ? undefined : selectedKeys}
        onOpenChange={key => setOpenKeys(key)}>
        {menus.map(({ iconType, label, routes, prefixUrl }) =>
          routes ? (
            <SubMenu key={prefixUrl} title={<TitleSpan iconType={iconType} label={label} />}>
              {routes.map(route => (
                <Menu.Item key={route.path} onClick={() => onMenuItemClick(prefixUrl, route)}>
                  {route.label}
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item key={prefixUrl} onClick={() => onMenuItemClick(prefixUrl)}>
              <TitleSpan iconType={iconType} label={label} />
            </Menu.Item>
          ),
        )}
      </Menu>
    </React.Fragment>
  )
}

const TitleSpan: React.FC<{ iconType?: string; label: string }> = ({ iconType, label }) => (
  <span>
    {iconType && <Icon type={iconType} />}
    <span>{label}</span>
  </span>
)
type MenuItemClickHandler = (prefixUrl: string, route?: NavigationSubItem) => void
type NavigationSubItem = {
  path: string
  label: string
}
export type NavigationItem = {
  routes?: NavigationSubItem[]
  iconType?: string
  label: string
  prefixUrl: string
}
type NavigatorPropTypes = {
  menus: NavigationItem[]
  onMenuItemClick: MenuItemClickHandler
  collapsed: boolean
}

const LogoContainer = styled.div`
  height: 64px;
  padding: 16px;
  padding-left: 24px;
  background-color: #001b3e;
`

const LogoTextSpan = styled.span`
  font-size: 20px;
  color: #ffffff;
`
