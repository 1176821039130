import React, { useImperativeHandle, useEffect, useState } from 'react'
import { StudyTransactionType, Material } from '@curvo/apollo'
import { MaterialSelect } from '../../Update/components/Select/MaterialSelect'
import { AgWrapperPropsType } from './ManufacturerSelectAgWrapper'

export const MaterialSelectAgWrapper = React.forwardRef<{}, AgWrapperPropsType<StudyTransactionType>>((params, ref) => {
  const { data, api } = params

  const value = data
    ? data.materialId
      ? {
          key: data.materialId,
          label: data.materialName,
        }
      : undefined
    : undefined
  const [selectedMaterial, setSelectedMaterial] = useState<Material>()

  useImperativeHandle(ref, () => ({
    getValue: () => selectedMaterial || undefined,
  }))

  useEffect(() => {
    if (selectedMaterial) {
      api.stopEditing()
    }
  }, [selectedMaterial, api])

  return <MaterialSelect value={value} onChangeFullValue={v => setSelectedMaterial(v)} />
})
