import React, { useImperativeHandle, useEffect, useState } from 'react'
import { StudyTransactionType, GicTypeTwo } from '@curvo/apollo'
import { GicsTypeTwoSelect, AllowedGICsTypeTwoSelect } from '../../Update/components/Select/GICTypeTwoSelect'
import { AgWrapperPropsType } from './ManufacturerSelectAgWrapper'

export const GicTypeTwoAgWrapper = React.forwardRef<{}, AgWrapperPropsType<StudyTransactionType>>((params, ref) => {
  const { data, api } = params

  const value = data
    ? data.typeTwoId
      ? {
          key: data.typeTwoId,
          label: data.typeTwoName,
        }
      : undefined
    : undefined
  const [selectedGicTypeTwo, setSelectedGicTypeTwo] = useState<GicTypeTwo>()

  useImperativeHandle(ref, () => ({
    getValue: () => selectedGicTypeTwo || undefined,
  }))

  useEffect(() => {
    if (selectedGicTypeTwo) {
      api.stopEditing()
    }
  }, [selectedGicTypeTwo, api])

  return <GicsTypeTwoSelect value={value} onChangeFullValue={v => setSelectedGicTypeTwo(v)} />
})

export const AllowedGicTypeTwoAgWrapper = React.forwardRef<{}, AgWrapperPropsType<StudyTransactionType>>(
  (params, ref) => {
    const { data, api } = params

    const value = data
      ? data.typeTwoId
        ? {
            key: data.typeTwoId,
            label: data.typeTwoName,
          }
        : undefined
      : undefined
    const [selectedGicTypeTwo, setSelectedGicTypeTwo] = useState<GicTypeTwo>()

    useImperativeHandle(ref, () => ({
      getValue: () => selectedGicTypeTwo || undefined,
    }))

    useEffect(() => {
      if (selectedGicTypeTwo) {
        api.stopEditing()
      }
    }, [selectedGicTypeTwo, api])

    return (
      <AllowedGICsTypeTwoSelect
        gicId={data.gicId || undefined}
        typeOneId={data.typeOneId || undefined}
        value={value}
        onChangeFullValue={v => setSelectedGicTypeTwo(v)}
      />
    )
  },
)
