import { createSubSegmentMutation, SubSegment, SubSegmentsData, SUB_SEGMENTS_QUERY } from '@curvo/apollo'
import { Icon } from 'antd'
import { LabeledValue, SelectProps } from 'antd/lib/select'
import { debounce } from 'lodash'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { TypedSelect } from './common'

export const SubSegmentSelect: React.FC<
  SelectProps<LabeledValue> & {
    createIfNotExist?: boolean
  }
> = ({ createIfNotExist, ...props }) => {
  const [searchText, setSearchText] = useState('')
  const dropdownRender = useCallback(
    (menu: any, options: SubSegment[]) =>
      !createIfNotExist || options.length > 0 ? (
        menu
      ) : (
        <div
          style={{ padding: '4px 8px', cursor: 'pointer' }}
          onMouseDown={e => e.preventDefault()}
          onClick={() => {
            createSubSegmentMutation(
              { input: { name: searchText } },
              {
                refetchQueries: [{ query: SUB_SEGMENTS_QUERY, variables: { name: searchText } }],
              },
            )
          }}>
          <Icon style={{ marginRight: '8px' }} type="plus" />
          {`Add Sub Segment: ${searchText}`}
        </div>
      ),
    [createIfNotExist, searchText],
  )
  return (
    <SubSegmentsData variables={{ name: searchText }}>
      {({ data, loading }) => {
        const options = ((data && data.subSegments && data.subSegments.edges) || []).map(edge => edge.node)
        return (
          <StyledSelect
            loading={loading}
            onSearch={debounce(setSearchText, 800)}
            showSearch
            labelInValue
            filterOption={false}
            showArrow={false}
            defaultActiveFirstOption={false}
            placeholder="Select Sub Segment"
            dropdownRender={menu => dropdownRender(menu, options)}
            {...props}>
            {options.map(seg => (
              <TypedSelect.Option key={seg.id} value={seg.id}>
                {seg.name}
              </TypedSelect.Option>
            ))}
          </StyledSelect>
        )
      }}
    </SubSegmentsData>
  )
}

const StyledSelect = styled(TypedSelect)<{ children?: React.ReactNode }>`
  width: 250px;
`
