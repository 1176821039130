import React, { useState } from 'react'
import {
  StudyFileTypeEnum,
  FileAlternativeHeadersInput,
  CheckFileHeaders,
  FileMappingType,
  CreateFileMappingInput,
  createFileMappingMutation,
  UpdateFileMappingInput,
  updateFileMappingMutation,
  FILE_MAPPING,
  Query,
} from '@curvo/apollo'
import { Row, Col, Form, Select, Button, message, Popover, Input } from 'antd'
import { SelectProps } from 'antd/lib/select'
import { omit } from 'lodash'
import { FileMappingSelect } from './FileMappingSelector'

type AlternativemappingInputsProps = {
  alternativeHeaders: Partial<FileAlternativeHeadersInput>
  onChange: (newMapping: Partial<FileAlternativeHeadersInput>) => void
  s3Key: string
  fileType?: StudyFileTypeEnum
}

type HeadersSelectorProps = SelectProps<string> & {
  options?: string[]
}

const HeadersSelector: React.FC<HeadersSelectorProps> = ({ options, ...selectProps }) => (
  <Select allowClear showSearch {...selectProps} style={{ width: '100%' }}>
    {options && options.map(option => <Select.Option key={option}>{option}</Select.Option>)}
  </Select>
)

export const AlternativeMappingInputs: React.FC<AlternativemappingInputsProps> = ({
  alternativeHeaders,
  onChange,
  s3Key,
  fileType,
}) => {
  const [selectedMapping, setSelectedMapping] = useState<FileMappingType>()
  const [showCreatePopup, setShowCreatePopup] = useState(false)

  const createNewFileMapping = async (input: CreateFileMappingInput) => {
    await createFileMappingMutation(
      {
        input,
      },
      {
        update: (cache, { data: createFileMapping }) => {
          const data = cache.readQuery<Pick<Query, 'fileMappings'>>({ query: FILE_MAPPING })
          const newData = {
            fileMappings: {
              ...data!.fileMappings,
              edges: (data ? data.fileMappings.edges : []).concat([
                { node: createFileMapping!.createFileMapping, cursor: '' },
              ]),
            },
          }

          cache.writeQuery({
            query: FILE_MAPPING,
            data: newData,
          })
        },
      },
    )
    message.success('Created new file mapping')
  }

  const updateNewFileMapping = async (input: UpdateFileMappingInput) => {
    await updateFileMappingMutation({
      id: selectedMapping!.id,
      input,
    })
    message.success('Updated file mapping')
  }

  const localMapping = {
    ...omit(selectedMapping, ['__typename', 'id']),
    ...alternativeHeaders,
  }

  return (
    <CheckFileHeaders variables={{ s3Key }}>
      {({ data, loading }) => {
        const alternativeHeadersSelectProps = (
          field: keyof Omit<FileAlternativeHeadersInput, 'priceTiers'>,
        ): HeadersSelectorProps => ({
          onChange: selected => onChange({ ...alternativeHeaders, [field]: selected || null }),
          value:
            (alternativeHeaders[field] !== undefined
              ? alternativeHeaders[field]
              : selectedMapping && selectedMapping[field]) || undefined,
          options: data && data.parseRawTransactionFileHeaders,
          loading,
        })
        return (
          <React.Fragment>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item label="Select Mapping">
                  <FileMappingSelect
                    onSelectionChanged={mapping => {
                      setSelectedMapping(mapping)
                      onChange({
                        ...alternativeHeaders,
                        ...omit(mapping, ['__typename', 'id', 'name']),
                        ponum: (mapping && mapping.ponum) || 'non-ponum',
                        qtypurchase: (mapping && mapping.qtypurchase) || alternativeHeaders.qtypurchase,
                      })
                    }}
                  />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="Mapping actions">
                  <Row>
                    <Col md={12}>
                      <Popover
                        content={
                          <NewFileMappingPopover
                            alternativeHeaders={alternativeHeaders}
                            setShowCreatePopup={setShowCreatePopup}
                            createNewFileMapping={createNewFileMapping}
                          />
                        }
                        trigger="click"
                        onVisibleChange={state => setShowCreatePopup(state)}
                        visible={showCreatePopup}
                        title="Save new Mapping">
                        <Button type="primary">Save as</Button>
                      </Popover>
                    </Col>

                    <Col md={12}>
                      <Button onClick={() => updateNewFileMapping(localMapping)} disabled={!selectedMapping}>
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={24}>
                <Form.Item label="Facility ID">
                  <HeadersSelector {...alternativeHeadersSelectProps('facilityId')} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item label="Vendor">
                  <HeadersSelector {...alternativeHeadersSelectProps('vendor')} />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="Vendor Item">
                  <HeadersSelector {...alternativeHeadersSelectProps('venitem')} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item label="Manufacturer">
                  <HeadersSelector {...alternativeHeadersSelectProps('manufacturer')} />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="Manufacturer Item">
                  <HeadersSelector {...alternativeHeadersSelectProps('mfgitem')} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item label="Unit of Measure">
                  <HeadersSelector {...alternativeHeadersSelectProps('uom')} />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="Unit of Measure Conversion">
                  <HeadersSelector {...alternativeHeadersSelectProps('uomconversion')} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item label="Quantity">
                  <HeadersSelector {...alternativeHeadersSelectProps('qtypurchase')} />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="Unit Price">
                  <HeadersSelector {...alternativeHeadersSelectProps('unitprice')} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item label="PO Number">
                  <HeadersSelector {...alternativeHeadersSelectProps('ponum')} />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="Extended Price">
                  <HeadersSelector {...alternativeHeadersSelectProps('extprice')} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item label="Description">
                  <HeadersSelector {...alternativeHeadersSelectProps('description')} />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="Date Purchase">
                  <HeadersSelector {...alternativeHeadersSelectProps('datepurchase')} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={fileType === StudyFileTypeEnum.XRef ? 12 : 24}>
                <Form.Item label="Waste">
                  <HeadersSelector {...alternativeHeadersSelectProps('waste')} />
                </Form.Item>
              </Col>
              {fileType === StudyFileTypeEnum.XRef && (
                <Col md={12}>
                  <Form.Item label="Cluster ID">
                    <HeadersSelector {...alternativeHeadersSelectProps('clusterId')} />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item label="Surgeon">
                  <HeadersSelector {...alternativeHeadersSelectProps('surgeon')} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item label="Age Group">
                  <HeadersSelector {...alternativeHeadersSelectProps('ageGroup')} />
                </Form.Item>
              </Col>
            </Row>

            {fileType === StudyFileTypeEnum.Contract &&
              (alternativeHeaders.priceTiers || []).map((priceTierMapping, index) => (
                <Row gutter={16} key={index}>
                  <Form.Item label={`Price Tier #${index + 1}`}>
                    <Col md={20}>
                      <HeadersSelector
                        {...alternativeHeadersSelectProps('ageGroup')} // just reference
                        value={priceTierMapping}
                        allowClear={false}
                        onChange={header => {
                          const newPriceTiers = [...alternativeHeaders.priceTiers!]
                          newPriceTiers.splice(index, 1, header)
                          onChange({ ...alternativeHeaders, priceTiers: newPriceTiers })
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <Button
                        type="danger"
                        onClick={() => {
                          const newPriceTiers = [...alternativeHeaders.priceTiers!]
                          newPriceTiers.splice(index, 1)
                          onChange({
                            ...alternativeHeaders,
                            priceTiers: newPriceTiers,
                          })
                        }}>
                        -
                      </Button>
                    </Col>
                  </Form.Item>
                </Row>
              ))}
            {fileType === StudyFileTypeEnum.Contract && (
              <Button
                onClick={() => {
                  onChange({
                    ...alternativeHeaders,
                    priceTiers: [...(alternativeHeaders.priceTiers || []), ''],
                  })
                }}>
                Add Price Tier
              </Button>
            )}
          </React.Fragment>
        )
      }}
    </CheckFileHeaders>
  )
}

const NewFileMappingPopover: React.FC<{
  alternativeHeaders: Partial<FileAlternativeHeadersInput>
  setShowCreatePopup: (v: boolean) => void
  createNewFileMapping: (input: CreateFileMappingInput) => Promise<void>
}> = ({ alternativeHeaders, setShowCreatePopup, createNewFileMapping }) => {
  const [name, setName] = useState('')
  return (
    <div>
      <Input placeholder="Enter mapping name" autoFocus value={name} onChange={e => setName(e.target.value || '')} />
      <Button
        type="primary"
        onClick={() => {
          createNewFileMapping({
            name,
            ...alternativeHeaders,
            vendor: alternativeHeaders.vendor || 'vendor',
            manufacturer: alternativeHeaders.manufacturer || 'manufacturer',
            mfgitem: alternativeHeaders.mfgitem || 'mfgitem',
            venitem: alternativeHeaders.venitem || 'venitem',
            unitprice: alternativeHeaders.unitprice || 'unitprice',
            ponum: alternativeHeaders.ponum || 'ponum',
          })
          setShowCreatePopup(false)
        }}
        block>
        Save
      </Button>
    </div>
  )
}
