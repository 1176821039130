import { useBatchNewPartListQuery } from '@curvo/apollo'
import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React from 'react'

const Option = Select.Option

export const BatchNewPartSelect: React.FC<SelectProps<string>> = ({ ...props }) => {
  const { data, loading } = useBatchNewPartListQuery()
  const options = data?.partsBatchNameList || []

  return (
    <Select loading={loading} {...props} style={{ width: 250 }} placeholder="Select Batch name">
      {options.map(option => (
        <Option key={option}>{option}</Option>
      ))}
    </Select>
  )
}
