import React from 'react'
import { Resizable } from 'react-resizable'

export const ResizableHeader: React.FC<{ width: number; onResize: (size: number) => void }> = ({
  width,
  onResize,
  ...restProps
}) => {
  if (!width) {
    return <th {...restProps} />
  }
  return (
    <Resizable
      width={width}
      onResize={(_, { size }) => onResize(size.width)}
      height={0}
      draggableOpts={{ enableUserSelectHack: false }}>
      <th {...restProps} />
    </Resizable>
  )
}
