import { SubSegment } from '@curvo/apollo'
import { Form, Input } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import React, { useEffect, useState } from 'react'
import { EditPanelWrapper } from '../../../../../components/common'
import { EditDrawer } from '../../../../../components/EditDrawer'
import { EditMode } from './EditManufacturer'

export const SubSegmentDrawer: React.FunctionComponent<
  {
    subSegment?: SubSegment
    onSubmit: (name: string) => void
    onCancel: () => void
    editMode: EditMode
  } & DrawerProps
> = ({ editMode, subSegment, onSubmit, onCancel, ...drawerProps }) => {
  const [name, setName] = useState('')
  useEffect(() => {
    setName(subSegment ? subSegment.name : name)
  }, [subSegment, name])
  return (
    <EditDrawer
      onCancel={onCancel}
      onSave={() => {
        onSubmit(name)
        setName('')
      }}
      {...drawerProps}>
      <EditPanelWrapper>
        {editMode === EditMode.edit && subSegment && (
          <Form.Item label="ID">
            <Input value={subSegment.id} disabled />
          </Form.Item>
        )}
        <Form.Item label="Name">
          <Input value={name} onChange={e => setName(e.target.value)} />
        </Form.Item>
      </EditPanelWrapper>
    </EditDrawer>
  )
}
