"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styled_components_1 = require("styled-components");
const ResetCSS = (0, styled_components_1.createGlobalStyle) `
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html, body, #root {
    min-height: 100vh;
  }
  body {
    font-size: 16px;
  }
  body * {
    font-size: 0.875rem;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.66em;
    font-weight: 600;
    line-height: 1.5;
    color: inherit;
  }

  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.75rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.125rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 0.875rem;
  }

  *:focus {
    outline: 0;
  }

  a {
    text-decoration: none;
  }

`;
exports.default = ResetCSS;
