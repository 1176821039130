import { useBasketLookupNamesQuery } from '@curvo/apollo'
import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import { uniq } from 'lodash'
import React, { useMemo, useState } from 'react'

export const BasketNameSelector: React.FC<SelectProps> = props => {
  const [searchText, setSearchText] = useState<string>()
  const { data, loading } = useBasketLookupNamesQuery({ fetchPolicy: 'network-only' })
  const names = useMemo(
    () => uniq([...(data?.basketLookupNames || []), ...(searchText && searchText.length >= 3 ? [searchText] : [])]),
    [data?.basketLookupNames, searchText],
  )
  return (
    <Select showSearch {...props} loading={loading} onSearch={v => setSearchText(v)}>
      {names.map(n => (
        <Select.Option value={n}>{n}</Select.Option>
      ))}
    </Select>
  )
}
