import { GridApi } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact'
import {
  BamfToHiMatchesBulkUpdateInput,
  BulkBamfToHiMatchingStudyLinkInput,
  BulkMoveToTwinMatchStudyLinkInput,
  BulkUpdateStudyLinkInput,
  StudyLinkCatalogEnum,
  StudyTransactionState,
} from '@curvo/apollo'
import { message } from 'antd'
import { pick } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { doBulkMoveToTwinMatch, doBulkUpdateBamfToHIMatches, doBulkUpdateStudyLinks } from './Buttons'
export type LastAction = {
  action: 'bulkUpdateStudyLinks' | 'moveToTwinMatch' | 'moveToHIMatch'
  payload: BulkUpdateStudyLinkInput | BulkMoveToTwinMatchStudyLinkInput | BamfToHiMatchesBulkUpdateInput
}

export type LastActionStack = Map<string, LastAction>
/**
 * ======================================
 * For Last action hook
 * ======================================
 */
type UpdateLastActionStackFn = (
  catalog: StudyLinkCatalogEnum,
  step?: StudyTransactionState,
  lastAction?: LastAction,
) => void

export type LastActionStackHookReturnType = {
  updateLastActionStack: UpdateLastActionStackFn
  lastActionStack?: LastActionStack
  undoLastAction: (catalog: StudyLinkCatalogEnum, step: StudyTransactionState) => Promise<void>
}

export const useLastActionTrack = (
  gridRef: React.RefObject<AgGridReact & { api: GridApi }>,
): LastActionStackHookReturnType => {
  const [lastActionStack, setLastActionStack] = useState<LastActionStack>()

  const updateLastActionStack: UpdateLastActionStackFn = useCallback((catalog, step, lastAction) => {
    setLastActionStack(old => {
      const clone = old ? new Map(old) : new Map()
      clone.set(catalog + step, lastAction)
      return clone
    })
  }, [])

  return useMemo(
    () => ({
      lastActionStack,
      updateLastActionStack,
      undoLastAction: async (catalog: StudyLinkCatalogEnum, step: StudyTransactionState) => {
        const lastAction = lastActionStack && lastActionStack.get(catalog + step)
        if (!lastAction) {
          message.info('Nothing to undo')
          return
        }
        message.info(`Undo action ${lastAction.action}`)
        switch (lastAction.action) {
          case 'bulkUpdateStudyLinks': {
            const { update, ...input } = lastAction.payload as BulkUpdateStudyLinkInput
            if (update.isApproved === false && (update.matchGudidId || update.matchMedId || update.matchStanId)) {
              gridRef?.current?.api.showLoadingOverlay()
              await doBulkUpdateStudyLinks(
                {
                  ...input,
                  update: pick(update, ['matchGudidId', 'matchMedId', 'matchStanId']),
                },
                undefined,
                undefined,
                undefined,
                true,
              )
            }
            await doBulkUpdateStudyLinks(
              {
                ...input,
                update: {
                  isApproved: update.isApproved !== undefined ? !update.isApproved : undefined, // undo isApproved
                  ignoreRow: update.ignoreRow !== undefined ? !update.ignoreRow : undefined, // undo ignoreRow
                },
              },
              gridRef,
            )
            updateLastActionStack(catalog, step, undefined)
            break
          }
          case 'moveToHIMatch': {
            await doBulkUpdateBamfToHIMatches(
              {
                ...(lastAction.payload as BulkBamfToHiMatchingStudyLinkInput),
                revert: true,
              },
              gridRef,
            )
            updateLastActionStack(catalog, step, undefined)
            break
          }
          case 'moveToTwinMatch': {
            await doBulkMoveToTwinMatch(
              {
                ...(lastAction.payload as BulkMoveToTwinMatchStudyLinkInput),
                revert: true,
              },
              gridRef,
            )
            updateLastActionStack(catalog, step, undefined)
            break
          }
        }
      },
    }),
    [updateLastActionStack, lastActionStack, gridRef],
  )
}
