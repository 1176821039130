import { BasketLookupInput, BasketLookupType } from '@curvo/apollo'
import { Col, Form, Input, Row, Select, Switch } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import { FormComponentProps } from 'antd/lib/form/Form'
import { omit } from 'lodash'
import React, { createRef, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { CustomerSelector } from '../../../components/CustomerSelector'
import { EditDrawer } from '../../../components/EditDrawer'
import { EditPanelWrapper } from '../../../components/common'
import { GmdnNameSelector } from '../DataCleaning/components/GmdnNameSelector'
import { GudidBrandSelector } from '../DataCleaning/components/GudidBrandSelector'
import { MasterDataSelector } from '../DataCleaning/components/MasterDataSelector'
import { NormalizedSupplierSelector } from '../DataCleaning/components/NormalizedSupplierSelector'
import { UnspscSelector } from '../DataCleaning/components/UnspscSelector'
import { GICSelect } from '../Update/components/Select/GICSelect'
import { AllowedGICsTypeOneSelect } from '../Update/components/Select/GICTypeOneSelect'
import { AllowedGICsTypeTwoSelect } from '../Update/components/Select/GICTypeTwoSelect'
import { PartSelect } from '../Update/components/Select/PartSelect'
import { BasketNameSelector } from './BasketNameSelector'

type CategoryRuleFormProps = {
  basketLookup?: BasketLookupType
  setFormModel: (data?: BasketLookupType) => void
} & FormComponentProps

const CategoryRuleForm: React.FunctionComponent<CategoryRuleFormProps> = forwardRef<
  FormComponentProps,
  CategoryRuleFormProps
>(({ form, basketLookup }, ref) => {
  useImperativeHandle(ref, () => ({
    form,
  }))

  const [selectedGic, setSelectedGic] = useState<number>()
  const [selectedTypeOne, setSelectedTypeOne] = useState<string>()
  const [negative, setNegative] = useState<boolean>(basketLookup?.negative || false)
  const [selectedRule, setSelectedRule] = useState<string>(getRuleField(basketLookup))

  const rules = useMemo(() => {
    if (negative) {
      return [
        { value: 'manufacturer', label: 'Manufacturer' },
        { value: 'vendor', label: 'Vendor' },
      ]
    }

    return [
      { value: 'tsId', label: 'TsId' },
      { value: 'gudidId', label: 'Gudid Id' },
      { value: 'gudidBrand', label: 'Gudid Brand' },
      { value: 'stanId', label: 'Stan Id' },
      { value: 'gic', label: 'Gic' },
      { value: 'gmdnName', label: 'Gmdn Name' },
      { value: 'commodityId', label: 'Commodity Id' },
      { value: 'unspscClassTitle', label: 'Unspsc Title' },
    ]
  }, [negative])

  useEffect(() => {
    if (rules.every(r => r.value !== selectedRule)) {
      setSelectedRule(rules[0].value)
    }
  }, [rules, selectedRule])

  useEffect(() => {
    setSelectedRule(getRuleField(basketLookup))
  }, [basketLookup])

  return (
    <div>
      <Form>
        <Form.Item label="Basket Name">
          {form.getFieldDecorator('basketName', {
            initialValue: basketLookup?.basketName,
            rules: [{ required: true, whitespace: true, message: 'Please input Basket Name' }],
          })(<BasketNameSelector />)}
        </Form.Item>
        <Form.Item label="Customer">
          {form.getFieldDecorator('customerId', {
            initialValue: basketLookup?.customer?.id,
          })(<CustomerSelector allowClear />)}
        </Form.Item>
        <Form.Item label="Negative">
          {form.getFieldDecorator('negative', {
            initialValue: basketLookup?.negative,
            valuePropName: 'checked',
            normalize: (value, prevValue) => {
              if (value !== prevValue) {
                setNegative(value)
              }
              return value
            },
          })(<Switch />)}
        </Form.Item>
        <Form.Item label="Rule">
          <Select value={selectedRule} style={{ width: 300 }} onChange={v => setSelectedRule(v)}>
            {rules.map(r => (
              <Select.Option key={r.value} value={r.value}>
                {r.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div>
          <span style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '14px' }}>Rule value:</span>
          <span style={{ marginLeft: '8px' }}>{`${getValueByRule(selectedRule, basketLookup) || ''}`}</span>
        </div>
        {selectedRule === 'tsId' && (
          <Form.Item label="Ts Id">
            {form.getFieldDecorator('tsId', {
              rules: [{ required: !basketLookup?.tsId, whitespace: true, message: 'Please input Ts Id' }],
            })(<Input />)}
          </Form.Item>
        )}
        {selectedRule === 'gudidId' && (
          <Form.Item label={'Gudid Id'}>
            {form.getFieldDecorator('gudidId', {
              rules: [{ required: !basketLookup?.gudidId, message: 'Please input Gudid Id' }],
            })(
              <MasterDataSelector
                source={'udi'}
                allowClear
                showSearch
                filterOption={false}
                dropdownMatchSelectWidth={false}
                dropdownMenuStyle={{ width: 1000 }}
              />,
            )}
          </Form.Item>
        )}
        {selectedRule === 'gudidBrand' && (
          <Form.Item label="Gudid Brand">
            {form.getFieldDecorator('gudidBrand', {
              rules: [{ required: !basketLookup?.gudidBrand, whitespace: true, message: 'Please input Gudid Brand' }],
            })(<GudidBrandSelector labelInValue={false} />)}
          </Form.Item>
        )}
        {selectedRule === 'stanId' && (
          <Form.Item label="Stan Id">
            {form.getFieldDecorator('stanId', {
              rules: [{ required: !basketLookup?.stanId, message: 'Please input Stan Id' }],
            })(<PartSelect allowClear={true} dropdownMatchSelectWidth={false} dropdownMenuStyle={{ width: 1000 }} />)}
          </Form.Item>
        )}
        {selectedRule === 'gic' && (
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="GIC">
                {form.getFieldDecorator('gic', {
                  rules: [{ required: !basketLookup?.gicTypeOneTypeTwo, message: 'Please select Gic' }],
                  normalize: (value, prevValue) => {
                    if (value?.key !== prevValue?.key) {
                      setSelectedGic(value?.key ? JSON.parse(value?.key).id : undefined)
                    }
                    return value
                  },
                })(<GICSelect fullData />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Type One">
                {form.getFieldDecorator('typeOne', {
                  normalize: (value, prevValue) => {
                    if (value?.key !== prevValue?.key) {
                      setSelectedTypeOne(value?.key ? JSON.parse(value?.key).id : undefined)
                    }
                    return value
                  },
                })(<AllowedGICsTypeOneSelect gicId={selectedGic} disabled={!selectedGic} fullData />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Type Two">
                {form.getFieldDecorator('typeTwo')(
                  <AllowedGICsTypeTwoSelect
                    gicId={selectedGic}
                    disabled={!selectedGic || !selectedTypeOne}
                    typeOneId={selectedTypeOne}
                    fullData
                  />,
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
        {selectedRule === 'gmdnName' && (
          <Form.Item label="Gmdn Name">
            {form.getFieldDecorator('gmdnName', {
              rules: [{ required: !basketLookup?.gmdnName, whitespace: true, message: 'Please input Gmdn Name' }],
            })(<GmdnNameSelector labelInValue={false} />)}
          </Form.Item>
        )}
        {selectedRule === 'commodityId' && (
          <Form.Item label="Commodity Id">
            {form.getFieldDecorator('commodityId', {
              rules: [{ required: !basketLookup?.commodityId, message: 'Please input Commodity Id' }],
            })(
              <MasterDataSelector
                source={'med'}
                allowClear
                showSearch
                filterOption={false}
                dropdownMatchSelectWidth={false}
                dropdownMenuStyle={{ width: 1000 }}
              />,
            )}
          </Form.Item>
        )}
        {selectedRule === 'unspscClassTitle' && (
          <Form.Item label="Unspsc Title">
            {form.getFieldDecorator('unspscClassTitle', {
              rules: [
                { required: !basketLookup?.unspscClassTitle, whitespace: true, message: 'Please input Unspsc Title' },
              ],
            })(<UnspscSelector labelInValue={false} />)}
          </Form.Item>
        )}
        {selectedRule === 'manufacturer' && (
          <Form.Item label="Manufacturer">
            {form.getFieldDecorator('manufacturer', {
              rules: [{ required: !basketLookup?.manufacturer, message: 'Please select Manufacturer' }],
            })(<NormalizedSupplierSelector disabled={!negative} labelInValue={false} />)}
          </Form.Item>
        )}
        {selectedRule === 'vendor' && (
          <Form.Item label="Vendor">
            {form.getFieldDecorator('vendor', {
              rules: [{ required: !basketLookup?.vendor, message: 'Please select Vendor' }],
            })(<NormalizedSupplierSelector disabled={!negative} placeholder="Select vendor" labelInValue={false} />)}
          </Form.Item>
        )}
      </Form>
    </div>
  )
})

const CategoryRuleFormWrapper = Form.create<CategoryRuleFormProps>({
  onValuesChange(props, _, allValues) {
    props.setFormModel({ id: props.basketLookup?.id || '', ...formModelToInput(allValues, props.basketLookup) })
  },
})(CategoryRuleForm)

export const CategoryRuleFormDrawer: React.FunctionComponent<
  {
    basketLookup?: BasketLookupType
    onSubmit: (values: BasketLookupInput) => void
    onCancel: () => void
    submitting?: boolean
  } & DrawerProps
> = ({ basketLookup, onCancel, onSubmit, submitting, ...drawerProps }) => {
  const formRef = createRef<FormComponentProps>()
  const [formModel, setFormModel] = useState(basketLookup)

  useEffect(() => {
    if (!drawerProps.visible) {
      setFormModel(undefined)
      formRef.current?.form.resetFields()
    }
  }, [drawerProps.visible, formRef])

  useEffect(() => {
    setFormModel(basketLookup)
  }, [basketLookup])

  return (
    <EditDrawer
      title={basketLookup ? 'Update Rule' : 'Add New Rule'}
      onCancel={onCancel}
      isDisabled={submitting}
      loading={submitting}
      onSave={() => {
        formRef.current?.form.validateFields((errors, values) => {
          if (!errors) {
            onSubmit(formModelToInput(values, basketLookup))
          }
        })
      }}
      {...drawerProps}>
      <EditPanelWrapper>
        {drawerProps.visible && (
          <CategoryRuleFormWrapper setFormModel={setFormModel} basketLookup={formModel} wrappedComponentRef={formRef} />
        )}
      </EditPanelWrapper>
    </EditDrawer>
  )
}

const getRuleField = (record?: BasketLookupType) => {
  if (!record) {
    return 'tsId'
  }
  if (record.negative) {
    if (record.vendor) {
      return 'vendor'
    }

    return 'manufacturer'
  } else {
    if (record.gudidId) {
      return 'gudidId'
    }
    if (record.gudidBrand) {
      return 'gudidBrand'
    }
    if (record.stanId) {
      return 'stanId'
    }
    if (record.gicTypeOneTypeTwo) {
      return 'gic'
    }
    if (record.gmdnName) {
      return 'gmdnName'
    }
    if (record.unspscClassTitle) {
      return 'unspscClassTitle'
    }
    if (record.commodityId) {
      return 'commodityId'
    }
    return 'tsId'
  }
}

const getValueByRule = (selectedRule: string, record?: BasketLookupType) => {
  if (!record) {
    return ''
  }
  switch (selectedRule) {
    case 'manufacturer':
      return record.manufacturer
    case 'vendor':
      return record.vendor
    case 'tsId':
      return record.tsId
    case 'gudidId':
      return record.gudidId
    case 'gudidBrand':
      return record.gudidBrand
    case 'stanId':
      return record.stanId
    case 'gic':
      return record.gicTypeOneTypeTwo
    case 'gmdnName':
      return record.gmdnName
    case 'unspscClassTitle':
      return record.unspscClassTitle
    case 'commodityId':
      return record.commodityId
    default:
      return ''
  }
}

const formModelToInput = (values: any, basketLookup?: BasketLookupType) => {
  if (Object.values(omit(values, ['id', 'basketName', 'negative', 'customerId'])).filter(v => !!v).length === 0) {
    return {
      ...omit(basketLookup, ['__typename', 'id', 'customer']),
      basketName: values.basketName,
      negative: values.negative,
      customerId: values.customerId,
    }
  }
  const gicTypeOneTypeTwo = [
    values.gic?.key && JSON.parse(values.gic?.key).name,
    values.typeOne?.key && JSON.parse(values.typeOne?.key).name,
    values.typeTwo?.key && JSON.parse(values.typeTwo?.key).name,
  ]
    .filter(i => !!i)
    .join('+')

  return {
    basketName: values.basketName,
    customerId: values.customerId,
    negative: values.negative,
    tsId: values.tsId,
    gudidId: values.gudidId?.key,
    gudidBrand: values.gudidBrand,
    stanId: !values.stanId || values.stanId?.key.startsWith('stn-') ? values.stanId?.key : 'stn-' + values.stanId?.key,
    gmdnName: values.gmdnName,
    gicTypeOneTypeTwo: gicTypeOneTypeTwo || undefined,
    manufacturer: values.manufacturer,
    vendor: values.vendor,
    commodityId: values.commodityId?.key,
    unspscClassTitle: values.unspscClassTitle,
  }
}
