import { MeWithoutPaymentContext, Role } from '@curvo/apollo'
import { Flex, Spinner } from '@curvo/common-ui'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'
import Cognito from '../config/Cognito'

const Container = styled(Flex)`
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`

function useAuth() {
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    Cognito.getSession()
      .then(session => {
        if (!session.getAccessToken().getJwtToken()) {
          throw new Error('Session invalid')
        }
        setLoading(false)
      })
      .catch(err => {
        console.info(err)
        navigate('/auth/signin', { state: { from: location.pathname } })
        setLoading(true)
      })
  }, [navigate, location.pathname])
  return loading
}

function useAdminCheck(authChecking: boolean) {
  const navigate = useNavigate()
  const meContext = useContext(MeWithoutPaymentContext)
  const me = meContext.data && meContext.data
  const [loading, setLoading] = React.useState(true)
  useEffect(() => {
    if (!me || authChecking) {
      return
    }
    if (me.role !== Role.CurvoAdmin && me.role !== Role.DataEnrichmentAdmin) {
      navigate('/auth/error-permission')
    }
    setLoading(false)
  }, [authChecking, navigate, me])
  return authChecking || loading
}

const AuthorizedRoute: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const authChecking = useAuth()
  const adminChecking = useAdminCheck(authChecking)
  if (adminChecking) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  return children
}

export default AuthorizedRoute
