import { useNormalizedsuppliersQuery } from '@curvo/apollo'
import LoadingIndicator from '@curvo/common-ui/dist/components/Loading/Spinner'
import { LabeledValue, SelectProps } from 'antd/lib/select'
import { debounce } from 'lodash'
import React, { useState } from 'react'
import { StyledSelect, StyledSelectPropsType } from '../../Update/components/Select/common'

export const NormalizedSupplierSelector: React.FunctionComponent<
  SelectProps<LabeledValue> & StyledSelectPropsType & { fullData?: boolean }
> = props => {
  const [searchText, setSearchText] = useState('')

  const { data, loading } = useNormalizedsuppliersQuery({ variables: { searchText } })

  const normalizedSuppliers = (data && data.normalizedSuppliers?.edges) || []
  const normStrings = normalizedSuppliers
    .filter(normalizedSupplier => normalizedSupplier.node.normalizedCurvo)
    .map(normalizedSupplier => normalizedSupplier.node.normalizedCurvo || '')
  const candidatesSet = [...new Set(normStrings)]

  return (
    <StyledSelect
      onSearch={debounce(setSearchText, 800)}
      loading={loading}
      labelInValue
      showSearch
      filterOption={false}
      showArrow={false}
      defaultActiveFirstOption={false}
      placeholder="Select manufacturer"
      notFoundContent={loading ? <LoadingIndicator /> : 'No Data'}
      {...props}>
      {candidatesSet.map(normalizedCurvo => (
        <StyledSelect.Option key={normalizedCurvo}>{normalizedCurvo}</StyledSelect.Option>
      ))}
    </StyledSelect>
  )
}
