import { ComparisonEnum } from '@curvo/apollo'
import { Modal } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import AdvanceForm from './AdvanceForm'

type Props = {
  isOpen: boolean
  isAdvancedMode: boolean
  setIsOpen: (isOpen: boolean) => void
  onFinish: (values: Values) => void
}

export const LOCAL_STORAGE_KEY = 'advanceSearchParams'

const initialValues = {
  addAfter: '',
  updateAfter: '',
  comparisonSign: '',
  sizeOne: '',
  sizeTwo: '',
  sizeThree: '',
  partialPartNumber: '',
  partialPartDesc: '',
}

export type Values = typeof initialValues & {
  manufacturer?: any
  gic?: any
  typeOne?: any
  typeTwo?: any
  material?: any
  segmentation?: any
  brand?: any
  productLine?: any
  user?: any
}

export const getSearchParams = ({
  manufacturer,
  gic,
  typeOne,
  typeTwo,
  material,
  segmentation,
  brand,
  productLine,
  addAfter,
  updateAfter,
  comparisonSign,
  sizeOne,
  sizeTwo,
  sizeThree,
  partialPartNumber,
  partialPartDesc,
}: Partial<Values>) => {
  const args = {
    manufacturer: manufacturer ? manufacturer.value : undefined,
    gic: gic ? gic.value : undefined,
    typeOne: typeOne ? typeOne.value : undefined,
    typeTwo: typeTwo ? typeTwo.value : undefined,
    material: material ? material.value : undefined,
    segmentation: segmentation ? segmentation.value : undefined,
    brand: brand ? brand.value : undefined,
    productLine: productLine ? productLine.value : undefined,
    addAfter: addAfter ? new Date(addAfter).getTime() / 1000 : undefined,
    updateAfter: updateAfter ? new Date(updateAfter).getTime() / 1000 : undefined,
  }
  const partNumber = partialPartNumber ? partialPartNumber : undefined
  const partDesc = partialPartDesc ? partialPartDesc : undefined
  const sizeConditions = {
    comparisonSign: comparisonSign ? (comparisonSign as ComparisonEnum) : undefined,
    sizeOne: sizeOne ? +sizeOne : undefined,
    sizeTwo: sizeTwo ? +sizeTwo : undefined,
    sizeThree: sizeThree ? +sizeThree : undefined,
  }

  return {
    args,
    sizeConditions,
    partialPartNumber: partNumber,
    partialPartDesc: partDesc,
  }
}

const loadRecentSearchParams = (): Values => {
  const str = localStorage.getItem(LOCAL_STORAGE_KEY)
  return str && JSON.parse(str)
}

const AdvancedSearchModal: React.FC<Props> = ({ isOpen, isAdvancedMode, setIsOpen, onFinish }) => {
  const handleFormSubmit = (values: Values) => {
    if (JSON.stringify(values) !== JSON.stringify(initialValues)) {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(values))
      onFinish(values)
    }
    setIsOpen(false)
  }

  const recentSearchParams = loadRecentSearchParams()

  const { handleSubmit, resetForm, values, setFieldValue, handleChange, ...restProps } = useFormik({
    initialValues: isAdvancedMode ? recentSearchParams : initialValues,
    onSubmit: handleFormSubmit,
  })

  useEffect(() => {
    if (!isAdvancedMode) {
      resetForm()
    }
  }, [isAdvancedMode, resetForm])

  return (
    <Modal width={1000} visible={isOpen} onCancel={() => setIsOpen(false)} footer={null}>
      <form onSubmit={handleSubmit}>
        <AdvanceForm
          values={values}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          resetForm={resetForm}
          {...restProps}
        />
        <div className="ant-modal-footer">
          <div>
            <button type="button" className="ant-btn" onClick={() => setIsOpen(false)}>
              <span>Cancel</span>
            </button>
            <button type="submit" className="ant-btn ant-btn-primary">
              <span>OK</span>
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default AdvancedSearchModal
