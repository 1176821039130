import { Segmentation, SegmentationCreateInput, SegmentationUpdateInput } from '@curvo/apollo'
import { Form, Input } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import React, { useEffect, useState } from 'react'
import { EditPanelWrapper } from '../../../../../components/common'
import { EditDrawer } from '../../../../../components/EditDrawer'
import { SegmentSelect } from '../Select/SegmentSelect'
import { SubSegmentSelect } from '../Select/SubSegmentSelect'
import { EditMode } from './EditManufacturer'

const newSegmentationInput = (): SegmentationCreateInput | SegmentationUpdateInput => ({
  id: '',
  segmentId: '',
  subSegmentId: '',
})

export const SegmentationDrawer: React.FunctionComponent<
  {
    segmentation?: Segmentation
    onSubmit: (value: SegmentationCreateInput | SegmentationUpdateInput) => void
    onCancel: () => void
    editMode: EditMode
  } & DrawerProps
> = ({ editMode, segmentation, onSubmit, onCancel, ...drawerProps }) => {
  const [editing, setEditing] = useState<SegmentationCreateInput | SegmentationUpdateInput>(newSegmentationInput())
  useEffect(() => {
    if (segmentation) {
      setEditing({
        segmentId: segmentation.segment ? segmentation.segment.id : '',
        subSegmentId: segmentation.subSegment ? segmentation.subSegment.id : '',
        id: segmentation.id,
      })
    }
  }, [segmentation])

  return (
    <EditDrawer
      onCancel={onCancel}
      onSave={() => {
        onSubmit(editing)
        setEditing(newSegmentationInput())
      }}
      {...drawerProps}>
      <EditPanelWrapper>
        <Form.Item label="Segment">
          <SegmentSelect
            value={
              editing.segmentId
                ? {
                    key: editing.segmentId,
                    label: segmentation && segmentation.segment ? segmentation.segment.name : editing.segmentId,
                  }
                : undefined
            }
            onChange={value => {
              setEditing({
                ...editing,
                segmentId: value.key,
              })
            }}
            createIfNotExist={true}
          />
        </Form.Item>
        <Form.Item label="Sub Segment">
          <SubSegmentSelect
            value={
              editing.subSegmentId
                ? {
                    key: editing.subSegmentId,
                    label:
                      segmentation && segmentation.subSegment ? segmentation.subSegment.name : editing.subSegmentId,
                  }
                : undefined
            }
            onChange={value => {
              setEditing({
                ...editing,
                subSegmentId: value.key,
              })
            }}
            createIfNotExist={true}
          />
        </Form.Item>
        {editMode === EditMode.edit && segmentation ? (
          <Form.Item label="ID">
            <Input value={segmentation.id} disabled />
          </Form.Item>
        ) : (
          <Form.Item label="ID">
            <Input
              value={editing.id}
              onChange={e =>
                setEditing({
                  ...editing,
                  id: e.target.value,
                })
              }
            />
          </Form.Item>
        )}
      </EditPanelWrapper>
    </EditDrawer>
  )
}
