import { Env } from '@curvo/apollo'
import { LicenseManager } from 'ag-grid-enterprise'
import 'antd/dist/antd.css'
import ReactDOM from 'react-dom'
import App from './App'
import './assets/style/candyfont.css'
import './assets/style/resizable.css'
import * as serviceWorker from './serviceWorker'

if ((Env as any).agGridLicense) {
  LicenseManager.setLicenseKey((Env as any).agGridLicense)
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
