import { NormalizedSupplier } from '@curvo/apollo'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import { NormalizedCurvoSuggestionsSelect } from '../../Update/components/Select/NormalizedCurvoSuggestionsSelect'
import { AgWrapperPropsType } from './ManufacturerSelectAgWrapper'

export const NormalizedCurvoSuggestionsAgSelect = React.forwardRef<{}, AgWrapperPropsType<NormalizedSupplier>>(
  (params, ref) => {
    const [suggestion, setSuggestion] = useState<string | null>()

    const { value: normalizedCurvo, api } = params
    useImperativeHandle(ref, () => ({
      getValue: () => suggestion,
    }))

    useEffect(() => {
      if (suggestion !== undefined) {
        api.stopEditing()
      }
    }, [suggestion, api])

    return (
      <NormalizedCurvoSuggestionsSelect
        onCreateNormalizedCurvo={selectedNormalizedCurvo => {
          setSuggestion(selectedNormalizedCurvo)
        }}
        value={normalizedCurvo}
        onChange={v => setSuggestion(v)}
        showArrow={false}
        allowClear
      />
    )
  },
)
