import {
  createSubSegmentMutation,
  deleteSubSegmentMutation,
  SubSegment,
  SubSegmentsData,
  SubSegmentsQueryArgs,
  SUB_SEGMENTS_QUERY,
  updateSubSegmentMutation,
} from '@curvo/apollo'
import { Button, message, Pagination, Popconfirm, Table } from 'antd'
import React, { useState } from 'react'
import { StyledPaginationWrapper } from '../Upload/common'
import { ColumnGenFunctionType, PageWrapper, RedA, SearchWrapper } from './common'
import { EditMode } from './components/EditPanel/EditManufacturer'
import { SubSegmentDrawer } from './components/EditPanel/SubSegment'

export const SubSegmentComponent: React.FC = () => {
  const limitPerPage = 10
  const [queryArgs, setQueryArgs] = useState<SubSegmentsQueryArgs>({})
  const [isOpenPanel, setIsOpenPanel] = useState(false)
  const [panelMode, setPanelMode] = useState<EditMode>(EditMode.edit)
  const [selected, setSelected] = useState<SubSegment>()
  const columns = columnsGenFn(
    record => {
      setIsOpenPanel(true)
      setPanelMode(EditMode.edit)
      setSelected(record)
    },
    record => {
      deleteSubSegmentMutation({ id: record.id }, { refetchQueries: [{ query: SUB_SEGMENTS_QUERY }] })
    },
  )
  return (
    <PageWrapper>
      <SubSegmentsData variables={queryArgs} fetchPolicy="network-only">
        {({ data, loading, error }) => {
          if (error) {
            message.error(error.message)
          }
          const subSegments = data && data.subSegments ? data.subSegments.edges.map(edge => edge.node) : []
          const total = data && data.subSegments ? data.subSegments.metadata.total : 0
          return (
            <div>
              <SearchWrapper>
                <Button
                  type="primary"
                  icon="plus"
                  onClick={() => {
                    setIsOpenPanel(true)
                    setPanelMode(EditMode.create)
                    setSelected(undefined)
                  }}>
                  Create
                </Button>
              </SearchWrapper>
              <Table
                dataSource={subSegments}
                loading={loading}
                columns={columns}
                rowKey={record => record.id.toString()}
                pagination={false}
              />
              <SubSegmentDrawer
                editMode={panelMode}
                subSegment={selected}
                visible={isOpenPanel}
                onCancel={() => setIsOpenPanel(false)}
                onSubmit={name => {
                  if (panelMode === EditMode.create) {
                    createSubSegmentMutation({ input: { name } }, { refetchQueries: [{ query: SUB_SEGMENTS_QUERY }] })
                  } else {
                    updateSubSegmentMutation(
                      { input: { id: selected!.id, name } },
                      { refetchQueries: [{ query: SUB_SEGMENTS_QUERY }] },
                    )
                  }
                }}
                width={250}
              />
              <StyledPaginationWrapper>
                <Pagination
                  total={total}
                  pageSize={limitPerPage}
                  onChange={page => setQueryArgs({ first: limitPerPage, skip: (page - 1) * limitPerPage })}
                />
              </StyledPaginationWrapper>
            </div>
          )
        }}
      </SubSegmentsData>
    </PageWrapper>
  )
}

const columnsGenFn: ColumnGenFunctionType<SubSegment> = (onEdit, onDelete) => [
  {
    title: 'NAME',
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: 'Action',
    key: 'action',
    render: (_text, record) => (
      <span>
        <Button type="link" onClick={() => onEdit(record)}>
          Edit
        </Button>
        <span> | </span>
        <Popconfirm
          title={`Are you sure you want to delete Segment ${record.name}?`}
          okText="Yes"
          cancelText="No"
          onConfirm={() => onDelete && onDelete(record)}>
          <RedA>Delete</RedA>
        </Popconfirm>
      </span>
    ),
  },
]
