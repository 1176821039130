import { Button, Result } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router'
import { signOut } from '../../config/Cognito'

export const NotPermission: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Result
      status="error"
      title="We can't let you see this page"
      subTitle="To access this page, you may need to log in with another account."
      extra={[
        <Button
          type="primary"
          onClick={() => {
            navigate('/')
          }}>
          Home
        </Button>,
        <Button
          onClick={() => {
            signOut()
            navigate('/auth/signin')
          }}>
          Logout
        </Button>,
      ]}
    />
  )
}
