import { BulkUpdateNewPartInputType, NewPart } from '@curvo/apollo'
import { Checkbox, Col, Form, Input, InputNumber, Row } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import { LabeledValue } from 'antd/lib/select'
import { Omit, omit } from 'lodash'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { EditPanelWrapper } from '../../../../../components/common'
import { EditDrawer } from '../../../../../components/EditDrawer'
import { useForm } from '../../../../../components/useForm'
import { BrandSelect } from '../../../Update/components/Select/BrandSelect'
import { GICSelect } from '../../../Update/components/Select/GICSelect'
import { GICsTypeOneSelect } from '../../../Update/components/Select/GICTypeOneSelect'
import { GicsTypeTwoSelect } from '../../../Update/components/Select/GICTypeTwoSelect'
import { ManufacturerSelect } from '../../../Update/components/Select/ManufacturerSelect'
import { MaterialSelect } from '../../../Update/components/Select/MaterialSelect'
import { ProductLineSelect } from '../../../Update/components/Select/ProductLineSelect'
import { SegmentationSelect } from '../../../Update/components/Select/SegmentationSelect'

export type NewPartsBulkUpdateInputValues = Omit<BulkUpdateNewPartInputType, 'ids'>

const transformFunction = (nps?: NewPart[]): Partial<NewPart> => {
  return nps && nps.length === 1 ? omit(nps[0], 'id') : {}
}

type Props = {
  onSubmit: (updateValues: NewPartsBulkUpdateInputValues) => void
  onCancel: () => void
  newParts: NewPart[]
} & DrawerProps

export const BulkUpdateQueuedPartPanel: React.FC<Props> = ({ onCancel, onSubmit, newParts, ...drawerProps }) => {
  const [values, setUpdateValues, update] = useForm<NewPartsBulkUpdateInputValues, NewPart[]>(
    transformFunction,
    newParts,
  )
  const newPartSample = transformFunction(newParts)
  const inputFieldProps = (field: keyof NewPartsBulkUpdateInputValues) => ({
    value: values[field] || '',
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setUpdateValues(field)(e.target.value),
  })
  const inputNumberFieldProps = (field: keyof NewPartsBulkUpdateInputValues) => ({
    value: values[field] || '',
    onChange: (v: number | undefined) => setUpdateValues(field)(v),
  })
  const createdAt = newPartSample.createdAt && moment(parseInt(newPartSample.createdAt, 10)).toISOString()
  const updatedAt = newPartSample.updatedAt && moment(parseInt(newPartSample.updatedAt, 10)).toISOString()
  return (
    <EditDrawer {...drawerProps} onCancel={onCancel} onSave={() => onSubmit(update)}>
      <EditPanelWrapper>
        <Row gutter={16}>
          <Col span={12} />
          <Col span={12}>
            <div>
              <StyledCreatedAtSpan>{`Created at: ${createdAt}`}</StyledCreatedAtSpan>
            </div>
            <div>
              <StyledCreatedAtSpan>{`Updated at: ${updatedAt}`}</StyledCreatedAtSpan>
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Manufacturer" validateStatus={!values.manufacturerId ? 'error' : ''}>
              <ManufacturerSelect
                value={
                  (values.manufacturerId && {
                    key: values.manufacturerId,
                    label: newPartSample.manufacturer,
                  }) ||
                  undefined
                }
                onChange={v => setUpdateValues('manufacturerId')(v.key)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Part name">
              <Input {...inputFieldProps('partName')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Input Name">
              <Input {...inputFieldProps('partNumber')} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Brand" validateStatus={!values.brandId ? 'error' : ''}>
              <BrandSelect
                value={
                  (values.brandId && {
                    key: values.brandId,
                    label: newPartSample.brand,
                  }) ||
                  undefined
                }
                manufacturerId={values?.manufacturerId || undefined}
                onChange={v => setUpdateValues('brandId')(v.key)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Product Line" validateStatus={!values.productLineId ? 'error' : ''}>
              <ProductLineSelect
                value={
                  (values.productLineId && {
                    key: values.productLineId,
                    label: newPartSample.productLine,
                  }) ||
                  undefined
                }
                manufacturerId={values?.manufacturerId || undefined}
                onChange={v => setUpdateValues('productLineId')(v.key)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="GIC" validateStatus={!values.gicId ? 'error' : ''}>
              <GICSelect
                value={(values.gicId && { key: values.gicId.toString(), label: newPartSample.gic }) || undefined}
                onChange={v => setUpdateValues('gicId')(parseInt(v.key, 10))}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="GIC type one" validateStatus={!values.typeOneId ? 'error' : ''}>
              <GICsTypeOneSelect
                value={(values.typeOneId && { key: values.typeOneId, label: newPartSample.typeOne }) || undefined}
                allowClear
                onChange={v => setUpdateValues('typeOneId')(v && (v as LabeledValue).key)}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="GIC type two" validateStatus={!values.typeTwoId ? 'error' : ''}>
              <GicsTypeTwoSelect
                value={(values.typeTwoId && { key: values.typeTwoId, label: newPartSample.typeTwo }) || undefined}
                allowClear
                onChange={v => setUpdateValues('typeTwoId')(v && (v as LabeledValue).key)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Part Attributes">
              <Input {...inputFieldProps('partAttributes')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Material" validateStatus={!values.materialId ? 'error' : ''}>
              <MaterialSelect
                value={
                  (values.materialId && {
                    key: values.materialId,
                    label: newPartSample.material,
                  }) ||
                  undefined
                }
                onChange={v => setUpdateValues('materialId')(v.key)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Sizes">
              <Input.Group compact>
                <InputNumber {...inputNumberFieldProps('sizeOne')} />
                <InputNumber {...inputNumberFieldProps('sizeTwo')} />
                <InputNumber {...inputNumberFieldProps('sizeThree')} />
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="MFG Size">
              <Input {...inputFieldProps('mfgSize')} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Quantity per box">
              <InputNumber {...inputNumberFieldProps('quantityPerBox')} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Side">
              <Input {...inputFieldProps('side')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Uom">
              <Input {...inputFieldProps('uom')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="ID 510k">
              <Input {...inputFieldProps('id_510k')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Is custom">
              <Checkbox checked={!!values.isCustom} onChange={e => setUpdateValues('isCustom')(e.target.checked)} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Is Reprocessed">
              <Checkbox
                checked={!!values.isReprocessed}
                onChange={e => setUpdateValues('isReprocessed')(e.target.checked)}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Is Sterile">
              <Checkbox checked={!!values.isSterile} onChange={e => setUpdateValues('isSterile')(e.target.checked)} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Udi">
              <Input {...inputFieldProps('udi')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Data source">
              <Input {...inputFieldProps('dataSource')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Segmentation"
              validateStatus={!values.segmentationId ? 'error' : values.segmentationId === '99.00' ? 'warning' : ''}>
              <SegmentationSelect
                value={
                  (values.segmentationId && {
                    key: values.segmentationId,
                    label: newPartSample.segmentation,
                  }) ||
                  undefined
                }
                onChange={v => setUpdateValues('segmentationId')(v.key)}
              />
            </Form.Item>
          </Col>
        </Row>
      </EditPanelWrapper>
    </EditDrawer>
  )
}

const StyledCreatedAtSpan = styled.span`
  font-size: 11px;
  opacity: 0.8;
  font-style: italic;
  text-align: right;
  padding-right: 16px;
  width: 100%;
  float: right;
`
