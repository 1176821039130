import {
  AllowedGicsTypeTwoData,
  AllowedTypeTwoUpdatesInput,
  createGicImageMutation,
  deleteGicImageMutation,
  GicImage,
  GicTypeOne,
  GIC_IMAGES,
  ListItemUpdateType,
  UpdateGicTypeOneInput,
} from '@curvo/apollo'
import { Form, Icon, Input } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import { LabeledValue } from 'antd/lib/select'
import React, { useEffect, useState } from 'react'
import { EditPanelWrapper, emptyOrReturn } from '../../../../../components/common'
import { EditDrawer } from '../../../../../components/EditDrawer'
import { S3Uploader } from '../../../Upload/components/Panel/S3Uploader'
import { GicsTypeTwoSelect } from '../Select/GICTypeTwoSelect'
import { gicImagesToFileUpload } from './EditGIC'

export const EditGicTypeOne: React.FunctionComponent<
  {
    onCancel: () => void
    gicId: number
    gicTypeOne?: GicTypeOne
    gicImages?: GicImage[]
    onSubmit: (update: AllowedTypeTwoUpdatesInput, updateTypeOneInput: UpdateGicTypeOneInput) => void
  } & DrawerProps
> = ({ gicImages, onSubmit, gicId, onCancel, gicTypeOne, ...drawerProps }) => {
  const [updating, setUpdating] = useState<AllowedTypeTwoUpdatesInput>({
    gicTypeOneId: gicTypeOne ? gicTypeOne.id : '',
    updates: [],
  })

  const [includes, setIncludes] = useState<string | null | undefined>()
  const [excludes, setExcludes] = useState<string | null | undefined>()

  useEffect(() => {
    if (gicTypeOne) {
      setUpdating(update => ({
        ...update,
        gicTypeOneId: gicTypeOne.id,
      }))
      setIncludes(gicTypeOne?.includes)
      setExcludes(gicTypeOne?.excludes)
    }
  }, [gicTypeOne])

  const onUpdateAllowedGicsTypeOne = (mode: 'select' | 'deselect') => (value: LabeledValue) => {
    const originUpdate = updating.updates || []
    const action = mode === 'select' ? ListItemUpdateType.Add : ListItemUpdateType.Delete
    const revertAction = mode === 'select' ? ListItemUpdateType.Delete : ListItemUpdateType.Add
    const remainUpdate = originUpdate.filter(
      update => update.updateType !== revertAction || update.gicTypeTwoId !== value.key,
    )
    if (remainUpdate.length < originUpdate.length) {
      setUpdating({
        ...updating,
        updates: remainUpdate,
      })
    } else {
      setUpdating({
        ...updating,
        updates: originUpdate.concat([
          {
            gicTypeTwoId: value.key,
            updateType: action,
            gics: [gicId],
          },
        ]),
      })
    }
  }

  const typeOneImages = ((gicTypeOne && gicImages) || []).filter(gicImage => gicImage.gicTypeOneId === gicTypeOne?.id)

  return (
    <EditDrawer
      onCancel={onCancel}
      onSave={() => {
        onSubmit(updating, { id: updating.gicTypeOneId, includes, excludes })
        setUpdating({
          gicTypeOneId: '',
          updates: [],
        })
      }}
      {...drawerProps}>
      <AllowedGicsTypeTwoData variables={{ gicId, typeOneId: gicTypeOne && gicTypeOne.id }}>
        {({ data }) => {
          const allowedGicsTypeTwoOpt = ((data && data.allowedGicsTypeTwo) || []).map(typeTwo => ({
            key: typeTwo.id,
            label: typeTwo.name,
          }))

          const genAllowedGicsTypeOneValue = (updating.updates || []).reduce((prev, cur) => {
            if (cur.updateType === ListItemUpdateType.Add) {
              return [...prev, { key: cur.gicTypeTwoId, label: cur.gicTypeTwoId }]
            }
            return prev.filter(p => p.key !== cur.gicTypeTwoId)
          }, allowedGicsTypeTwoOpt)

          return (
            <EditPanelWrapper>
              <Form.Item label="Allowed Gic Type Two">
                <GicsTypeTwoSelect
                  mode="multiple"
                  value={genAllowedGicsTypeOneValue}
                  onSelect={selected => onUpdateAllowedGicsTypeOne('select')(selected!)}
                  onDeselect={deSelected => onUpdateAllowedGicsTypeOne('deselect')(deSelected!)}
                  createIfNotExist={true}
                />
              </Form.Item>
              <Form.Item label="Includes">
                <Input.TextArea
                  value={emptyOrReturn(includes, gicTypeOne && gicTypeOne.includes)}
                  onChange={e => setIncludes(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Excludes">
                <Input.TextArea
                  value={emptyOrReturn(excludes, gicTypeOne && gicTypeOne.excludes)}
                  onChange={e => setExcludes(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Gic Picture">
                <div className="clearfix">
                  <S3Uploader
                    isDragger={false}
                    listType="picture-card"
                    fileList={gicImagesToFileUpload(typeOneImages)}
                    accept=".jpg"
                    onRemove={e => {
                      deleteGicImageMutation(
                        { id: e.uid },
                        { refetchQueries: [{ query: GIC_IMAGES, variables: { id: gicId } }] },
                      )
                    }}
                    onUploaded={async e => {
                      if (gicTypeOne) {
                        await createGicImageMutation(
                          {
                            input: {
                              imageUrl: e,
                              gicId: gicId,
                              gicTypeOneId: gicTypeOne.id,
                            },
                          },
                          { refetchQueries: [{ query: GIC_IMAGES, variables: { id: gicId } }] },
                        )
                      }
                    }}>
                    <div className="ant-upload-list-picture-card-container">
                      <Icon type="plus" />
                      <div className="ant-upload-text">Upload</div>
                    </div>
                  </S3Uploader>
                </div>
              </Form.Item>
            </EditPanelWrapper>
          )
        }}
      </AllowedGicsTypeTwoData>
    </EditDrawer>
  )
}
