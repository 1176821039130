import React, { useState, useImperativeHandle, useEffect } from 'react'
import { StudyTransactionType, StudyLinkType } from '@curvo/apollo'
import { LabeledValue } from 'antd/lib/select'
import { GridApi } from '@ag-grid-community/core'
import { PartSelect } from '../../Update/components/Select/PartSelect'
import { AgWrapperPropsType } from './ManufacturerSelectAgWrapper'

const usePartSelect = (api: GridApi, ref: any) => {
  const [labeledValue, setLabeledValue] = useState<LabeledValue | null>()

  useImperativeHandle(ref, () => ({
    getValue: () => labeledValue || null,
  }))

  useEffect(() => {
    if (labeledValue !== undefined) {
      api.stopEditing()
    }
  }, [labeledValue, api])

  return { setLabeledValue }
}

export const PartSelectAgWrapper = React.forwardRef<{}, AgWrapperPropsType<StudyTransactionType>>((params, ref) => {
  const { data, api } = params

  const { setLabeledValue } = usePartSelect(api, ref)
  if (!data || !data.matchManufacturerId) {
    return <div />
  }

  const value = data
    ? data.bamfPartNumber
      ? { key: data.matchPartId || data.bamfPartNumber, label: data.bamfPartNumber }
      : undefined
    : undefined

  return (
    <PartSelect
      manufacturerId={data.matchManufacturerId}
      value={value}
      allowClear={true}
      onChange={v => setLabeledValue(v || null)}
    />
  )
})

export const PartSelectStudyLinkAgWrapper = React.forwardRef<{}, AgWrapperPropsType<StudyLinkType>>((params, ref) => {
  const { data, api } = params

  const { setLabeledValue } = usePartSelect(api, ref)
  if (!data || !data.stanManufacturerId) {
    return <div />
  }

  const value = data
    ? data.stanPartNumber
      ? { key: data.matchStanId || data.stanPartNumber, label: data.stanPartNumber }
      : undefined
    : undefined

  return (
    <PartSelect
      manufacturerId={data.stanManufacturerId}
      value={value}
      allowClear={true}
      onChange={v => setLabeledValue(v || null)}
      dropdownMatchSelectWidth={false}
      dropdownMenuStyle={{ width: 1000 }}
    />
  )
})
