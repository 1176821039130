import {
  ColumnState,
  DragStoppedEvent,
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
} from '@ag-grid-community/core'
import { AgGridColumnProps } from '@ag-grid-community/react'
import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import {
  bulkUpsertNormalizedSuppliersMutation,
  Manufacturer,
  ManufacturerType,
  NormalizedSupplier,
  NormalizedSupplierExt,
  StudySuppliersMatchQueryArgs,
  useStudyNormalizedSuppliersMatchCountQuery,
  useStudyNormalizedSuppliersMatchQuery,
} from '@curvo/apollo'
import { Button, DatePicker, Input, message, PageHeader, Pagination, Tag, Tooltip } from 'antd'
import Select from 'antd/lib/select'
import { isNil, omit, pick } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import styled from 'styled-components'
import { PaginationContainer } from '../Update/common'
import { currencyFormatter } from './common'
import { ManufacturerSelectAgWrapperNormSuppliers } from './components/ManufacturerSelectAgWrapper'
import { ManufacturerTypeSelectAgWrapper } from './components/ManufacturerTypeSelect'
import { NormalizedCurvoSuggestionsAgSelect } from './components/NormalizedCurvoSuggestionsAgSelect'
import { StudyIdAgRenderer } from './Study'

type AgGridRefProps = AgGridReact & {
  api: GridApi
}

type NormalizedType = 'normalized' | 'unNormalized' | 'partial' | 'all'

const SUPPLIER_GROOM = 'SUPPLIER_GROOM '

type NormalizedSupplierChanges = {
  [inputSupplier: string]: NormalizedSupplierExt
}

export const SuppliersGroom: React.FC = () => {
  const { studyId } = useParams<{ studyId?: string }>()
  const navigate = useNavigate()
  const [updating, setUpdating] = useState(false)
  const [total, setTotal] = useState(0)
  const [queryParams, setQueryParams] = useState<
    Pick<StudySuppliersMatchQueryArgs, 'limit' | 'offset' | 'search' | 'studyDateRange'>
  >({
    limit: 100,
    offset: 0,
  })

  const gridRef = useRef<AgGridRefProps>(null)
  const { data, loading } = useStudyNormalizedSuppliersMatchQuery({
    variables: {
      id: studyId ? parseInt(studyId!, 10) : undefined,
      limit: queryParams.limit,
      offset: queryParams.offset,
      search: queryParams.search,
      studyDateRange: queryParams.studyDateRange,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  const { data: count } = useStudyNormalizedSuppliersMatchCountQuery({
    variables: {
      id: studyId ? parseInt(studyId!, 10) : undefined,
      search: queryParams.search,
      studyDateRange: queryParams.studyDateRange,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const [normsGroup, setNormsGroup] = useState<{
    all?: NormalizedSupplierExt[] | null
    unNormalized?: NormalizedSupplierExt[] | null
    normalized?: NormalizedSupplierExt[]
    partial?: NormalizedSupplierExt[]
  }>({})

  const [changes, setChanges] = useState<NormalizedSupplierChanges>({})
  const [stateFilter, setStateFilter] = useState<NormalizedType>('all')

  useEffect(() => {
    if (loading) {
      gridRef.current?.api.showLoadingOverlay()
    }
  }, [loading])

  useEffect(() => {
    if (count?.studySuppliersMatchCount) {
      setTotal(count.studySuppliersMatchCount)
    }
  }, [count])

  useEffect(() => {
    if (loading) {
      return
    }
    const norms = data && data.studySuppliersMatch.hits
    const unNormalized = norms ? norms.filter(norm => !norm.manufacturer && !norm.normalizedCurvo) : null
    const normalized = norms && norms.filter(norm => norm.manufacturer)
    const partial = norms && norms.filter(norm => norm.normalizedCurvo && !norm.manufacturer)
    setNormsGroup({
      all: norms,
      unNormalized,
      normalized,
      partial,
    })
  }, [data, loading, studyId])

  const onChange = (newData: NormalizedSupplierExt) => {
    setChanges(oldChanges => ({
      ...oldChanges,
      [newData.inputSupplier]: newData,
    }))
  }
  const handleUpdateSuggestion = useCallback(
    (inputSupplier: string, suggestion: NormalizedSupplier) => {
      setChanges(old => {
        const newData = {
          ...data?.studySuppliersMatch.hits.find(h => h.inputSupplier === inputSupplier),
          inputSupplier,
          normalizedCurvo: suggestion.normalizedCurvo,
          manufacturer: suggestion.manufacturer,
          type: suggestion.type,
          retry: suggestion.retry,
          producesReprocessedItems: suggestion.producesReprocessedItems,
        }
        gridRef.current?.api.applyTransaction({
          update: [newData],
        })
        return {
          ...old,
          [inputSupplier]: newData,
        }
      })
    },
    [data?.studySuppliersMatch.hits],
  )

  const colDefs: AgGridColumnProps[] = useMemo(
    () => [
      {
        headerName: 'Study',
        field: 'studies',
        colId: 'studies',
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRendererFramework: params => <StudyIdAgRenderer data={params.data.studies} history={params.history} />,
        cellRendererParams: {
          history: history,
        },
        autoHeight: true,
        wrapText: true,
        width: 200,
      },
      {
        headerName: 'Input Supplier',
        field: 'inputSupplier',
        colId: 'inputSupplier',
        onCellValueChanged: () => {},
      },
      {
        headerName: 'Normalized Curvo',
        field: 'normalizedCurvo',
        editable: true,
        colId: 'normalizedCurvo',
        cellEditorFramework: NormalizedCurvoSuggestionsAgSelect,
        valueSetter: ({ newValue, data: newData, node }) => {
          if (newData.normalizedCurvo !== newValue) {
            node?.setData({ ...newData, normalizedCurvo: newValue })
            return true
          }
          return false
        },
        cellRendererParams: { changes },
      },
      {
        headerName: 'Type',
        field: 'type',
        colId: 'type',
        editable: true,
        cellEditorFramework: ManufacturerTypeSelectAgWrapper,
        filter: 'agSetColumnFilter',
        valueSetter: ({ newValue, data: newData, node }) => {
          if (newValue !== undefined && newValue !== newData.type) {
            node?.setData({ ...newData, type: newValue })
            return true
          }
          return false
        },
        valueFormatter: ({ value }) => {
          switch (value) {
            case ManufacturerType.I:
              return 'Instruments'
            case ManufacturerType.O:
              return 'Ortho'
            case ManufacturerType.P:
              return 'PPI'
            case ManufacturerType.L:
              return 'Laboratory'
            case ManufacturerType.Other:
              return 'Other'
            case ManufacturerType.Unknown:
              return 'Unknown'
            case 'B':
              return 'Blacklist'
            default: {
              return value
            }
          }
        },
      },
      {
        headerName: 'Retry',
        editable: false,
        cellRendererFramework: SuggestionRenderer,
        colId: 'retry',
        valueGetter: ({ data: newValue }) => {
          return newValue.retry
        },
      },
      {
        headerName: 'Manufacturer',
        colId: 'manufacturerId',
        field: 'manufacturer',
        editable: true,
        cellEditorFramework: ManufacturerSelectAgWrapperNormSuppliers,
        filter: 'agSetColumnFilter',
        filterParams: {},
        valueFormatter: ({ data: newData }) => {
          if (newData && newData.manufacturer) {
            return `${newData.manufacturer.id} | ${newData.manufacturer.name || ''}`
          }
          return ''
        },
        valueGetter: ({ data: newValue }) => {
          return newValue?.manufacturer
        },
        valueSetter: ({ newValue, data: nData, node }) => {
          if (newValue !== undefined) {
            const newData = {
              ...nData,
              manufacturer: newValue,
              normalizedCurvo: newValue && (newValue.shortName?.toUpperCase() || newValue.name.toUpperCase()),
              type: newValue && newValue.type,
              retry: newValue && newValue.retry,
            }
            node?.setData(newData)
          }

          return true
        },
      },
      {
        headerName: 'Total Spend',
        field: 'totalSpend',
        colId: 'totalSpend',
        type: 'numericColumn',
        valueFormatter: currencyFormatter,
      },
      {
        headerName: 'Suggestions',
        field: 'suggestions',
        colId: 'suggestions',
        cellRendererFramework: NormalizedSuggestions,
        cellRendererParams: {
          onSelect: handleUpdateSuggestion,
        },
      },
    ],
    [changes, handleUpdateSuggestion],
  )

  const FilterExtra = (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
      {!studyId && (
        <>
          <Input.Search
            placeholder="Search by Input Supplier"
            onSearch={v => setQueryParams(q => ({ ...q, offset: 0, search: v }))}
          />
          <DatePicker.RangePicker
            style={{ width: '400px' }}
            format="YYYY-MM-DD"
            placeholder={['Study Created From', 'To']}
            defaultValue={[moment().subtract(3, 'days'), moment()]}
            onChange={value => {
              const [from, to] = value

              if (!from || !to) {
                return
              }

              setQueryParams(q => ({
                ...q,
                offset: 0,
                studyDateRange: {
                  from: moment(from).format('YYYY-MM-DD'),
                  to: moment(to).format('YYYY-MM-DD'),
                },
              }))
            }}
          />
        </>
      )}
      <Select value={stateFilter} onChange={(v: NormalizedType) => setStateFilter(v)} style={{ width: 200 }}>
        <Select.Option value="all">All</Select.Option>
        <Select.Option value="normalized">Normalized</Select.Option>
        <Select.Option value="unNormalized">Un-Normalized</Select.Option>
        <Select.Option value="partial">Partial</Select.Option>
      </Select>
    </div>
  )

  const saveButtonHandle = useCallback(() => {
    const input = Object.values(changes).map(v => ({
      ...omit(v, ['studies', 'suggestions']),
      __typename: undefined,
      manufacturer: undefined,
      totalSpend: undefined,
      manufacturerId: v.manufacturer && v.manufacturer.id,
      retry: v.retry && v.retry.map(retry => pick(retry, ['id', 'name'])),
    }))
    setUpdating(true)
    bulkUpsertNormalizedSuppliersMutation({ input })
      .then(() => {
        message.success('updated')
        setChanges({})
      })
      .catch(e => message.error(e.message))
      .finally(() => setUpdating(false))
  }, [changes])

  const applyAllSuggestions = useCallback(() => {
    setChanges(old => {
      const updatingItems =
        data?.studySuppliersMatch.hits
          ?.filter(h => isNil(old[h.inputSupplier]) && !isNil(h.suggestions))
          .map(h => ({
            ...h,
            inputSupplier: h.inputSupplier,
            normalizedCurvo: h.suggestions![0].normalizedCurvo,
            manufacturer: h.suggestions![0].manufacturer,
            type: h.suggestions![0].type,
            retry: h.suggestions![0].retry,
            producesReprocessedItems: h.suggestions![0].producesReprocessedItems,
          })) || []
      const updates = Object.fromEntries(updatingItems.map(h => [h.inputSupplier, h]))
      gridRef.current?.api.applyTransaction({
        update: updatingItems,
      })
      return {
        ...old,
        ...updates,
      }
    })
  }, [data?.studySuppliersMatch.hits])

  return (
    <div style={{ height: '100%', flexDirection: 'column', display: 'flex', padding: '0 24px 24px 24px' }}>
      <PageHeader
        title={'Normalize Supplier Grooming'}
        onBack={() => navigate('/data-cleaning/')}
        extra={FilterExtra}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
        }}>
        <div className="ag-theme-balham" style={{ flex: 1, minHeight: '200px', width: 'fill-parent' }}>
          <AgGridReact
            ref={gridRef}
            onCellValueChanged={({ data: newData, column }) => {
              if (column.colId !== 'inputSupplier' && column.colId !== 'suggestions') {
                onChange(newData)
              }
            }}
            getContextMenuItems={({ column, node }: GetContextMenuItemsParams) => {
              if (column.getId() === 'normalizedCurvo' && !node.group) {
                return [
                  {
                    name: 'Set NULL',
                    action: () => {
                      node.setData({
                        ...node.data,
                        normalizedCurvo: null,
                      })
                    },
                  },
                  'copy',
                  'paste',
                ]
              }
              return ['copy', 'paste']
            }}
            onDragStopped={(e: DragStoppedEvent) => {
              const columnState = JSON.stringify(
                e.columnApi.getColumnState().map(colState => pick(colState, ['colId', 'width', 'pinned', 'hide'])),
              )
              localStorage.setItem(SUPPLIER_GROOM, columnState)
            }}
            onGridReady={({ api, columnApi }: GridReadyEvent) => {
              const columnStateStr = localStorage.getItem(SUPPLIER_GROOM)
              if (columnStateStr) {
                const savedColumnStates: ColumnState[] = JSON.parse(columnStateStr)
                const currentColumnStates = columnApi.getColumnState()
                const unsavedColumns = currentColumnStates.filter(
                  col => !savedColumnStates.find(savedCol => savedCol.colId === col.colId),
                )

                columnApi.setColumnState([...savedColumnStates, ...unsavedColumns])
                columnApi.setColumnVisible('state', false)

                columnApi.moveColumn('index', 0)
              }
              api.onFilterChanged()
            }}
            immutableData
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,
              headerCheckboxSelectionFilteredOnly: true,
            }}
            getRowNodeId={(row: NormalizedSupplierExt) => row.inputSupplier}
            enableFillHandle
            enableRangeSelection
            columnDefs={colDefs}
            modules={AllModules}
            rowData={normsGroup[stateFilter]}
          />
        </div>
        <PaginationWrapper>
          <Pagination
            total={total}
            pageSize={queryParams.limit!}
            current={queryParams.offset! / queryParams.limit! + 1}
            onChange={(pageNumber, pageSize) => {
              setQueryParams(q => ({
                ...q,
                limit: pageSize || q.limit,
                offset: (pageNumber - 1) * (pageSize || q.limit!),
              }))
            }}
            showSizeChanger
            pageSizeOptions={['100', '200', '500', '1000']}
            onShowSizeChange={(_current, size) => {
              setQueryParams(q => ({
                ...q,
                offset: 0,
                limit: size,
              }))
            }}
          />
          <div>
            <Button loading={updating} onClick={() => saveButtonHandle()}>
              Save
            </Button>
            <Button loading={updating} onClick={() => applyAllSuggestions()}>
              Apply All Suggestions
            </Button>
          </div>
        </PaginationWrapper>
      </div>
    </div>
  )
}

const PaginationWrapper = styled(PaginationContainer)`
  justify-content: space-between;

  .ant-pagination-options-size-changer.ant-select {
    margin-right: 0;
  }

  button {
    margin-right: 1em;
  }
`

const NormalizedSuggestions: React.FC<{
  value: NormalizedSupplier[]
  onSelect: (input: string, suggestion: NormalizedSupplier) => any
  data: NormalizedSupplier
}> = ({ value: suggestions, data, onSelect }) => {
  if (isNil(suggestions)) {
    return null
  }
  return (
    <Select
      style={{ width: 200 }}
      placeholder={suggestions[0].normalizedCurvo}
      onChange={v => onSelect(data.inputSupplier, suggestions.find(sg => sg.inputSupplier === v)!)}>
      {(suggestions || []).map((i: NormalizedSupplier) => (
        <Select.Option key={i.inputSupplier} value={i.inputSupplier} title={i.normalizedCurvo || undefined}>
          <b>{i.normalizedCurvo}</b>
          <br />
          {i.inputSupplier}
        </Select.Option>
      ))}
    </Select>
  )
}

const SuggestionRenderer: React.FC<{
  value: Pick<NormalizedSupplier, 'retry'>
  onSelect: (input: string, suggestion: NormalizedSupplier) => any
  data: NormalizedSupplierExt
}> = ({ value, data: nodeData }) => {
  if (!value) {
    if (nodeData?.suggestions?.length && (nodeData?.suggestions[0].retry || []).length) {
      return (
        <div>
          Suggest:{' '}
          {(nodeData?.suggestions[0].retry || []).map((m: any) => (
            <Tooltip title={m.name} key={m.id}>
              <Tag style={{ borderStyle: 'dashed' }}>{m.id}</Tag>
            </Tooltip>
          ))}
        </div>
      )
    } else {
      return <div />
    }
  }
  return (
    <>
      {(value as Pick<Manufacturer, 'id' | 'name'>[]).map(m => (
        <Tooltip title={m.name} key={m.id}>
          <Tag>{m.id}</Tag>
        </Tooltip>
      ))}
    </>
  )
}
