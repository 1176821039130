"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Rotate360 = void 0;
const styled_components_1 = require("styled-components");
exports.Rotate360 = (0, styled_components_1.keyframes) `
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
