import {
  createSegementationMutation,
  deleteSegmentationMutation,
  Segmentation,
  SegmentationCreateInput,
  SegmentationData,
  SegmentationUpdateInput,
  SEGMENTATION_QUERY,
  updateSegmentationMutation,
} from '@curvo/apollo'
import { Button, Col, message, Popconfirm, Row, Table } from 'antd'
import React, { useState } from 'react'
import { ColumnGenFunctionType, PageWrapper, RedA } from './common'
import { SegmentationDrawer } from './components/EditPanel/CreateEditSegmentation'
import { EditMode } from './components/EditPanel/EditManufacturer'
import { JSONToCSVConvertor } from './Segment'

export const SegmentationComponent: React.FC<{}> = () => {
  const [panelMode, setPanelMode] = useState<EditMode>(EditMode.edit)
  const [selected, setSelected] = useState<Segmentation>()
  const [isOpenPanel, setIsOpenPanel] = useState(false)
  const mutationAct = {
    refetchQueries: [{ query: SEGMENTATION_QUERY }],
  }
  const columns = columnsGen(
    seg => {
      setSelected(seg)
      setIsOpenPanel(true)
      setPanelMode(EditMode.edit)
    },
    seg => {
      deleteSegmentationMutation(
        {
          id: seg.id,
        },
        mutationAct,
      )
        .then(() => message.success('Success'))
        .catch(e => message.error(e.message))
    },
  )
  const title = panelMode === EditMode.create ? 'Create new Segmentation' : `Edit ${selected && selected.id}`
  return (
    <PageWrapper>
      <SegmentationData fetchPolicy="network-only">
        {({ data, error, loading }) => {
          if (error) {
            message.error(error.message)
          }
          const segmentations = (data && data.segmentations) || []
          const handleExport = () => {
            JSONToCSVConvertor(
              (data?.segmentations || []).map(segmentation => ({
                id: segmentation.id,
                segment: segmentation.segment?.name,
                subSegment: segmentation.subSegment?.name,
              })),
              'segmentations.csv',
              true,
            )
          }
          return (
            <div>
              <Row gutter={16}>
                <Col md={2}>
                  <Button
                    type="primary"
                    icon="plus"
                    onClick={() => {
                      setPanelMode(EditMode.create)
                      setSelected(undefined)
                      setIsOpenPanel(true)
                    }}>
                    New Segementation
                  </Button>
                </Col>
                <Col md={20} />

                <Col md={2}>
                  <Button type="primary" icon="export" onClick={handleExport}>
                    Export
                  </Button>
                </Col>
              </Row>
              <Table bordered loading={loading} columns={columns} dataSource={segmentations} rowKey="id" />
              <SegmentationDrawer
                width={300}
                onSubmit={value => {
                  panelMode === EditMode.edit
                    ? updateSegmentationMutation(
                        {
                          input: value as SegmentationUpdateInput,
                        },
                        mutationAct,
                      )
                        .then(() => message.success('Success'))
                        .catch(e => message.error(e.message))
                    : createSegementationMutation(
                        {
                          input: value as SegmentationCreateInput,
                        },
                        mutationAct,
                      )
                        .then(() => message.success('Success'))
                        .catch(e => message.error(e.message))
                  setIsOpenPanel(false)
                  setSelected(undefined)
                }}
                onCancel={() => {
                  setIsOpenPanel(false)
                }}
                editMode={panelMode}
                visible={isOpenPanel}
                segmentation={selected}
                title={title}
              />
            </div>
          )
        }}
      </SegmentationData>
    </PageWrapper>
  )
}

const compareString = (a: string | undefined, b: string | undefined): number => {
  if (a === b) {
    return 0
  }
  if (!a) {
    return 1
  }
  if (!b) {
    return -1
  }
  if (a < b) {
    return 1
  }
  return -1
}

const columnsGen: ColumnGenFunctionType<Segmentation> = (onEdit, onDelete) => [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id',
    width: 80,
    sorter: (a, b) => compareString(a.id, b.id),
  },
  {
    title: 'Segment',
    key: 'segment',
    dataIndex: 'segment.name',
    sorter: (a, b) => compareString(a.segment ? a.segment.name : undefined, b.segment ? b.segment.name : undefined),
  },
  {
    title: 'Sub Segment',
    key: 'subSegment',
    dataIndex: 'subSegment.name',
    sorter: (a, b) =>
      compareString(a.subSegment ? a.subSegment.name : undefined, b.subSegment ? b.subSegment.name : undefined),
  },
  {
    title: 'Action',
    key: 'action',
    render: (_text: any, record: Segmentation) => (
      <span>
        <Button type="link" onClick={() => onEdit(record)}>
          Edit
        </Button>
        <span> | </span>
        <Popconfirm
          title={`Are you sure you want to delete Segmentation ${record.id}?`}
          okText="Yes"
          cancelText="No"
          onConfirm={() => onDelete && onDelete(record)}>
          <RedA>Delete</RedA>
        </Popconfirm>
      </span>
    ),
    width: 120,
  },
]
