import { Alert, Button, Form, Icon, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'
import Cognito from '../../config/Cognito'

type SignInValues = {
  username: string
  password: string
  error?: Error
}

export const SignIn: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const loading = useAuthorizedRoute()
  const [login, setLogin] = useState<SignInValues>({
    username: '',
    password: '',
  })
  const handleSignIn = async () => {
    try {
      await Cognito.signIn(login.username.toLowerCase(), login.password)
      if (location.state && location.state.from) {
        navigate(location.state.from)
      } else {
        navigate('/')
      }
    } catch (error) {
      setLogin({
        ...login,
        error,
      })
    }
  }
  return loading ? null : (
    <SignInWrapper>
      <StyledSignInForm>
        <Form.Item>
          <Input
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Username"
            onChange={e => setLogin({ ...login, username: e.target.value.toLowerCase() })}
          />
        </Form.Item>
        <Form.Item>
          <Input
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
            onChange={e => setLogin({ ...login, password: e.target.value })}
          />
        </Form.Item>
        <StyledLoginButton onClick={handleSignIn} type="primary">
          Submit
        </StyledLoginButton>
      </StyledSignInForm>
      {login.error && <Alert message="Error" description={login.error.message} type="error" showIcon />}
    </SignInWrapper>
  )
}

const StyledSignInForm = styled.div`
  width: 200px;
  margin: auto;
`

const StyledLoginButton = styled(Button)`
  width: 100%;
`

const SignInWrapper = styled.div`
  width: 100%;
  padding-top: 200px;
`

const useAuthorizedRoute = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    Cognito.getSession()
      .then(session => {
        if (session.isValid() && session.getAccessToken !== undefined) {
          navigate({
            pathname: '/',
          })
        } else {
          setLoading(false)
        }
      })
      .catch(error => {
        console.info(error)
        setLoading(false)
      })
  }, [navigate])
  return loading
}
