"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPadding = exports.getMargin = exports.getCSS = exports.convertCamelCaseToDash = exports.extractPropsByKeys = exports.extractMarginAndPadding = exports.extractElementType = exports.extractElementSize = exports.elementType = exports.extractElementTypes = exports.hexToRGBA = exports.Breakpoints = exports.elementSize = exports.extractElementSizes = void 0;
const styles_1 = require("../styles");
const colorUtils_1 = require("./colorUtils");
const cssUtils_1 = require("./cssUtils");
const elementSizeKeys = ['small', 'large', 'extraLarge'];
const extractElementSizes = (props) => (0, exports.extractPropsByKeys)(props, elementSizeKeys);
exports.extractElementSizes = extractElementSizes;
const elementSize = (props) => {
    if (props.small) {
        return `
        padding: 0.25rem 0.5rem;
        border-radius: 3px;
        font-size: 0.75rem;
      `;
    }
    if (props.large) {
        return `
        padding: 0.75rem 1.25rem;
        border-radius: 3px;
        font-size: 1em;
      `;
    }
    if (props.extraLarge) {
        return `
      padding: 1.5rem 2rem;
    `;
    }
    return `
        padding: 0.5rem 1rem;
        border-radius: 3px;
      `;
};
exports.elementSize = elementSize;
var Breakpoints;
(function (Breakpoints) {
    Breakpoints[Breakpoints["xs"] = 0] = "xs";
    Breakpoints[Breakpoints["sm"] = 576] = "sm";
    Breakpoints[Breakpoints["md"] = 768] = "md";
    Breakpoints[Breakpoints["lg"] = 992] = "lg";
    Breakpoints[Breakpoints["xl"] = 1200] = "xl";
})(Breakpoints = exports.Breakpoints || (exports.Breakpoints = {}));
const hexToRGBA = (color, alpha) => {
    const regex = new RegExp('^#([a-zA-Z0-9]{2})([a-zA-Z0-9]{2})([a-zA-Z0-9]{2})([a-zA-Z0-9]{2})?');
    try {
        const [, r, g, b] = color.match(regex);
        const hexToInt = (hex) => parseInt(hex, 16);
        return `rgba(${hexToInt(r)}, ${hexToInt(g)}, ${hexToInt(b)}, ${alpha})`;
    }
    catch (error) {
        return;
    }
};
exports.hexToRGBA = hexToRGBA;
const elementTypeKeys = [
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    'gray',
    'outline',
];
const extractElementTypes = (props) => (0, exports.extractPropsByKeys)(props, elementTypeKeys);
exports.extractElementTypes = extractElementTypes;
const elementType = (props) => {
    if (props.secondary) {
        return `
      background: ${props.outline ? styles_1.Colors.Transparent : styles_1.Colors.Secondary};
      border-color: ${styles_1.Colors.Secondary};
      color: ${props.outline ? styles_1.Colors.Dark : styles_1.Colors.Dark};
      &:hover {
        background: ${props.outline ? styles_1.Colors.Transparent : `${(0, exports.hexToRGBA)(styles_1.Colors.Secondary, 0.8)}`}
      }
    `;
    }
    if (props.success) {
        return `
      background: ${props.outline ? styles_1.Colors.Transparent : styles_1.Colors.Success};
      border-color: ${styles_1.Colors.Success};
      color: ${props.outline ? styles_1.Colors.Success : styles_1.Colors.White};
    `;
    }
    if (props.danger) {
        return `
      background: ${props.outline ? styles_1.Colors.Transparent : styles_1.Colors.Danger};
      border-color: ${styles_1.Colors.Danger};
      color: ${props.outline ? styles_1.Colors.Danger : styles_1.Colors.White};
      &:hover {
        border-color: ${props.outline && `${(0, exports.hexToRGBA)(styles_1.Colors.Danger, 0.5)}`};
        background: ${props.outline ? styles_1.Colors.Transparent : `${(0, exports.hexToRGBA)(styles_1.Colors.Danger, 0.8)}`}
      }
    `;
    }
    if (props.warning) {
        return `
      background: ${props.outline ? styles_1.Colors.Transparent : styles_1.Colors.Warning};
      border-color: ${styles_1.Colors.Warning};
      color: ${props.outline ? styles_1.Colors.Warning : styles_1.Colors.White};
    `;
    }
    if (props.info) {
        return `
      background: ${props.outline ? styles_1.Colors.Transparent : styles_1.Colors.Info};
      border-color: ${styles_1.Colors.Info};
      color: ${props.outline ? styles_1.Colors.Info : styles_1.Colors.White};
    `;
    }
    if (props.light) {
        return `
      background: ${props.outline ? styles_1.Colors.Transparent : styles_1.Colors.Light};
      border-color: ${styles_1.Colors.Light};
      color: ${styles_1.Colors.Dark};
      &:hover {
        color: ${props.outline ? (0, colorUtils_1.darken)(styles_1.Colors.Light, 0.15) : styles_1.Colors.Dark};
        background: ${props.outline ? styles_1.Colors.Transparent : (0, colorUtils_1.darken)(styles_1.Colors.Light, 0.15)};
      }
    `;
    }
    if (props.dark) {
        return `
      background: ${props.outline ? styles_1.Colors.Transparent : styles_1.Colors.Dark};
      border-color: ${styles_1.Colors.Dark};
      color: ${props.outline ? styles_1.Colors.Dark : styles_1.Colors.White};
    `;
    }
    if (props.link) {
        return `
      background: ${styles_1.Colors.Transparent};
      border-color: ${styles_1.Colors.Transparent};
      color: ${styles_1.Colors.Primary};
    `;
    }
    if (props.ghost) {
        return `
    background: ${styles_1.Colors.Transparent};
    border-color: ${styles_1.Colors.Transparent};
    color: ${styles_1.Colors.Dark};
    `;
    }
    return `
    background: ${props.outline ? styles_1.Colors.Transparent : styles_1.Colors.Primary};
    border-color: ${styles_1.Colors.Primary};
    color: ${props.outline ? styles_1.Colors.Primary : styles_1.Colors.White};
    &:hover {
      color: ${props.outline ? (0, colorUtils_1.lighten)(styles_1.Colors.Primary, 0.15) : styles_1.Colors.White};
      background: ${props.outline ? styles_1.Colors.Transparent : (0, colorUtils_1.lighten)(styles_1.Colors.Primary, 0.15)};
    }
  `;
};
exports.elementType = elementType;
const extractElementSize = ({ small, large, ...rest }) => ({
    elementSizes: {
        small,
        large,
    },
    rest,
});
exports.extractElementSize = extractElementSize;
const extractElementType = ({ primary, secondary, success, danger, warning, info, light, dark, link, gray, outline, ...rest }) => ({
    elementTypes: {
        primary,
        secondary,
        success,
        danger,
        warning,
        info,
        light,
        dark,
        link,
        gray,
        outline,
    },
    rest,
});
exports.extractElementType = extractElementType;
const marginKeys = ['margin', 'marginTop', 'marginRight', 'marginBottom', 'marginLeft'];
const paddingKeys = ['padding', 'paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft'];
const extractMarginAndPadding = (props) => {
    const [paddings, notPaddinds] = (0, exports.extractPropsByKeys)(props, paddingKeys);
    const [margins, rest] = (0, exports.extractPropsByKeys)(notPaddinds, marginKeys);
    return {
        paddings,
        margins,
        rest,
    };
};
exports.extractMarginAndPadding = extractMarginAndPadding;
const extractPropsByKeys = (props, keys) => Object.keys(props).reduce((previousResult, currentItem) => {
    if (keys.includes(currentItem)) {
        return [
            {
                ...previousResult[0],
                [currentItem]: props[currentItem],
            },
            previousResult[1],
        ];
    }
    return [previousResult[0], { ...previousResult[1], [currentItem]: props[currentItem] }];
}, [{}, {}]);
exports.extractPropsByKeys = extractPropsByKeys;
const convertCamelCaseToDash = (textInCamelCase) => {
    const regex = new RegExp('([A-Z])', 'g');
    const regexGroups = [...new Set(textInCamelCase.match(regex))];
    return regexGroups.reduce((result, match) => result.replace(new RegExp(match, 'g'), `-${match.toLowerCase()}`), textInCamelCase);
};
exports.convertCamelCaseToDash = convertCamelCaseToDash;
const getCSS = (props, include) => {
    const keys = Object.keys(props).filter(key => include.includes(key));
    const objectCss = keys.reduce((object, currentKey) => {
        if ((0, cssUtils_1.isNotUndefinedOrNull)(props[currentKey])) {
            return {
                ...object,
                [(0, exports.convertCamelCaseToDash)(currentKey)]: (0, cssUtils_1.addUnit)(props[currentKey]),
            };
        }
        return object;
    }, {});
    return Object.entries(objectCss)
        .map(entry => entry.join(': '))
        .reduce((result, current) => `${result}${current};`, '');
};
exports.getCSS = getCSS;
const getMargin = (props) => {
    if (!(0, cssUtils_1.isNotUndefinedOrNull)(props.margin) &&
        !(0, cssUtils_1.isNotUndefinedOrNull)(props.marginTop) &&
        !(0, cssUtils_1.isNotUndefinedOrNull)(props.marginRight) &&
        !(0, cssUtils_1.isNotUndefinedOrNull)(props.marginBottom) &&
        !(0, cssUtils_1.isNotUndefinedOrNull)(props.marginLeft)) {
        return '';
    }
    return (0, exports.getCSS)(props, marginKeys);
};
exports.getMargin = getMargin;
const getPadding = (props) => {
    if (!(0, cssUtils_1.isNotUndefinedOrNull)(props.padding) &&
        !(0, cssUtils_1.isNotUndefinedOrNull)(props.paddingTop) &&
        !(0, cssUtils_1.isNotUndefinedOrNull)(props.paddingRight) &&
        !(0, cssUtils_1.isNotUndefinedOrNull)(props.paddingBottom) &&
        !(0, cssUtils_1.isNotUndefinedOrNull)(props.paddingLeft)) {
        return '';
    }
    return (0, exports.getCSS)(props, paddingKeys);
};
exports.getPadding = getPadding;
