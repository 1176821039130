import {
  AllowedGicsTypeTwoData,
  GICS_TYPE_TWO_QUERY,
  GicTypeTwo,
  GicsTypeTwoData,
  createGicTypeTwoMutation,
} from '@curvo/apollo'
import LoadingIndicator from '@curvo/common-ui/dist/components/Loading/Spinner'
import { Icon } from 'antd'
import { LabeledValue, SelectProps } from 'antd/lib/select'
import React, { useCallback, useState } from 'react'
import { StyledMultipleSelect, StyledSelect, StyledSelectPropsType, TypedSelect } from './common'

type AllowedGICsTypeTwoSelectProps = SelectProps<LabeledValue | undefined> &
  StyledSelectPropsType & {
    gicId?: number
    typeOneId?: string
    onChangeFullValue?: (v?: GicTypeTwo) => void
  }

const OTHER_TYPE_ONE = 'Other'
const UNSPEC_TYPE_ONE = 'Unspec'

export const AllowedGICsTypeTwoSelect: React.FC<AllowedGICsTypeTwoSelectProps> = ({
  gicId,
  typeOneId,
  onChange,
  onChangeFullValue,
  ...props
}) => (
  <AllowedGicsTypeTwoData variables={{ gicId, typeOneId }}>
    {({ data, loading }) => {
      const typeTwos = (data && data.allowedGicsTypeTwo) || []

      const otherTypeOne = typeTwos.filter(to => to.name === OTHER_TYPE_ONE)
      const unspecTypeOne = typeTwos.filter(to => to.name === UNSPEC_TYPE_ONE)

      // move Unspec to the top of typeOne list, and Other to btm, for accessibility
      const options = [
        ...unspecTypeOne,
        ...typeTwos.filter(to => to.name !== OTHER_TYPE_ONE && to.name !== UNSPEC_TYPE_ONE),
        ...otherTypeOne,
      ]
      return (
        <StyledSelect
          loading={loading}
          showSearch
          labelInValue
          filterOption={false}
          showArrow={false}
          defaultActiveFirstOption={false}
          placeholder="Select Allowed GIC Type Two"
          allowClear={true}
          onChange={(v, com) => {
            if (onChange) {
              onChange(v, com)
            }
            if (onChangeFullValue && v && !Array.isArray(v)) {
              const selectedGicTypeTwo = options.find(typeTwo => typeTwo.id === v.key)
              onChangeFullValue(selectedGicTypeTwo)
            }
          }}
          {...props}>
          {options.map(typeTwo => (
            <TypedSelect.Option key={props.fullData ? JSON.stringify(typeTwo) : typeTwo.id}>
              {typeTwo.name}
            </TypedSelect.Option>
          ))}
        </StyledSelect>
      )
    }}
  </AllowedGicsTypeTwoData>
)

type GicsTypeTwoSelectProps = SelectProps<LabeledValue | LabeledValue[] | undefined> &
  StyledSelectPropsType & {
    createIfNotExist?: boolean
    onChangeFullValue?: (v?: GicTypeTwo) => void
  }

export const GicsTypeTwoSelect: React.FC<GicsTypeTwoSelectProps> = ({
  createIfNotExist,
  onChange,
  onChangeFullValue,
  ...props
}) => {
  const [searchText, setSearchText] = useState('')
  const dropdownRender = useCallback(
    (menu: React.ReactNode, options: GicTypeTwo[]) =>
      !createIfNotExist || options.length > 0 ? (
        menu
      ) : (
        <div
          style={{ padding: '4px 8px', cursor: 'pointer' }}
          onMouseDown={e => e.preventDefault()}
          onClick={() => {
            createGicTypeTwoMutation(
              { input: { name: searchText } },
              { refetchQueries: [{ query: GICS_TYPE_TWO_QUERY }] },
            )
          }}>
          <Icon style={{ marginRight: '8px' }} type="plus" />
          {`Add GIC type Two: ${searchText}`}
        </div>
      ),
    [createIfNotExist, searchText],
  )
  return (
    <GicsTypeTwoData>
      {({ data, loading }) => {
        const typeTwos = ((data && data.gicsTypeTwo) || []).filter(typeTwo =>
          new RegExp(searchText, 'i').test(typeTwo.name),
        )

        const otherTypeOne = typeTwos.filter(to => to.name === OTHER_TYPE_ONE)
        const unspecTypeOne = typeTwos.filter(to => to.name === UNSPEC_TYPE_ONE)

        // move Unspec to the top of typeOne list, and Other to btm, for accessibility
        const options = [
          ...unspecTypeOne,
          ...typeTwos.filter(to => to.name !== OTHER_TYPE_ONE && to.name !== UNSPEC_TYPE_ONE),
          ...otherTypeOne,
        ]
        return (
          <StyledMultipleSelect
            onSearch={setSearchText}
            loading={loading}
            showSearch
            labelInValue
            filterOption={false}
            showArrow={false}
            defaultActiveFirstOption={false}
            placeholder="Select GIC Type Two"
            allowClear={true}
            dropdownRender={menu => dropdownRender(menu, options)}
            notFoundContent={loading ? <LoadingIndicator /> : 'No Data'}
            onChange={(v, com) => {
              if (onChange) {
                onChange(v, com)
              }
              if (onChangeFullValue && v && !Array.isArray(v)) {
                const selectedGicTypeTwo = options.find(typeTwo => typeTwo.id === v.key)
                onChangeFullValue(selectedGicTypeTwo)
              }
            }}
            {...props}>
            {options.map(typeTwo => (
              <TypedSelect.Option key={typeTwo.id}>{typeTwo.name}</TypedSelect.Option>
            ))}
          </StyledMultipleSelect>
        )
      }}
    </GicsTypeTwoData>
  )
}
