import { ColDef, ColGroupDef } from '@ag-grid-community/core'
import { BasketLookupType } from '@curvo/apollo'
import { Icon } from 'antd'

const NegativeCheckAgRenderer = ({ value }) => {
  if (value) {
    return <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
  }
  return <Icon type="close-circle" twoToneColor="#eb2f96" theme="twoTone" />
}

export const columns: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Basket Name',
    colId: 'basketName',
    field: 'basketName',
    type: 'text',
  },
  {
    headerName: 'Customer',
    colId: 'customer',
    field: 'customer.name',
    type: 'text',
  },
  {
    headerName: 'TsId',
    colId: 'tsId',
    field: 'tsId',
    type: 'text',
  },
  {
    headerName: 'Gudid Id',
    colId: 'gudidId',
    field: 'gudidId',
    type: 'text',
  },
  {
    headerName: 'Gudid Brand',
    colId: 'gudidBrand',
    field: 'gudidBrand',
    type: 'text',
  },
  {
    headerName: 'Stan Id',
    colId: 'stanId',
    field: 'stanId',
    type: 'text',
  },
  {
    headerName: 'Gic',
    colId: 'gic',
    field: 'gic',
    type: 'text',
    valueGetter: ({ data }) => {
      const r: BasketLookupType = data
      return (r.gicTypeOneTypeTwo || '').split('+')[0]
    },
  },
  {
    headerName: 'Gic Type One',
    colId: 'gicTypeOne',
    field: 'gicTypeOne',
    type: 'text',
    valueGetter: ({ data }) => {
      const r: BasketLookupType = data
      return (r.gicTypeOneTypeTwo || '').split('+')[1]
    },
  },
  {
    headerName: 'Gic Type Two',
    colId: 'gicTypeTwo',
    field: 'gicTypeTwo',
    type: 'text',
    valueGetter: ({ data }) => {
      const r: BasketLookupType = data
      return (r.gicTypeOneTypeTwo || '').split('+')[2]
    },
  },
  {
    headerName: 'Gmdn Name',
    colId: 'gmdnName',
    field: 'gmdnName',
    type: 'text',
  },
  {
    headerName: 'Unspsc Title',
    colId: 'unspscClassTitle',
    field: 'unspscClassTitle',
    type: 'text',
  },
  {
    headerName: 'Commodity Id',
    colId: 'commodityId',
    field: 'commodityId',
    type: 'text',
  },
  {
    headerName: 'Negative',
    colId: 'negative',
    field: 'negative',
    type: 'bool',
    cellRendererFramework: NegativeCheckAgRenderer,
  },
  {
    headerName: 'Manufacturer',
    colId: 'manufacturer',
    field: 'manufacturer',
    type: 'text',
  },
  {
    headerName: 'Vendor',
    colId: 'vendor',
    field: 'vendor',
    type: 'text',
  },
]
