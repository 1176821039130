import { GridApi } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact'
import {
  BulkBamfToHiMatchingStudyLinkInput,
  BulkMoveToTwinMatchStudyLinkInput,
  BulkRestoreOriginalFieldsStudyLinkInput,
  BulkUpdateStudyLinkInput,
  RematchStudyLinksInput,
  STUDY_QUERY,
  StringFilterType,
  StudyLinkCatalogEnum,
  StudyLinkType,
  StudyStatusEnum,
  StudyTransactionState,
  bulkMoveToTwinMatchMutation,
  bulkRestoreOriginalFieldsStudyLinkMutation,
  bulkUpdateBamfStudyLinksToHIMatchesMutation,
  bulkUpdateStudyLinksMutation,
  commitStudyLinkMutation,
  createNewPartsFromStudyTransaction,
  pushLinkTsIdToGroomingMutation,
  rematchStudyLinksMutation,
  useStudyData,
} from '@curvo/apollo'
import { Button, Dropdown, Icon, Input, Menu, Modal, Popconfirm, Select, Table, message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { from, bufferCount, map as rxMap, mergeMap, lastValueFrom } from 'rxjs'
import { transformForBamfString } from '../../TransactionGroom'
import { getManufacturerString, getPartString } from '../Grooming2'
import { AgGridRefProps, Grooming2Context, Grooming2ContextProps, getMatchedSimilarityScore } from './common'
import { LastActionStackHookReturnType } from './useLastActionHook'

const ID_PER_UPDATE_CHUNK = 500

export const ApprovedTopButtons: React.FC<{ studyId: number }> = ({ studyId }) => {
  const [committing, setCommitting] = useState(false)
  const [pushing, setPushing] = useState(false)
  const { data: studyData } = useStudyData({ id: studyId })
  const study = studyData?.study

  return (
    <TwoSideFullDiv>
      <div />
      <div>
        {study && study.status === StudyStatusEnum.Grooming && (
          <Button
            loading={pushing}
            onClick={() => {
              message.info('Pushing to grooming 1.0...')
              setPushing(true)
              pushLinkTsIdToGroomingMutation({ id: studyId })
                .then(() => {
                  message.success('Pushed')
                })
                .catch(e => {
                  message.error(e.message)
                })
                .finally(() => {
                  setPushing(false)
                })
            }}>
            Push To Grooming
          </Button>
        )}
        <Button
          type="primary"
          loading={committing}
          onClick={() => {
            message.info('Committing Study')
            setCommitting(true)
            commitStudyLinkMutation({ id: studyId })
              .then(() => {
                message.success('Commited')
              })
              .catch(e => {
                message.error(e.message)
              })
              .finally(() => {
                setCommitting(false)
              })
          }}>
          Commit Ts IDs
        </Button>
      </div>
    </TwoSideFullDiv>
  )
}

export const HIMatchTopButtons: React.FC<{ toShow?: boolean; state?: StudyTransactionState | null }> = ({
  toShow = false,
  state,
}) => {
  if (state === StudyTransactionState.SupplierMatchingAndFinalReview) {
    return (
      <TwoSideFullDiv>
        <div>
          <ApproveButton />
          <RestoreOriginalStudyLinkButton />
          <UnmatchStudyLinksButton />
        </div>
        <div>
          <ClearAllFiltersButton />
        </div>
      </TwoSideFullDiv>
    )
  }
  return (
    <TwoSideFullDiv>
      <div>
        <ApproveButton />
        <MoveToTwinButton />
        <RestoreOriginalStudyLinkButton />
        <UnmatchStudyLinksButton />
        <IgnoreButton />
        <AddToQueuedPartButton />
        <HideButton toShow={toShow} />
      </div>
      <div>
        <ClearAllFiltersButton />
        <UndoButton />
      </div>
    </TwoSideFullDiv>
  )
}

export const AIMatchTopButtons: React.FC = () => {
  return (
    <TwoSideFullDiv>
      <div>
        <ApproveButton />
        <MoveToHIMatchButton />
      </div>
      <div>
        <ClearAllFiltersButton />
        <UndoButton />
      </div>
    </TwoSideFullDiv>
  )
}

export const UndoButton: React.FC = () => {
  const { props } = useContext(Grooming2Context)
  const { catalog, step, lastActionStackHook } = props!
  return (
    <Popconfirm
      title="Do you want to undo last action?"
      onConfirm={() => {
        lastActionStackHook.undoLastAction(catalog, step || StudyTransactionState.SupplierMatching)
      }}>
      <Button icon="undo">Undo</Button>
    </Popconfirm>
  )
}

export const ClearAllFiltersButton: React.FC = () => {
  const { props } = useContext(Grooming2Context)
  const { gridRef } = props!
  return (
    <Button
      icon="clear"
      onClick={() => {
        if (gridRef.current) {
          gridRef.current.api.setFilterModel(null)
        }
      }}>
      Clear filters
    </Button>
  )
}

export const MoveToHIMatchButton: React.FC = () => {
  const { props } = useContext(Grooming2Context)
  const { studyId, lastActionStackHook, gridRef, catalog } = props!
  useEffect(() => {
    function handleKey(e: KeyboardEvent) {
      // U + alt
      if (e.keyCode === 72 && e.altKey) {
        doBulkUpdateBamfToHIMatches(
          { studyId, tsIds: getSelectedRows(gridRef).map(row => row.tsId), catalog },
          gridRef,
          lastActionStackHook,
          catalog,
        )
      }
    }
    document.addEventListener('keyup', handleKey)
    return function cleanUp() {
      document.removeEventListener('keyup', handleKey)
    }
  }, [gridRef, lastActionStackHook, catalog, studyId])

  return (
    <Button
      icon="swap-right"
      onClick={() =>
        doBulkUpdateBamfToHIMatches(
          { studyId, tsIds: getSelectedRows(gridRef).map(row => row.tsId), catalog },
          props!.gridRef,
          props!.lastActionStackHook,
          props!.catalog,
        )
      }>
      Move to &nbsp; <u>H</u>I
    </Button>
  )
}

export const handleUnmatchStudyLinks = (props: Grooming2ContextProps, stopSuggesting?: boolean) => {
  const { studyId, catalog, gridRef } = props
  const selectedRows = getSelectedRows(gridRef)
  if (!selectedRows.length) {
    message.info('Please select at least one row!')
    return
  }
  return bulkUpdateStudyLinks(
    {
      studyId,
      catalog,
      update:
        catalog === StudyLinkCatalogEnum.Stan
          ? { matchStanId: null }
          : catalog === StudyLinkCatalogEnum.Medline
          ? { matchMedId: null }
          : { matchGudidId: null },
      tsIds: selectedRows.map(row => row.tsId),
      stopSuggesting,
    },
    gridRef?.current?.api,
  )
}

export const UnmatchStudyLinksButton: React.FC = () => {
  const { props } = useContext(Grooming2Context)

  useEffect(() => {
    function handleKey(e: KeyboardEvent) {
      // U + alt
      if (e.keyCode === 85 && e.altKey) {
        handleUnmatchStudyLinks(props!)
      }
    }
    document.addEventListener('keyup', handleKey)
    return function cleanUp() {
      document.removeEventListener('keyup', handleKey)
    }
  }, [props])

  const handleMenuClick = ({ key }) => {
    if (key === 'unmatch-stop-suggesting') {
      handleUnmatchStudyLinks(props!, true)
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="unmatch-stop-suggesting">Unmatch And Stop Suggesting</Menu.Item>
    </Menu>
  )

  return (
    <Dropdown.Button icon={<Icon type="down" />} onClick={() => handleUnmatchStudyLinks(props!)} overlay={menu}>
      <Icon type="scissor" /> <u>U</u>nmatch
    </Dropdown.Button>
  )
}

export const handleRestoreOriginalFields = ({ studyId, catalog, gridRef }: Grooming2ContextProps) =>
  doBulkRestoreOriginalFieldsStudyLinks(
    {
      studyId,
      tsIds: getSelectedRows(gridRef).map(row => row.tsId),
      catalog,
    },
    gridRef,
  )

export const RestoreOriginalStudyLinkButton: React.FC = () => {
  const { props } = useContext(Grooming2Context)
  useEffect(() => {
    function handleKey(e: KeyboardEvent) {
      if (e.keyCode === 69 && e.altKey) {
        handleRestoreOriginalFields(props!)
      }
    }
    document.addEventListener('keyup', handleKey)
    return function cleanUp() {
      document.removeEventListener('keyup', handleKey)
    }
  }, [props])

  return (
    <Button icon="undo" onClick={() => handleRestoreOriginalFields(props!)}>
      R<u>e</u>store
    </Button>
  )
}

const handleMoveToTwin = (props: Grooming2ContextProps) => {
  const { studyId, catalog, gridRef, lastActionStackHook } = props
  doBulkMoveToTwinMatch(
    { studyId, tsIds: getSelectedRows(gridRef).map(row => row.tsId), catalog },
    gridRef,
    lastActionStackHook,
    catalog,
  )
}

export const MoveToTwinButton: React.FC = () => {
  const { props } = useContext(Grooming2Context)
  useEffect(() => {
    function handleKey(e: KeyboardEvent) {
      if (e.keyCode === 84 && e.altKey) {
        handleMoveToTwin(props!)
      }
    }
    document.addEventListener('keyup', handleKey)
    return function cleanUp() {
      document.removeEventListener('keyup', handleKey)
    }
  }, [props])

  return (
    <Button icon="team" onClick={() => handleMoveToTwin(props!)}>
      Move to &nbsp; <u>T</u>win
    </Button>
  )
}

const handleRetry = (
  props: Grooming2ContextProps,
  inputs: Omit<RematchStudyLinksInput, 'studyId' | 'catalog' | 'tsIds'>,
) => {
  const { catalog, studyId, gridRef } = props
  return doRematchStudyLinks(
    {
      tsIds: getSelectedRows(gridRef).map(row => row.tsId),
      catalog,
      ...inputs,
    },
    gridRef,
    studyId,
  )
}

export const RetryButton: React.FC<Omit<RematchStudyLinksInput, 'studyId' | 'catalog' | 'tsIds'>> = inputs => {
  const { props } = useContext(Grooming2Context)
  return (
    <Button icon="retweet" onClick={() => handleRetry(props!, inputs)}>
      Retry with
    </Button>
  )
}

export const handleApprove = (props: Grooming2ContextProps, ignoreTsIds?: string[]) => {
  const { studyId, catalog, gridRef, lastActionStackHook, step } = props
  return bulkUpdateStudyLinks(
    {
      studyId,
      catalog,
      update: { isApproved: true },
      tsIds: getSelectedRows(gridRef)
        .map(row => row.tsId)
        .filter(tsId => (ignoreTsIds ? !ignoreTsIds.includes(tsId) : true)),
    },
    gridRef?.current?.api,
    lastActionStackHook,
    step,
  )
}

export const ApproveButton: React.FC = () => {
  const [warning, setWarning] = useState(false)
  const [badMatches, setBadMatches] = useState<StudyLinkType[]>([])

  const { props } = useContext(Grooming2Context)
  useEffect(() => {
    function handleKey(e: KeyboardEvent) {
      if (e.keyCode === 65 && e.altKey) {
        handleApprove(props!)
      }
    }
    document.addEventListener('keyup', handleKey)
    return function cleanUp() {
      document.removeEventListener('keyup', handleKey)
    }
  }, [props])

  const handlePressApprove = async () => {
    const selectedRows = getSelectedRows(props!.gridRef)
    const curBadMatches = selectedRows.filter(row => {
      if (row) {
        const similarity = getMatchedSimilarityScore(row, props!.catalog)
        return similarity && similarity < 0.9
      }
      return false
    })

    setBadMatches(curBadMatches)
    if (curBadMatches.length === 0) {
      handleApprove(props!)
    } else {
      setWarning(true)
    }
  }

  return (
    <>
      <Button icon="check" type="primary" onClick={handlePressApprove}>
        <u>A</u>pprove
      </Button>
      <Modal
        visible={warning}
        width={800}
        footer={[
          <Button
            type="primary"
            onClick={() => {
              handleApprove(props!)
              setWarning(false)
            }}>
            Approve All
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              handleApprove(
                props!,
                badMatches.map(m => m.tsId),
              )
              setWarning(false)
            }}>
            Approve Good Matches
          </Button>,
          <Button onClick={() => setWarning(false)}>Cancel</Button>,
        ]}>
        <div>
          There {badMatches.length > 1 ? 'are' : 'is'}{' '}
          <strong>
            {badMatches.length} bad {badMatches.length > 1 ? 'matches' : 'match'}
          </strong>{' '}
          about to be approved, do you want to continue?
          <Table
            scroll={{ x: 750, y: 230 }}
            style={{ marginTop: '16px' }}
            pagination={false}
            size="small"
            columns={[
              {
                title: 'Sim score',
                render: (_v, record) => getMatchedSimilarityScore(record, props!.catalog),
              },
              { title: 'Matched Supplier', render: (_v, record) => getManufacturerString(record, props!.catalog) },
              {
                title:
                  props!.catalog === StudyLinkCatalogEnum.Stan
                    ? 'Matched Part'
                    : props!.catalog === StudyLinkCatalogEnum.Gudid
                    ? 'Catalog Number'
                    : 'SKU',
                render: (_v, record) => getPartString(record, props!.catalog),
              },
              { title: 'IN Vendor', dataIndex: 'vendor' },
              { title: 'IN Vendor Item', dataIndex: 'venitem' },
              { title: 'IN Manufacturer', dataIndex: 'manufacturer' },
              { title: 'IN Manufacturer Item', dataIndex: 'mfgitem' },
            ]}
            dataSource={badMatches}
          />
        </div>
      </Modal>
    </>
  )
}

export const handleTwinApprove = (props: Grooming2ContextProps) => {
  const { studyId, catalog, gridRef, lastActionStackHook, step } = props
  return bulkUpdateStudyLinks(
    {
      studyId,
      catalog,
      update: { isTwinApproved: true },
      tsIds: getSelectedRows(gridRef).map(row => row.tsId),
    },
    gridRef?.current?.api,
    lastActionStackHook,
    step,
  )
}

export const TwinApproveButton: React.FC = () => {
  const { props } = useContext(Grooming2Context)
  useEffect(() => {
    function handleKey(e: KeyboardEvent) {
      if (e.keyCode === 65 && e.altKey) {
        handleTwinApprove(props!)
      }
    }
    document.addEventListener('keyup', handleKey)
    return function cleanUp() {
      document.removeEventListener('keyup', handleKey)
    }
  }, [props])

  return (
    <Button icon="check" type="primary" onClick={() => handleTwinApprove(props!)}>
      <u>A</u>pprove
    </Button>
  )
}

export const handleIgnore = (props: Grooming2ContextProps) => {
  const { studyId, catalog, gridRef, lastActionStackHook, step } = props
  return bulkUpdateStudyLinks(
    {
      studyId,
      catalog,
      tsIds: getSelectedRows(gridRef).map(row => row.tsId),
      update: {
        ignoreRow: true,
      },
    },
    gridRef?.current?.api,
    lastActionStackHook,
    step,
  )
}

const handleHide = (props: Grooming2ContextProps, toShow: boolean) => {
  const { studyId, catalog, gridRef, lastActionStackHook, step } = props
  return bulkUpdateStudyLinks(
    {
      studyId,
      catalog,
      tsIds: getSelectedRows(gridRef).map(row => row.tsId),
      update: {
        hide: !toShow,
      },
    },
    gridRef?.current?.api,
    lastActionStackHook,
    step,
  )
}

export const UnIgnoreRowButton: React.FC = () => {
  const { props } = useContext(Grooming2Context)
  const { studyId, gridRef } = props!
  return (
    <Button
      onClick={() => {
        bulkUpdateStudyLinks(
          {
            studyId,
            tsIds: getSelectedRows(gridRef).map(row => row.tsId),
            update: {
              ignoreRow: false,
            },
          },
          gridRef?.current?.api,
        )
      }}>
      Un-Ignore
    </Button>
  )
}

const IgnoreButton: React.FC = () => {
  const { props } = useContext(Grooming2Context)
  useEffect(() => {
    function handleKey(e: KeyboardEvent) {
      if (e.keyCode === 73 && e.altKey) {
        handleIgnore(props!)
      }
    }
    document.addEventListener('keyup', handleKey)
    return function cleanUp() {
      document.removeEventListener('keyup', handleKey)
    }
  }, [props])

  return (
    <Button icon="branches" onClick={() => handleIgnore(props!)}>
      <u>I</u>gnore
    </Button>
  )
}

const HideButton: React.FC<{ toShow: boolean }> = ({ toShow = false }) => {
  const { props } = useContext(Grooming2Context)
  useEffect(() => {
    function handleKey(e: KeyboardEvent) {
      if (e.keyCode === 72 && e.altKey) {
        handleHide(props!, toShow)
      }
    }
    document.addEventListener('keyup', handleKey)
    return function cleanUp() {
      document.removeEventListener('keyup', handleKey)
    }
  }, [props, toShow])

  return (
    <Button icon="eye-invisible" onClick={() => handleHide(props!, toShow)}>
      {toShow ? (
        <span>
          <u>[H]</u>Show
        </span>
      ) : (
        <span>
          <u>H</u>ide
        </span>
      )}
    </Button>
  )
}

export const TwoSideFullDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2em;
  justify-content: space-between;

  & > div {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
`

export const doBulkRestoreOriginalFieldsStudyLinks = async (
  input: BulkRestoreOriginalFieldsStudyLinkInput,
  gridRef: React.RefObject<AgGridRefProps>,
) => {
  const gridApi = gridRef.current && gridRef.current.api
  gridApi && gridApi.showLoadingOverlay()
  try {
    const chunks =
      input.tsIds && input.tsIds.length > ID_PER_UPDATE_CHUNK
        ? Array.from(Array(Math.ceil(input.tsIds.length / ID_PER_UPDATE_CHUNK)).keys()).map(i => ({
            ...input,
            tsIds: input.tsIds!.slice(
              i * ID_PER_UPDATE_CHUNK,
              Math.min((i + 1) * ID_PER_UPDATE_CHUNK, input.tsIds!.length),
            ),
          }))
        : [input]
    const results = await Promise.all(chunks.map(chunk => bulkRestoreOriginalFieldsStudyLinkMutation({ input: chunk })))

    results.forEach(result => {
      if (result) {
        const { data, errors } = result
        if (errors) {
          message.error('Update failed, please reload web page!')
        }
        if (data) {
          const updated = data.bulkRestoreOriginalFieldsStudyLink
          updateUpdatedRows(updated, gridApi)
        }
      }
    })
    gridApi && gridApi.deselectAll()
    message.success('Update success')
  } catch (e) {
    message.error(e.message)
  } finally {
    gridApi && gridApi.hideOverlay()
  }
}

export const IgnoreSupplierButtons: React.FC = () => {
  const { props } = useContext(Grooming2Context)
  const { studyId, gridRef } = props!
  return (
    <React.Fragment>
      <Button
        onClick={() =>
          bulkUpdateStudyLinks(
            {
              update: {
                ignoreVendor: false,
                ignoreManufacturer: false,
              },
              studyId,
              tsIds: getSelectedRows(gridRef).map(row => row.tsId),
            },
            gridRef?.current?.api,
          )
        }>
        Remove ignored supplier
      </Button>
    </React.Fragment>
  )
}

export const BlacklistSupplierButtons: React.FC = () => {
  const { props } = useContext(Grooming2Context)
  const { studyId, gridRef } = props!
  return (
    <React.Fragment>
      <Button
        onClick={() =>
          bulkUpdateStudyLinks(
            {
              studyId,
              update: {
                blacklistManufacturer: false,
                blacklistVendor: false,
              },
              tsIds: getSelectedRows(gridRef).map(row => row.tsId),
            },
            gridRef?.current?.api,
          )
        }>
        Remove blacklist supplier
      </Button>
    </React.Fragment>
  )
}

export const BlacklistStrippedItemButtons: React.FC = () => {
  const { props } = useContext(Grooming2Context)
  const { studyId, gridRef } = props!
  return (
    <React.Fragment>
      <Button
        onClick={() =>
          bulkUpdateStudyLinks(
            {
              studyId,
              update: {
                blacklistMfgitem: false,
                blacklistVenitem: false,
              },
              tsIds: getSelectedRows(gridRef).map(row => row.tsId),
            },
            gridRef?.current?.api,
          )
        }>
        Remove blacklist Items
      </Button>
    </React.Fragment>
  )
}

export const ApprovedItemButtons: React.FC = () => {
  const { props } = useContext(Grooming2Context)
  const { studyId, gridRef, catalog } = props!
  return (
    <Button
      onClick={() =>
        bulkUpdateStudyLinks(
          {
            studyId,
            catalog,
            update: { isApproved: false },
            tsIds: getSelectedRows(gridRef).map(row => row.tsId),
          },
          gridRef?.current?.api,
        )
      }>
      Undo approval
    </Button>
  )
}

export const TwinApprovedItemButtons: React.FC = () => {
  const { props } = useContext(Grooming2Context)
  const { studyId, gridRef, catalog } = props!
  return (
    <Button
      onClick={() =>
        bulkUpdateStudyLinks(
          {
            studyId,
            catalog,
            update: { isTwinApproved: false },
            tsIds: getSelectedRows(gridRef).map(row => row.tsId),
          },
          gridRef?.current?.api,
        )
      }>
      Undo approval
    </Button>
  )
}

export const TwinMatchButtons: React.FC = () => {
  const { props } = useContext(Grooming2Context)
  const { studyId, gridRef, catalog, lastActionStackHook } = props!
  return (
    <>
      <Button
        onClick={() => {
          doBulkMoveToTwinMatch(
            {
              studyId,
              tsIds: getSelectedRows(gridRef).map(row => row.tsId),
              catalog,
              revert: true,
            },
            gridRef,
            lastActionStackHook,
            catalog,
          )
        }}>
        Move to HI Match
      </Button>
      <TwinApproveButton />
    </>
  )
}

export const SupplierMatchingButtons: React.FC = () => {
  const [replacePosition, setReplacePosition] = useState('Leading')
  const [numberOfZeros, setNumberOfZeros] = useState(0)
  const [findTerm, setFindTerm] = useState<string>()
  const [replaceTerm, setReplaceTerm] = useState<string>()
  const [filterType, setFilterType] = useState<StringFilterType>(StringFilterType.Regex)
  const [findAndReplaceColumn, setFindAndReplaceColumn] = useState<string>('vendor')
  return (
    <React.Fragment>
      <RetryButton
        find={findTerm}
        replace={replaceTerm}
        filterType={filterType}
        isLeadingZeros={replacePosition === 'Leading'}
        numberOfZeros={numberOfZeros}
        field={findAndReplaceColumn}
      />
      <Select value={replacePosition} onChange={(v: string) => setReplacePosition(v)}>
        <Select.Option key={1} value="Leading">
          Leading 0
        </Select.Option>
        <Select.Option key={2} value="Trailing">
          Trailing 0
        </Select.Option>
      </Select>
      <Select value={numberOfZeros} onChange={(v: number) => setNumberOfZeros(v)}>
        {[0, 1, 2, 3, 4].map(i => (
          <Select.Option key={i} value={i}>
            {i}
          </Select.Option>
        ))}
      </Select>
      <span>and</span>
      <Input.Group compact style={{ marginRight: '1em' }}>
        <Select
          style={{ width: 150 }}
          placeholder="Select column"
          value={findAndReplaceColumn}
          onChange={v => setFindAndReplaceColumn(v)}>
          <Select.Option key={1} value="vendor">
            IN Vendor
          </Select.Option>
          <Select.Option key={2} value="venitem">
            IN Venitem
          </Select.Option>
        </Select>
        <Select style={{ width: 150 }} value={filterType} onChange={v => setFilterType(v)}>
          <Select.Option key={StringFilterType.Contains} value={StringFilterType.Contains}>
            {StringFilterType.Contains.toString()}
          </Select.Option>
          <Select.Option key={StringFilterType.EndsWith} value={StringFilterType.EndsWith}>
            {StringFilterType.EndsWith.toString()}
          </Select.Option>
          <Select.Option key={StringFilterType.StartsWith} value={StringFilterType.StartsWith}>
            {StringFilterType.StartsWith.toString()}
          </Select.Option>
          <Select.Option key={StringFilterType.Regex} value={StringFilterType.Regex}>
            {StringFilterType.Regex.toString()}
          </Select.Option>
        </Select>

        <Input style={{ width: 150 }} placeholder="Find" value={findTerm} onChange={e => setFindTerm(e.target.value)} />
        <Input
          style={{ width: 150 }}
          placeholder="Replace"
          value={replaceTerm}
          onChange={e => setReplaceTerm(e.target.value)}
        />
      </Input.Group>
    </React.Fragment>
  )
}

const handleAddNewPartFromStudyLink = ({ gridRef }: Grooming2ContextProps) => {
  const selectedRows: StudyLinkType[] = getSelectedRows(gridRef)
  if (selectedRows && selectedRows.length) {
    createNewPartsFromStudyTransaction({
      inputs: selectedRows.map(currentRow => ({
        manufacturerId: currentRow.stanManufacturerId,
        partNumber: currentRow.venitem || currentRow.mfgitem,
        partName: currentRow.description || '',
        strippedPartNumber: transformForBamfString(currentRow.venitem || currentRow.mfgitem),
      })),
    })
      .then(() => {
        gridRef.current && gridRef.current.api.deselectAll()
        message.success('Added new part to queued part')
      })
      .catch(e => {
        message.error(e.message)
      })
  }
}

const AddToQueuedPartButton: React.FC = () => {
  const { props } = useContext(Grooming2Context)
  useEffect(() => {
    function handleKey(e: KeyboardEvent) {
      if (e.keyCode === 73 && e.altKey) {
        handleAddNewPartFromStudyLink(props!)
      }
    }
    document.addEventListener('keyup', handleKey)
    return function cleanUp() {
      document.removeEventListener('keyup', handleKey)
    }
  }, [props])
  return <Button onClick={() => handleAddNewPartFromStudyLink(props!)}>Add to queued Part</Button>
}

export const doBulkUpdateBamfToHIMatches = async (
  input: BulkBamfToHiMatchingStudyLinkInput,
  gridRef: React.RefObject<AgGridReact & { api: GridApi; step?: StudyTransactionState }>,
  lastActionStackHook?: LastActionStackHookReturnType,
  catalog?: StudyLinkCatalogEnum,
) => {
  const gridApi = gridRef.current && gridRef.current.api
  gridApi && gridApi.showLoadingOverlay()
  try {
    const chunks =
      input.tsIds && input.tsIds.length > ID_PER_UPDATE_CHUNK
        ? Array.from(Array(Math.ceil(input.tsIds.length / ID_PER_UPDATE_CHUNK)).keys()).map(i => ({
            ...input,
            tsIds: input.tsIds.slice(
              i * ID_PER_UPDATE_CHUNK,
              Math.min((i + 1) * ID_PER_UPDATE_CHUNK, input.tsIds!.length),
            ),
          }))
        : [input]
    const results = await Promise.all(
      chunks.map(chunk =>
        bulkUpdateBamfStudyLinksToHIMatchesMutation(
          { input: chunk },
          {
            refetchQueries: [
              {
                query: STUDY_QUERY,
                variables: {
                  id: input.studyId,
                },
              },
            ],
          },
        ),
      ),
    )

    results.forEach(result => {
      if (result) {
        const { data, errors } = result
        if (errors) {
          message.error('Update failed, please reload web page!')
        }
        if (data) {
          const updated = data.bulkUpdateBamfStudyLinksToHIMatches
          updateUpdatedRows(updated, gridApi)
        }
      }
    })
    if (lastActionStackHook && catalog) {
      const { updateLastActionStack } = lastActionStackHook
      updateLastActionStack(catalog, StudyTransactionState.BamfMatching, {
        payload: input,
        action: 'moveToHIMatch',
      })
    }
    gridApi && gridApi.deselectAll()
    message.success('Update success')
  } catch (e) {
    message.error(e.message)
  } finally {
    gridApi && gridApi.hideOverlay()
  }
}

export const bulkUpdateStudyLinks = async (
  input: BulkUpdateStudyLinkInput,
  gridApi?: GridApi | null,
  lastActionStackHook?: LastActionStackHookReturnType,
  step?: StudyTransactionState,
  catalog?: StudyLinkCatalogEnum,
  skipNotify?: boolean,
) => {
  gridApi && gridApi.showLoadingOverlay()
  try {
    const chunks =
      input.tsIds && input.tsIds.length > ID_PER_UPDATE_CHUNK
        ? Array.from(Array(Math.ceil(input.tsIds.length / ID_PER_UPDATE_CHUNK)).keys()).map(i => ({
            ...input,
            tsIds: input.tsIds!.slice(
              i * ID_PER_UPDATE_CHUNK,
              Math.min((i + 1) * ID_PER_UPDATE_CHUNK, input.tsIds!.length),
            ),
          }))
        : [input]
    const results = await Promise.all(
      chunks
        .map(chunk => {
          if (chunk.update.isApproved !== undefined) {
            return { ...chunk, update: { isApproved: chunk.update.isApproved } }
          }
          return chunk
        })
        .map(chunk =>
          bulkUpdateStudyLinksMutation(
            { input: chunk },
            {
              refetchQueries:
                input.update.isApproved !== undefined
                  ? [
                      {
                        query: STUDY_QUERY,
                        variables: { id: input.studyId },
                      },
                    ]
                  : [],
            },
          ),
        ),
    )

    results.forEach(result => {
      if (result) {
        const { data, errors } = result
        if (errors) {
          message.error('Update failed, please reload web page!')
        }
        if (data) {
          const updated = data.bulkUpdateStudyLinks
          updateUpdatedRows(updated, gridApi)
        }
      }
    })
    if (lastActionStackHook) {
      const { updateLastActionStack } = lastActionStackHook
      if (
        (catalog || input.catalog) &&
        step &&
        (input.update.isApproved !== undefined || input.update.ignoreRow !== undefined)
      ) {
        updateLastActionStack(catalog || input.catalog!, step, {
          payload: input,
          action: 'bulkUpdateStudyLinks',
        })
      }
    }
    gridApi && gridApi.deselectAll()
    if (!skipNotify) {
      message.success('Update success')
    }
  } catch (e) {
    message.error(e.message)
  } finally {
    gridApi && gridApi.hideOverlay()
  }
}

export const doBulkUpdateStudyLinks = async (
  input: BulkUpdateStudyLinkInput,
  gridRef?: React.RefObject<AgGridReact & { api: GridApi; step?: StudyTransactionState }>,
  lastActionStackHook?: LastActionStackHookReturnType,
  step?: StudyTransactionState,
  skipNotify?: boolean,
) => {
  const gridApi = gridRef?.current && gridRef.current.api
  return bulkUpdateStudyLinks(input, gridApi, lastActionStackHook, step, undefined, skipNotify)
}

export const doBulkMoveToTwinMatch = async (
  input: BulkMoveToTwinMatchStudyLinkInput,
  gridRef: React.RefObject<AgGridReact & { api: GridApi; step?: StudyTransactionState }>,
  lastActionStackHook?: LastActionStackHookReturnType,
  catalog?: StudyLinkCatalogEnum,
) => {
  const gridApi = gridRef.current && gridRef.current.api
  gridApi && gridApi.showLoadingOverlay()
  try {
    const chunks =
      input.tsIds && input.tsIds.length > ID_PER_UPDATE_CHUNK
        ? Array.from(Array(Math.ceil(input.tsIds.length / ID_PER_UPDATE_CHUNK)).keys()).map(i => ({
            ...input,
            tsIds: input.tsIds.slice(
              i * ID_PER_UPDATE_CHUNK,
              Math.min((i + 1) * ID_PER_UPDATE_CHUNK, input.tsIds!.length),
            ),
          }))
        : [input]
    const results = await Promise.all(chunks.map(chunk => bulkMoveToTwinMatchMutation({ input: chunk })))

    results.forEach(result => {
      if (result) {
        const { data, errors } = result
        if (errors) {
          message.error('Update failed, please reload web page!')
        }
        if (data) {
          const updated = data.bulkMoveToTwinMatch
          updateUpdatedRows(updated, gridApi)
        }
      }
    })
    if (lastActionStackHook && catalog) {
      const { updateLastActionStack } = lastActionStackHook
      updateLastActionStack(catalog, StudyTransactionState.SupplierMatching, {
        payload: input,
        action: 'moveToTwinMatch',
      })
    }
    gridApi && gridApi.deselectAll()
    message.success('Update success')
  } catch (e) {
    message.error(e.message)
  } finally {
    gridApi && gridApi.hideOverlay()
  }
}

export const doRematchStudyLinks = async (
  { tsIds, ...rematchInput }: Omit<RematchStudyLinksInput, 'studyId'>,
  gridRef: React.RefObject<AgGridRefProps>,
  studyId: number,
) => {
  const gridApi = gridRef.current && gridRef.current.api
  gridApi && gridApi.hideOverlay()
  gridApi && gridApi.showLoadingOverlay()
  try {
    const obs = from(tsIds).pipe(
      bufferCount(ID_PER_UPDATE_CHUNK),
      mergeMap(
        chunk =>
          rematchStudyLinksMutation({
            input: {
              tsIds: chunk,
              studyId,
              ...rematchInput,
            },
          }),
        4,
      ),
      rxMap(result => {
        if (result) {
          const { data, errors } = result
          if (errors) {
            message.error('Update failed, please reload web page!')
          }
          if (data) {
            const updated = data.rematchStudyLinks
            updateUpdatedRows(updated, gridApi)
          }
        }
      }),
    )
    await lastValueFrom(obs)
    gridApi && gridApi.deselectAll()
    message.success('Update success')
  } catch (e) {
    message.error(e.message)
  } finally {
    gridApi && gridApi.hideOverlay()
  }
}

export const getSelectedRows = (gridRef?: React.RefObject<AgGridRefProps>): StudyLinkType[] => {
  return gridRef && gridRef.current ? gridRef.current.api.getSelectedRows() : []
}

const updateUpdatedRows = (updated: StudyLinkType[], gridApi?: GridApi | null) => {
  if (gridApi) {
    gridApi.applyTransaction({
      update: updated,
    })
  }
}
