import {
  BATCH_NEW_PART_LIST_QUERY,
  clearNewPartsTableMutation,
  CountQueuedParts,
  COUNT_QUEUED_PARTS,
  parsePartsMutation,
  QueuedPartsCount,
} from '@curvo/apollo'
import { Button, Form, Icon, Input, message, Popconfirm, Skeleton, Steps } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { NewPartsStepContentProps, StyledPaginationWrapper, UploadContentWrapper } from './common'
import { BatchNewPartSelect } from './components/BatchNewPartSelect'
import { NewPartsDataStep } from './components/NewPartsDataStep'
import { S3Uploader } from './components/Panel/S3Uploader'

const UploadStep: React.FC<
  Omit<NewPartsStepContentProps, 'batchName'> & {
    hasData?: boolean
  }
> = () => {
  const [s3Key, setS3Key] = useState<string>('')
  const [isProcessing, setIsProcessing] = useState(false)
  const [batchName, setBatchName] = useState<string>('')

  const onProcessFile = () => {
    message.info('Processing data...')
    setIsProcessing(true)
    parsePartsMutation(
      { input: { id: s3Key, batchName } },
      {
        refetchQueries: [{ query: COUNT_QUEUED_PARTS }, { query: BATCH_NEW_PART_LIST_QUERY }],
        awaitRefetchQueries: true,
      },
    )
      .catch(e => message.error(e.message))
      .then(() => {
        message.success('Bulk update file is successfully processed')
      })
  }

  return (
    <UploadWrapper>
      <Form.Item label="Batch Name">
        <Input
          placeholder="Enter your batch name"
          value={batchName}
          onChange={e => setBatchName(e.target.value)}
          disabled={isProcessing}
          style={{ width: 400 }}
        />
      </Form.Item>
      <S3Uploader
        onChange={e => {
          if (!batchName) {
            setBatchName(e.file.name)
          }
        }}
        onUploaded={key => setS3Key(key)}
        disabled={isProcessing}
        style={{ width: 400 }}
      />
      <StyledPaginationWrapper>
        <Button onClick={() => onProcessFile()} disabled={!s3Key || !batchName || isProcessing}>
          {isProcessing && <Icon type="sync" spin />}
          {isProcessing ? 'Processing' : 'Process'}
        </Button>
        <Button
          type="ghost"
          icon="download"
          onClick={() => window.open('/Update_Parts_Sample.xlsx')}
          style={{ marginRight: 16 }}>
          Download Template
        </Button>
      </StyledPaginationWrapper>
    </UploadWrapper>
  )
}

export const NewParts = () => {
  return (
    <CountQueuedParts pollInterval={10000}>
      {({ data, loading, error }) => {
        if (error) {
          message.error(error.message)
        }
        if (loading) {
          return <Skeleton active />
        }
        return <NewPartsComponent data={data && data.countQueuedParts} />
      }}
    </CountQueuedParts>
  )
}

const NewPartsComponent: React.FC<{
  data?: QueuedPartsCount
}> = ({ data }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [discarding, setDiscarding] = useState(false)

  const [selectedBatchName, setSelectedBatchName] = useState<string>()

  useEffect(() => {
    if (!data || data.total === 0) {
      setCurrentStep(0)
    } else {
      setCurrentStep(1)
    }
  }, [data])
  const handleDiscard = () => {
    setDiscarding(true)
    if (selectedBatchName) {
      clearNewPartsTableMutation(
        { batchName: selectedBatchName },
        { refetchQueries: [{ query: COUNT_QUEUED_PARTS }, { query: BATCH_NEW_PART_LIST_QUERY }] },
      )
        .then(() => {
          message.success('Successfully cleared')
          setSelectedBatchName(undefined)
        })
        .catch(e => {
          message.error(e.message)
        })
        .finally(() => {
          setDiscarding(false)
        })
    }
  }
  const hasData = data && data.total > 0

  return (
    <UploadContentWrapper>
      <React.Fragment>
        <Steps current={currentStep} onChange={setCurrentStep}>
          <Steps.Step title="Upload" icon={<Icon type="upload" />} />
          <Steps.Step
            title="Handle Errors"
            disabled={!hasData}
            icon={<Icon type="exclamation-circle" />}
            description={hasData ? `${data!.withErrors} records` : undefined}
          />
          <Steps.Step
            title="Approve"
            disabled={!hasData}
            icon={<Icon type="check-circle" />}
            description={hasData ? `${data!.valid} records` : undefined}
          />
          <Steps.Step
            title="Finish"
            icon={<Icon type="import" />}
            disabled={!hasData}
            description={hasData ? `${data!.total - data!.valid - data!.withErrors} records` : undefined}
          />
        </Steps>
        <StyledStepContent>
          {currentStep === 0 && (
            <UploadStep setCurrentStep={setCurrentStep} currentStep={currentStep} hasData={hasData} />
          )}
          {(currentStep === 1 || currentStep === 2 || currentStep === 3) && (
            <>
              <BatchNewPartSelect
                value={selectedBatchName}
                onChange={newBatchName => setSelectedBatchName(newBatchName)}
              />
              {selectedBatchName && (
                <NewPartsDataStep
                  batchName={selectedBatchName}
                  setCurrentStep={setCurrentStep}
                  currentStep={currentStep}
                />
              )}
            </>
          )}
        </StyledStepContent>
      </React.Fragment>
      <StyledPaginationWrapper>
        <Popconfirm title="Do you want to discard all queued parts?" onConfirm={handleDiscard}>
          <Button type="danger" icon="delete" disabled={discarding || !selectedBatchName}>
            Discard all
          </Button>
        </Popconfirm>
      </StyledPaginationWrapper>
    </UploadContentWrapper>
  )
}

const StyledStepContent = styled.div`
  margin-top: 20px;
`

const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
