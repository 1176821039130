import React, { useImperativeHandle, useEffect, useState } from 'react'
import { StudyTransactionType, GicTypeOne } from '@curvo/apollo'
import { GICsTypeOneSelect, AllowedGICsTypeOneSelect } from '../../Update/components/Select/GICTypeOneSelect'
import { AgWrapperPropsType } from './ManufacturerSelectAgWrapper'

export const GicTypeOneAgWrapper = React.forwardRef<{}, AgWrapperPropsType<StudyTransactionType>>((params, ref) => {
  const { data, api } = params

  const value = data
    ? data.typeOneId
      ? {
          key: data.typeOneId,
          label: data.typeOneName,
        }
      : undefined
    : undefined
  const [selectedGicTypeOne, setSelectedGicTypeOne] = useState<GicTypeOne>()

  useImperativeHandle(ref, () => ({
    getValue: () => selectedGicTypeOne || undefined,
  }))

  useEffect(() => {
    if (selectedGicTypeOne) {
      api.stopEditing()
    }
  }, [selectedGicTypeOne, api])

  return <GICsTypeOneSelect value={value} onChangeFullValue={v => setSelectedGicTypeOne(v)} />
})

export const AllowedGicTypeOneAgWrapper = React.forwardRef<{}, AgWrapperPropsType<StudyTransactionType>>(
  (params, ref) => {
    const { data, api } = params

    const value = data
      ? data.typeOneId
        ? {
            key: data.typeOneId,
            label: data.typeOneName,
          }
        : undefined
      : undefined
    const [selectedGicTypeOne, setSelectedGicTypeOne] = useState<GicTypeOne | null>()

    useImperativeHandle(ref, () => ({
      getValue: () => selectedGicTypeOne,
    }))

    useEffect(() => {
      if (selectedGicTypeOne !== undefined) {
        api.stopEditing()
      }
    }, [selectedGicTypeOne, api])

    return (
      <AllowedGICsTypeOneSelect
        gicId={data.gicId || undefined}
        value={value}
        onChangeFullValue={v => {
          setSelectedGicTypeOne(v || null)
        }}
      />
    )
  },
)
