import { Material, MaterialsData } from '@curvo/apollo'
import LoadingIndicator from '@curvo/common-ui/dist/components/Loading/Spinner'
import Select, { LabeledValue, SelectProps } from 'antd/lib/select'
import { debounce } from 'lodash'
import React, { useState } from 'react'
import { StyledSelect, StyledSelectPropsType } from './common'

type MaterialSelectProps = SelectProps<LabeledValue> &
  StyledSelectPropsType & { onChangeFullValue?: (v?: Material) => void }

export const MaterialSelect: React.FC<MaterialSelectProps> = ({ onChange, onChangeFullValue, ...props }) => {
  const [searchText, setSearchText] = useState('')
  return (
    <MaterialsData fetchPolicy="network-only" variables={{ searchText }}>
      {({ data, loading }) => (
        <StyledSelect
          onSearch={debounce(setSearchText, 800)}
          loading={loading}
          showSearch
          labelInValue
          filterOption={false}
          showArrow={false}
          defaultActiveFirstOption={false}
          placeholder="Select Material"
          notFoundContent={loading ? <LoadingIndicator /> : 'No Data'}
          onChange={(v, com) => {
            if (onChange) {
              onChange(v, com)
            }
            if (onChangeFullValue && v) {
              const selectedMaterial =
                data && data.materials && data.materials.edges.find(edge => edge.node.id === v.key)
              onChangeFullValue(selectedMaterial && selectedMaterial.node)
            }
          }}
          {...props}>
          {data &&
            data.materials &&
            data.materials.edges.map(edge => (
              <Select.Option
                key={
                  props.fullData ? JSON.stringify(edge.node) : edge.node.id
                }>{`${edge.node.id} | ${edge.node.name}`}</Select.Option>
            ))}
        </StyledSelect>
      )}
    </MaterialsData>
  )
}
