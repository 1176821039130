import { ManufacturerType } from '@curvo/apollo'
import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import { AgWrapperPropsType } from './ManufacturerSelectAgWrapper'

export const ManufacturerTypeSelect: React.FC<SelectProps<ManufacturerType>> = props => (
  <Select placeholder="Select Type" {...props}>
    <Select.Option key="O" value={ManufacturerType.O}>
      Ortho
    </Select.Option>
    <Select.Option key="P" value={ManufacturerType.P}>
      PPI
    </Select.Option>
    <Select.Option key="I" value={ManufacturerType.I}>
      Instruments
    </Select.Option>
    <Select.Option key="L" value={ManufacturerType.L}>
      Laboratory
    </Select.Option>
    <Select.Option key="Other" value={ManufacturerType.Other}>
      Other
    </Select.Option>
    <Select.Option key="Unknown" value={ManufacturerType.Unknown}>
      Unknown NO BL
    </Select.Option>
    <Select.Option key="B" value={ManufacturerType.B}>
      Blacklist
    </Select.Option>
    <Select.Option key="G" value={ManufacturerType.G}>
      Ignore
    </Select.Option>
    <Select.Option key="R" value={ManufacturerType.R}>
      Pharmaceuticals
    </Select.Option>
    <Select.Option key="C" value={ManufacturerType.C}>
      Commodities
    </Select.Option>
    <Select.Option key="D" value={ManufacturerType.D}>
      Distributor
    </Select.Option>
  </Select>
)

export const ManufacturerTypeSelectAgWrapper = React.forwardRef<{}, AgWrapperPropsType<any>>((params, ref) => {
  const { value, api } = params

  const [selectedType, setSelectedType] = useState<ManufacturerType | null>()

  useImperativeHandle(ref, () => ({
    getValue: () => selectedType,
  }))

  useEffect(() => {
    if (selectedType !== undefined) {
      api.stopEditing()
    }
  }, [selectedType, api])

  return (
    <ManufacturerTypeSelect
      style={{ width: 164 }}
      value={value}
      allowClear
      onChange={v => {
        if (v) {
          setSelectedType(v)
        } else {
          setSelectedType(null)
        }
      }}
    />
  )
})
