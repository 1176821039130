import { GridApi, ValueSetterParams } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact'
import { StudyLinkCatalogEnum, StudyLinkType, StudyTransactionState } from '@curvo/apollo'
import { message } from 'antd'
import { isEqual } from 'lodash'
import React, { RefObject } from 'react'
import { bulkUpdateStudyLinks } from './Buttons'
import { LastActionStackHookReturnType } from './useLastActionHook'

export const matchedManufacturerValueSetter =
  (catalog: StudyLinkCatalogEnum, studyId: number, gridApi?: GridApi | null, gridRef?: RefObject<AgGridRefProps>) =>
  ({ newValue, oldValue, node, data }: Pick<ValueSetterParams, 'newValue' | 'node' | 'data'> & { oldValue?: any }) => {
    if (gridRef?.current?.onRangeFilling) {
      return false
    }
    const tsIds = node?.group ? node.childrenAfterFilter?.map(row => row.data.tsId) : [data.tsId]
    if (newValue !== undefined && !isEqual(newValue, oldValue)) {
      if (catalog === StudyLinkCatalogEnum.Stan) {
        if (newValue === null || newValue.key) {
          // remove or selected new manufacturer
          bulkUpdateStudyLinks(
            {
              tsIds,
              studyId,
              catalog,
              update: { stanManufacturerId: newValue ? newValue.key : newValue },
            },
            gridApi,
          )
          return true
        }
      } else if (catalog === StudyLinkCatalogEnum.Medline) {
        bulkUpdateStudyLinks(
          {
            tsIds,
            studyId,
            catalog,
            update: { medManufacturer: newValue },
          },
          gridApi,
        )
        return true
      } else if (catalog === StudyLinkCatalogEnum.Gudid) {
        bulkUpdateStudyLinks(
          {
            tsIds,
            studyId,
            catalog,
            update: { gudidManufacturer: newValue },
          },
          gridApi,
        )
      }
    }

    return false
  }

export const matchedPartValueSetter =
  (
    props: Pick<Grooming2ContextProps, 'studyId' | 'catalog'> & {
      gridApi?: GridApi | null
      gridRef?: RefObject<AgGridRefProps>
    },
  ) =>
  ({ newValue, node, oldValue, data }: Pick<ValueSetterParams, 'newValue' | 'node' | 'data'> & { oldValue?: any }) => {
    const { studyId, catalog, gridApi, gridRef } = props
    if (node?.group) {
      message.error('You can only edit Part for one study link at a time')
      return false
    }

    if (gridRef?.current?.onRangeFilling) {
      return false
    }

    if (newValue !== undefined && !isEqual(newValue, oldValue)) {
      if (catalog === StudyLinkCatalogEnum.Stan) {
        bulkUpdateStudyLinks(
          {
            studyId,
            tsIds: [data.tsId],
            catalog,
            update: {
              matchStanId: newValue && newValue.key,
            },
          },
          gridApi,
        )
      } else {
        bulkUpdateStudyLinks(
          {
            studyId,
            tsIds: [data.tsId],
            catalog,
            update: {
              [catalog === StudyLinkCatalogEnum.Gudid ? 'matchGudidId' : 'matchMedId']: newValue && newValue.id,
            },
          },
          gridApi,
        )
      }

      return true
    }
    return false
  }

export const getMatchedSimilarityScore = (data: StudyLinkType, catalog: StudyLinkCatalogEnum) => {
  switch (catalog) {
    case StudyLinkCatalogEnum.Stan: {
      if (data && data.stanSimilarity) {
        return Math.round(data.stanSimilarity * 100) / 100
      }
      return null
    }
    case StudyLinkCatalogEnum.Medline: {
      return data && data.medSimilarity ? Math.round(data.medSimilarity * 100) / 100 : null
    }
    case StudyLinkCatalogEnum.Gudid:
    default: {
      return data && data.gudidSimilarity ? Math.round(data.gudidSimilarity * 100) / 100 : null
    }
  }
}

export type AgGridRefProps = AgGridReact & {
  api: GridApi
  step?: StudyTransactionState
  onRangeFilling?: boolean
  catalog?: StudyLinkCatalogEnum
}

export type Grooming2ContextProps = {
  studyId: number
  catalog: StudyLinkCatalogEnum
  gridRef: React.RefObject<AgGridRefProps & { api: GridApi; step?: StudyTransactionState }>
  step?: StudyTransactionState
  lastActionStackHook: LastActionStackHookReturnType
}

export const Grooming2Context = React.createContext<{ props?: Grooming2ContextProps }>({})

export const STUDY_TRANSACTION_STATE_OPTION_MAPPING = {
  HI: { value: StudyTransactionState.SupplierMatching, label: 'Match' },
  BL: { value: StudyTransactionState.BlacklistSupplier, label: 'Blacklist Supplier' },
  BLI: { value: StudyTransactionState.BlacklistStrippedItem, label: 'Blacklist Items' },
  Ignored: { value: StudyTransactionState.IgnoreSupplier, label: 'Ignored Supplier' },
  IgnoredRow: { value: StudyTransactionState.IgnoreRow, label: 'Ignored Rows' },
  HiddenRow: { value: StudyTransactionState.Hidden, label: 'Hidden Rows' },
  Approved: { value: StudyTransactionState.FinalReview, label: 'Approved' },
  MatchAndApproved: {
    value: StudyTransactionState.SupplierMatchingAndFinalReview,
    label: 'Match And Approved',
  },
  TwinMatch: { value: StudyTransactionState.TwinMatch, label: 'Twin Match' },
  TwinApproved: { value: StudyTransactionState.TwinApproved, label: 'Twin Approved' },
  Conflict: { value: StudyTransactionState.Conflict, label: 'Conflict' },
}

export const getStudyTransactionStateKey = (state?: StudyTransactionState | null) => {
  if (!state) {
    return undefined
  }

  return Object.keys(STUDY_TRANSACTION_STATE_OPTION_MAPPING).find(k => {
    const value = STUDY_TRANSACTION_STATE_OPTION_MAPPING[k].value
    return value === state
  })
}
