import { NewStudyWithTransactionInput, FileAlternativeHeadersInput, StudyFileTypeEnum } from '@curvo/apollo'
import { Form, Input, Switch, Select, Row, Col, DatePicker } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import { InputProps } from 'antd/lib/input'
import React, { useState } from 'react'
import moment from 'moment'
import { EditPanelWrapper } from '../../../../components/common'
import { EditDrawer } from '../../../../components/EditDrawer'
import { S3Uploader } from '../../Upload/components/Panel/S3Uploader'
import { AlternativeMappingInputs } from './AlternativeMapping'

export type InputRawTransactionDrawerProps = DrawerProps & {
  onSave: (importData: NewStudyWithTransactionInput) => void
  onCancel: () => void
}
export const ImportRawTransactionDrawer: React.FC<InputRawTransactionDrawerProps> = ({ onSave, ...props }) => {
  const [hasAlternativeHeader, setExtendAlternativeHeader] = useState(false)
  const [alternativeHeaders, setAlternativeHeaders] = useState<Partial<FileAlternativeHeadersInput>>({})
  const [input, setInput] = useState<Partial<Omit<NewStudyWithTransactionInput, 'alternativeHeaders'>>>({})
  const inputFieldProps = (
    field: keyof Omit<NewStudyWithTransactionInput, 'alternativeHeaders' | 'disableMatching' | 'doLinkParts'>,
    isNumber: boolean = false,
  ): InputProps => ({
    value: input[field] || '',
    onChange: e => setInput({ ...input, [field]: isNumber ? parseInt(e.target.value, 10) : e.target.value }),
  })
  return (
    <EditDrawer
      width={hasAlternativeHeader ? 500 : 400}
      {...props}
      onSave={() => onSave({ ...input, alternativeHeaders } as NewStudyWithTransactionInput)}>
      <EditPanelWrapper>
        <S3Uploader
          onUploaded={s3Key => {
            setInput({
              ...input,
              s3Key,
            })
          }}
        />
        <Row gutter={16}>
          <Col md={12}>
            <Form.Item label="Customer ID">
              <Input placeholder="Customer ID" {...inputFieldProps('facilityId')} />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="File Type">
              <Select
                placeholder="File Type"
                style={{ width: '100%' }}
                value={input.fileType || undefined}
                onChange={(v: StudyFileTypeEnum) => setInput({ ...input, fileType: v })}>
                <Select.Option value={StudyFileTypeEnum.Epic}>EPIC</Select.Option>
                <Select.Option value={StudyFileTypeEnum.Contract}>Contract</Select.Option>
                <Select.Option value={StudyFileTypeEnum.XRef}>XRef</Select.Option>
                <Select.Option value={StudyFileTypeEnum.Other}>Other</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Customer Name">
          <Input placeholder="Customer Name" {...inputFieldProps('facilityName')} />
        </Form.Item>
        <Form.Item label="Version">
          <Input placeholder="Version" {...inputFieldProps('version')} />
        </Form.Item>
        <Form.Item label="Due Date">
          <DatePicker
            placeholder="Due Date"
            value={input.dueDate !== null ? moment(input.dueDate) : undefined}
            onChange={v => {
              setInput(old => ({ ...old, dueDate: v?.format('YYYY-MM-DD') }))
            }}
          />
        </Form.Item>
        <Form.Item label="Disable matching process">
          <Switch
            checked={input.disableMatching || false}
            onChange={v => setInput(inp => ({ ...inp, disableMatching: v }))}
          />
        </Form.Item>
        <Form.Item label="Do Link Ts Ids?">
          <Switch checked={!!input.doLinkParts} onChange={v => setInput(old => ({ ...old, doLinkParts: v }))} />
        </Form.Item>
        <Form.Item label="Alternative headers">
          <Switch
            checked={hasAlternativeHeader}
            disabled={!input.s3Key}
            onChange={v => setExtendAlternativeHeader(v)}
          />
        </Form.Item>
        {hasAlternativeHeader && input.s3Key && (
          <AlternativeMappingInputs
            fileType={input.fileType || undefined}
            alternativeHeaders={alternativeHeaders}
            onChange={setAlternativeHeaders}
            s3Key={input.s3Key}
          />
        )}
      </EditPanelWrapper>
    </EditDrawer>
  )
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
