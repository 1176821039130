"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.darken = exports.lighten = exports.mix = exports.extractRGBFromHex = exports.isColorValid = exports.decimalToHex = exports.hexToDecimal = void 0;
const hexToDecimal = (hex) => parseInt(hex, 16);
exports.hexToDecimal = hexToDecimal;
const decimalToHex = (decimal) => {
    const hex = decimal.toString(16);
    if (hex.length < 2) {
        return `${hex}${hex}`;
    }
    return hex;
};
exports.decimalToHex = decimalToHex;
const isColorValid = (color) => {
    const regex = new RegExp('[#]([a-zA-Z0-9]{3,6})', 'g');
    return !!color.match(regex);
};
exports.isColorValid = isColorValid;
const extractRGBFromHex = (color) => {
    const regex6 = new RegExp('[#]([a-zA-Z0-9]{2})([a-zA-Z0-9]{2})([a-zA-Z0-9]{2})');
    const regex3 = new RegExp('[#]([a-zA-Z0-9]{1})([a-zA-Z0-9]{1})([a-zA-Z0-9]{1})');
    if (color.length >= 7) {
        const [, rr, gg, bb] = color.match(regex6);
        return [rr, gg, bb];
    }
    const [, r, g, b] = color.match(regex3);
    return [`${r}${r}`, `${g}${g}`, `${b}${b}`];
};
exports.extractRGBFromHex = extractRGBFromHex;
const mixCalculator = (v1, v2, p) => Math.round(v1 + (v2 - v1) * p);
const mix = (color1, color2, percent) => {
    if (!(0, exports.isColorValid)(color1) && !(0, exports.isColorValid)(color2)) {
        return '';
    }
    const [hR1, hG1, hB1] = (0, exports.extractRGBFromHex)(color1);
    const [hR2, hG2, hB2] = (0, exports.extractRGBFromHex)(color2);
    const [r, g, b] = [
        (0, exports.decimalToHex)(mixCalculator((0, exports.hexToDecimal)(hR1), (0, exports.hexToDecimal)(hR2), percent)),
        (0, exports.decimalToHex)(mixCalculator((0, exports.hexToDecimal)(hG1), (0, exports.hexToDecimal)(hG2), percent)),
        (0, exports.decimalToHex)(mixCalculator((0, exports.hexToDecimal)(hB1), (0, exports.hexToDecimal)(hB2), percent)),
    ];
    return `#${r}${g}${b}`;
};
exports.mix = mix;
const lighten = (color, percent) => (0, exports.mix)(color, '#ffffff', percent);
exports.lighten = lighten;
const darken = (color, percent) => (0, exports.mix)(color, '#000000', percent);
exports.darken = darken;
