import '@ag-grid-community/all-modules/dist/styles/ag-grid.css'
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css'
import {
  ColDef,
  ColGroupDef,
  ColumnState,
  DragStoppedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  ServerSideStoreType,
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules, ModuleRegistry } from '@ag-grid-enterprise/all-modules'
import { FilterModel, SortModel } from '@curvo/apollo'
import { Pagination } from 'antd'
import { pick } from 'lodash'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

export type FetchDataParams = {
  endRow: number
  filter: FilterModel[]
  sorting: SortModel[]
  startRow: number
  selectedFields?: string[]
}
type FetchDataResponse = {
  data: any[]
  total: number
}
type FetchDataFn = (request: FetchDataParams) => Promise<FetchDataResponse>

type Props = {
  gridKey: string
  columns: (ColDef | ColGroupDef)[]
  fetchData: FetchDataFn
  fetching: boolean
  gridApiRef?: (api: GridApi | null) => void
  gridProps?: Omit<
    GridOptions,
    | 'columnDefs'
    | 'rowModelType'
    | 'serverSideStoreType'
    | 'serverSideDatasource'
    | 'onDragStopped'
    | 'onPaginationChanged'
    | 'onGridReady'
  >
}

ModuleRegistry.registerModules(AllModules)
export const ServerSideGrid: React.FC<Props> = ({ gridKey, columns, fetchData, fetching, gridProps, gridApiRef }) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [pagination, setPagination] = useState<{ total: number; current: number; pageSize: number }>({
    total: 0,
    current: 1,
    pageSize: 100,
  })

  const serverSideDatasource = useMemo(() => {
    return new ServerSideDataSource(fetchData, columns)
  }, [fetchData, columns])

  return (
    <div className="ag-theme-balham" style={{ height: '70vh', width: '100%' }}>
      <AgGridReact
        columnDefs={columns}
        rowModelType="serverSide"
        serverSideStoreType={ServerSideStoreType.Partial}
        cacheBlockSize={pagination.pageSize}
        rowBuffer={pagination.pageSize}
        maxBlocksInCache={10}
        blockLoadDebounceMillis={1000}
        defaultColDef={{ filter: true, sortable: true, resizable: true, autoHeight: true }}
        serverSideDatasource={serverSideDatasource}
        getRowNodeId={row => row.id}
        overlayNoRowsTemplate={fetching ? 'Loading...' : 'No Rows To Show'}
        frameworkComponents={{ customCellLoading: CustomCellLoading }}
        loadingCellRenderer={'customCellLoading'}
        onDragStopped={(e: DragStoppedEvent) => {
          const columnState = JSON.stringify(
            e.columnApi.getColumnState().map(colState => pick(colState, ['colId', 'width', 'pinned', 'hide'])),
          )
          localStorage.setItem(gridKey, columnState)
        }}
        onPaginationChanged={event => {
          setPagination({
            current: event.api.paginationGetCurrentPage(),
            total: event.api.paginationGetRowCount(),
            pageSize: event.api.paginationGetPageSize(),
          })
        }}
        onGridReady={({ api, columnApi }: GridReadyEvent) => {
          setGridApi(api)
          gridApiRef && gridApiRef(api)
          const columnStateStr = localStorage.getItem(gridKey)
          if (columnStateStr) {
            const savedColumnStates: ColumnState[] = JSON.parse(columnStateStr)
            const currentColumnStates = columnApi.getColumnState()
            const unsavedColumns = currentColumnStates.filter(
              col => !savedColumnStates.find(savedCol => savedCol.colId === col.colId),
            )

            columnApi.setColumnState([...savedColumnStates, ...unsavedColumns])
            columnApi.setColumnVisible('state', false)

            columnApi.moveColumn('index', 0)
          }

          columnApi.getColumn('id')?.setSort('desc')
          api.onFilterChanged()
        }}
        columnTypes={{
          number: {
            filter: 'agNumberColumnFilter',
            filterParams: { suppressAndOrCondition: true, buttons: ['clear'] },
          },
          text: { filter: 'agTextColumnFilter', filterParams: { suppressAndOrCondition: true, buttons: ['clear'] } },
        }}
        pagination
        suppressPaginationPanel
        {...gridProps}
      />
      <PaginationWrapper>
        <Pagination
          disabled={fetching}
          current={pagination.current + 1}
          total={pagination.total}
          pageSize={pagination.pageSize}
          pageSizeOptions={['100', '200', '500', '1000']}
          showSizeChanger
          showQuickJumper
          onChange={page => {
            gridApi?.paginationGoToPage(page - 1)
          }}
          onShowSizeChange={(_current, size) => {
            gridApi?.paginationSetPageSize(size)
          }}
        />
      </PaginationWrapper>
    </div>
  )
}

const PaginationWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: end;
`

class ServerSideDataSource implements IServerSideDatasource {
  private getData: FetchDataFn
  private columns: (ColDef | ColGroupDef)[]

  constructor(getData: FetchDataFn, columns: (ColDef | ColGroupDef)[]) {
    this.getData = getData
    this.columns = columns
  }

  getRows(params: IServerSideGetRowsParams): void {
    const request = params.request
    params.api.showLoadingOverlay()
    this.getData({
      selectedFields: this.columns
        .flatMap(c => (c as ColGroupDef).children || c)
        .filter(c => !!(c as ColDef).field)
        .map(c => (c as ColDef).field!),
      startRow: request.startRow || 0,
      endRow: request.endRow || 100,
      sorting: request.sortModel || [],
      filter: Object.keys(request.filterModel || {}).map(k => ({
        field: k,
        ...request.filterModel[k],
        filter: request.filterModel[k].filter?.toString(),
        filterTo: request.filterModel[k].filterTo?.toString(),
      })),
    })
      .then(resp => {
        const rowData = resp.data || []
        params.success({
          rowData,
          rowCount: resp.total || rowData.length,
        })
      })
      .catch(() => {
        params.fail()
      })
      .finally(() => {
        params.api.hideOverlay()
      })
  }
}

const CustomCellLoading = () => {
  return <></>
}
