import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import {
  Study,
  StudyTransactionType,
  useStudyData,
  useSubscriptionStudyUpdated,
  Client,
  StudyTransactionState,
} from '@curvo/apollo'
import moment from 'moment'
import { AgGridReact } from '@ag-grid-community/react'
import { GridApi } from '@ag-grid-community/core'
import Cognito from '../../../../config/Cognito'
import { GroomTransactionsQueryType, GroomingCache } from './GroomingCache'
import { StudyTransactionStateFilter } from './StudyTransactionStateFilter'

const ROWS_PER_LOAD = 500
const MAX_CONNECTION = 8

type LoadStudyResult = {
  progress?: number
  error?: Error
  loading: boolean
  updatedStudy?: Study
}

const getToken = async () => {
  const session = await Cognito.getSession()
  return session.getAccessToken().getJwtToken()
}

const doFetchTransactionsData = async (
  args: GroomTransactionsQueryType,
  first: number,
  skip: number,
  baseUrl: string,
  studyId: number,
) => {
  const token = await getToken()
  const result = await axios.get(`${window.location.protocol}//${baseUrl}/study/${studyId}/transaction`, {
    params: {
      ...args,
      first,
      skip,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return result.data.transactions
}

const getTransactionsCount = async (args: GroomTransactionsQueryType, baseUrl: string, studyId: number) => {
  const token = await getToken()
  const result = await axios.get(`${window.location.protocol}//${baseUrl}/study/${studyId}/transaction/count`, {
    params: args,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return result.data.count || 0
}

export function useLoadStudyTransactions(
  studyId: number,
  gridRef: React.RefObject<AgGridReact & { api: GridApi }>,
  queryArgs: GroomTransactionsQueryType,
  needDatepurchaseRange: boolean = false,
): LoadStudyResult {
  const [updatedDatepurchase, setUpdatedDatepurchase] = useState(!needDatepurchaseRange)
  const cachedData = GroomingCache.studies.get(studyId)
  const [studyTransactions, setStudyTransactions] = useState<StudyTransactionType[]>([])
  const [total, setTotal] = useState(-1)
  const [error, setError] = useState<Error>()
  const studyDataResult = useStudyData({ id: studyId })
  const [availableConnection, setAvailableConnection] = useState(1)
  const [loadOffset, setLoadOffset] = useState(0)

  const memoQueryArgs = useRef<GroomTransactionsQueryType>()
  if (memoQueryArgs.current !== queryArgs) {
    memoQueryArgs.current = queryArgs
  }

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.api.setRowData([])
    }
    if (GroomingCache.savedQueryArgs.get(studyId) !== memoQueryArgs.current) {
      GroomingCache.studies.delete(studyId)
    }
    setTotal(-1)
    setStudyTransactions([])
    setAvailableConnection(1)
    setLoadOffset(0)
  }, [studyId, gridRef, queryArgs])

  const updatedResult = useSubscriptionStudyUpdated(studyId)
  useEffect(() => {
    const baseUrl = Client.getUrl()

    const getShouldUpdateQueryArgs = (study: Study) =>
      updatedDatepurchase ? memoQueryArgs.current! : updateGroomQueryArgs(memoQueryArgs.current!, study)

    const study = studyDataResult?.data?.study
    const shouldUpdateQueryArgs = study ? getShouldUpdateQueryArgs(study) : undefined
    if (study && shouldUpdateQueryArgs) {
      if (total === -1 || studyTransactions.length < total) {
        if (
          cachedData &&
          cachedData.size > 0 &&
          cachedData.size >= total &&
          GroomingCache.savedQueryArgs.get(studyId) === memoQueryArgs.current
        ) {
          const cachedRows = [...cachedData.values()]
          setStudyTransactions(cachedRows)
          setTotal(cachedData.size)
          if (gridRef.current) {
            gridRef.current.api.setRowData(cachedRows)

            const stateFilter: StudyTransactionStateFilter = gridRef.current!.api.getFilterInstance('state') as any
            stateFilter.getModel().state = memoQueryArgs.current?.state || StudyTransactionState.BamfMatching

            gridRef.current.api.onFilterChanged()
          }
        } else if (total === -1) {
          if (availableConnection > 0) {
            if (!updatedDatepurchase) {
              setUpdatedDatepurchase(true)
              return
            }
            setAvailableConnection(0)
            getTransactionsCount(shouldUpdateQueryArgs, baseUrl, study.id).then(count => {
              setTotal(count)
              setAvailableConnection(MAX_CONNECTION)
            })
          }
        } else if (availableConnection > 0) {
          setAvailableConnection(old => old - 1)
          if (loadOffset < total) {
            setLoadOffset(offset => offset + ROWS_PER_LOAD)
            doFetchTransactionsData(shouldUpdateQueryArgs, ROWS_PER_LOAD, loadOffset, baseUrl, studyId)
              .then(transactions => {
                setStudyTransactions(trxes => [...trxes, ...transactions])
                GroomingCache.addToStudy(transactions, studyId, memoQueryArgs.current!)

                if (gridRef.current) {
                  gridRef.current.api.updateRowData({
                    add: transactions,
                  })
                }
                setAvailableConnection(old => old + 1)
              })
              .catch(e => {
                setError(e)
              })
          }
        }
      }
    }
  }, [
    studyId,
    total,
    loadOffset,
    availableConnection,
    studyTransactions,
    gridRef,
    cachedData,
    studyDataResult,
    updatedDatepurchase,
    queryArgs,
  ])

  return {
    progress: Math.round(studyTransactions && total ? ((studyTransactions.length * 1.0) / total) * 100 : 0),
    error,
    loading: total === -1 || studyTransactions.length < total,
    updatedStudy:
      (updatedResult.data && updatedResult.data.studyUpdated) || (studyDataResult.data && studyDataResult.data.study),
  }
}

/**
 * update Groom queryArgs if current args has empty datepurchaseFrom/To by using study.maxDatePurchase
 *
 **/
export const updateGroomQueryArgs = (queryArgs: GroomTransactionsQueryType, study: Study): GroomTransactionsQueryType =>
  queryArgs.datepurchaseFrom
    ? queryArgs
    : {
        ...queryArgs,
        datepurchaseFrom:
          (study.maxDatePurchase &&
            moment(study.maxDatePurchase, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD')) ||
          undefined,
        datepurchaseTo: study.maxDatePurchase || undefined,
      }
