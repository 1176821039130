import {
  PRODUCT_LINES_QUERY,
  ProductLine,
  ProductLineCreateInput,
  ProductLineUpdateInput,
  ProductLinesData,
  ProductLinesQueryArgs,
  createProductLineMutation,
  deleteProductLineMutation,
  updateProductLineMutation,
} from '@curvo/apollo'
import { Button, Checkbox, Pagination, Popconfirm, message } from 'antd'
import Table from 'antd/lib/table'
import React, { useState } from 'react'
import {
  BrandOrProductLineSearchWrapper,
  ColumnGenFunctionType,
  PageWrapper,
  PaginationContainer,
  RedA,
  SearchWrapper,
  StyledSearchInput,
} from './common'
import { BrandAndProductLineDrawer } from './components/EditPanel/CreateEditBrandAndProductLine'
import { EditMode } from './components/EditPanel/EditManufacturer'

export const ProductLines: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [limitPerPage, setLimitPerPage] = useState(10)
  const [queryArgs, setQueryArgs] = useState<ProductLinesQueryArgs>({
    first: limitPerPage,
    skip: 0,
  })
  const [selected, setSelected] = useState<ProductLine>()
  const [editMode, setEditMode] = useState<EditMode>(EditMode.edit)
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const columns = columnsGen(
    productLine => {
      setSelected(productLine)
      setEditMode(EditMode.edit)
      setIsOpenEdit(true)
    },
    productLine => {
      deleteProductLineMutation(
        { id: productLine.id },
        { refetchQueries: [{ query: PRODUCT_LINES_QUERY, variables: queryArgs }] },
      ).catch(() =>
        message.error('Unable to delete this ProductLine, please check if there are parts using this ProductLine'),
      )
    },
  )
  return (
    <ProductLinesData variables={queryArgs} fetchPolicy="network-only">
      {({ data, error, loading, refetch }) => {
        if (error) {
          message.error(error.message)
        }
        const productLines = data && data.productLines ? data.productLines.edges.map(edge => edge.node) : []
        const totalPages =
          data && data.productLines && data.productLines.metadata ? data.productLines.metadata.total : 0
        return (
          <PageWrapper>
            <SearchWrapper>
              <BrandOrProductLineSearchWrapper>
                <StyledSearchInput
                  placeholder="Search ProductLine by name"
                  enterButton
                  onSearch={searchText => {
                    setQueryArgs(query => ({
                      ...query,
                      searchText,
                    }))
                  }}
                />
                <Checkbox
                  style={{ marginLeft: '10px' }}
                  onChange={e => {
                    setQueryArgs(query => ({
                      ...query,
                      isInactive: e.target.value,
                    }))
                  }}>
                  Inactive
                </Checkbox>
              </BrandOrProductLineSearchWrapper>
              <Button
                type="primary"
                icon="plus"
                onClick={() => {
                  setEditMode(EditMode.create)
                  setIsOpenEdit(true)
                }}>
                New ProductLine
              </Button>
            </SearchWrapper>
            <Table columns={columns} dataSource={productLines} loading={loading} pagination={false} rowKey="id" />
            <PaginationContainer>
              <Pagination
                total={totalPages}
                defaultCurrent={currentPage}
                onChange={pageNumber => {
                  setCurrentPage(pageNumber)
                  setQueryArgs(query => ({
                    ...query,
                    first: limitPerPage,
                    skip: (pageNumber - 1) * limitPerPage,
                  }))
                }}
                showSizeChanger
                onShowSizeChange={(_, size) => setLimitPerPage(size)}
              />
            </PaginationContainer>
            <BrandAndProductLineDrawer
              brandOrProduct={selected}
              onCancel={() => setIsOpenEdit(false)}
              editMode={editMode}
              submitting={submitting}
              onSubmit={v => {
                setSubmitting(true)
                if (editMode === EditMode.create) {
                  createProductLineMutation({ input: v as ProductLineCreateInput })
                    .then(() => {
                      setIsOpenEdit(false)
                    })
                    .then(() => refetch(queryArgs))
                    .then(() => {
                      message.success('Created')
                    })
                    .catch(e => {
                      message.error(e.message)
                    })
                    .finally(() => setSubmitting(false))
                } else {
                  updateProductLineMutation({ input: v as ProductLineUpdateInput })
                    .then(() => {
                      setIsOpenEdit(false)
                    })
                    .catch(e => {
                      message.error(e.message)
                    })
                    .finally(() => setSubmitting(false))
                }
              }}
              visible={isOpenEdit}
              width={300}
            />
          </PageWrapper>
        )
      }}
    </ProductLinesData>
  )
}

const columnsGen: ColumnGenFunctionType<ProductLine> = (onEdit, onDelete) => [
  {
    title: 'Name',
    dataIndex: 'name',
    width: 200,
    key: 'name',
    sorter: true,
  },
  {
    title: 'Manufacturer',
    dataIndex: 'manufacturer.name',
    width: 200,
    key: 'manufacturer',
    render: (_text: any, record: ProductLine) =>
      record.manufacturer && `${record.manufacturer.id} | ${record.manufacturer.name}`,
    sorter: true,
  },
  {
    title: 'Action',
    width: 150,
    key: 'action',
    render: (_text: any, record: ProductLine) => (
      <span>
        <Button onClick={() => onEdit(record)}>Edit</Button>
        <span> | </span>
        <Popconfirm
          title={`Are you sure you want to delete ProductLine ${record.name}?`}
          okText="Yes"
          cancelText="No"
          onConfirm={() => onDelete && onDelete(record)}>
          <RedA>Delete</RedA>
        </Popconfirm>
      </span>
    ),
  },
]
