import React from 'react'
import { RowNode, GridApi, ICellEditorParams } from '@ag-grid-community/all-modules'
import Select, { LabeledValue } from 'antd/lib/select'
import { PartEdge } from '@curvo/apollo'
import { ColDef } from '@ag-grid-community/core'
import { ManufacturerSelect } from './Select/ManufacturerSelect'
import { BrandSelect } from './Select/BrandSelect'
import { ProductLineSelect } from './Select/ProductLineSelect'
import { GICSelect } from './Select/GICSelect'
import { AllowedGICsTypeOneSelect } from './Select/GICTypeOneSelect'
import { AllowedGICsTypeTwoSelect } from './Select/GICTypeTwoSelect'
import { MaterialSelect } from './Select/MaterialSelect'
import { SegmentationSelect } from './Select/SegmentationSelect'
import { StyledSelect } from './Select/common'

type AgWrapperPropsType<T extends { node: any }> = {
  data: T
  node: RowNode
  api: GridApi
  colDef: ColDef
  params: ICellEditorParams
  stopEditing: (suppressNavigateAfterEdit?: boolean) => void
}

abstract class SelectAgWrapper<T extends { node: any }> extends React.Component<
  AgWrapperPropsType<T>,
  { labeledValue: LabeledValue | undefined }
> {
  state = {
    labeledValue: this.getDefaultValue(),
  }

  componentDidUpdate(): void {
    this.props.stopEditing()
  }

  getDefaultValue() {
    const field = this.getField()
    if (!field || !this.props.data.node[field]) {
      return { key: '', label: '' }
    }

    return { key: this.props.data.node[field].id, label: this.props.data.node[field].name }
  }

  getValue() {
    const field = this.getField()

    if (!field || !this.props.data.node[field]) {
      return { key: '', label: '' }
    }

    if (
      this.state.labeledValue === undefined ||
      String(this.props.data.node[field].id) === this.state.labeledValue.key
    ) {
      return this.props.data.node[field]
    }

    return JSON.parse(this.state.labeledValue.key)
  }

  private getField() {
    return this.props.colDef.field && this.props.colDef.field.split('.')[1]
  }
}

export class SelectManufacturerAgWrapper extends SelectAgWrapper<PartEdge> {
  public render() {
    const { labeledValue } = this.state

    return (
      <ManufacturerSelect
        fullData={true}
        value={labeledValue}
        allowClear
        onChange={v => {
          this.setState({ labeledValue: v })
        }}
      />
    )
  }
}

export class SelectBrandAgWrapper extends SelectAgWrapper<PartEdge> {
  public render() {
    const { labeledValue } = this.state

    return (
      <BrandSelect
        manufacturerId={this.props.data.node.manufacturer ? this.props.data.node.manufacturer.id : undefined}
        fullData={true}
        value={labeledValue}
        allowClear
        onChange={v => {
          this.setState({ labeledValue: v })
        }}
      />
    )
  }
}

export class SelectProductLineAgWrapper extends SelectAgWrapper<PartEdge> {
  public render() {
    const { labeledValue } = this.state

    return (
      <ProductLineSelect
        manufacturerId={this.props.data.node.manufacturer ? this.props.data.node.manufacturer.id : undefined}
        fullData={true}
        value={labeledValue}
        allowClear
        onChange={v => {
          this.setState({ labeledValue: v })
        }}
      />
    )
  }
}

export class SelectGicLineAgWrapper extends SelectAgWrapper<PartEdge> {
  public render() {
    const { labeledValue } = this.state

    return (
      <GICSelect
        fullData={true}
        value={labeledValue}
        allowClear
        onChange={v => {
          this.setState({ labeledValue: v })
        }}
      />
    )
  }
}

export class SelectTypeOneAgWrapper extends SelectAgWrapper<PartEdge> {
  public render() {
    const { labeledValue } = this.state

    return (
      <AllowedGICsTypeOneSelect
        gicId={this.props.data.node.gic ? this.props.data.node.gic.id : undefined}
        fullData={true}
        value={labeledValue}
        allowClear
        onChange={v => {
          this.setState({ labeledValue: v })
        }}
      />
    )
  }
}

export class SelectTypeTwoAgWrapper extends SelectAgWrapper<PartEdge> {
  public render() {
    const { labeledValue } = this.state

    return (
      <AllowedGICsTypeTwoSelect
        disabled={!this.props.data.node.gic}
        gicId={this.props.data.node.gic.id}
        typeOneId={this.props.data.node.typeOne ? this.props.data.node.typeOne.id : undefined}
        fullData={true}
        value={labeledValue}
        allowClear
        onChange={v => {
          this.setState({ labeledValue: v })
        }}
      />
    )
  }
}

export class SelectMaterialAgWrapper extends SelectAgWrapper<PartEdge> {
  public render() {
    const { labeledValue } = this.state

    return (
      <MaterialSelect
        fullData={true}
        value={labeledValue}
        allowClear
        onChange={v => {
          this.setState({ labeledValue: v })
        }}
      />
    )
  }
}

export class SelectSegmentationAgWrapper extends SelectAgWrapper<PartEdge> {
  public render() {
    const { labeledValue } = this.state

    return (
      <SegmentationSelect
        fullData={true}
        value={labeledValue}
        allowClear
        onChange={v => {
          this.setState({ labeledValue: v })
        }}
      />
    )
  }
}

export class SelectLiteralAgWrapper extends SelectAgWrapper<PartEdge> {
  public render() {
    const { labeledValue } = this.state

    return (
      <StyledSelect
        labelInValue
        filterOption={false}
        showArrow={false}
        defaultActiveFirstOption={false}
        placeholder="Select"
        value={labeledValue}
        allowClear
        onChange={v => {
          this.setState({ labeledValue: v })
        }}>
        {this.props.colDef.cellEditorParams &&
          this.props.colDef.cellEditorParams.values &&
          this.props.colDef.cellEditorParams.values.map(option => (
            <Select.Option key={JSON.stringify(option.value)} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
      </StyledSelect>
    )
  }
}
