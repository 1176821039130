import { IDoesFilterPassParams, IFilterComp, IFilterParams } from '@ag-grid-community/core'
import { StudyTransactionState } from '@curvo/apollo'

export class StudyTransactionStateFilter implements IFilterComp {
  private model: StudyTransactionStateFilterModel = { state: StudyTransactionState.BamfMatching }
  private filterParams: IFilterParams | undefined

  isFilterActive() {
    return true
  }

  init(params: IFilterParams) {
    this.filterParams = params
  }

  getGui() {
    return document.createElement('div')
  }

  getModel() {
    return this.model
  }

  setModel(newModel: StudyTransactionStateFilterModel) {
    if (newModel) {
      this.model = newModel
    }
  }

  doesFilterPass(params: IDoesFilterPassParams) {
    if (!this.filterParams) {
      return false
    }
    if (this.model.state === null) {
      return true
    }
    const value: StudyTransactionState = this.filterParams.valueGetter(params.node)
    if (params.node.expanded) {
      return true
    }
    return value === this.model.state
  }
}

type StudyTransactionStateFilterModel = {
  state: StudyTransactionState | null
}
