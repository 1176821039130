import { StudyTransactionType, StudyTransactionState } from '@curvo/apollo'

export type GroomTransactionsQueryType = {
  state?: StudyTransactionState
  manufacturerType?: string
  datepurchaseFrom?: string
  datepurchaseTo?: string
  masterGroup?: string
}

export class StudiesMap {
  public studies: Map<number, Map<string, StudyTransactionType>>
  public savedQueryArgs: Map<number, GroomTransactionsQueryType>

  constructor() {
    this.studies = new Map<number, Map<string, StudyTransactionType>>()
    this.savedQueryArgs = new Map<number, GroomTransactionsQueryType>()
  }

  private getOrCreateStudy(studyId: number): Map<string, StudyTransactionType> {
    if (this.studies.has(studyId)) {
      return this.studies.get(studyId)!
    }

    const study = new Map<string, StudyTransactionType>()
    this.studies.set(studyId, study)
    return study
  }

  public addToStudy(transactions: StudyTransactionType[], studyId: number, newQueryArgs: GroomTransactionsQueryType) {
    const study = this.getOrCreateStudy(studyId)
    this.savedQueryArgs.set(studyId, newQueryArgs)

    transactions.forEach(transaction => {
      study.set(transaction.id, transaction)
    })
  }
}

export const GroomingCache = new StudiesMap()
