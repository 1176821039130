import { Select } from 'antd'
import { LabeledValue } from 'antd/lib/select'
import styled from 'styled-components'

export class TypedMultipleSelect extends Select<LabeledValue | LabeledValue[]> {}
export class TypedSelect extends Select<LabeledValue> {}

export const StyledSelect = styled(TypedSelect)<{ width?: number; children?: React.ReactNode }>`
  width: ${props => (props.width ? `${props.width}px` : '100%')};
`

export const StyledMultipleSelect = styled(TypedMultipleSelect)<{
  width?: number
  children?: React.ReactNode
}>`
  width: ${props => (props.width ? `${props.width}px` : '100%')};
`

export type StyledSelectPropsType = {
  width?: number
  fullData?: boolean
}
