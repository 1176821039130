import { Segment } from '@curvo/apollo'
import { Form, Input } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import React, { useEffect, useState } from 'react'
import { EditPanelWrapper } from '../../../../../components/common'
import { EditDrawer } from '../../../../../components/EditDrawer'
import { EditMode } from './EditManufacturer'

export const SegmentDrawer: React.FunctionComponent<
  {
    segment?: Segment
    onSubmit: (name: string) => void
    onCancel: () => void
    editMode: EditMode
  } & DrawerProps
> = ({ editMode, segment, onSubmit, onCancel, ...drawerProps }) => {
  const [name, setName] = useState('')
  useEffect(() => {
    if (segment) {
      setName(segment.name)
    }
  }, [segment])
  return (
    <EditDrawer
      onCancel={onCancel}
      onSave={() => {
        onSubmit(name)
        setName('')
      }}
      {...drawerProps}>
      <EditPanelWrapper>
        {editMode === EditMode.edit && segment && (
          <Form.Item label="ID">
            <Input value={segment.id} disabled />
          </Form.Item>
        )}
        <Form.Item label="Name">
          <Input
            value={name}
            onChange={e => {
              setName(e.target.value)
            }}
          />
        </Form.Item>
      </EditPanelWrapper>
    </EditDrawer>
  )
}
