import {
  MeWithoutPaymentContext,
  recoverStudyMutation,
  Study,
  StudyStatusEnum,
  useStudiesData,
  useSubscriptionStudiesUpdated,
} from '@curvo/apollo'
import { Avatar, Button, Dropdown, Icon, Layout, List, Menu, message, Popover, Progress } from 'antd'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { signOut } from '../config/Cognito'

type Props = {
  setNavCollapsed: (state: boolean) => void
  navCollapsed: boolean
}

export const Header: React.FC<Props> = ({ setNavCollapsed, navCollapsed }) => {
  const meContext = useContext(MeWithoutPaymentContext)
  const navigate = useNavigate()

  const menu = (
    <Menu>
      <Menu.Item disabled>
        <Button type="link">{meContext.data && meContext.data.username}</Button>
      </Menu.Item>
      <Menu.Item>
        <Button
          type="link"
          onClick={() => {
            signOut()
            navigate('/auth/signin')
          }}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  )

  return (
    <StyledHeader>
      <CollapsedIcon type={navCollapsed ? 'menu-unfold' : 'menu-fold'} onClick={() => setNavCollapsed(!navCollapsed)} />
      <div style={{ paddingRight: '32px', display: 'flex', alignItems: 'center' }}>
        <Popover
          content={<ProcessingStudiesChecker />}
          trigger="click"
          placement="bottomRight"
          title="Processing Studies">
          <Button icon="bell" type="primary" shape="circle" style={{ marginRight: 8 }} />
        </Popover>
        <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
          <Avatar icon="user" />
        </Dropdown>
      </div>
    </StyledHeader>
  )
}

const CheckingStudiesStatuses = [
  StudyStatusEnum.Failed,
  StudyStatusEnum.AutoLinking,
  StudyStatusEnum.AutoMatching,
  StudyStatusEnum.Committing,
  StudyStatusEnum.CommittingCase,
  StudyStatusEnum.QueuedAutoMatching,
  StudyStatusEnum.QueuedForGrooming2,
  StudyStatusEnum.QueuedForCommitting,
  StudyStatusEnum.QueuedForPushingToGrooming1,
]

const ProcessingStudiesChecker: React.FC = () => {
  const { error, loading, ...studiesDataResult } = useStudiesData({
    fetchPolicy: 'no-cache',
    variables: {
      first: 100,
      statusIn: CheckingStudiesStatuses,
    },
  })
  if (error) {
    message.error(error.message)
  }
  const updatedStudiesResult = useSubscriptionStudiesUpdated()
  if (updatedStudiesResult.error) {
    message.error(updatedStudiesResult.error)
  }

  const studies = (studiesDataResult?.data?.studies || [])
    .map(study =>
      study.id === updatedStudiesResult.data?.studiesUpdated.id ? updatedStudiesResult.data?.studiesUpdated : study,
    )
    .filter(study => CheckingStudiesStatuses.indexOf(study.status) > -1)

  return (
    <List
      loading={loading}
      dataSource={studies}
      style={{ width: 200 }}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta avatar={<StudyStatusCell data={item} />} title={item.id} description={item.status} />
        </List.Item>
      )}
    />
  )
}

const StudyStatusCell: React.FC<{ data: Study }> = ({ data }) => {
  if (data.status === StudyStatusEnum.AutoMatching) {
    return (
      <Progress
        type="circle"
        width={36}
        percent={Math.round(((data.bamfMatchedCount || 0) * 100.0) / (data.recordCount || 1))}
      />
    )
  }

  if (data.status === StudyStatusEnum.AutoLinking) {
    return (
      <Progress
        type="circle"
        width={36}
        percent={Math.round(((data.linkedTsId || 0) * 100.0) / (data.totalUniqueTsId || 1))}
      />
    )
  }

  if (data.status === StudyStatusEnum.Committing) {
    return (
      <Progress
        type="circle"
        width={36}
        percent={Math.round(((data.gradedPoCount || 0) * 100.0) / (data.poCount || 1))}
      />
    )
  }

  if (data.status === StudyStatusEnum.Failed) {
    return (
      <Button
        icon="rollback"
        title="recover study"
        onClick={() => {
          recoverStudyMutation({ id: data.id }).catch(e => message.error(e.message))
        }}
      />
    )
  }
  return <div />
}

const StyledHeader = styled(Layout.Header)`
  background: #ffffff;
  padding: 0px;
  display: flex;
  justify-content: space-between;
`
const CollapsedIcon = styled(Icon)`
  font-size: 20px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  height: 64px;
  :hover {
    color: #1890ff;
  }
`
