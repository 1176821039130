import React, { useState, useEffect, useImperativeHandle } from 'react'
import { Study } from '@curvo/apollo'
import moment from 'moment'
import { DatePicker } from 'antd'
import { AgWrapperPropsType } from './ManufacturerSelectAgWrapper'

export const AgGridDatePickerEditor = React.forwardRef<{}, AgWrapperPropsType<Study>>((params, ref) => {
  const { api } = params
  const [dueDate, setDueDate] = useState<moment.Moment | null>()

  useImperativeHandle(ref, () => ({
    getValue: () => dueDate,
  }))

  useEffect(() => {
    if (dueDate !== undefined) {
      api.stopEditing()
    }
  }, [dueDate, api])

  return (
    <DatePicker
      allowClear
      onChange={v => {
        if (v) {
          setDueDate(v)
        } else {
          setDueDate(null)
        }
      }}
    />
  )
})
