import {
  BRANDS_QUERY,
  Brand,
  BrandCreateInput,
  BrandUpdateInput,
  BrandsData,
  BrandsQueryArgs,
  createBrandMutation,
  deleteBrandMutation,
  updateBrandMutation,
} from '@curvo/apollo'
import { Button, Checkbox, Pagination, Popconfirm, message } from 'antd'
import Table from 'antd/lib/table'
import { omit } from 'lodash'
import React, { useState } from 'react'
import {
  BrandOrProductLineSearchWrapper,
  ColumnGenFunctionType,
  PageWrapper,
  PaginationContainer,
  RedA,
  SearchWrapper,
  StyledSearchInput,
} from './common'
import { BrandAndProductLineDrawer } from './components/EditPanel/CreateEditBrandAndProductLine'
import { EditMode } from './components/EditPanel/EditManufacturer'

export const Brands: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [limitPerPage, setLimitPerPage] = useState(10)
  const [queryArgs, setQueryArgs] = useState<BrandsQueryArgs>({
    first: limitPerPage,
    skip: 0,
  })
  const [selected, setSelected] = useState<Brand>()
  const [editMode, setEditMode] = useState<EditMode>(EditMode.edit)
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const columns = columnsGen(
    brand => {
      setSelected(brand)
      setEditMode(EditMode.edit)
      setIsOpenEdit(true)
    },
    brand => {
      deleteBrandMutation({ id: brand.id }, { refetchQueries: [{ query: BRANDS_QUERY, variables: queryArgs }] })
        .then(() => message.success('deleted'))
        .catch(() => message.error('Unable to delete this Brand, please check if there are parts using this Brand'))
    },
  )
  return (
    <BrandsData variables={queryArgs} fetchPolicy="network-only">
      {({ data, error, loading, refetch }) => {
        if (error) {
          message.error(error.message)
        }
        const brands = data && data.brands ? data.brands.edges.map(edge => edge.node) : []
        const totalPages = data && data.brands && data.brands.metadata ? data.brands.metadata.total : 0
        return (
          <PageWrapper>
            <SearchWrapper>
              <BrandOrProductLineSearchWrapper>
                <StyledSearchInput
                  placeholder="Search Brand by name"
                  enterButton
                  onSearch={searchText => {
                    setQueryArgs(query => ({
                      ...query,
                      searchText,
                    }))
                  }}
                />
                <Checkbox
                  style={{ marginLeft: '10px' }}
                  onChange={e => {
                    setQueryArgs(query => ({
                      ...query,
                      isInactive: e.target.value,
                    }))
                  }}>
                  Inactive
                </Checkbox>
              </BrandOrProductLineSearchWrapper>
              <Button
                type="primary"
                icon="plus"
                onClick={() => {
                  setEditMode(EditMode.create)
                  setIsOpenEdit(true)
                }}>
                New Brand
              </Button>
            </SearchWrapper>
            <Table columns={columns} dataSource={brands} loading={loading} pagination={false} rowKey="id" />
            <PaginationContainer>
              <Pagination
                total={totalPages}
                defaultCurrent={currentPage}
                onChange={pageNumber => {
                  setCurrentPage(pageNumber)
                  setQueryArgs(query => ({
                    ...query,
                    first: limitPerPage,
                    skip: (pageNumber - 1) * limitPerPage,
                  }))
                }}
                showSizeChanger
                onShowSizeChange={(_, size) => setLimitPerPage(size)}
              />
            </PaginationContainer>
            <BrandAndProductLineDrawer
              brandOrProduct={selected}
              onCancel={() => setIsOpenEdit(false)}
              editMode={editMode}
              submitting={submitting}
              onSubmit={v => {
                setSubmitting(true)
                if (editMode === EditMode.create) {
                  createBrandMutation({ input: omit(v, ['id']) as BrandCreateInput })
                    .then(() => {
                      setIsOpenEdit(false)
                    })
                    .then(() => refetch(queryArgs))
                    .then(() => {
                      message.success('created')
                    })
                    .catch(e => {
                      message.error(e.message)
                    })
                    .finally(() => setSubmitting(false))
                } else {
                  updateBrandMutation({ input: v as BrandUpdateInput })
                    .then(() => {
                      setIsOpenEdit(false)
                    })
                    .catch(e => {
                      message.error(e.message)
                    })
                    .finally(() => setSubmitting(false))
                }
              }}
              visible={isOpenEdit}
              width={300}
            />
          </PageWrapper>
        )
      }}
    </BrandsData>
  )
}

const columnsGen: ColumnGenFunctionType<Brand> = (onEdit, onDelete) => [
  {
    title: 'Name',
    dataIndex: 'name',
    width: 200,
    key: 'name',
    sorter: true,
  },
  {
    title: 'Manufacturer',
    dataIndex: 'manufacturer.name',
    width: 200,
    key: 'manufacturer',
    render: (_text: any, record: Brand) =>
      record.manufacturer && `${record.manufacturer.id} | ${record.manufacturer.name}`,
    sorter: true,
  },
  {
    title: 'Action',
    width: 150,
    key: 'action',
    render: (_text: any, record: Brand) => (
      <span>
        <Button type="link" onClick={() => onEdit(record)}>
          Edit
        </Button>
        <span> | </span>
        <Popconfirm
          title={`Are you sure you want to delete Brand ${record.name}?`}
          okText="Yes"
          cancelText="No"
          onConfirm={() => onDelete && onDelete(record)}>
          <RedA>Delete</RedA>
        </Popconfirm>
      </span>
    ),
  },
]
