import { Gic, GicsData } from '@curvo/apollo'
import LoadingIndicator from '@curvo/common-ui/dist/components/Loading/Spinner'
import { Select } from 'antd'
import { LabeledValue, SelectProps } from 'antd/lib/select'
import { debounce } from 'lodash'
import React, { useState } from 'react'
import { StyledSelect, StyledSelectPropsType } from './common'

const { Option } = Select

export const GICSelect: React.FunctionComponent<
  SelectProps<LabeledValue> & StyledSelectPropsType & { onChangeFullValue?: (full?: Gic) => void }
> = ({ onChange, onChangeFullValue, ...props }) => {
  const [searchText, setSearchText] = useState('')
  return (
    <GicsData variables={{ searchText }} fetchPolicy="network-only">
      {({ data, loading }) => (
        <StyledSelect
          onSearch={debounce(setSearchText, 800)}
          loading={loading}
          showSearch
          filterOption={false}
          showArrow={false}
          defaultActiveFirstOption={false}
          placeholder="Select GIC"
          notFoundContent={loading ? <LoadingIndicator /> : 'No Data'}
          labelInValue
          onChange={(v, div) => {
            onChange && onChange(v, div)
            const selectedGic =
              v && v.key ? data && data.gics.edges.find(gic => gic.node.id.toString() === v.key) : undefined
            onChangeFullValue && onChangeFullValue(selectedGic && selectedGic.node)
          }}
          {...props}>
          {data &&
            data.gics &&
            data.gics.edges.map(edge => (
              <Option
                key={
                  props.fullData ? JSON.stringify(edge.node) : edge.node.id
                }>{`${edge.node.id} | ${edge.node.name}`}</Option>
            ))}
        </StyledSelect>
      )}
    </GicsData>
  )
}
