import { useSearchMasterDataGmdnName } from '@curvo/apollo'
import LoadingIndicator from '@curvo/common-ui/dist/components/Loading/Spinner'
import { LabeledValue, SelectProps } from 'antd/lib/select'
import { debounce } from 'lodash'
import React, { useState } from 'react'
import { StyledSelect, StyledSelectPropsType } from '../../Update/components/Select/common'

export const GmdnNameSelector: React.FunctionComponent<SelectProps<LabeledValue> & StyledSelectPropsType> = props => {
  const [searchText, setSearchText] = useState('')

  const { data, loading } = useSearchMasterDataGmdnName({ variables: { input: { search: searchText } } })

  const gmdnNames = (data && data.searchGmdnNames) || []

  return (
    <StyledSelect
      onSearch={debounce(setSearchText, 800)}
      loading={loading}
      labelInValue
      showSearch
      filterOption={false}
      showArrow={false}
      defaultActiveFirstOption={false}
      placeholder="Select Gmdn name"
      notFoundContent={loading ? <LoadingIndicator /> : 'No Data'}
      {...props}>
      {gmdnNames.map(item => (
        <StyledSelect.Option key={item}>{item}</StyledSelect.Option>
      ))}
    </StyledSelect>
  )
}
