import { GridApi, IDetailCellRendererParams, RowNode } from '@ag-grid-enterprise/all-modules'
import { StudyLinkCatalogEnum, StudyLinkType } from '@curvo/apollo'
import { Button, Descriptions } from 'antd'
import React, { useCallback, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { ManufacturerSelect } from '../../../Update/components/Select/ManufacturerSelect'
import { PartSelect } from '../../../Update/components/Select/PartSelect'
import { MasterDataSelector } from '../../components/MasterDataSelector'
import { NormalizedSupplierSelector } from '../../components/NormalizedSupplierSelector'
import { bulkUpdateStudyLinks } from './Buttons'
import {
  Grooming2Context,
  getMatchedSimilarityScore,
  matchedManufacturerValueSetter,
  matchedPartValueSetter,
} from './common'

export const CrossMatching: React.FC<{
  data: StudyLinkType
  gridApi: GridApi
  node: RowNode
  studyId: number
}> = ({ data, gridApi, node, studyId }) => {
  useEffect(() => {
    return () => {
      gridApi.onFilterChanged()
    }
  }, [node, gridApi])
  const { props } = useContext(Grooming2Context)
  const handleApprove = useCallback(
    (catalog: StudyLinkCatalogEnum, tsId: string, approve: boolean, matchId?: string | null) => {
      return bulkUpdateStudyLinks(
        {
          studyId,
          catalog,
          update: {
            isApproved: approve,
            ...(approve && matchId
              ? {}
              : {
                  ...(catalog === StudyLinkCatalogEnum.Stan
                    ? { matchStanId: matchId }
                    : catalog === StudyLinkCatalogEnum.Gudid
                    ? { matchGudidId: matchId }
                    : { matchMedId: matchId }),
                }),
          },
          tsIds: [tsId],
        },
        gridApi,
        props?.lastActionStackHook,
        props?.step,
        props?.catalog,
      )
    },
    [gridApi, studyId, props?.lastActionStackHook, props?.step, props?.catalog],
  )

  return (
    <Wrapper>
      <DescWrapper title={<h4>INPUT</h4>} inputSection>
        <Descriptions.Item label="Manufacturer">{data.manufacturer}</Descriptions.Item>
        <Descriptions.Item label="Mfgitem">{data.mfgitem}</Descriptions.Item>
        <Descriptions.Item label="Desc">{data.description}</Descriptions.Item>
        <Descriptions.Item label="Vendor">{data.vendor}</Descriptions.Item>
        <Descriptions.Item label="Venitem">{data.venitem}</Descriptions.Item>
      </DescWrapper>
      <DescWrapper
        title={
          <div>
            <span style={{ width: 100, display: 'inline-block' }}>
              <h4>STAN</h4>
            </span>{' '}
            <ManufacturerSelect
              width={200}
              value={
                data.stanManufacturerId
                  ? { label: data.stanManufacturer || data.stanManufacturerId, key: data.stanManufacturerId }
                  : undefined
              }
              onChange={v => {
                matchedManufacturerValueSetter(StudyLinkCatalogEnum.Stan, studyId, gridApi)({ newValue: v, node, data })
              }}
            />{' '}
            {data.stanManufacturerId ? (
              <PartSelect
                width={200}
                manufacturerId={data.stanManufacturerId}
                value={data.matchStanId ? { key: data.matchStanId.slice(4), label: data.stanPartNumber } : undefined}
                allowClear={true}
                dropdownMatchSelectWidth={false}
                dropdownMenuStyle={{ width: 1000 }}
                onChange={v => {
                  matchedPartValueSetter({
                    catalog: StudyLinkCatalogEnum.Stan,
                    studyId,
                    gridApi,
                  })({ newValue: v || null, node, data })
                }}
              />
            ) : undefined}{' '}
            <Button
              disabled={!data.matchStanId}
              onClick={() => {
                handleApprove(StudyLinkCatalogEnum.Stan, data.tsId, !data.isStanApproved, data.matchStanId)
              }}>
              {data.isStanApproved ? 'Unapprove' : 'Approve'}
            </Button>{' '}
            {data.matchStanId ? (
              <span>
                Match Similarity: {getMatchedSimilarityScore(data, StudyLinkCatalogEnum.Stan)} | {data.stanMatch}
              </span>
            ) : undefined}
          </div>
        }>
        {data.matchStanId ? (
          <>
            <Descriptions.Item label="Manufacturer">{data.stanManufacturer}</Descriptions.Item>
            <Descriptions.Item label="Part #">{data.stanPartNumber}</Descriptions.Item>
            <Descriptions.Item label="Desc">{data.stanPartDescription}</Descriptions.Item>
          </>
        ) : (
          <Descriptions.Item label="Matched">N/A</Descriptions.Item>
        )}
      </DescWrapper>
      <DescWrapper
        title={
          <div>
            <span style={{ width: 100, display: 'inline-block' }}>
              <h4>GUDID</h4>
            </span>{' '}
            <NormalizedSupplierSelector
              width={200}
              value={(data.gudidManufacturer as any) || undefined}
              onChange={v => {
                matchedManufacturerValueSetter(
                  StudyLinkCatalogEnum.Gudid,
                  studyId,
                  gridApi,
                )({ newValue: v, node, data })
              }}
            />{' '}
            <MasterDataSelector
              source={'udi'}
              allowClear
              width={200}
              showSearch
              onChangeFullValue={v => {
                matchedPartValueSetter({
                  catalog: StudyLinkCatalogEnum.Gudid,
                  studyId,
                  gridApi,
                })({ node, data, newValue: v })
              }}
              value={data.matchGudidId ? { key: data.matchGudidId, label: data.gudidCatalogNumber } : undefined}
              filterOption={false}
              dropdownMatchSelectWidth={false}
              dropdownMenuStyle={{ width: 1000 }}
            />{' '}
            <Button
              disabled={!data.matchGudidId}
              onClick={() => {
                handleApprove(StudyLinkCatalogEnum.Gudid, data.tsId, !data.isGudidApproved, data.matchGudidId)
              }}>
              {data.isGudidApproved ? 'Unapprove' : 'Approve'}
            </Button>{' '}
            {data.matchGudidId ? (
              <span>
                Match Similarity: {getMatchedSimilarityScore(data, StudyLinkCatalogEnum.Gudid)} | {data.gudidMatch}
              </span>
            ) : undefined}
          </div>
        }>
        {data.matchGudidId ? (
          <>
            <Descriptions.Item label="Manufacturer">
              {data.gudidManufacturer || data.gudidCompanyName}
            </Descriptions.Item>
            <Descriptions.Item label="Catalog Number">{data.gudidCatalogNumber}</Descriptions.Item>
            <Descriptions.Item label="Desc">{data.gudidDescription}</Descriptions.Item>
            <Descriptions.Item label="Company Name">{data.gudidCompanyName}</Descriptions.Item>
          </>
        ) : (
          <Descriptions.Item label="Matched">N/A</Descriptions.Item>
        )}
      </DescWrapper>
      <DescWrapper
        title={
          <div>
            <span style={{ width: 100, display: 'inline-block' }}>
              <h4>MEDLINE</h4>
            </span>{' '}
            <NormalizedSupplierSelector
              width={200}
              value={(data.medManufacturer as any) || undefined}
              onChange={v => {
                matchedManufacturerValueSetter(
                  StudyLinkCatalogEnum.Medline,
                  studyId,
                  gridApi,
                )({ newValue: v, node, data })
              }}
            />{' '}
            <MasterDataSelector
              source={'med'}
              allowClear
              showSearch
              width={200}
              onChangeFullValue={v => {
                matchedPartValueSetter({
                  catalog: StudyLinkCatalogEnum.Medline,
                  studyId,
                  gridApi,
                })({ node, data, newValue: v })
              }}
              value={data.matchMedId ? { key: data.matchMedId, label: data.medSku } : undefined}
              filterOption={false}
              dropdownMatchSelectWidth={false}
              dropdownMenuStyle={{ width: 1000 }}
            />{' '}
            <Button
              disabled={!data.matchMedId}
              onClick={() => {
                handleApprove(StudyLinkCatalogEnum.Medline, data.tsId, !data.isMedApproved, data.matchMedId)
              }}>
              {data.isMedApproved ? 'Unapprove' : 'Approve'}
            </Button>{' '}
            {data.matchMedId ? (
              <span>
                Match Similarity: {getMatchedSimilarityScore(data, StudyLinkCatalogEnum.Medline)} | {data.medMatch}
              </span>
            ) : null}
          </div>
        }>
        {data.matchMedId ? (
          <>
            <Descriptions.Item label="Manufacturer">{data.medManufacturer}</Descriptions.Item>
            <Descriptions.Item label="SKU">{data.medSku}</Descriptions.Item>
            <Descriptions.Item label="Desc">{data.medDescription}</Descriptions.Item>
            <div />
            <Descriptions.Item label="Material Code">{data.medMaterialCode}</Descriptions.Item>
          </>
        ) : (
          <Descriptions.Item label="Matched">N/A</Descriptions.Item>
        )}
      </DescWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding 0.5em;
  flex-wrap: wrap;
`

const DescWrapper = styled(Descriptions)<{ inputSection?: boolean }>`
  .ant-descriptions-item-label {
    font-weight: bold;
  }
  margin-right: 1em;
  margin-bottom: 0.5em;
  border-bottom: ${({ inputSection }) => (inputSection ? '3px' : '1px')} solid grey;
`

export const AgCrossMatchingRenderer: React.FC<IDetailCellRendererParams & { studyId: number }> = ({
  data,
  api,
  node,
  studyId,
}) => {
  return <CrossMatching data={data} gridApi={api} node={node} studyId={studyId} />
}
