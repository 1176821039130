import { MaterialCreateInput } from '@curvo/apollo'
import { Form, Input } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import React, { useState } from 'react'
import { EditPanelWrapper } from '../../../../../components/common'
import { EditDrawer } from '../../../../../components/EditDrawer'

const newMaterialCreateInput = (): MaterialCreateInput => ({
  id: '',
  name: '',
  longName: '',
})

export const CreateMaterialDrawer: React.FunctionComponent<
  {
    onSubmit: (creating: MaterialCreateInput) => void
    onCancel: () => void
  } & DrawerProps
> = ({ onSubmit, onCancel, ...drawerProps }) => {
  const [editing, setEditing] = useState<MaterialCreateInput>(newMaterialCreateInput())

  const getValue = (field: keyof MaterialCreateInput) => editing[field]
  const handleChange = (field: keyof MaterialCreateInput) => (e: React.ChangeEvent<HTMLInputElement>) => {
    editing &&
      setEditing({
        ...editing,
        [field]: e.target.value,
      })
  }
  return (
    <EditDrawer
      onCancel={onCancel}
      onSave={() => {
        onSubmit(editing)
        setEditing(newMaterialCreateInput())
      }}
      {...drawerProps}>
      {editing && (
        <EditPanelWrapper>
          <Form.Item label="ID">
            <Input value={getValue('id')} onChange={handleChange('id')} />
          </Form.Item>
          <Form.Item label="Name">
            <Input value={getValue('name')} onChange={handleChange('name')} />
          </Form.Item>
          <Form.Item label="Long Name">
            <Input value={getValue('longName')} onChange={handleChange('longName')} />
          </Form.Item>
        </EditPanelWrapper>
      )}
    </EditDrawer>
  )
}
