import { StudyTransactionType } from '@curvo/apollo'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import { AgWrapperPropsType } from './ManufacturerSelectAgWrapper'
import { NormalizedSupplierSelector } from './NormalizedSupplierSelector'

export const NormalizedSupplierSelectAgWrapper = React.forwardRef<{}, AgWrapperPropsType<StudyTransactionType>>(
  (params, ref) => {
    const { api } = params
    const [normalizedCurvo, setNormalizedCurvo] = useState<string | undefined>()

    useImperativeHandle(ref, () => ({
      getValue: () => normalizedCurvo,
    }))

    useEffect(() => {
      if (normalizedCurvo) {
        api.stopEditing()
      }
    }, [normalizedCurvo, api])

    return (
      <NormalizedSupplierSelector
        allowClear
        onChange={v => {
          if (v) {
            setNormalizedCurvo(v.key)
          } else {
            setNormalizedCurvo(undefined)
          }
        }}
      />
    )
  },
)
