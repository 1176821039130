import { AllowedGicsTypeTwoData, GicTypeTwo } from '@curvo/apollo'
import { SelectInput } from '@curvo/common-ui'
import React from 'react'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & {
  gicId?: number
  gicTypeOneId?: string
  value: any
  onChange: (option: any) => void
}

const transformDataToOption = (data: GicTypeTwo[]) => {
  if (!data) {
    return []
  }
  return data.map(gicTypeTwo => ({
    label: gicTypeTwo.name,
    value: gicTypeTwo.id,
  }))
}

const GicTypeTwosSelect: React.SFC<Props> = ({ gicId, gicTypeOneId, onChange, value }) => (
  <AllowedGicsTypeTwoData variables={{ gicId: gicId ? +gicId : -1, typeOneId: gicTypeOneId ? gicTypeOneId : '' }}>
    {({ data, loading }) => {
      return (
        <SelectInput
          label="Type 2"
          loading={loading}
          options={data ? transformDataToOption(data.allowedGicsTypeTwo) : []}
          disabled={gicTypeOneId ? false : true}
          value={value}
          onChange={onChange}
        />
      )
    }}
  </AllowedGicsTypeTwoData>
)

GicTypeTwosSelect.defaultProps = defaultProps

export default GicTypeTwosSelect
