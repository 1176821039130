import { ColDef, ColGroupDef, ValueFormatterParams } from '@ag-grid-community/core'
import moment from 'moment'

export const columns: (ColDef | ColGroupDef)[] = [
  {
    colId: 'selection',
    checkboxSelection: true,
    pinned: 'left',
    width: 40,
  },
  {
    headerName: 'Part',
    marryChildren: true,
    children: [
      {
        headerName: 'ID',
        colId: 'id',
        field: 'id',
        type: 'text',
        hide: true,
      },
      {
        headerName: 'Vendor',
        colId: 'vendor',
        field: 'vendor',
        type: 'text',
      },
      {
        headerName: 'Venitem',
        colId: 'venitem',
        field: 'venitem',
        type: 'text',
      },
      {
        headerName: 'Manufacturer',
        colId: 'manufacturer',
        field: 'manufacturer',
        type: 'text',
      },
      {
        headerName: 'Mfgitem',
        colId: 'mfgitem',
        field: 'mfgitem',
        type: 'text',
      },
      {
        headerName: 'Description',
        colId: 'description',
        field: 'description',
        type: 'text',
      },
    ],
  },
  {
    headerName: 'Block Suggesting Item',
    marryChildren: true,
    children: [
      {
        headerName: 'Block Item Id',
        colId: 'blockMatchId',
        field: 'blockMatchId',
        type: 'text',
      },
      {
        headerName: 'Block Catalog',
        colId: 'blockMatchCatalog',
        field: 'blockMatchCatalog',
        type: 'text',
      },
      {
        headerName: 'Block Manufacturer',
        colId: 'blockManufacturer',
        field: 'blockManufacturer',
        type: 'text',
      },
      {
        headerName: 'Block Manufacturer Id',
        colId: 'blockMfgitem',
        field: 'blockMfgitem',
        type: 'text',
      },
      {
        headerName: 'Block Item Description',
        colId: 'blockDescription',
        field: 'blockDescription',
        type: 'text',
      },
    ],
  },
  {
    headerName: 'Meta',
    marryChildren: true,
    children: [
      {
        headerName: 'Created By',
        colId: 'createdBy',
        field: 'createdBy',
        type: 'text',
        filter: false,
        valueGetter: params => {
          return params.data.createdByUser?.username || ''
        },
      },
      {
        headerName: 'Created At',
        colId: 'createdAt',
        field: 'createdAt',
        type: 'text',
        filter: false,
        valueFormatter: (params: ValueFormatterParams) => moment(parseInt(params.value, 10)).format('lll'),
      },
    ],
  },
]
