import React, { useState, useEffect } from 'react'
import { Form, InputNumber, Checkbox } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import {
  PurchaseOrderTransactionCreateInput,
  StudyTransactionBulkUpdateInput,
  Part,
  Gic,
  GicTypeOne,
  GicTypeTwo,
  Material,
  StudyTransactionType,
} from '@curvo/apollo'
import { pick } from 'lodash'
import { LabeledValue } from 'antd/lib/select'
import { EditPanelWrapper } from '../../../../components/common'
import { EditDrawer } from '../../../../components/EditDrawer'
import { GICSelect } from '../../Update/components/Select/GICSelect'
import { GICsTypeOneSelect } from '../../Update/components/Select/GICTypeOneSelect'
import { GicsTypeTwoSelect } from '../../Update/components/Select/GICTypeTwoSelect'
import { MaterialSelect } from '../../Update/components/Select/MaterialSelect'
import { ManufacturerSelect } from '../../Update/components/Select/ManufacturerSelect'
import { PartSearchSelect } from './PartSearchAgWrapper'

type Props = DrawerProps & {
  onSave: (newPurchaseOrderPart: PurchaseOrderTransactionCreateInput) => void
  onCancel: () => void
  isEditMode?: boolean
  selectedTransaction?: StudyTransactionType
}

type SelectedPart = Pick<Part, 'id' | 'partNumber' | 'partName'>

export const PurchaseOrderPartCreateDrawer: React.FC<Props> = ({
  onSave,
  isEditMode,
  selectedTransaction,
  ...props
}) => {
  const [manufacturerId, setManufacturerId] = useState<LabeledValue>()
  const [input, setInput] = useState<PurchaseOrderTransactionCreateInput | StudyTransactionBulkUpdateInput>({})
  const [selectedPart, setSelectedPart] = useState<SelectedPart>()

  useEffect(() => {
    if (selectedTransaction) {
      setManufacturerId(
        selectedTransaction.matchManufacturerId
          ? {
              key: selectedTransaction.matchManufacturerId,
              label: selectedTransaction.bamfManufacturer,
            }
          : undefined,
      )
      setSelectedPart(
        selectedTransaction.bamfPartId || selectedTransaction.matchPartId
          ? {
              id: (selectedTransaction.bamfPartId || selectedTransaction.matchPartId)!,
              partNumber: selectedTransaction.bamfPartNumber!,
              partName: selectedTransaction.bamfPartDescription!,
            }
          : undefined,
      )
      setInput({
        isApproved: selectedTransaction.isApproved,
        matchPartId: selectedTransaction.bamfPartId || selectedTransaction.matchPartId,
        gic:
          selectedTransaction.gicId && selectedTransaction.gicName
            ? {
                id: selectedTransaction.gicId,
                name: selectedTransaction.gicName,
              }
            : undefined,
        typeOne:
          selectedTransaction.typeOneId && selectedTransaction.typeOneName
            ? {
                id: selectedTransaction.typeOneId,
                name: selectedTransaction.typeOneName,
              }
            : undefined,
        typeTwo:
          selectedTransaction.typeTwoId && selectedTransaction.typeTwoName
            ? {
                id: selectedTransaction.typeTwoId,
                name: selectedTransaction.typeTwoName,
              }
            : undefined,
        material:
          selectedTransaction.materialId && selectedTransaction.materialName
            ? {
                id: selectedTransaction.materialId,
                name: selectedTransaction.materialName,
              }
            : undefined,
        unitprice: selectedTransaction.unitprice || undefined,
        qtypurchase: selectedTransaction.qtypurchase,
        extprice: selectedTransaction.extprice,
      })
    } else {
      setInput({})
    }
  }, [selectedTransaction])

  useEffect(() => {
    if (!props.visible) {
      setInput({})
      setSelectedPart(undefined)
      setManufacturerId(undefined)
    }
  }, [props.visible])

  return (
    <EditDrawer
      width={500}
      {...props}
      title={isEditMode ? 'Edit Transaction' : 'Add New Purchase Order Transaction'}
      onSave={() => onSave(input)}>
      <EditPanelWrapper>
        <Form.Item label="Manufacturer">
          <ManufacturerSelect
            value={manufacturerId}
            onChange={manufacturer => {
              setManufacturerId(manufacturer)
            }}
          />
        </Form.Item>
        <Form.Item label="Part">
          <PartSearchSelect
            value={
              selectedPart
                ? {
                    key: selectedPart.id,
                    label: `${selectedPart.partNumber} | ${selectedPart.partName}`,
                  }
                : undefined
            }
            manufacturerId={manufacturerId && manufacturerId.key}
            onFullValueChange={part => {
              if (part) {
                setInput({
                  ...input,
                  matchPartId: part.id,
                  gic: pick<Gic, 'id' | 'name'>(part.gic, ['id', 'name']),
                  typeOne: part.typeOne && pick<GicTypeOne, 'id' | 'name'>(part.typeOne, ['id', 'name']),
                  typeTwo: part.typeTwo && pick<GicTypeTwo, 'id' | 'name'>(part.typeTwo, ['id', 'name']),
                  material: part.material && pick<Material, 'id' | 'name'>(part.material, ['id', 'name']),
                })
              }
              setSelectedPart(part)
            }}
          />
        </Form.Item>
        <Form.Item label="Gic">
          <GICSelect
            value={
              input.gic ? { key: input.gic.id.toString(), label: `${input.gic.id} | ${input.gic.name}` } : undefined
            }
            onChangeFullValue={v => {
              const newValue = v && { id: v.id, name: v.name }
              setInput({
                ...input,
                gic: newValue,
                matchPartId: undefined,
              })
            }}
          />
        </Form.Item>
        <Form.Item label="Gic Type One">
          <GICsTypeOneSelect
            value={input.typeOne ? { key: input.typeOne.id, label: input.typeOne.name } : undefined}
            onChangeFullValue={v => {
              const newValue = v && { id: v.id, name: v.name }
              setInput({
                ...input,
                typeOne: newValue,
                matchPartId: undefined,
              })
            }}
          />
        </Form.Item>
        <Form.Item label="Gic Type Two">
          <GicsTypeTwoSelect
            value={input.typeTwo ? { key: input.typeTwo.id, label: input.typeTwo.name } : undefined}
            onChangeFullValue={v => {
              const newValue = v && { id: v.id, name: v.name }
              setInput({
                ...input,
                typeTwo: newValue,
                matchPartId: undefined,
              })
            }}
          />
        </Form.Item>
        <Form.Item label="Material">
          <MaterialSelect
            value={input.material ? { key: input.material.id, label: input.material.name } : undefined}
            onChangeFullValue={v => {
              const newValue = v && { id: v.id, name: v.name }
              setInput({
                ...input,
                material: newValue,
                matchPartId: undefined,
              })
            }}
          />
        </Form.Item>
        <Form.Item label="Qty Purchase">
          <InputNumber
            placeholder="0"
            type="number"
            value={input.qtypurchase || 0.0}
            onChange={v => setInput({ ...input, qtypurchase: v })}
          />
        </Form.Item>
        <Form.Item label="Unit Price">
          <InputNumber
            placeholder="0"
            type="number"
            value={input.unitprice || 0.0}
            onChange={v => setInput({ ...input, unitprice: v })}
          />
        </Form.Item>
        <Form.Item label="Ext Price">
          <InputNumber
            placeholder="0"
            disabled
            type="number"
            value={input.qtypurchase && input.unitprice ? input.qtypurchase * input.unitprice : 0}
          />
        </Form.Item>
        {isEditMode ? (
          <Form.Item label="Is Approved">
            <Checkbox
              onChange={v => setInput({ ...input, isApproved: v.target.checked })}
              checked={(input as StudyTransactionBulkUpdateInput).isApproved || false}
            />
          </Form.Item>
        ) : null}
      </EditPanelWrapper>
    </EditDrawer>
  )
}
