import { Input } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import styled from 'styled-components'
const { Search } = Input

export const PageWrapper = styled.div`
  padding: 16px;
`

export const NormalTextSpan = styled.span`
  font-weight: normal;
`

export const RedA = styled.a`
  color: red;
`

export const PaginationContainer = styled.div`
  margin-top: 16px;
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
`

export const SearchWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`

export const BrandOrProductLineSearchWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StyledSearchInput = styled(Search)`
  width: 200px;
`

export type ColumnGenFunctionType<T> = (onEdit: (record: T) => void, onDelete?: (record: T) => void) => ColumnProps<T>[]
