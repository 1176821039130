import {
  Brand,
  BrandCreateInput,
  BrandUpdateInput,
  ProductLine,
  ProductLineCreateInput,
  ProductLineUpdateInput,
} from '@curvo/apollo'
import { Colors } from '@curvo/common-ui'
import { Checkbox, Form, Input } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import { LabeledValue } from 'antd/lib/select'
import React, { useEffect, useState } from 'react'
import { EditDrawer } from '../../../../../components/EditDrawer'
import { EditPanelWrapper } from '../../../../../components/common'
import { ManufacturerSelect } from '../Select/ManufacturerSelect'
import { EditMode } from './EditManufacturer'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

export const BrandAndProductLineDrawer: React.FunctionComponent<
  {
    submitting?: boolean
    brandOrProduct?: Brand | ProductLine
    onSubmit: (name: BrandUpdateInput | BrandCreateInput | ProductLineUpdateInput | ProductLineCreateInput) => void
    onCancel: () => void
    editMode: EditMode
  } & DrawerProps
> = ({ editMode, brandOrProduct, onSubmit, onCancel, submitting, ...drawerProps }) => {
  const [editing, setEditing] = useState<BrandUpdateInput | BrandCreateInput>({ id: '' })
  useEffect(() => {
    if (editMode === EditMode.edit && brandOrProduct) {
      setEditing({
        id: brandOrProduct.id,
        name: brandOrProduct.name,
        manufacturerId: (brandOrProduct.manufacturer && brandOrProduct.manufacturer.id) || '',
        isInactive: editing.isInactive || false,
      })
    } else {
      setEditing({
        name: '',
        manufacturerId: '',
        isInactive: false,
      })
    }
  }, [brandOrProduct, editMode, editing.isInactive])
  return (
    <EditDrawer
      onCancel={onCancel}
      isDisabled={submitting}
      loading={submitting}
      onSave={() => {
        onSubmit(editing)
        setEditing({ id: '' })
      }}
      {...drawerProps}>
      <EditPanelWrapper>
        {editMode === EditMode.edit && brandOrProduct && (
          <Form.Item label="ID">
            <Input value={brandOrProduct.id} disabled />
          </Form.Item>
        )}
        <Form.Item label="Name">
          <Input
            color={editing.isInactive ? Colors.Warning : Colors.Black}
            value={editing.name || ''}
            onChange={e => setEditing({ ...editing, name: e.target.value })}
            disabled={submitting}
          />
        </Form.Item>
        <Form.Item label="Manufacturer">
          <ManufacturerSelect
            value={
              (editing.manufacturerId && {
                key: editing.manufacturerId,
                label: (brandOrProduct && brandOrProduct.manufacturer && brandOrProduct.manufacturer.name) || '',
              }) ||
              undefined
            }
            disabled={submitting}
            onChange={v =>
              setEditing(ed => ({
                ...ed,
                manufacturerId: (v as LabeledValue).key,
              }))
            }
          />
        </Form.Item>
        <Form.Item {...layout} label="Deactivate">
          <Checkbox onChange={e => setEditing({ ...editing, isInactive: e.target.value })} disabled={submitting} />
        </Form.Item>
      </EditPanelWrapper>
    </EditDrawer>
  )
}
