import {
  AllowedGicMaterialUpdateInput,
  AllowedGicsMaterialData,
  Gic,
  ListItemUpdateType,
  MaterialsData,
} from '@curvo/apollo'
import { LabeledValue, SelectProps } from 'antd/lib/select'
import React, { useState } from 'react'
import { StyledMultipleSelect, StyledSelectPropsType, TypedMultipleSelect } from './common'

type GicMaterialProps = {
  gic?: Gic
  onSelect: (v?: LabeledValue) => void
  onDeselect: (v?: LabeledValue) => void
  allowedGicMaterials: AllowedGicMaterialUpdateInput[] | null | undefined
}

type GicMaterialSelectProps = SelectProps<LabeledValue | LabeledValue[] | undefined> &
  StyledSelectPropsType & {
    createNewIfNotFound?: boolean
  }

export const GicMaterial: React.FC<GicMaterialProps> = ({ gic, onSelect, onDeselect, allowedGicMaterials }) => {
  return (
    <AllowedGicsMaterialData fetchPolicy="network-only" variables={{ gicId: (gic && gic.id) || 0 }}>
      {({ data }) => {
        const allowedMaterials = ((data && data.allowedGicsMaterial) || []).map(typeOne => ({
          key: typeOne.id,
          label: typeOne.name,
        }))
        const genAllowedGicsMaterialValue = () =>
          (allowedGicMaterials || []).reduce((prev, cur) => {
            if (cur.updateType === ListItemUpdateType.Add) {
              return prev.concat([{ key: cur.gicMaterialId, label: cur.gicMaterialId }])
            }
            return prev.filter(p => p.key !== cur.gicMaterialId)
          }, allowedMaterials)

        return (
          <GICMaterialSelect
            mode="multiple"
            onSelect={onSelect}
            onDeselect={onDeselect}
            value={genAllowedGicsMaterialValue()}
          />
        )
      }}
    </AllowedGicsMaterialData>
  )
}

export const GICMaterialSelect: React.FC<GicMaterialSelectProps> = ({ onChange, ...props }) => {
  const [searchText, setSearchText] = useState('')

  return (
    <MaterialsData fetchPolicy="network-only" variables={{ searchText }}>
      {({ data, loading }) => {
        const materials = data && data.materials ? data.materials.edges : []

        return (
          <StyledMultipleSelect
            onSearch={setSearchText}
            loading={loading}
            showSearch
            labelInValue
            filterOption={false}
            showArrow={false}
            defaultActiveFirstOption={false}
            placeholder="Select GIC Material"
            onChange={onChange}
            {...props}>
            {materials.map(material => (
              <TypedMultipleSelect.Option key={material.node.id}>{material.node.name}</TypedMultipleSelect.Option>
            ))}
          </StyledMultipleSelect>
        )
      }}
    </MaterialsData>
  )
}
