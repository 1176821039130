import {
  PARTS_QUERY,
  Part,
  PartCreateInput,
  PartFilterInput,
  PartUpdateInput,
  PartsData,
  PartsOrderByEnum,
  PartsQueryArgs,
  SortOrderEnum,
  createPartMutation,
  deletePartMutation,
  partUpdateMutation,
} from '@curvo/apollo'
import { Button, Checkbox, Input, Pagination, Popconfirm, Table, message } from 'antd'
import { LabeledValue } from 'antd/lib/select'
import { useState } from 'react'
import styled from 'styled-components'
import { ResizableHeader } from '../../../components/ResizableColumnHeader'
import { ColumnGenFunctionType, PageWrapper, PaginationContainer, RedA } from './common'
import { DropdownFilter } from './components/DropdownFilter'
import { EditMode } from './components/EditPanel/EditManufacturer'
import { EditPart } from './components/EditPanel/EditPart'
import { BrandSelect } from './components/Select/BrandSelect'
import { GICSelect } from './components/Select/GICSelect'
import { ManufacturerSelect } from './components/Select/ManufacturerSelect'
import { ProductLineSelect } from './components/Select/ProductLineSelect'

const { Search } = Input
const sortMapping: Partial<Record<keyof Part, keyof PartFilterInput>> = {
  brand: 'brandId',
  manufacturer: 'manufacturerId',
  gic: 'gicId',
  productLine: 'productLineId',
}

export const Parts = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [openEdit, setOpenEdit] = useState(false)
  const [editMode, setEditMode] = useState<EditMode>(EditMode.edit)
  const [editting, setEditting] = useState(false)
  const [selectedPart, setSelectedPart] = useState<Part>()
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [searchCritical, setSearchCritical] = useState<PartsQueryArgs>({
    first: limit,
    skip: 0,
  })
  const [columns, setColumns] = useState(
    columnsTemplate(
      record => {
        setSelectedPart(record)
        setOpenEdit(true)
        setEditMode(EditMode.edit)
      },
      record => {
        deletePartMutation({ id: record.id }, { refetchQueries: [{ query: PARTS_QUERY, variables: searchCritical }] })
      },
    ).map((col, i) => ({
      ...col,
      onHeaderCell: () => ({
        width: col.width,
        onResize: (size: number) => {
          const nextCols = [...columns]
          nextCols[i].width = size
          setColumns(nextCols)
        },
      }),
    })),
  )
  return (
    <PageWrapper>
      <PartsData variables={searchCritical} fetchPolicy="network-only" selfHandleError>
        {({ data, loading, error }) => {
          if (error) {
            message.error(error.message)
          }
          const parts = data && data.parts ? data.parts.edges : []
          const total = data && data.parts ? data.parts.metadata.total : 0
          return (
            <div>
              <SearchWrapper>
                <CheckboxSearchWrapper>
                  <StyledSearchInput
                    placeholder="Search"
                    enterButton
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    onSearch={searchText => {
                      setSearchCritical({
                        ...searchCritical,
                        searchText,
                      })
                    }}
                  />
                  <Checkbox
                    style={{ marginLeft: '10px' }}
                    onChange={() => {
                      setSearchCritical(query => ({
                        ...query,
                        isInactive: !query.isInactive,
                      }))
                    }}>
                    Inactive
                  </Checkbox>
                </CheckboxSearchWrapper>

                <Button
                  type="primary"
                  icon="plus"
                  onClick={() => {
                    setOpenEdit(true)
                    setEditMode(EditMode.create)
                  }}>
                  New Part
                </Button>
              </SearchWrapper>
              <Table
                bordered
                loading={loading}
                columns={columns}
                components={{ header: { cell: ResizableHeader } }}
                dataSource={parts.map(edge => edge.node)}
                rowKey={record => record.id}
                pagination={false}
                onChange={(_pagination, filters, sorter) => {
                  setSearchCritical({
                    ...searchCritical,
                    args: Object.keys(filters).reduce(
                      (args, key) => ({
                        ...args,
                        [sortMapping[key]]:
                          key === 'gic' && filters[key] && filters[key]!.length
                            ? parseInt(filters[key]![0], 10)
                            : filters[key][0],
                      }),
                      searchCritical.args,
                    ),
                    orderBy: sorter.columnKey
                      ? [
                          {
                            sort: sorter.column.key as PartsOrderByEnum,
                            direction: sorter.order === 'descend' ? SortOrderEnum.Desc : SortOrderEnum.Asc,
                          },
                        ]
                      : undefined,
                  })
                }}
              />
              <PaginationContainer>
                <Pagination
                  total={total}
                  defaultCurrent={currentPage}
                  onChange={pageNumber => {
                    setCurrentPage(pageNumber)
                    setSearchCritical({
                      ...searchCritical,
                      first: limit,
                      skip: (pageNumber - 1) * limit,
                    })
                  }}
                  showSizeChanger
                  onShowSizeChange={(_, size) => setLimit(size)}
                />
              </PaginationContainer>
              <EditPart
                part={selectedPart}
                editMode={editMode}
                submitting={editting}
                onSubmit={editingPart => {
                  setEditting(true)
                  if (editMode === EditMode.edit) {
                    partUpdateMutation({ input: editingPart as PartUpdateInput })
                      .then(() => {
                        setOpenEdit(false)
                        message.success('Updated')
                      })
                      .catch(e => {
                        message.error(e.message)
                      })
                      .finally(() => setEditting(false))
                    setSelectedPart(undefined)
                  } else {
                    createPartMutation({ input: editingPart as PartCreateInput })
                      .then(() => {
                        setOpenEdit(false)
                        message.success('Created')
                      })
                      .catch(e => {
                        if (e.message && e.message.startsWith('Duplicated with parts: ')) {
                          const [partNumber, manufacturerId] = e.message
                            .substr('Duplicated with parts: '.length)
                            .split('|')
                          message.error(
                            <Button
                              onClick={() => {
                                setSearch(partNumber)
                                setOpenEdit(false)
                                setSearchCritical({
                                  searchText: partNumber,
                                  args: { manufacturerId },
                                })
                              }}
                              type="link">
                              {e.message}
                            </Button>,
                          )
                          return
                        }
                        message.error(e.message)
                      })
                      .finally(() => setEditting(false))
                  }
                }}
                onCancel={() => {
                  setOpenEdit(false)
                  setSelectedPart(undefined)
                }}
                visible={openEdit}
                title={
                  selectedPart &&
                  `Edit ${selectedPart.partNumber} ${selectedPart.manufacturer ? selectedPart.manufacturer.name : ''}`
                }
              />

              <StyledTotalResultsSpan>
                <StyledTotalResultBold>{total} </StyledTotalResultBold>
                results
              </StyledTotalResultsSpan>
            </div>
          )
        }}
      </PartsData>
    </PageWrapper>
  )
}

const StyledTotalResultsSpan = styled.span`
  font-style: italic;
  margin-right: 12px;
  opacity: 0.8;
  font-size: 13px;
`

const StyledTotalResultBold = styled.span`
  font-weight: 800;
`

const SearchWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`

const CheckboxSearchWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledSearchInput = styled(Search)`
  width: 200px;
`

const columnsTemplate: ColumnGenFunctionType<Part> = (onEdit, onDelete) => [
  {
    title: 'Manufacturer',
    dataIndex: 'manufacturer.name',
    width: 100,
    key: 'manufacturer',
    render: (text: string, _record: Part, _index: number) => <BlueTextSpan>{text}</BlueTextSpan>,
    filterDropdown: props => (
      <DropdownFilter {...props}>
        {({ selected, setSelected }) => (
          <ManufacturerSelect value={selected} onChange={v => setSelected(v as LabeledValue)} />
        )}
      </DropdownFilter>
    ),
    sorter: true,
  },
  {
    title: 'Part Number',
    width: 100,
    dataIndex: 'partNumber',
    key: 'partNumber',
    sorter: true,
  },
  {
    title: 'Description',
    width: 150,
    dataIndex: 'partName',
    key: 'partName',
    sorter: true,
  },
  {
    title: 'Brand',
    dataIndex: 'brand.name',
    width: 100,
    key: 'brand',
    render: (text: string, _record: Part, _index: number) => <BlueTextSpan>{text}</BlueTextSpan>,
    filterDropdown: props => (
      <DropdownFilter {...props}>
        {({ selected, setSelected }) => <BrandSelect value={selected} onChange={v => setSelected(v as LabeledValue)} />}
      </DropdownFilter>
    ),
    sorter: true,
  },
  {
    title: 'GIC',
    width: 100,
    key: 'gic',
    render: (_: string, record: Part, _index: number) => (
      <BlueTextSpan>{`${record.gic.id} | ${record.gic.name}`}</BlueTextSpan>
    ),
    filterDropdown: props => (
      <DropdownFilter {...props}>
        {({ selected, setSelected }) => <GICSelect value={selected} onChange={setSelected} />}
      </DropdownFilter>
    ),
    sorter: true,
  },
  {
    title: 'Product Line',
    dataIndex: 'productLine.name',
    width: 100,
    key: 'productLine',
    render: (text: string, _record: Part, _index: number) => <BlueTextSpan>{text}</BlueTextSpan>,
    filterDropdown: props => (
      <DropdownFilter {...props}>
        {({ selected, setSelected }) => <ProductLineSelect value={selected} onChange={setSelected} />}
      </DropdownFilter>
    ),
    sorter: true,
  },
  {
    title: 'Note',
    dataIndex: 'description',
    width: 100,
    key: 'note',
    render: (text: string, _record: Part, _index: number) => <BlueTextSpan>{text}</BlueTextSpan>,
  },
  {
    title: 'Action',
    key: 'action',
    width: 150,
    render: (_text: any, record: Part) => (
      <span>
        <Button type="link" onClick={() => onEdit(record)}>
          Edit
        </Button>
        <span> | </span>
        <Popconfirm
          title={`Are you sure you want to delete Part: ${record.partNumber}?`}
          okText="Yes"
          cancelText="No"
          onConfirm={() => {
            onDelete && onDelete(record)
          }}>
          <RedA>Delete</RedA>
        </Popconfirm>
      </span>
    ),
  },
]

const BlueTextSpan = styled.span`
  color: #0288d1;
`
