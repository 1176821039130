// import styled from 'styled-components'
import {
  ALLOWED_GIC_TYPE_ONE_QUERY,
  createGicMutation,
  deleteGicMutation,
  Gic,
  GicConnection,
  GicsData,
  GicsOrderByEnum,
  GicsQueryArgs,
  GICS_QUERY,
  GICS_TYPE_ONE_QUERY,
  GicTypeOne,
  GicUpdateInput,
  ListItemUpdateType,
  SortOrderEnum,
  updateGicMutation,
  Client,
  Query,
  FULL_GICS_QUERY,
} from '@curvo/apollo'
import { Button, message, Pagination, Popconfirm, Table, Row, Col } from 'antd'
import { range } from 'lodash'
import { ColumnProps } from 'antd/es/table'
import { useCallback, useEffect, useState } from 'react'
import { AllowedGicsTypeOne } from '../../../components/GICTypesTreeView'
import { ResizableHeader } from '../../../components/ResizableColumnHeader'
import { ColumnGenFunctionType, PageWrapper, PaginationContainer, RedA, StyledSearchInput } from './common'
import { CreateGIC } from './components/EditPanel/CreateGIC'
import { EditGIC } from './components/EditPanel/EditGIC'
import { JSONToCSVConvertor } from './Segment'

export const GICs = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [limitPerPage, setLimitPerPage] = useState(10)
  const [queryArgs, setQueryArgs] = useState<GicsQueryArgs>({
    first: limitPerPage,
    skip: 0,
  })
  const [selectedGIC, setSelectedGIC] = useState<Gic>()
  const [showEditPanel, setShowEditPanel] = useState(false)
  const [showCreatePanel, setShowCreatePanel] = useState(false)
  const [columns, setColumns] = useState<ColumnProps<Gic>[]>([])
  useEffect(() => {
    setColumns(
      columnsGen(
        gic => {
          setSelectedGIC(gic)
          setShowEditPanel(true)
        },
        gic => {
          handleGicDeleteMutation(queryArgs, gic.id)
        },
      ),
    )
  }, [queryArgs])
  const handleUpdateColumnSize = (index: number) => (size: number) => {
    const nextCols = [...columns]
    nextCols[index].width = size
    setColumns(nextCols)
  }

  const AllowedGicsTypeOneRenderer = useCallback((gic: Gic) => <AllowedGicsTypeOne gic={gic} />, [])

  const client = Client.getClient()

  return (
    <PageWrapper>
      <GicsData variables={queryArgs} fetchPolicy="network-only" selfHandleError>
        {({ data, error, loading }) => {
          if (error) {
            message.error(error.message)
          }
          const gics = data && data.gics ? data.gics.edges.map(edge => edge.node) : []
          const totalPages = data && data.gics ? data.gics.metadata.total : 0
          const handleExport = async () => {
            const results = await Promise.all(
              range(0, totalPages, 10).map(offset =>
                client.query<Pick<Query, 'gics'>>({
                  query: FULL_GICS_QUERY,
                  variables: { ...queryArgs, skip: offset },
                }),
              ),
            )
            const allGics = results
              .map(
                result =>
                  result?.data.gics?.edges
                    .map(edge => {
                      return edge.node.allowedGicTypeOnes?.length
                        ? edge.node.allowedGicTypeOnes
                            .map(typeOne =>
                              typeOne.allowedGicTypeTwos?.length
                                ? typeOne.allowedGicTypeTwos.map(typeTwo => ({
                                    ...edge.node,
                                    typeTwoName: typeTwo.name,
                                    typeTwoIncludes: typeTwo.includes,
                                    typeOneName: typeOne.gicTypeOne?.name,
                                    typeOneIncludes: typeOne.gicTypeOne?.includes,
                                    typeOneExcludes: typeOne.gicTypeOne?.excludes,
                                    allowedGicTypeOnes: undefined,
                                    __typename: undefined,
                                  }))
                                : [
                                    {
                                      ...edge.node,
                                      allowedGicTypeOnes: undefined,
                                      __typename: undefined,

                                      typeOneName: typeOne.gicTypeOne?.name,
                                      typeOneIncludes: typeOne.gicTypeOne?.includes,
                                      typeOneExcludes: typeOne.gicTypeOne?.excludes,
                                    },
                                  ],
                            )
                            .flat()
                        : [
                            {
                              ...edge.node,
                              allowedGicTypeOnes: undefined,
                              __typename: undefined,
                            },
                          ]
                    })
                    .flat() || [],
              )
              .flat()
            JSONToCSVConvertor(allGics, 'gics.csv', true)
          }
          return (
            <div>
              <Row gutter={16}>
                <Col md={4}>
                  <StyledSearchInput
                    placeholder="Search GIC by name"
                    enterButton
                    onSearch={(searchText: string) => {
                      setQueryArgs(query => ({
                        ...query,
                        searchText,
                      }))
                    }}
                  />
                </Col>
                <Col md={16} />
                <Col md={2}>
                  <Button type="primary" icon="plus" onClick={() => setShowCreatePanel(true)}>
                    New GIC
                  </Button>
                </Col>
                <Col md={2}>
                  <Button type="primary" icon="export" onClick={handleExport}>
                    Export
                  </Button>
                </Col>
              </Row>
              <Table
                loading={loading}
                components={{ header: { cell: ResizableHeader } }}
                columns={columns.map((col, i) => ({
                  ...col,
                  onHeaderCell: () => ({ width: col.width, onResize: handleUpdateColumnSize(i) }),
                }))}
                dataSource={gics}
                rowKey={record => record.id.toString()}
                onChange={(_, __, sorter) =>
                  setQueryArgs({
                    ...queryArgs,
                    orderBy: sorter.field ? (sorter.column.key as GicsOrderByEnum) : GicsOrderByEnum.Id,
                    direction: sorter.order === 'descend' ? SortOrderEnum.Desc : SortOrderEnum.Asc,
                  })
                }
                pagination={false}
                bordered
                expandedRowRender={AllowedGicsTypeOneRenderer}
              />
              <PaginationContainer>
                <Pagination
                  total={totalPages}
                  defaultCurrent={currentPage}
                  onChange={pageNumber => {
                    setCurrentPage(pageNumber)
                    setQueryArgs(query => ({
                      ...query,
                      first: limitPerPage,
                      skip: (pageNumber - 1) * limitPerPage,
                    }))
                  }}
                  showSizeChanger
                  onShowSizeChange={(_, size) => setLimitPerPage(size)}
                />
              </PaginationContainer>
              <EditGIC
                onCancel={() => setShowEditPanel(false)}
                visible={showEditPanel}
                gic={selectedGIC}
                onSubmit={async updatingGic => {
                  handleGicUpdateMutation(updatingGic)
                  setShowEditPanel(false)
                  setSelectedGIC(undefined)
                }}
              />
              <CreateGIC
                onCancel={() => setShowCreatePanel(false)}
                visible={showCreatePanel}
                onSubmit={newGic => {
                  createGicMutation(
                    { input: newGic },
                    { refetchQueries: [{ query: GICS_QUERY, variables: queryArgs }] },
                    true,
                    newError => message.error(newError.message),
                  ).then(() => message.success('Created'))
                  setShowCreatePanel(false)
                }}
              />
            </div>
          )
        }}
      </GicsData>
    </PageWrapper>
  )
}

const handleGicDeleteMutation = (currentQueryArgs: GicsQueryArgs, id: number) =>
  deleteGicMutation(
    { id },
    {
      update: (cache, result) => {
        const { deleteGic } = result.data!
        const { gics } = cache.readQuery<{ gics: GicConnection }>({
          query: GICS_QUERY,
          variables: currentQueryArgs,
        })!
        const newEdges = gics.edges.filter(edge => edge.node.id !== deleteGic.id)
        cache.writeQuery({
          query: GICS_QUERY,
          variables: currentQueryArgs,
          data: {
            gics: {
              ...gics,
              edges: newEdges,
            },
          },
        })
      },
    },
  ).then(() => message.success('deleted'))

const handleGicUpdateMutation = (updatingGic: GicUpdateInput) =>
  updateGicMutation(
    { input: updatingGic },
    {
      update: (cache, result) => {
        const { allowedGicTypeOnesUpdated, gic } = result.data!.updateGic
        const { gicsTypeOne } = cache.readQuery<{ gicsTypeOne: GicTypeOne[] }>({
          query: GICS_TYPE_ONE_QUERY,
        })!

        const { allowedGicsTypeOne } = cache.readQuery<{
          allowedGicsTypeOne: GicTypeOne[]
        }>({
          query: ALLOWED_GIC_TYPE_ONE_QUERY,
          variables: { gicId: gic.id },
        })!
        const deletedTypeOnes = allowedGicTypeOnesUpdated
          .filter(p => p.updateType === ListItemUpdateType.Delete)
          .map(p => p.gicTypeOneId)
        const addedTypeOnes = allowedGicTypeOnesUpdated
          .filter(p => p.updateType === ListItemUpdateType.Add)
          .map(p => p.gicTypeOneId)
          .map(typeOneId => gicsTypeOne.find(p => p.id === typeOneId)!)
        const newOne = allowedGicsTypeOne
          .filter(p => !deletedTypeOnes.find(typeOneId => typeOneId === p.id))
          .concat(addedTypeOnes)
        cache.writeQuery({
          query: ALLOWED_GIC_TYPE_ONE_QUERY,
          variables: { gicId: gic.id },
          data: {
            allowedGicsTypeOne: newOne,
          },
        })
      },
    },
    true,
    error => message.error(error.message),
  )

const columnsGen: ColumnGenFunctionType<Gic> = (onEdit, onDelete) => [
  {
    title: 'ID',
    key: 'id',
    width: 80,
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: 'Name',
    key: 'name',
    width: 200,
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: 'Parts Count',
    key: 'partsCount',
    width: 60,
    dataIndex: 'partsCount',
  },
  {
    title: 'Size 1 Description',
    key: 'sizeOneDescription',
    width: 150,
    dataIndex: 'sizeOneDescription',
  },
  {
    title: 'Size 2 Description',
    key: 'sizeTwoDescription',
    width: 150,
    dataIndex: 'sizeTwoDescription',
  },
  {
    title: 'Size 3 Description',
    key: 'sizeThreeDescription',
    width: 150,
    dataIndex: 'sizeThreeDescription',
  },
  {
    title: 'Action',
    key: 'action',
    width: 150,
    render: (_text: any, record: Gic) => (
      <span>
        <Button type="link" onClick={() => onEdit(record)}>
          Edit
        </Button>
        <span> | </span>
        <Popconfirm
          title={`Are you sure you want to delete GIC ${record.name}?`}
          okText="Yes"
          cancelText="No"
          onConfirm={() => onDelete && onDelete(record)}>
          <RedA>Delete</RedA>
        </Popconfirm>
      </span>
    ),
  },
]
