import { CreateManufacturerInput, Manufacturer, UpdateManufacturerInput } from '@curvo/apollo'
import { Form, Input, Checkbox } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import { LabeledValue } from 'antd/lib/select'
import React, { useState } from 'react'
import { EditPanelWrapper, emptyOrReturn } from '../../../../../components/common'
import { EditDrawer } from '../../../../../components/EditDrawer'
import { MultipleManufacturerSelect } from '../Select/ManufacturerSelect'

export enum EditMode {
  create,
  edit,
}

export const EditManufacturerDrawer: React.FunctionComponent<
  {
    manufacturer: Manufacturer
    onSubmit: (editing: UpdateManufacturerInput | CreateManufacturerInput) => void
    onCancel: () => void
    editMode: EditMode
  } & DrawerProps
> = ({ editMode, manufacturer, onSubmit, onCancel, ...drawerProps }) => {
  const [editing, setEditing] = useState<UpdateManufacturerInput>({
    id: manufacturer.id,
  })
  const getFieldValue = (field: keyof UpdateManufacturerInput) => emptyOrReturn(editing[field], manufacturer[field])
  const onInputChange = (field: keyof UpdateManufacturerInput) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditing({
      ...editing,
      [field]: e.target.value,
    })
  }
  const manuIdValidationStatus = (getFieldValue('id') as string).length > 6 ? 'error' : ''
  return (
    <EditDrawer onCancel={onCancel} onSave={() => onSubmit(editing)} {...drawerProps}>
      <EditPanelWrapper>
        {editMode === EditMode.create && (
          <Form.Item label="ID" validateStatus={manuIdValidationStatus}>
            <Input value={getFieldValue('id')} onChange={onInputChange('id')} />
          </Form.Item>
        )}
        <Form.Item label="Name">
          <Input value={getFieldValue('name')} onChange={onInputChange('name')} />
        </Form.Item>
        <Form.Item label="City">
          <Input value={getFieldValue('city')} onChange={onInputChange('city')} />
        </Form.Item>
        <Form.Item label="Produces Reprocessed Items">
          <Checkbox
            checked={emptyOrReturn(editing.producesReprocessedItems, manufacturer.producesReprocessedItems)}
            onChange={e => setEditing({ ...editing, producesReprocessedItems: e.target.checked })}
          />
        </Form.Item>
        <Form.Item label="State">
          <Input value={getFieldValue('state')} onChange={onInputChange('state')} />
        </Form.Item>
        <Form.Item label="Country">
          <Input value={getFieldValue('country')} onChange={onInputChange('country')} />
        </Form.Item>
        <Form.Item label="Url">
          <Input value={getFieldValue('url')} onChange={onInputChange('url')} />
        </Form.Item>
        <Form.Item label="Type">
          <Input value={getFieldValue('type')} onChange={onInputChange('type')} />
        </Form.Item>
        {editMode === EditMode.edit && (
          <React.Fragment>
            <Form.Item label="Ticker">
              <Input value={getFieldValue('ticker')} onChange={onInputChange('ticker')} />
            </Form.Item>
            <Form.Item label="Name Short">
              <Input value={getFieldValue('shortName')} onChange={onInputChange('shortName')} />
            </Form.Item>
            <Form.Item label="Comments">
              <Input value={getFieldValue('comments')} onChange={onInputChange('comments')} />
            </Form.Item>
          </React.Fragment>
        )}
        <Form.Item label="Retry">
          <MultipleManufacturerSelect
            value={
              getFieldValue('retry')
                ? (getFieldValue('retry') as Pick<Manufacturer, 'id' | 'name'>[]).map(retry => ({
                    key: retry.id,
                    label: retry.name,
                  }))
                : undefined
            }
            allowClear
            onChange={v => {
              setEditing({
                ...editing,
                retry:
                  v &&
                  (v as LabeledValue[]).map(selected => ({
                    id: selected.key,
                    name:
                      (selected.label &&
                        selected.label
                          .toString()
                          .replace(/.+\s\|/, '')
                          .trim()) ||
                      '',
                  })),
              })
            }}
          />
        </Form.Item>
      </EditPanelWrapper>
    </EditDrawer>
  )
}
