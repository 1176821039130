import { Rate } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as StartSvg } from '../../../../../assets/images/star-svgrepo-com.svg'
import { AnimatedLoadingBar } from './AnimatedLoadingBar'

export const ScoreBoard: React.FC<{ level: number; score: number; percentage: number }> = ({
  level,
  score,
  percentage,
}) => {
  return (
    <ScoreBoardWrapper>
      <Rate
        allowHalf
        disabled
        character={<StartSvg width={14} height={14} />}
        count={level}
        style={{ marginLeft: 8, width: 160 }}
      />
      <AnimatedLoadingBar progress={percentage} />
      <ScoreDiv>
        <span>Score: {(score || 0).toFixed()}</span>
      </ScoreDiv>
    </ScoreBoardWrapper>
  )
}

const ScoreBoardWrapper = styled.div`
  display: flex;
  background-image: url('/up.png');
  margin-left: 12px;
  width: 520px;
  height: 40px;
  padding: 8px;
  justify-content: space-between;
  background-size: 100% 100%;
`

const ScoreDiv = styled.div`
  font-family: CandyTime;
  color: white;
  font-size: 20px;
  width: 180px;
  padding-left: 20px;
  color: white;
`
